import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {Quotation, QuotationLine} from '../../../../../models/quotation.model';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {currency} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {OrderSubjectService} from '../../../../../shared/services/order-subject.service';
import {Order, OrderItem} from '../../../../../models/order.model';
import {QuotationType} from '../../../../../models/enums/quotationType.enum';
import {OrderStatus} from '../../../../../models/enums/orderStatus.enum';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {OmsService} from '../../../../../shared/services/oms.service';
import {AppState} from '../../../../../store/app.state';
import {getCurrencySymbol} from '@angular/common';
import {SparePartService} from '../../../../../shared/services/spare-part.service';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-spare-part-retrieval',
    templateUrl: './waiting-spare-part-retrieval.component.html',
    styleUrls: ['./waiting-spare-part-retrieval.component.scss']
})
export class WaitingSparePartRetrievalComponent extends Unsubscriber implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    sparePartRetrieve: boolean;
    @Input() variablesTask: any;
    currency: string;

    quotationId: string;
    quotation: Quotation;

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    remainingAmount: String;
    description$: Observable<string>;
    order = new Order();
    orderedSpareParts: QuotationLine[] = [];

    constructor(private orderSubjectService: OrderSubjectService,
                private omsService: OmsService,
                private translateService: TranslateService,
                private sparePartService: SparePartService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.initOrder();

        if (this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG) {
            this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
             this.quotation = this.getLastQuotation();
            this.getRemainingAmount();
        }
    }

    initOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.buildDataLines();
            this.sparePartRetrieve = this.isSparePartRetrieved();
        });
    }

    buildDataLines() {
        this.orderedSpareParts = [];
        this.order.orderItems.forEach(orderItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.orderItemId = orderItem.id;
            dataSourceLine.code = orderItem.code;
            dataSourceLine.label = orderItem.label;
            dataSourceLine.quantity = orderItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: orderItem.price,
                currency: orderItem.currency
            };
            dataSourceLine.stock = orderItem.stock;
            dataSourceLine.type = QuotationType.SPARE_PART;
            dataSourceLine['supplierName'] = orderItem.supplier?.name;
            dataSourceLine.status = orderItem.itemStatus;
            dataSourceLine.totalLinePrice = {
                value: orderItem.rowTotal,
                currency: orderItem.currency
            };
            dataSourceLine.vat = {
                rate: orderItem.taxPercent?.toString()
            };
            dataSourceLine.discount = orderItem.discountPercent;
            this.orderedSpareParts.push(dataSourceLine);
        });
    }

    private getRemainingAmount() {
        if (this.quotation?.totalPrice?.value !== undefined &&
            this.quotation?.totalPrice?.value !== null &&
            this.quotation?.downPayment?.value !== undefined &&
            this.quotation?.downPayment?.value !== null &&
            this.quotation.totalPrice.value !== this.quotation.downPayment.value) {

            this.remainingAmount = this.sparePartService
                .formatPriceAccordingToCurrency(
                    this.sparePartService.normalizeFloat(this.quotation.totalPrice.value - this.quotation.downPayment.value), this.currency);
            this.buildMessage();
        }

    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    private buildMessage(): void {
        if (!!this.remainingAmount) {
            this.description$ = this.translateService.stream('COMPONENT.REMAINING_AMOUNT.DESCRIPTION',
                {
                    price: this.remainingAmount,
                    currency: getCurrencySymbol(this.currency || this.currency, 'narrow')
                });
        }
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    updateOrderStatus() {
        if (!!this.order?.id) {
            this.store$.dispatch(new StartLoading());
            const orderUpdateRequest = this.prepareOrder();
            this.omsService.updateOrder(this.order.id, orderUpdateRequest).subscribe((order) => {
                this.order = order;
                this.orderSubjectService.orderLoaded(this.order);
                this.store$.dispatch(new StopLoading());
            }, () => this.store$.dispatch(new StopLoading()));

        }
    }

    private prepareOrder() {
        return {
            orderItemsStatuses: this.toItems()
        };
    }

    private toItems(): OrderItem[] {
        return this.orderedSpareParts
            .map((sparePart) => {
                return {
                    id: sparePart.orderItemId,
                    itemStatus: this.computeStatus(),
                };
            });
    }

    private computeStatus() {
        return this.sparePartRetrieve ? OrderStatus.DELIVERED_TO_CLIENT : OrderStatus.DELIVERED;
    }

    private isSparePartRetrieved() {
        return this.order?.orderStatus === OrderStatus.DELIVERED_TO_CLIENT;
    }
}
