export class PushNotification {
    id: string;
    //TODO enum
    type: string;
    organizationCode: string;
    applicationName: string;
    businessId: string;
    //TODO enum
    status: string;
    previousStatus: string;
    extraInfo: Map<string, string>;
    creationDate: string;
}

export interface PushNotificationUpdateRequest {
    status: string;
}


