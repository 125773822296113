    <mat-card-content>
        <form fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="12px">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
                        <label class="h3 text-bold">
                            {{'FOLDER.REPAIR_IN_PROGRESS.LIST_SPARE_PARTS' | translate}}
                        </label>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end end">
                        <div (click)="openQuotationModal()" [ngClass]="'cursor-pointer pr-10'" fxLayout="row"
                             fxLayoutGap="5px"
                             fxLayoutAlign="end center">
                            <ng-container
                                *ngIf="dataSource.data.length>0;else SHOW_ADD_BUTTON">
                                <mat-icon class="mr-8">edit</mat-icon>
                                <span [ngClass]="'link-underline'">{{'BUTTON.EDIT' | translate}}</span>
                            </ng-container>
                            <ng-template #SHOW_ADD_BUTTON>
                                <mat-icon>add_circle_outline</mat-icon>
                                <span [ngClass]="'link-underline'">{{'BUTTON.ADD' | translate}}</span>
                            </ng-template>
                        </div>

                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <table mat-table [dataSource]="dataSource"
                           class="mat-elevation-z4 w-100-p mt-16 mb-16">
                        <ng-container matColumnDef="code">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}  </th>
                            <td mat-cell *matCellDef="let sparePart">
                                <span class="translate">{{sparePart.code}}</span>
                                 </td>
                        </ng-container>
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header"> <span  class="notranslate">{{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}} </span>  </th>
                            <td mat-cell
                                *matCellDef="let sparePart"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="label" >
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}  </th>
                            <td mat-cell *matCellDef="let sparePart">
                                <span class="translate">{{sparePart.label}} </span>
                                </td>
                        </ng-container>
                        <ng-container matColumnDef="payer">
                            <th mat-header-cell *matHeaderCellDef
                                class="table-header">  {{'MODAL.QUOTATION.PAYER' | translate}}  </th>
                            <td mat-cell
                                *matCellDef="let sparePart">{{!!sparePart.payer ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + sparePart.payer | translate) : "-"}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
                    </table>

                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                <div fxLayout="row" fxLayoutAlign="end end">
                    <mat-form-field class="py-4" appearance="outline" fxFlex="100" id="folderComment">
                        <mat-label>{{'FOLDER.COMMENT' | translate}}</mat-label>
                        <textarea matInput rows="4" cols="7" name="comment" inputControl [(ngModel)]="comment"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayoutAlign="end center" class="mt-24">
                <ng-container *ngIf="totalPrice < 0">
                    <p class="error-text-color">{{'FOLDER.REPAIR_IN_PROGRESS.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>

                <button type="submit" mat-stroked-button
                        id="submitButton"
                        [disabled]="disabledButtonForm()"
                        [ngClass]="disabledButtonForm() ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>