import {Pipe} from '@angular/core';
import {CurrencyPipe} from "@angular/common";
import {Amount} from "../../app/models/amount.model";

export const SPACE = ' ';

@Pipe({name: 'formatAmount'})
export class FormatAmountPipe {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(price: Amount): string {
        const formattedPrice = this.currencyPipe.transform(price.value, price.currency, 'symbol-narrow');
        return this.insertSpace(formattedPrice);
    }

    insertSpace(formattedPrice): string {
        if (!formattedPrice) {
            return '';
        }
        const firstDigitIndex = formattedPrice.search(/\d/);
        return formattedPrice.substr(0, firstDigitIndex) + SPACE + formattedPrice.substr(firstDigitIndex);
    }

}
