import {Component, Inject, OnInit} from '@angular/core';
import {CountriesUtils} from '../../../../../shared/data/countries-utils';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {currentUser} from '../../../../../store/user/user.selectors';
import {SupplierDocument} from '../../../../../models/SupplierDocument';
import {ConfigV2Service} from '../../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../../models/element.model';
import {ConfigCodesEnum} from '../../../../../models/codeLabelObject.model';
import {currentLanguage} from '../../../../../store/organization/organization.selectors';

@Component({
    selector: 'detail-supplier-dialog',
    templateUrl: './detail-supplier-dialog.component.html',
    styleUrls: ['./detail-supplier-dialog.component.scss']
})
export class DetailSupplierDialogComponent extends Unsubscriber implements OnInit {

    supplier: SupplierDocument;
    supplierContactsTypes: CodeLabel[] = [];
    supplierContactsTypesOfSupplier = new Array<any>();
    supplierContacts = new Array<any>();
    configCode = ConfigCodesEnum.SUPPLIER_CONTACTS;
    currentLanguage: string;
    roles = [];
    constructor(private dialogRef: MatDialogRef<DetailSupplierDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private configV2Service: ConfigV2Service,
                private store$: Store<AppState>) {
        super();
    }


    ngOnInit(): void {
        if (!!this.data) {
            this.anotherSubscription = this.store$.pipe(select(currentLanguage)).subscribe(it => this.currentLanguage = it);
            this.supplier = this.data.supplier;
            this.getSupplierContacts();
            this.getSupplierContactsType();
        }
    }
    onClose(): void {
        this.dialogRef.close();
    }

    isValidCountryCode(countryCode: string): boolean {
        return CountriesUtils.isValidCountryCode(countryCode);
    }

    getSupplierContactsByRole(role: string): any[] {
        return this.supplierContacts.filter(value => value.role === role);
    }

    getSupplierContactsType(): void {
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.SUPPLIER_CONTACTS]).subscribe(
            response => {
                this.supplierContactsTypes = Object.assign([], response);
                this.getUnlocalizedSupplierContactsTypes();
            }
        );
    }

    getSupplierContacts(): void {
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(() => {
            this.supplierContacts = Object.assign([], this.supplier.supplierContacts);
            this.roles = this.supplier.supplierContacts.map(it => it.role);
        });
    }

    buildSupplierContactsTypesOfSupplier() {
        this.supplierContactsTypesOfSupplier = this.supplierContactsTypes.filter(value => this.roles.includes(value.code));
    }
    getUnlocalizedSupplierContactsTypes(){
        const localizedContactTypesCodes = this.supplierContactsTypes.map(item => item.code);
        this.configV2Service.findAllValues(this.configCode)
            .subscribe((supplierContactsConfig: any) => {
                if (!supplierContactsConfig) { return; }

                const filteredContactTypes: Set<string> = new Set(supplierContactsConfig
                    .filter(config => !localizedContactTypesCodes.includes(config.code) && config.locale !== this.currentLanguage)
                    .map(contact => contact.code));

                filteredContactTypes.forEach(code => {  this.supplierContactsTypes.push({code}); });
                this.buildSupplierContactsTypesOfSupplier();
            });
    }
}
