import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Site} from '../../../../../models/site.model';
import {SharedService} from '../../../../../shared/services/shared.service';
import {Observable} from 'rxjs';
import {Address} from '../../../../../models/customer/customer.model';
import {DeliveryModeEnum} from '../../../../../models/enums/deliveryMode.enum';
import {ReceptionModeEnum} from '../../../../../models/enums/receptionMode.enum';
import {OperationModeEnum} from '../../../../../models/enums/operationMode.enum';
import {AddressType} from '../../../../../models/enums/addressType.enum';
import {TaskVariables} from '../../task.variables';
import {SiteService} from '../../../../../shared/services/site.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {CustomerUtils} from '../../../../../shared/utils/customer-utils';
import {accessSites, affectedSite} from '../../../../../store/user/user.selectors';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {SnackBarService} from '../../../../../shared/services/snack-bar.service';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {ConfigV2Service} from '../../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../../models/element.model';

@Component({
    selector: 'app-choose-product-shipment-mode',
    templateUrl: './choose-product-shipment-mode.component.html',
    styleUrls: ['./choose-product-shipment-mode.component.scss'],
    providers: [SharedService]
})
export class ChooseProductShipmentModeComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    folder: Folder;
    sitesOfCurrentUser$: Observable<string[]>;
    sites: CodeLabel[];

    siteSelected: Site;
    today = new Date();
    chooseProductShipmentModeForm: any = {
        form: null,
        placeholders: []
    };
    address: Address;
    defaultAddress: Address;
    validateFormAddress: boolean;
    siteCodeOfCurrentUser: string;
    productLocation: string;
    sentToSupplier: string;
    operationMode: string;

    constructor(private _sharedService: SharedService,
                private snackBar: SnackBarService,
                private siteService: SiteService,
                private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private configV2Service: ConfigV2Service) {
        super();
        this.getSubscriptionDate();
    }

    getSubscriptionDate(): void {
        this.anotherSubscription = this._sharedService.getUpdateAddressEvent().subscribe(address => {
            if (!!address) {
                this.address = address;
            }
        });
        this.anotherSubscription = this._sharedService.getValidateForm().subscribe(validate => {
            this.validateFormAddress = validate;
        });
    }

    ngOnInit(): void {
        this.sitesOfCurrentUser$ = this.store$.pipe(select(accessSites));
        this.anotherSubscription = this.store$.pipe(select(affectedSite)).subscribe(code => this.siteCodeOfCurrentUser = code);
        this.anotherSubscription = this.configV2Service.findAllValues(ConfigCodeMapsEnum.SITE).subscribe(
            response => {
                this.sites = response;
            }
        );
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.operationMode = folder.operationMode;
        });
        this.productLocation = this.variablesTask[TaskVariables.productLocation];
        this.initChooseProductShipmentMode();
    }

    private initChooseProductShipmentMode(): void {
        this.chooseProductShipmentModeForm.form = new FormGroup({
            mode: new FormControl(null, Validators.required),
            siteCode: new FormControl(null),
        });
        this.chooseProductShipmentModeForm.placeholders = [
            (this.getPlaceholders()),
            {
                mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.CUSTOMER_TO_STORE',
                value: DeliveryModeEnum.CUSTOMER_TO_STORE,
            }
        ];
        if (this.siteCodeOfCurrentUser !== 'ALL' && !this.operationMode.startsWith('SERVICE_CENTER_REPAIR')) {
            this.chooseProductShipmentModeForm.placeholders.push({
                mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.ALREADY_IN_STORE',
                value: DeliveryModeEnum.ALREADY_IN_STORE
            });
        }
    }

    getPlaceholders(): any {

        if (this.folder.operationMode !== OperationModeEnum.SWAP) {
            return {
                mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.CUSTOMER_TO_REPAIRER',
                value: DeliveryModeEnum.CUSTOMER_TO_REPAIRER
            };
        } else {
            switch (this.productLocation) {
                case 'CUSTOMER':
                case 'CLIENT':
                    if (this.variablesTask['sentToSupplier'] === 'true') {
                        return {
                            mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.CUSTOMER_TO_SUPPLIER',
                            value: DeliveryModeEnum.CUSTOMER_TO_SUPPLIER
                        };
                    }
                    return {
                        mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.CUSTOMER_TO_DISTRIBUTOR',
                        value: DeliveryModeEnum.CUSTOMER_TO_DISTRIBUTOR
                    };
                case 'REPAIR_CENTER':
                    return {
                        mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.REPAIRER_TO_SUPPLIER',
                        value: DeliveryModeEnum.REPAIRER_TO_SUPPLIER
                    };
                case 'STORE':
                    return {
                        mode: 'FOLDER.WORKFLOW.CHOOSE_PRODUCT_SHIPMENT_MODE.STORE_TO_SUPPLIER',
                        value: DeliveryModeEnum.STORE_TO_SUPPLIER
                    };
            }
        }
    }

    onSubmit(): void {
        let senderAndReceiverInformation;
        switch (this.chooseProductShipmentModeForm.form.value.mode) {
            case DeliveryModeEnum.CUSTOMER_TO_REPAIRER :
            case DeliveryModeEnum.CUSTOMER_TO_DISTRIBUTOR :
            case DeliveryModeEnum.CUSTOMER_TO_SUPPLIER : {
                senderAndReceiverInformation = {
                    firstName: this.folder.customer.firstName,
                    lastName: this.folder.customer.lastName,
                    email: this.folder.customer.contact.email,
                    phoneNumber: this.folder.customer.contact.mobileNumber,
                    address: this.address,
                    gender: this.folder.customer.gender
                };
                this.completeTask(senderAndReceiverInformation);
                break;
            }
            case DeliveryModeEnum.CUSTOMER_TO_STORE: {
                this.inputMap.emit({
                    'deliveryMode': this.chooseProductShipmentModeForm.form.value.mode
                });
                break;
            }
            case DeliveryModeEnum.ALREADY_IN_STORE: {
                this.senderAndReceiverInformation();
                break;
            }
        }

    }

    private senderAndReceiverInformation(): void {
        let senderAndReceiverInformation;
        this.store$.dispatch(new StartLoading());
        this.siteService.findNearestSiteByCode(this.chooseProductShipmentModeForm.form.value.siteCode).subscribe(site => {
            this.store$.dispatch(new StopLoading());
            if (!site) {
                this.snackBar.openWithIcon('ERRORS.TECHNICAL.DETAILED.SITE_NOT_FOUND', 'Error');
                return;
            }

            senderAndReceiverInformation = {
                firstName: site.name,
                lastName: '',
                email: !!site.contact ? site.contact.email : '',
                phoneNumber: !!site.contact ? site.contact.phoneNumber : '',
                address: site.address,
                gender: null
            };

            this.siteSelected = site;
            this.completeTask(senderAndReceiverInformation);
            return;
        }, error => {
            this.store$.dispatch(new StopLoading());
            this.snackBar.openWithIcon('ERRORS.TECHNICAL.DETAILED.SITE_NOT_FOUND', 'Error');
            return;
        });
    }

    private completeTask(senderAndReceiverInformation): void {
        let dataToComplete;
        if (this.folder.operationMode === OperationModeEnum.SWAP) {
            dataToComplete = {
                deliveryMode: this.chooseProductShipmentModeForm.form.value.mode,
                receptionMode: this.getReceptionModeFromDeliveryMode(this.chooseProductShipmentModeForm.form.value.mode),
                senderInformation: JSON.stringify(senderAndReceiverInformation),
                senderToSupplierInformation: this.getSenderToSupplierInformation(this.chooseProductShipmentModeForm.form.value.mode, senderAndReceiverInformation)
            };
        } else {
            dataToComplete = {
                deliveryMode: this.chooseProductShipmentModeForm.form.value.mode,
                receptionMode: this.getReceptionModeFromDeliveryMode(this.chooseProductShipmentModeForm.form.value.mode),
                senderInformation: JSON.stringify(senderAndReceiverInformation),
                receiverInformation: JSON.stringify(senderAndReceiverInformation)
            };
        }
        dataToComplete.managementSite = this.siteSelected ? this.buildSiteFromSelected(this.siteSelected) : null;
        this.inputMap.emit(dataToComplete);
    }

    private buildSiteFromSelected(siteSelected: Site): string {
        let site;
        site = {
            name: siteSelected.name,
            code: siteSelected.code,
            address: siteSelected.address
        };
        return JSON.stringify(site);
    }

    private getSenderToSupplierInformation(mode: DeliveryModeEnum, senderAndReceiverInformation: any): any {
        switch (mode) {
            case DeliveryModeEnum.ALREADY_IN_STORE:
                return JSON.stringify(senderAndReceiverInformation);
            default:
                return null;
        }
    }

    private getReceptionModeFromDeliveryMode(mode: DeliveryModeEnum): ReceptionModeEnum {
        switch (mode) {
            case DeliveryModeEnum.CUSTOMER_TO_REPAIRER:
                return ReceptionModeEnum.REPAIRER_TO_CUSTOMER;
            case DeliveryModeEnum.CUSTOMER_TO_STORE:
            case DeliveryModeEnum.ALREADY_IN_STORE:
                if (this.folder.operationMode === OperationModeEnum.SWAP) {
                    return ReceptionModeEnum.STORE_TO_SUPPLIER;
                } else {
                    return ReceptionModeEnum.REPAIRER_TO_STORE;
                }
            case DeliveryModeEnum.CUSTOMER_TO_DISTRIBUTOR:
                return ReceptionModeEnum.DISTRIBUTOR_TO_SUPPLIER;
        }
    }

    getConfigValueSite(siteCode): CodeLabel {
        const filteredSites = this.sites.filter(site => site.code === siteCode);
        if (filteredSites.length > 0) {
            return filteredSites[0];
        }
        return;
    }

    changeMode(): void {
        this.siteSelected = null;
        this.chooseProductShipmentModeForm.form.value.siteCode = null;
        if (this.chooseProductShipmentModeForm.form.value.mode === DeliveryModeEnum.CUSTOMER_TO_REPAIRER) {
            this.address = CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.INTERVENTION);
        }
        this.disabledForm();
    }

    disabledForm(): boolean {
        if (this.chooseProductShipmentModeForm.form.invalid) {
            return true;
        }
        if ((this.chooseProductShipmentModeForm.form.value.mode === DeliveryModeEnum.CUSTOMER_TO_REPAIRER
            || this.chooseProductShipmentModeForm.form.value.mode === DeliveryModeEnum.CUSTOMER_TO_DISTRIBUTOR) && !this.validateFormAddress) {
            return true;
        }
        return this.chooseProductShipmentModeForm.form.value.mode === DeliveryModeEnum.ALREADY_IN_STORE
            && !this.chooseProductShipmentModeForm.form.value.siteCode;
    }

    getCustomerDefaultAddress(): Address {
        if (!!this.folder.customer && !!this.folder.customer.addresses) {
            this.defaultAddress = CustomerUtils.getCustomerAddressByType(this.folder.customer.addresses, AddressType.DEFAULT);
        }
        return this.defaultAddress;
    }
}
