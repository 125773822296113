
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>

    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
        <app-quotation-content [quotationLines]="quotation?.quotationLines" class="mat-elevation-z2 w-100-p mt-16 mb-16" fxFlex="70"
                               fxFlex.lt-md="100"></app-quotation-content>
        <div  fxLayout="row" fxFlex="30">
            <mat-card class="mat-elevation-z2 mt-16 mb-16"  fxFlex.lt-md="100">
                <div  fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong>{{'QUOTATION.CONTENT.TOTAL_HT' | translate}} : </strong>
                        <div>{{ quotation?.totalPriceHTAfterDiscount?.value  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong> {{'QUOTATION.CONTENT.TOTAL_TTC' | translate}} : </strong>
                        <div>{{ quotation?.totalPrice?.value |currency: currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
    </div>

    <app-attachment-uploader  class="mb-24"
                         [folder]="folder"
                         [quotation]="quotation"
                          (attachmentsFolder)="getAttachments($event)"></app-attachment-uploader>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="8px">
        <mat-card class="mat-elevation-z2 mt-16 mb-16" >
            <div fxLayout="column" >
                <div fxLayoutAlign="space-between center">
                    <strong> {{'QUOTATION.FEES_REFUSED_QUOTATION_WITHOUT_RESTITUTION' | translate}} : </strong>
                    <div class="h3">{{quotation.feesRefusedQuotationWithoutRestitution?.value |currency:quotation.feesRefusedQuotationWithoutRestitution?.currency :'symbol-narrow' | space}}
                    </div>
                </div>
                <div fxLayoutAlign="space-between center">
                    <strong> {{'QUOTATION.FEES_REFUSED_QUOTATION_WITH_RESTITUTION' | translate}} : </strong>
                    <div class="h3">{{quotation.feesRefusedQuotationWithRestitution?.value |currency:quotation.feesRefusedQuotationWithRestitution?.currency :'symbol-narrow' | space}}
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <div>
        <form [formGroup]="quotationChoiceForm" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="8px"
              (ngSubmit)="completeTask()">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="status" class="ml-20" fxLayoutGap="10px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">
                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let choice of quotationChoices"
                                      [value]="choice.status">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ choice.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
                <ng-container *ngIf="isNegative()">
                    <p class="red-color">{{'QUOTATION.ACCEPTATION.NEGATIVE_QUOTATION' | translate}}</p>
                </ng-container>
                <button type="submit" mat-stroked-button
                        [ngClass]="stopPropagation()?'aster_btn_disabled':'aster_btn'"
                        [disabled]="stopPropagation()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </div>