import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, Payment} from '../../../../../models/folder.model';
import {TranslateService} from '@ngx-translate/core';
import {TaskVariables} from '../../task.variables';
import {WorkflowService} from '../../../../../shared/services/workflow.service';
import {getCurrencySymbol} from '@angular/common';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {Observable} from 'rxjs';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-payment.component',
    templateUrl: './waiting-payment.component.html',
    styleUrls: ['./waiting-payment.component.scss']
})
export class WaitingPaymentComponent implements OnInit {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() refreshWorkflowStatus = new EventEmitter();
    titleCustomerToPay$: Observable<string>;
    currency: string;
    private paymentId: string;
    private payment: Payment;

    constructor(private translateService: TranslateService,
                private store$: Store<AppState>,
                private workflowService: WorkflowService) {
    }

    ngOnInit(): void {
        this.initVariables();
        this.findPayment();
        this.getCurrency();
        this.buildMessage();
    }

    private findPayment() {
        this.payment = this.folder.payments.find(value => value.id === this.paymentId);
    }

    initVariables(): void {
        this.paymentId = this.variablesTask[TaskVariables.PAYMENT_ID];
    }

    private buildMessage(): void {
        this.titleCustomerToPay$ = this.translateService.stream('FOLDER.WAITING_PAYMENT.CUSTOMER_TO_PAY',
            {
                price: this.payment.amountToPay,
                currency: getCurrencySymbol(this.currency, 'narrow')
            });
    }

    private getCurrency(): void {
        this.currency = this.variablesTask[TaskVariables.currency];
    }

    switchPaymentMode(): void {
        this.store$.dispatch(new StartLoading());
        this.workflowService.switchPaymentMode(this.folder.id).subscribe(() => {
            this.store$.dispatch(new StopLoading());
            this.refreshWorkflowStatus.emit();
        }, () => {
            this.store$.dispatch(new StopLoading());

        });
    }
}
