<mat-card-content *ngIf="!!checkAndDiagForm">
    <form [formGroup]="checkAndDiagForm" fxLayout="column" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutAlign="start center">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <mat-radio-group formControlName="diagnosticDecision" class="ml-20" labelPosition="after" fxLayout="column"
                         fxLayoutAlign="start stretch" fxLayoutGap="10px">
            <ng-container *ngFor="let status of choices" >
                <mat-radio-button class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="!status.hidden"
                                  [id]="status.choice" [value]="status.choice">
                    <span>{{  status.value | translate}}</span>
                </mat-radio-button>
            </ng-container>
        </mat-radio-group>
    </form>
    <div fxLayoutAlign="end center">
        <button type="submit" mat-stroked-button
                class="py-8 px-24 font-weight-900 font-size-30"
                (click)="onSubmit()"
                id="submitButton"
                [ngClass]="checkAndDiagForm.invalid ? 'aster_btn_disabled' : 'aster_btn'"
                [disabled]="checkAndDiagForm.invalid">
            {{'BUTTON.VALIDATE' | translate}}
        </button>
    </div>
</mat-card-content>