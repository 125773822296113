import {RouterModule} from '@angular/router';
import {AppAuthGuard} from './shared/utils/app.authguard';
import {ModuleWithProviders} from '@angular/core';
import {NotificationsDetailsComponent} from './main/notifications/notifications-details/notifications-details.component';
import {ErrorComponent} from './error/error.component';
import {ApiMetricsComponent} from './layout/components/api-metrics/api-metrics.component';

import {FolderListComponent} from './main/folder/folder-list/folder-list.component';

export const RoutingModule: ModuleWithProviders<any> = RouterModule.forRoot([
        {
            path: 'public',
            pathMatch: 'full',
            redirectTo: '/repairer'
        }, {
            path: '',
            pathMatch: 'full',
            redirectTo: 'folder/list',
        },
        {
            path: 'folder/list',
            component: FolderListComponent,
            canActivate: [AppAuthGuard]
        },
        {
            path: 'notifications',
            component: NotificationsDetailsComponent,
            canActivate: [AppAuthGuard],
            data: {
                scope: 'notifications'
            }
        },
        {
            path: 'error',
            pathMatch: 'full',
            component: ErrorComponent,
        },
        {
            path: 'api-metrics',
            component: ApiMetricsComponent,
            canActivate: [AppAuthGuard],
        },
        {
            path: '**',
            redirectTo: ''
        },

    ]
);

