import {Product} from './product.model';
import {Amount} from './amount.model';
import {Supplier} from './supplier.model';

export class Item {
    id: string;
    label: string;
    imageUrl: string;
    code: string;
    sellingPrice: Amount;
    lineNumber: number;
    quantity: number;
    underWarranty: boolean;
    afterSalesEligible?: boolean;
    endOfWarrantyDate?: Date;
    manufacturerReference?: string;
    supplier: Supplier;

    constructor(product: Product) {
        this.label = product.label;
        this.code = product.code;
        this.imageUrl = product.imageUrl;
        this.quantity = 1;
        this.afterSalesEligible = true;
    }
}

export class Invoice {
    id: string;
    siteCode: string;
    customerCode?: string;
    creationDate: string;
}

export class InvoiceProducts {
    content?: Item[];
    totalElements?: number;
    totalPages?: number;
    first?: boolean;
    last?: boolean;
    size?: number;
    numberOfElements?: number;
}


export class InvoiceItem {
    invoiceNumber: string;
    purchaseDate: string;
    lineNumber: number;
    site: SiteItem;
    product: ProductItem;
}

export class SiteItem {
    siteCode: string;
    siteLabel: string;
}

export class ProductItem {
    productCode: string;
    productLabel: string;
    imageUrl: string;
    quantity: number;
    sellingPrice: Amount;
    family: FamilyItem;
}

export class FamilyItem {
    familyCode: string;
    familyLabel: string;
}

