import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {Observable} from 'rxjs';
import {Folder} from '../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {ConfigV2Service} from '../../../../../shared/services/config-v2.service';
import {take} from 'rxjs/operators';
import {CodeLabel} from '../../../../../models/element.model';

@Component({
    selector: 'app-add-return-info',
    templateUrl: './add-return-info.component.html',
    styleUrls: ['./add-return-info.component.css']
})
export class AddReturnInfoComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();

    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    carriers$: Observable<CodeLabel[]>;

    repairStatusForm: any = {
        form: null,
        placeholders: []
    };

    constructor(private configV2Service: ConfigV2Service) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initCarrierList();
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            carrier: new FormControl(null, Validators.required),
            trackingNumber: new FormControl(null, Validators.required),
        });
    }

    initCarrierList(): void {
        this.carriers$ = this.configV2Service.findAllValues(ConfigCodeMapsEnum.CARRIER).pipe(take(1));
    }

    onSubmit(): void {
        this.inputMap.emit({
            'carrierCode': this.repairStatusForm.form.value.carrier.code,
            'trackingNumber': this.repairStatusForm.form.value.trackingNumber
        });
    }

}
