import {NgModule} from '@angular/core';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {loaderReducer} from './loader/loader.reducer';
import {EffectsModule} from '@ngrx/effects';
import {localStorageSync} from 'ngrx-store-localstorage';
import {organizationReducer} from './organization/organization.reducer';
import {userReducer} from './user/user.reducer';
import {OrganizationEffects} from './organization/organization.effects';
import {UserEffects} from './user/user.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';
import {customerReducer} from './customer/customer.reducer';
import {CustomerEffects} from './customer/customer.effects';
import {dashboardReducer} from './dashboard/dashboard.reducer';
import {DashboardEffects} from './dashboard/dashboard.effects';

const reducers = {
    organization: organizationReducer,
    user: userReducer,
    loader: loaderReducer,
    customer: customerReducer,
    dashboard: dashboardReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [{user: ['organizationCode', 'context', 'businessLink']}, {organization: ['languageKey']}],
        rehydrate: true
    })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    imports: [
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([
            OrganizationEffects,
            UserEffects,
            CustomerEffects,
            DashboardEffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !environment.production
        }),
    ]
})
export class AppStoreModule {
}
