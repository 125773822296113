import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {RepairStatusType} from '../../../../models/enums/repairStatusType.enum';
import {FolderPublic} from '../../../../models/folder.public.model';
import {CommentAuthor} from '../../../../models/enums/commentAuthor.enum';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';
import {SingleUploadModalComponent} from '../../../folder/single-upload-modal/single-upload-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {PublicService} from '../../../../shared/services/public.service';
import {select, Store} from '@ngrx/store';
import {AttachmentType, AttachmentTypeSharedWithEnum} from '../../../../models/attachment-type.model';
import {AppState} from '../../../../store/app.state';
import {Unsubscriber} from '../../../../unsubscriber';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FolderService} from '../../../../shared/services/folder.service';
import {FileInfo} from '../../../../models/file-info.model';

@Component({
    selector: 'app-diagnosis-in-progress',
    templateUrl: './diagnosis-in-progress.component.html',
    styleUrls: ['./diagnosis-in-progress.component.scss']
})
export class DiagnosisInProgressComponent extends Unsubscriber  implements OnInit, OnDestroy {

    @Input() folder: FolderPublic;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();

    destroy$ = new Subject<void>();

    form: FormGroup;

    warrantyCode: string;
    breakDownButtons: any[];

    warrantyButtons: any[];
    productButtons: any[];
    warrantyDisclaimerOptions: any[];
    currentLanguage: string;
    allowedAttachmentTypes: AttachmentType[] = [];
    attachments: FileInfo[] = [];
    displayedColumns: string[] = ['file', 'date', 'attachmentType', 'action'];

    constructor(private matDialog: MatDialog,
                private store$: Store<AppState>,
                private folderService: FolderService,
                private imageCompress: NgxImageCompressService,
                private publicService: PublicService) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.currentLanguage = language;
                this.getAllowedListAttachmentTypes(AttachmentTypeSharedWithEnum.REPAIRER, this.folder.externalUid);
            });
        this.warrantyCode = this.folder.warranty.warranty;
        this.changeWarrantyValidator();
        this.changeDisclaimerReasonValidator();
    }

    private changeDisclaimerReasonValidator() {
        this.form.get('status').valueChanges.subscribe(status => {
            if (!!status) {
                const warrantyDisclaimerOption = this.form.get('warrantyDisclaimerOption');

                if (status === RepairStatusType.WARRANTY_DISCLAIMER) {
                    warrantyDisclaimerOption.setValidators(Validators.required);
                } else {
                    warrantyDisclaimerOption.clearValidators();
                    warrantyDisclaimerOption.setValue(null);
                }
                warrantyDisclaimerOption.updateValueAndValidity();
            }
        });
    }

    private changeWarrantyValidator() {
        this.form.get('breakDownStatus')
            .valueChanges
            .subscribe(breakDownStatus => {
                if (!!breakDownStatus) {
                    const productStatus = this.form.get('productStatus');
                    const status = this.form.get('status');

                    if (breakDownStatus === 'BREAKDOWN_CONFIRMED') {
                        productStatus.setValidators(Validators.required);
                        if (this.warrantyCode === 'SG') {
                            status.setValidators(Validators.required);
                        } else {
                            this.clearStatusValidator(status);
                        }
                    } else {
                        this.clearProductStatusValidator(productStatus);
                        this.clearStatusValidator(status);
                    }
                    status.updateValueAndValidity();
                    productStatus.updateValueAndValidity();
                }
            });
    }

    private clearProductStatusValidator(productStatus: AbstractControl) {
        productStatus.clearValidators();
        productStatus.setValue(null);
    }

    private clearStatusValidator(status: AbstractControl) {
        status.clearValidators();
        status.setValue(null);
    }

    private initForm(): void {
        this.form = new FormGroup({
            status: new FormControl(null),
            warrantyDisclaimerOption: new FormControl(null),
            breakDownStatus: new FormControl(null, Validators.required),
            productStatus: new FormControl(null),
            description: new FormControl(''),
            comment: new FormControl('')
        });
    }

    private initButtons(): void {
        this.breakDownButtons = [
            {
                status: 'BREAKDOWN_CONFIRMED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN_CONFIRMED',
            },
            {
                status: 'NO_BREAKDOWN',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NO_BREAKDOWN',
            }
        ];

        this.warrantyButtons = [
            {
                status: 'WARRANTY_ACCEPTED',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_ACCEPTED'
            },
            {
                status: 'WARRANTY_DISCLAIMER',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER'
            }
        ];

        this.productButtons = [
            {
                status: 'REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.REPAIRABLE'
            },
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.DIAGNOSIS_IN_PROGRESS.NOT_REPAIRABLE'
            }
        ];

        this.warrantyDisclaimerOptions = [
            'USAGE_PROBLEM',
            'NOT_COVERED_BY_WARRANTY'
        ];
    }

    onSubmit(): void {
        if (this.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.form.value.comment,
                type: this.toCommentType(),
                from: CommentAuthor.REPAIRER
            });
        }

        const attachmentsToAdd = this.getAttachmentsToAdd();
        if (attachmentsToAdd.length > 0) {
            this.updateAttachmentsAndEmitInputMap(attachmentsToAdd);
        } else {
            this.emitInputMap();
        }
    }
    updateAttachmentsAndEmitInputMap(attachmentsToAdd: any[]): void {
        this.publicService.updateAttachments(this.folder.externalUid, attachmentsToAdd)
            .subscribe(() => this.emitInputMap());
    }

    emitInputMap(): void {
        this.inputMap.emit({
            'breakDownStatus': this.form.value.breakDownStatus,
            'reparationStatus': this.form.value.status,
            'productStatus': this.form.value.productStatus,
            'warrantyDisclaimerOption': this.form.controls['warrantyDisclaimerOption'].value?.key,
        });
    }
    getAttachmentsToAdd(): FileInfo[] {
        return this.attachments.filter(item => this.folder.attachments.indexOf(item) < 0);
    }

    private toCommentType(): string  {
        if (this.form.value.productStatus === 'NOT_REPAIRABLE') {
            return CommentType.COMMENT_NOT_REPAIRABLE;
        } else if (this.form.value.productStatus === 'REPAIRABLE') {
            return CommentType.COMMENT_REPAIRABLE;
        } else if (this.form.value.status === 'WARRANTY_DISCLAIMER') {
            return CommentType.COMMENT_WARRANTY_DISCLAIMER;
        } else if (this.form.value.breakDownStatus === 'NO_BREAKDOWN') {
            return CommentType.COMMENT_NO_BREAKDOWN;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getAllowedListAttachmentTypes(sharedWith: string, uid: string) {
        this.publicService.getAllowedListAttachmentTypes(sharedWith, uid, this.currentLanguage).subscribe(
            value => {
                if (value){
                    this.allowedAttachmentTypes = value;
                }
            }
        );
    }
    onFileUpload() {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            disableClose: true,
            data: {
                attachmentTypes: this.allowedAttachmentTypes,
                folderId: this.folder.externalUid,
                organizationCode: this.folder.organizationCode,
                uploadAttachment: true
            }
        });

        dialogRef.afterClosed().subscribe(
            data => {
                if (!!data) {
                    this.attachments = [...this.attachments, data];
                }
            }
        );
    }

}
