import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FolderService} from '../../../shared/services/folder.service';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {FolderSubjectService} from '../folder-subject.service';
import {IFolderUpdateRequest} from '../../../models/folder.model';
import {Scopes} from '../../../models/scopes/scopes.model';
import {hasScope} from '../../../store/user/user.selectors';

@Component({
    selector: 'app-folder-external-references-dialog',
    templateUrl: './folder-external-references-dialog.component.html',
    styleUrls: ['./folder-external-references-dialog.component.scss']
})
export class FolderExternalReferencesDialogComponent {

    editIndex: number;
    updateReference = new Map<string, string>();
    isDisabled = true;
    tempValue = '';

    protected readonly Scopes = Scopes;
    public hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private folderService: FolderService,
                private folderSubjectService: FolderSubjectService,
                private store$: Store<AppState>) {
    }

    editReference(index: number, value: string) {
        this.editIndex = index;
        this.tempValue = value;
    }

    onValueChange(newValue: string): void {
        this.isDisabled = newValue === this.tempValue;
    }

    saveReference(key: string, value: string) {
        this.editIndex = null;
        this.store$.dispatch(new StartLoading());
        this.updateReference[key] = value;
        const folderUpdateRequest: IFolderUpdateRequest = {
            externalReferences: this.updateReference
        };
        this.folderService.updateFolder(this.data.id, folderUpdateRequest)
            .then(data => {
                this.folderSubjectService.folderLoaded(data);
                this.store$.dispatch(new StopLoading());
            }).catch(() => {
            this.store$.dispatch(new StopLoading());
        });
        this.isDisabled = true;
    }

    cancelEdit(): void {
        this.editIndex = null;
    }

}
