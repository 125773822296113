import {Component, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {FolderPublic} from '../../../models/folder.public.model';
import {PublicService} from '../../../shared/services/public.service';
import {GENERIC_REPAIRER_COMPONENTS_TASKS} from './generic-repairer-components-tasks';
import {GENERIC_SUPPLIER_COMPONENTS_TASKS} from './generic-supplier-components-tasks';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {InitializeLanguage} from '../../../store/organization/organization.actions';
import {GrowthbookService} from '../../../shared/services/growthbook.service';
import {AppFeatures} from '../../../shared/features/app-features';
import * as moment from 'moment';
import {mergeMap} from 'rxjs/operators';
import {IEvent} from '../../../models/events.model';
import {of} from 'rxjs';
import {Unsubscriber} from '../../../unsubscriber';
import {currentLanguage} from '../../../store/organization/organization.selectors';

@Component({
    selector: 'app-public-folder-workflow',
    templateUrl: './public-folder-workflow.component.html',
    styleUrls: ['./public-folder-workflow.component.scss'],
    animations: fuseAnimations
})
export class PublicFolderWorkflowComponent extends Unsubscriber implements OnInit {

    folder: FolderPublic;
    externalUid: string;
    genericComponent: Component;
    private genericComponents: any;
    inputDynamicData = {
        folder: null
    };
    outputDynamicData = {
        inputMap: mapInput => this.completeTaskWorkflow(mapInput),
        inputMapQuotation: mapInputQuotation => this.completeTaskQuotationRepairer(mapInputQuotation),
        commentGiven: comment => this.addCommentToFolder(comment)
    };
    isSupplier: boolean;
    providerReadOnly: string;
    private dateConfirm: Date;
    currentLanguage: string;
    private waitingReparationAgreementEvent: IEvent;
    private deploymentDate: any;
    constructor(private publicService: PublicService,
                private activatedRoute: ActivatedRoute,
                private store$: Store<AppState>,
                private growthbookService: GrowthbookService,
                private router: Router) {
        super();
    }

    private addCommentToFolder(comment: { value: string, type: string, author: string }): void {
        this.publicService.addRepairerComment(this.folder.externalUid, comment.value, comment.type).subscribe();
    }

    ngOnInit(): void {
        this.initLanguage();
        this.isSupplier = this.isSupplierWF();
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.currentLanguage = language;
                this.getFolderDetails();
            });
    }

    private initLanguage() {
        this.store$.dispatch(new InitializeLanguage());
    }

    getFolderDetails(): void {
        if (this.activatedRoute.snapshot.params.uid) {
            this.externalUid = this.activatedRoute.snapshot.params.uid;
            this.publicService.getFolderByUid(this.externalUid, this.isSupplier, this.currentLanguage)
                .subscribe(folder => {
                    if ((folder) && (folder.status in this.genericComponents)) {
                        this.folder = folder;
                        this.inputDynamicData.folder = this.folder;
                        if (this.atWaitingReparationAgreementUserTask(this.folder.status)) {
                            this.handleWaitingReparationAgreement();
                        } else {
                            this.genericComponent = this.genericComponents[this.folder.status];
                        }
                    } else {
                        this.providerReadOnly = this.isSupplier ? 'supplier' : 'repairer';
                        this.router.navigate([`public/${this.providerReadOnly}/read-only/${this.externalUid}`]);
                    }
                });
        }
    }

    completeTaskWorkflow(mapInput): void {
        mapInput['currentTaskFrontOffice'] = this.folder.status;
        this.store$.dispatch(new StartLoading());
        this.publicService.getCompleteTaskWorkflow(this.folder.externalUid, mapInput, this.isSupplier).then(value => {
            this.store$.dispatch(new StopLoading());
            this.getFolderDetails();
        });
    }

    /* Complete Task Workflow quotation repairer*/
    completeTaskQuotationRepairer(mapInputQuotation): void {
        const attachments = mapInputQuotation.attachments;
        const quotation = mapInputQuotation.quotation;
        const description = mapInputQuotation.description;
        this.store$.dispatch(new StartLoading());
        this.publicService.completeTaskQuotationRepairer(this.folder.externalUid, attachments, quotation, description).subscribe(value => {
            this.store$.dispatch(new StopLoading());
            this.getFolderDetails();
        });
    }

    isSupplierWF(): boolean {
        // @ts-ignore
        if (this.activatedRoute.url.value.map(val => val.path).includes('supplier')) {
            this.genericComponents = GENERIC_SUPPLIER_COMPONENTS_TASKS;
            return true;
        } else {
            this.genericComponents = GENERIC_REPAIRER_COMPONENTS_TASKS;
            return false;
        }
    }

    private atWaitingReparationAgreementUserTask(taskDefinitionKey: string): boolean {
        return taskDefinitionKey === 'WAITING_REPARATION_AGREEMENT';
    }

    //todo to be deleted after 60 days
    private handleWaitingReparationAgreement(): void {
        this.publicService.getEventWorkflow(this.externalUid, 'CONFIRM_CARRIER_AND_REPAIRER')
            .pipe(mergeMap((eventConfirmCarrierAndRepairer: IEvent) => {
                    if (!!eventConfirmCarrierAndRepairer?.userAction?.actionDate) {
                        this.dateConfirm = eventConfirmCarrierAndRepairer.userAction.actionDate;
                        return this.growthbookService.getFeatureValue(AppFeatures.NEW_VERSION_OF_AGREEMENT_REPARATION, {}, null);
                    } else {
                        return of(null);
                    }
                })
            ).subscribe(value => {
            if (!!value && value <= moment(this.dateConfirm).format('YYYY-MM-DD')) {
                this.genericComponent = this.genericComponents['WAITING_REPARATION_AGREEMENT'];
            } else {
                this.checkOnDateOfWaitingReparationAgreemtEvent();
            }
        });
    }

    private checkOnDateOfWaitingReparationAgreemtEvent() {
        this.publicService.getEventWorkflow(this.externalUid, 'WAITING_REPARATION_AGREEMENT')
            .pipe(mergeMap((event: IEvent) => {
                this.waitingReparationAgreementEvent = event;
                return this.growthbookService.getFeatureValue(AppFeatures.WAITING_REPARATION_AGREEMENT_CONFIGURATION, {}, null);
            })).subscribe(deploymentDate => {
            if (deploymentDate > !!this.waitingReparationAgreementEvent?.userAction?.actionDate) {
                this.genericComponent = this.genericComponents['OLD_WAITING_REPARATION_AGREEMENT'];
            } else {
                this.genericComponent = this.genericComponents['WAITING_REPARATION_AGREEMENT'];
            }
        });
    }
}



