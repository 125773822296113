import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {countryCode} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';
import {PhoneNumberPipe} from '../../../../../@fuse/pipes/phone-number.pipe';
import libphonenumber from 'google-libphonenumber';
import PhoneNumberFormat = libphonenumber.PhoneNumberFormat;


@Component({
    selector: 'app-search-phone-countries',
    templateUrl: './search-phone-countries.component.html',
    styleUrls: ['./search-phone-countries.component.scss']
})
export class SearchPhoneCountriesComponent extends Unsubscriber implements OnInit, OnChanges {

    @Input() value: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('phoneInput') phoneInput: ElementRef;

    numberFormControl: FormControl = new FormControl();
    countryCode = 'fr';
    intlTelInputInstance: any;

    constructor(private store$: Store<AppState>, private cdRef: ChangeDetectorRef, private phoneNumberPipe: PhoneNumberPipe) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.value.currentValue) {
            this.numberFormControl.reset();
        }
        this.cdRef.detectChanges();
    }

    ngOnInit(): void {
        this.numberFormControl.setValue(this.value);
    }

    hasError($event: boolean) {
        const hasError = !$event;
        const empty = this.numberFormControl.value === undefined || this.numberFormControl.value === null || this.numberFormControl.value === '';
        const invalid = hasError && !empty;

        if (empty) {
            this.valueChange.emit(null);
        } else if (invalid) {
            this.numberFormControl.setErrors({'invalid': true});
            this.valueChange.emit(null);
        } else {
            this.numberFormControl.setErrors(null);
        }
    }

    getNumber(number: string) {
        this.numberFormControl.setValue(number);
        this.value = number;
        this.valueChange.emit(number);
    }

    emitValue(){
       this.getNumber(this.value);
    }

    onCountryChange($event: any) {
        this.numberFormControl.reset();
        this.countryCode = $event.iso2;
    }

    telInputObject(obj) {
        this.anotherSubscription = this.store$.pipe(select(countryCode))
            .subscribe((countryCode: string) => {
                this.countryCode = countryCode.toLowerCase();
                obj.setCountry(this.countryCode);
            });
    }

    updatePhoneInput(event?: Event) {
        this.value = this.phoneNumberPipe.getFormattedMobilePhone(this.numberFormControl.value, this.countryCode, PhoneNumberFormat.E164).mobileNumber;
        this.numberFormControl.setValue(this.value);
    }
}