import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
    CountryConfigurationSetSuccessfully,
    OperationModeSetSuccessfully,
    OrganizationActionType,
    SetCountryConfiguration,
    SuccessLoadClaimSensibleInfo,
    UpdateLanguage
} from './organization.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {CountryService} from '../../shared/services/country.service';
import {EMPTY} from 'rxjs';
import {CampaignService} from '../../shared/services/campaign.service';
import {DateAdapter} from '@angular/material/core';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {browserLanguageOriginKey} from './organization.selectors';
import {TranslateService} from '@ngx-translate/core';
import {UserActionType} from '../user/user.actions';
import {ClaimSensibleInfoService} from '../../shared/services/store/claim-sensible-info.service';
import {UpdateDashboardLanguage} from '../dashboard/dashboard.actions';
import {setCulture} from '@syncfusion/ej2-base';

@Injectable()
export class OrganizationEffects {

    constructor(private actions$: Actions,
                private countryService: CountryService,
                private campaignService: CampaignService,
                private store$: Store<AppState>,
                private translateService: TranslateService,
                private claimSensibleService: ClaimSensibleInfoService,
                private dateAdapter: DateAdapter<any>) {
    }

    @Effect()
    initializeLanguage$ = this.actions$.pipe(
        ofType(OrganizationActionType.INITIALIZE_LANGUAGE),
        withLatestFrom(this.store$.select(browserLanguageOriginKey)),
        map(([, browserLanguage]) => new UpdateLanguage(browserLanguage))
    );

    @Effect()
    updateLanguage$ = this.actions$.pipe(
        ofType(OrganizationActionType.UPDATE_LANGUAGE),
        tap((action: UpdateLanguage) => this.dateAdapter.setLocale(action.language)),
        tap((action: UpdateLanguage) => this.translateService.use(action.language)),
        tap((action: UpdateLanguage) => setCulture(action.language)),
        switchMap((action: UpdateLanguage) => [
            new UpdateDashboardLanguage(action.language)
        ])
    );

    @Effect()
    setOperationMode$ = this.actions$.pipe(
        ofType(OrganizationActionType.SET_OPERATION_MODE, UserActionType.UPDATE_BUSINESS_LINKS, UserActionType.SWITCH_BUSINESS_LINK),
        switchMap(() => this.campaignService.getOperatingsModeByBusinessLink()
            .pipe(
                map(operationMode => new OperationModeSetSuccessfully(operationMode.body))
            )
        )
    );

    @Effect()
    setCountryConfiguration$ = this.actions$.pipe(
        ofType(OrganizationActionType.SET_COUNTRY_CONFIGURATION),
        switchMap((action: SetCountryConfiguration) => this.countryService.getByCountryCode(action.countryCode)
            .pipe(
                map(countryConfiguration => new CountryConfigurationSetSuccessfully(countryConfiguration)),
                catchError(() => {
                    console.error('COULD NOT LOAD COUNTRY CONFIGURATION');
                    return EMPTY;
                })
            ))
    );

    @Effect()
    loadClaimSensibleInfo$ = this.actions$.pipe(
        ofType(OrganizationActionType.LOAD_CLAIM_SENSIBLE_INFO),
        switchMap(() => this.claimSensibleService.loadClaimSensibleInfo()
            .pipe(
                map(claimSensibleAPIInfo => new SuccessLoadClaimSensibleInfo(claimSensibleAPIInfo))
            )
        )
    );
}
