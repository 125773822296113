export class AppFeatures {
    public static readonly USER_TASK_FEATURE_PREFIX = 'user_task';

    public static readonly USER_TASK_VALIDATE_SWAP_IN_STORE = 'user_task:validate_swap_in_store';
    public static readonly USER_TASK_WAITING_CREDIT_SUPPLIER = 'user_task:waiting_credit_supplier';
    public static readonly USER_TASK_WAITING_PICKUP = 'user_task:waiting_pickup';
    public static readonly UNKNOWN_PRODUCT_NOMENCLATURE = 'unknown_product_nomenclature';
    public static readonly SEARCH_CUSTOMER_INVOICES = 'search_customer_invoices';
    public static readonly UNKNOWN_PRODUCT_SEARCH = 'unknown_product_search';
    public static readonly SEARCH_CUSTOMER_INVOICES_YEARS_DROPDOWN_LIST = 'search_customer_invoices_years_dropdown_list';
    public static readonly ADD_UNREFERENCED_SPARE_PART_BUTTON = 'add_unreferenced_spare_part_button';
    public static readonly DOWN_PAYMENT_INPUTS = 'down_payment_inputs';
    public static readonly ORGANIZATION_LANGUAGES = 'organization_languages';
    public static readonly CLAIM_EXTERNAL_REFERENCES = 'claim_external_references';
    public static readonly USER_TASK_CONFIRM_CARRIER_AND_REPAIRER = 'user_task:confirm_carrier_and_repairer';
    public static readonly NEW_VERSION_OF_AGREEMENT_REPARATION = 'agreement_reparation';
    public static readonly NEW_VERSION_OF_PAYMENT = 'user_task_bo:payment';
    public static readonly SPARE_PARTS_DEPLOYMENT_DATE = 'spare_parts_deployment_date';
    public static readonly WAITING_REPARATION_AGREEMENT_CONFIGURATION = 'waiting_reparation_agreement_configuration';
    public static readonly FOLDER_CREATION_FIELDS_OPTIONS = 'folder_creation_fields_options';
    public static readonly SHOW_EXPLODED_VIEW_BUTTON = 'show_exploded_view_button';

}

export class NomenclatureFeatureData{
    code: string;
    required: boolean;
}
