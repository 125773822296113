import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProductState} from '../../../../models/product.model';
import {ShowImageModalComponent} from '../../../../main/image-modal/show-image-modal.component';
import * as moment from 'moment';
import {Moment} from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {AttachmentTypeEnum} from '../../../../models/enums/attachmentType.enum';
import {FileInfo} from '../../../../models/file-info.model';
import {of} from 'rxjs';
import {AppState} from '../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../unsubscriber';
import {organizationCode} from '../../../../store/user/user.selectors';
import {ConfigCodeMapsEnum} from '../../../services/configuration-item-enum';
import {SingleUploadModalComponent} from '../../../../main/folder/single-upload-modal/single-upload-modal.component';
import {ConfigV2Service} from '../../../services/config-v2.service';
import {CodeLabel} from '../../../../models/element.model';

@Component({
    selector: 'app-product-state',
    templateUrl: './product-state.component.html',
    styleUrls: ['./product-state.component.scss']
})
export class ProductStateComponent extends Unsubscriber implements OnInit, OnChanges {
    listProductState: CodeLabel[];
    fileName: string;
    @Input() productState: ProductState;
    @Output() productStateChange: EventEmitter<ProductState> = new EventEmitter<ProductState>();

    public attachmentsFiles = new Array<FileInfo>();
    organizationCode: string;
    selectedValue: string;

    constructor(private matDialog: MatDialog,
                private store$: Store<AppState>,
                private configV2Service: ConfigV2Service,
    ) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(organizationCode)).subscribe(organizationCode => {
            this.organizationCode = organizationCode;
        });
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.PRODUCT_STATE]).subscribe(
            response => {
                this.listProductState = response;
                this.selectedValue = this.listProductState[0]?.code;
                this.productState.state = this.selectedValue;
            }
        );
    }

    changeProductStatus(status): void {
        this.productState.state = status;
    }


    onUploadImage(): void {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {
                hideFileType: true,
                attachmentType: AttachmentTypeEnum.PRODUCT_STATUS_IMAGE,
                uploadAttachment: true,
                organizationCode: this.organizationCode
            }
        });
        dialogRef.afterClosed().subscribe(
            (data) => {
                if (data) {
                    this.attachmentsFiles.push(data);
                }
                this.productState.stateAttachments = this.attachmentsFiles;

            }
        );
    }
    showImageModal(srcImage): void {
        this.matDialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '50%',
            data: of([srcImage])
        });
    }

    removeFileFromAttachment(index: number): void {
        this.attachmentsFiles.splice(index, 1);
    }

    getDate(uploadDate: string): Moment {
        return moment(uploadDate);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.productState.state !== this.selectedValue) {
            this.productStateChange.emit(this.productState);

        }
        this.productState.state = this.selectedValue;
        this.productStateChange.emit(this.productState);


    }

}
