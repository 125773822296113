<mat-form-field appearance="outline">
    <input
        [formControl]="numberFormControl"
        (input)="updatePhoneInput($event)"
        matInput
        ng2TelInput
        [ng2TelInputOptions]="{initialCountry: countryCode, preferredCountries: [countryCode], utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'}"
        (hasError)="hasError($event)"
        (ng2TelOutput)="getNumber($event)"
        (intlTelInputObject)="telInputObject($event)"
        (countryChange)="onCountryChange($event)"
        (keydown.enter)="emitValue()"/>
    <mat-hint>* {{ 'COMPONENT.SEARCH_PHONE_COUNTRIES.NUMBER' | translate }}</mat-hint>
</mat-form-field>