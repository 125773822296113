import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';
import {FuseThemeOptionsModule} from '../../../@fuse/components';
import {MdePopoverModule} from '@material-extended/mde';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {CountUpModule} from 'ngx-countup';
import {DashboardRouting} from './dashboard.routing';
import {DashBoardDayComponent} from './itinerant/dash-board-day/dash-board-day.component';
import {TechnicianInterventionMapComponent} from './itinerant/dash-board-map/technician-intervention-map.component';
import {PlanningComponent} from './itinerant/planning/planning.component';
import {CalendarModule as AngularCalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {ItemClientInfosComponent} from './itinerant/dash-board-day/item-client-infos/item-client-infos.component';
import {ReOptimizeConfirmDialogComponent} from './itinerant/dash-board-day/reoptimize-confirm-dialog/reoptimize-confirm-dialog.component';
import {DashboardHomePageComponent} from './dashboard-v2/dashboard-home-page.component';
import {CreateDashboardComponent} from './dashboard-v2/crud/create-dashboard.component';
import {ConfigDashboardComponent} from './dashboard-v2/config/config-dashboard.component';
import {EditDashboardComponent} from './dashboard-v2/crud/edit-dashboard.component';
import {ViewDashboardComponent} from './dashboard-v2/crud/view-dashboard.component';
import {GridModule} from '@syncfusion/ej2-angular-grids';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {DropDownListModule, MultiSelectModule} from '@syncfusion/ej2-angular-dropdowns';
import {TextBoxModule} from '@syncfusion/ej2-angular-inputs';
import {AddEditDashboardDialogComponent} from './dashboard-v2/add-edit-dashboard-dialog/add-edit-dashboard-dialog.component';


@NgModule({
    declarations: [
        DashboardHomePageComponent,
        CreateDashboardComponent,
        EditDashboardComponent,
        ViewDashboardComponent,
        ConfigDashboardComponent,
        AddEditDashboardDialogComponent,
        DashBoardDayComponent,
        ItemClientInfosComponent,
        TechnicianInterventionMapComponent,
        PlanningComponent,
        ReOptimizeConfirmDialogComponent
    ],
    imports: [
        RouterModule.forChild(DashboardRouting),

        TranslateModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        FuseSharedModule,
        FuseThemeOptionsModule,
        CountUpModule,

        NgxChartsModule,
        ChartsModule,
        MdePopoverModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        GridModule,
        DialogModule,
        DropDownListModule,
        MultiSelectModule,
        TextBoxModule,
    ], exports: [
        CountUpModule,
        TranslateModule,
    ]
})
export class DashboardModule {
}
