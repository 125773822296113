import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {Folder} from '../../../../models/folder.model';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {switchMap} from 'rxjs/operators';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';
import {Unsubscriber} from '../../../../unsubscriber';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {FileInfo} from '../../../../models/file-info.model';
import {FilesUtils} from '../../../../shared/utils/files-utils';
import {FileService} from '../../../../shared/services/file.service';
import {InstructionUserTask} from '../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-user-task-header',
    templateUrl: './user-task-header.component.html',
    styleUrls: ['./user-task-header.component.scss']
})
export class UserTaskHeaderComponent extends Unsubscriber implements OnInit, OnChanges {

    @Input() userTaskCode: string;
    @Input() additionalTitle: string;
    @Input() info: string;
    @Input() claim: Folder;
    @Input() instructionUserTask: InstructionUserTask;

    userTaskTitle: string;
    subTitle: string;
    instructionText: string;
    attachments: FileInfo[];

    constructor(private store$: Store<AppState>,
                private backOfficeService: BackOfficeService,
                private fileService: FileService) {
        super();
    }

    ngOnInit(): void {
        this.findUserTask();
        this.findShipmentInstruction();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['instructionUserTask'] || changes['userTaskCode']) {
            this.findUserTask();
        }
    }

    private findUserTask() {
        if (this.instructionUserTask) {
            this.userTaskTitle = this.instructionUserTask.label;
            this.subTitle = this.instructionUserTask.secondaryTitle;
        }
    }

    findShipmentInstruction(): void {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.store$.pipe(select(currentLanguage)).pipe(
            switchMap((value) => {
                const ruleEvaluationContext: RuleEvaluationContext = RuleEvaluationContext.fromFolder(this.claim);
                ruleEvaluationContext.parcours = 'BO';
                ruleEvaluationContext.status = this.instructionUserTask.code;
                return this.backOfficeService.getInstructions(ruleEvaluationContext);
            })
        ).subscribe(instruction => {
                if (instruction) {
                    this.instructionText = instruction.textInstruction;
                    this.attachments = instruction.attachments;
                }
                this.store$.dispatch(new StopLoading());
            }, () =>
                this.store$.dispatch(new StopLoading())
        );
    }

    downloadAttachment(fileAttachment: FileInfo): void {
        this.fileService.getAttachmentFile(fileAttachment.id)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, fileAttachment.name, resFile.type);
                };
            });
    }

}
