<div *ngIf="dataSource.data.length > 0">

    <div fxLayout="column" [ngClass]="'mat-elevation-z4'">
        <table mat-table [dataSource]="dataSource" fxFill>
            <!-- Image Column -->
            <ng-container matColumnDef="image">
                <th class="text-center" mat-header-cell *matHeaderCellDef>{{'PRODUCT.TABLE.IMAGE'|translate}}</th>
                <td mat-cell *matCellDef="let element">

                    <app-product-image [image]="element?.imageUrl"
                                       [loaderCss]="'w-40 h-40'"
                                       [imageCss]="'w-60 h-60'"
                    ></app-product-image>

                </td>
            </ng-container>

            <!-- code Column -->
            <ng-container matColumnDef="code">
                <th class="text-center" mat-header-cell *matHeaderCellDef>{{'PRODUCT.TABLE.CODE'|translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Label Column -->
            <ng-container matColumnDef="label">
                <th class="text-center" mat-header-cell *matHeaderCellDef>{{'PRODUCT.TABLE.LABEL'|translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.label }} </td>
            </ng-container>

            <!-- Price Column -->
            <ng-container matColumnDef="sellingPrice">
                <th class="text-center" mat-header-cell
                    *matHeaderCellDef>{{'PRODUCT.TABLE.SELLING.PRICE'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.sellingPrice">
                        {{ element.sellingPrice.value | currency: element.sellingPrice.currency :'symbol-narrow' }}
                    </ng-container>
                </td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="quantity">
                <th class="text-center" mat-header-cell *matHeaderCellDef>{{'PRODUCT.TABLE.QUANTITY'|translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.quantity }} </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th class="text-center" mat-header-cell *matHeaderCellDef>{{'PRODUCT.TABLE.ACTION'|translate}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="!element.afterSalesEligible; else ELIGIBLE">
                        <span
                            class="product-text-color  ml-12 at-body-1">{{"PRODUCT.ITEM_CARD_NOT_SALES_ELIGIBLE"|translate}}</span>
                    </ng-container>
                    <ng-template #ELIGIBLE>
                        <ng-container *ngIf="element.quantity >0 else PRODUCT">
                            <mat-radio-button [value]="element"
                                              (change)="onProductSelect($event.value)"></mat-radio-button>
                        </ng-container>
                        <ng-template #PRODUCT>
                            <span class="text-color">{{"PRODUCT.ITEM_CARD_NOT_FOUND_LABEL"|translate}}</span>
                        </ng-template>
                    </ng-template>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <mat-paginator
            [length]="pageConfig.totalElements"
            (page)="changePage($event)"
            [pageIndex]="pageIndex"
            showFirstLastButtons
            [pageSizeOptions]="[5, 10, 20, 30]"
            [pageSize]="pageConfig.size" class="w-100-p"></mat-paginator>
    </div>

</div>







