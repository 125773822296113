    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <ng-container *ngIf="folder?.warrantyEndDate">
            <label class="h3"><span
                class="text-bold"> {{ 'FOLDER.WARRANTY_END_DATE' | translate }}
                :</span> {{ folder.warrantyEndDate  | moment:('GENERAL.DATE_FORMAT' | translate) }}
            </label>
        </ng-container>

        <ng-container *ngIf="isOutOfWarranty && remainingAmount">
            <app-request-payment [folder]="folder"
                                 [remainingAmount]="remainingAmount"
                                 [currency]="currency">
            </app-request-payment>
        </ng-container>

        <div fxLayout="column" fxLayoutGap="10px">
            <app-accessories-label-list *ngIf="accessoriesLabel.length>0" [accessoriesLabel]="accessoriesLabel"></app-accessories-label-list>
            <div class="mt-16">
                <mat-checkbox [(ngModel)]="retrievalDone">
                    {{'BUTTON.PRODUCT_RECOVERED' | translate}}
                </mat-checkbox>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!retrievalDone"
                    [ngClass]="!retrievalDone ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>

    </mat-card-content>
