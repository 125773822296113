import {GENERIC_COMPONENTS_TASKS} from '../../folder/folder-workflow/generic-components-tasks';

export const COLOR_SCHEME_DOMAIN: any[] = [
    '#2196F3',
    '#1DE5CB',
    '#FF8A80',
    '#8C9EFF',
    '#80D8FF',
    '#A7FFEB',
    '#CCFF90',
    '#FFFF8D',
    '#FF9E80',
    '#181551',
    '#AAAAAA'
];
export const FOLDER_INDEX: any[] = [
    {name: 'currentWorkFlowStatus', type: 'workflowStatusList'},
    {name: 'workflowStatusDate', type: 'Date'},
    {name: 'newWarranty', type: 'string'},
    {name: 'warrantyReason', type: 'warrantyReasonList'},
    {name: 'operationMode', type: 'operationModeList'},
    {name: 'site', type: 'string'},
    {name: 'origin', type: 'originList'},
    {name: 'creationDate', type: 'Date'},
    {name: 'repairCenterCode', type: 'string'},
    {name: 'family1Code', type: 'family1List'},
    {name: 'family2Code', type: 'family2List'},
    {name: 'family3Code', type: 'family3List'},
    {name: 'family4Code', type: 'family4List'},
    {name: 'brand', type: 'brandList'},
    {name: 'appointmentDate', type: 'Date'}];


export const STATUS_FOLDER: any[] = Object.keys(GENERIC_COMPONENTS_TASKS);
export const STATUS_CLOSED_FOLDER_AUTO_REPAIR_REASON: string[] = [
    'FOLDER_CLOSED_ALREADY_REPAIRED',
    'FOLDER_CLOSED_CUSTOMER_REPAIR_PRODUCT',
    'FOLDER_CLOSED_CALL_CENTER_ASSISTANCE',
    'FOLDER_CLOSED_CUSTOMER_NOT_CONCERNED_BY_CAMPAIGN',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_IN_STORE_PRODUCT_HAS_NO_DAMAGE',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_OTHERS',
];
export const STATUS_CLOSED_FOLDER_HOME_REPAIR_REASON: string[] = [
    'FOLDER_CLOSED_CUSTOMER_REJECT_INTERVENTION',
    'FOLDER_CLOSED_ALREADY_REPAIRED',
    'FOLDER_CLOSED_TECHNICIAN_INTERVENE',
    'FOLDER_CLOSED_CUSTOMER_NOT_AFFECTED_BY_PREVENTION',
    'FOLDER_CLOSED_CANCEL_INTERVENTION',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_REPARATION_FINISHED',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_OTHERS',
];
export const STATUS_CLOSED_FOLDER_SWAP_REASON: string[] = [
    'FOLDER_CLOSED_PRODUCT_REPAIRED_BY_CUSTOMER',
    'FOLDER_CLOSED_CUSTOMER_NOT_CONCERNED_BY_CAMPAIGN',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_END_EXCHANGE',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_OTHERS',
];

export const STATUS_CLOSED_FOLDER_SERVICE_CENTER_REPAIR_REASON: string[] = [
    'FOLDER_CLOSED_IN_STORE_PRODUCT_HAS_NO_DAMAGE',
    'FOLDER_CLOSED_CUSTOMER_REPAIR_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_NOT_CONCERNED_BY_CAMPAIGN',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_ALREADY_REPAIRED',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_REPARATION_FINISHED',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_OTHERS',
];

export const STATUS_CLOSED_FOLDER_STORE_DEPOSIT_REASON: string[] = [
    'FOLDER_CLOSED_DEPOSED_FINISHED_WITHOUT_STORE_SELECTION',
    'FOLDER_CLOSED_DEPOSED_FINISHED_WITH_STORE_SELECTION',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_CUSTOMER_NOT_CONCERNED_BY_CAMPAIGN',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_OTHERS'
];

export const STATUS_CLOSED_FOLDER_REFUND_REASON: string[] = [
    'FOLDER_CLOSED_ALREADY_REPAIRED',
    'FOLDER_CLOSED_CUSTOMER_REPAIR_PRODUCT',
    'FOLDER_CLOSED_CALL_CENTER_ASSISTANCE',
    'FOLDER_CLOSED_CUSTOMER_NOT_CONCERNED_BY_CAMPAIGN',
    'FOLDER_CLOSED_DUPLICATES',
    'FOLDER_CLOSED_PRODUCT_ALREADY_CHANGED',
    'FOLDER_CLOSED_IN_STORE_PRODUCT_HAS_NO_DAMAGE',
    'FOLDER_CLOSED_CLIENT_REFUND',
    'FOLDER_CLOSED_ONLINE_REFUND',
    'FOLDER_CLOSED_CUSTOMER_INFORMED_BY_POSTAL_LETTER',
    'FOLDER_CLOSED_CUSTOMER_KEEP_HIS_PRODUCT',
    'FOLDER_CLOSED_CUSTOMER_DOES_NOT_RESPOND_TO_REMINDERS',
    'FOLDER_CLOSED_OTHERS',
];
