import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Observable} from 'rxjs';
import {PushNotificationSearchResponse} from '../../models/pushNotification/pushNotification-search-response.model';
import {PushNotification} from '../../models/pushNotification/pushNotification.model';
import {Alert} from '../../models/Alert.model';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    private path = `${GLOBAL.gatewayEndpoint}/push-notifications/api`;
    constructor(
        public httpClient: HttpClient) {
    }

    getAllPushNotifications(page: number, size: number, pushNotificationFilterRequest?: any): Observable<PushNotificationSearchResponse> {
        let params = new HttpParams({fromObject: pushNotificationFilterRequest});
        params = params.append('page', page + '');
        params = params.append('size', size + '');
        return this.httpClient.get<PushNotificationSearchResponse>(`${this.path}/pushNotification/search`, {params: params});
    }

    updateNotificationsStatus(notificationIds: any[], status: string): Observable<PushNotification[]> {
        const pushNotificationUpdateRequest = {
            notificationIds: notificationIds,
            status: status
        };
        return this.httpClient.put<PushNotification[]>(`${this.path}/pushNotification/update-status`, pushNotificationUpdateRequest);
    }

    countPushNotifications(pushNotificationFilterRequest: any): Observable<number> {
        return this.httpClient.get<number>(`${this.path}/pushNotification/count`, {params: pushNotificationFilterRequest});
    }

    countAlerts(filters: Map<string, any>): Observable<number> {
        const requestParam = this.buildRequestParamFromInputs(filters);
        return this.httpClient.get<number>(`${this.path}/v1/alert/count?${requestParam}`);
    }

    getAlertByBusinessId(businessId: string): Observable<Alert[]> {
        return this.httpClient.get<Alert[]>(`${this.path}/v1/alert/${businessId}`);
    }

    private buildRequestParamFromInputs(inputs: Map<string, string>): any {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });
        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

}
