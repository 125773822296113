import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {Comment} from '../../../../models/comment.model';
import {KeyValue} from '@angular/common';
import {SurveyElement} from '../../../../models/element.model';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currentLanguage} from '../../../../store/organization/organization.selectors';


@Component({
  selector: 'app-folder-comments-card',
  templateUrl: './folder-comments-card.component.html',
  styleUrls: ['./folder-comments-card.component.scss']
})
export class FolderCommentsCardComponent implements OnInit, OnChanges {

    @Input() comments: Map<CommentType, Comment[]> = new Map();

    currentLanguage$: Observable<string>;
    commentsList: Comment[];

    constructor(private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
    }
    ngOnChanges() {
        this.commentsList =   Array.from(this.comments.values()).reduce(
            (acc, comments) => acc.concat(comments),
            []
        );
        this.commentsList.sort((firstComment, secondComment) => {
            const oldDate = new Date(firstComment.createdBy.actionDate);
            const recentDate = new Date(secondComment.createdBy.actionDate);
            return recentDate.getTime() - oldDate.getTime();
        });
    }

}
