import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CodeToLabelService} from '../services/code-to-label.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {currentLanguage} from '../../app/store/organization/organization.selectors';
import {skipWhile, switchMap} from 'rxjs/operators';

@Pipe({name: 'codeToLabel'})
export class CodeToLabelPipe implements PipeTransform {

    constructor(private codeToLabelService: CodeToLabelService,
                private store$: Store<AppState>) {
    }


    transform(code: string, codeConfig: string): Observable<string> {
        return this.store$.pipe(
            select(currentLanguage),
            skipWhile(language => !language),
            switchMap(() => {
                if (code === null || code === undefined) {
                    return of('');
                }
                if (code === 'CONSTANT' && codeConfig === 'SAV.CONFIG.CONDITIONS') {
                    return of('COMPONENT.CONSTANT.CONDITION');
                }
                return this.codeToLabelService.getLabelOfCode(codeConfig, code);
            })
        );

    }
}