import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {TaskVariables} from '../../../task.variables';
import {ShippingEventType} from '../../../../../../models/enums/shipmentEventType.enum';
import {FileService} from '../../../../../../shared/services/file.service';
import {TranslateService} from '@ngx-translate/core';
import {ShipmentType} from '../../../../../../models/enums/shipmentType.enum';
import {FilesUtils} from '../../../../../../shared/utils/files-utils';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {FileInfo} from '../../../../../../models/file-info.model';
import {ReportUtils} from '../../../../../../shared/utils/report-utils';
import {ProductAccessory} from '../../../../../../models/ProductAccessory';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {ShipmentItem} from '../../../../../../models/shipmentItem.model';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {ShipmentItemType} from '../../../../../../models/enums/shipmentItemType.enum';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {AttachmentTypeEnum} from '../../../../../../models/enums/attachmentType.enum';

@Component({
    selector: 'app-parcel-to-send',
    templateUrl: './parcel-to-send.component.html',
    styleUrls: ['./parcel-to-send.component.scss']
})
export class ParcelToSendComponent extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Input() workflowStatus: any;
    @Input() processName: any;
    @Output() inputMap = new EventEmitter<any>();

    trackingNumber: string;
    shipmentMode: ShipmentType;
    shipmentInProgress = false;
    carrierCode: string;
    reservationHasAnomalie: boolean;
    file: FileInfo;
    selectedProductAccessories: ProductAccessory[] = [];
    showProductAccessories: boolean;
    private shipmentItems: ShipmentItem[];

    constructor(
        private folderSubjectService: FolderSubjectService,
        private fileService: FileService,
        private store$: Store<AppState>,
        private folderService: FolderService,
        private translateService: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.initializeVariablesTask();
        this.file = ReportUtils.findReportByCode(this.folder.attachments, 'SUPPORT_FOR_REPAIRER');
        const shipmentType = this.folder.shipments.filter(shipment => shipment.type.endsWith('TO_REPAIRER'));
        if (shipmentType?.length > 0){
            this.showProductAccessories = true;
        }

    }

    initializeVariablesTask(): void {
        if (!!this.variablesTask[TaskVariables.trackingNumber]) {
            this.trackingNumber = this.variablesTask[TaskVariables.trackingNumber];
        }
        if (!!this.variablesTask[TaskVariables.shipmentMode]) {
            this.shipmentMode = ShipmentType[this.variablesTask[TaskVariables.shipmentMode]];
        }
        if (!!this.variablesTask[TaskVariables.carrierCode]) {
            this.carrierCode = this.variablesTask[TaskVariables.carrierCode];
        }
        this.reservationHasAnomalie = !!this.variablesTask['reservationHasAnomalie'];
    }
    getShipmentAttachment(): void {
        this.store$.dispatch(new StartLoading());
        const attachment = this.getShipmentDocument();
        const shipmentDocumentName = this.translateService.instant('COMPONENT.PARCEL_TO_SEND.SHIPPING_LABEL');
        this.fileService.getAttachmentFile(attachment.id).subscribe(downloadedFile => {
                this.downloadFile(downloadedFile, shipmentDocumentName);
                this.store$.dispatch(new StopLoading());
            },
            () => {
                console.error('ERROR DURING DOWNLOADING FILE');
                this.store$.dispatch(new StopLoading());
            });
    }
    shouldDisplayDownloadButton(): boolean {
        return !!this.getShipmentDocument();
    }

    getShipmentDocument() {
        return this.folder?.attachments?.find(value =>
            value.attachmentType === AttachmentTypeEnum.SHIPMENT_DOCUMENT ||
            this.deprecatedShipmentAttachmentTypes(value)
        );
    }

    // TODO After 3 months, delete 2 attachment types shipment: ATTACHMENTS_STORE_TO_REPAIRER and ATTACHMENTS_CUSTOMER_TO_REPAIRER
    private deprecatedShipmentAttachmentTypes(value: FileInfo) {
        return value.attachmentType === AttachmentTypeEnum.ATTACHMENTS_STORE_TO_REPAIRER ||
            value.attachmentType === AttachmentTypeEnum.ATTACHMENTS_CUSTOMER_TO_REPAIRER;
    }

    downloadFile(resFile: any, fileName: string): void {
        const reader = new FileReader();
        reader.readAsDataURL(resFile);
        reader.onloadend = () => {
            FilesUtils.saveFile(reader.result, fileName, resFile.type);
        };
    }

    onSubmit(): void {
        if (!this.shipmentInProgress) {
            return;
        }
        this.store$.dispatch(new StartLoading());
        if (this.selectedProductAccessories.length > 0) {
            this.shipmentItems = this.selectedProductAccessories.map(value => this.toShipmentItem(value));
            const folderUpdateRequest: IFolderUpdateRequest = {
                shipmentItems: this.shipmentItems
            };
            this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
                this.fireWorkflow();
                this.folderSubjectService.folderLoaded(folder);
            }).catch(() => {
                console.log('ERROR Update Folder with id :' + this.folder.id);
                this.store$.dispatch(new StopLoading());
            });
        } else {
            this.fireWorkflow();
        }
    }

    private fireWorkflow() {
        this.inputMap.emit({
            'forcedShippingStatus': ShippingEventType.SHIPMENT_IN_PROGRESS.toString()
        });
    }

    toShipmentItem(productAccessory: ProductAccessory): ShipmentItem {
        return {
            code: productAccessory.code,
            label: productAccessory.label,
            type: ShipmentItemType.SECONDARY
        };
    }
    getReportAttachment(): void {
        this.fileService.downloadFile(this.file);
    }

    updateSelectedAccessories($event: ProductAccessory[]){
       this.selectedProductAccessories = $event;
    }

}
