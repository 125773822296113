<iframe *ngIf="isLeroyAndTechAdmin$ | async"
        [src]="iframeAdeoDatadogURL"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen>
</iframe>
<iframe *ngIf="isNorautoAndTechAdmin$ | async"
        [src]="iframeNorautoDatadogURL"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen>
</iframe>
<iframe *ngIf="isElectroDepotAndTechAdmin$ | async"
        [src]="iframeElectrodepotDatadogURL"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen>
</iframe>
<iframe *ngIf="isBLAndTechAdmin$ | async"
        [src]="iframeBLDatadogURL"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen>
</iframe>

