<div>
    <h2>
        <mat-icon class="mt-20">event_note</mat-icon>
        {{'FOLDER.EVENT.TITLE' | translate}}
    </h2>
</div>
<div class="page-layout simple fullwidth mat-elevation-z4">
    <table mat-table [dataSource]="dataSourceEvents" matSort>
        <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.EVENT.ACTION' | translate}}</th>
            <td mat-cell *matCellDef="let element">
                {{(element.eventType === 'WORKFLOW_STATUS' ?
                (!!((findUserTask(element.eventName) | async).label) ? (findUserTask(element.eventName) | async).label : (getTranslatedEventName(element) | async) )
                : (getTranslatedEventName(element) | async) ) }}
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef class="table-header"> {{'FOLDER.EVENT.USER' | translate}}</th>
            <td *matCellDef="let element"
                mat-cell> {{!!element.userAction.user.fullName ? element.userAction.user.fullName : element.userAction.user.reference }}
            </td>
        </ng-container>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                (click)="updateSort()" class="table-header">  {{'FOLDER.EVENT.DATE' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="text-center">
                <span class="cursor-pointer"> {{element.userAction.actionDate | date: ('GENERAL.DATE_TIME_SECOND_FORMAT' | translate) : (currentLanguage$ | async)}}</span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 30]" showFirstLastButtons></mat-paginator>

</div>

