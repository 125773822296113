<ejs-grid [dataSource]="products"
          [allowPaging]="true"
          [allowSorting]="true"
          [allowFiltering]="false"
          [showColumnMenu]="false"
          [allowGrouping]="true"
          [groupSettings]="{showDropArea: true}"
          [pageSettings]="{pageSize: this.pageSize, pageCount: this.page + 1}"
          [showColumnChooser]="true"
          [filterSettings]="{type: 'CheckBox'}"
          [searchSettings]="{fields: fieldsToUseInSearch, operator: 'contains', key: filterValue }"
          [selectionSettings]="{type: 'Multiple', mode: 'Row'}"
          [toolbar]="toolbar"
          (rowSelected)="rowSelected($event)"
          (actionBegin)="handle($event)"
>
    <e-columns>
        <e-column field="code" width="10%"
                  [headerText]="'PRODUCT.TABLE.CODE' | translate"
        >
        </e-column>
        <e-column field="label" width="20%"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.LABEL' | translate"
        >
        </e-column>
        <e-column field="brand" width="10%"
                  [headerText]="'PRODUCT.BRAND' | translate"
        >
        </e-column>
        <e-column field="codeEAN" width="10%"
                  [headerText]="'PRODUCT.CODE_EAN' | translate"
        >
        </e-column>
        <e-column field="manufacturerReference" width="10%"
                  [headerText]="'PRODUCT_CONFIG.FIELDS.MANUFACTURER_REFERENCE' | translate"
        >
        </e-column>
        <e-column field="families.0.label" width="10%"
                  [headerText]=" 'PRODUCT.FAMILY' | translate"
        >
        </e-column>
        <e-column field="families.1.label" width="10%"
                  [headerText]=" 'PRODUCT.SUB_FAMILY' | translate" [visible]="false"
        >
        </e-column>
        <e-column field="families.2.label" width="10%"
                  [headerText]=" 'PRODUCT.TYPE' | translate" [visible]="false"
        >
        </e-column>
        <e-column field="families.3.label" width="10%"
                  [headerText]=" 'RULE.FORM.FIELDS.SUB_TYPE' | translate" [visible]="false"
        >
        </e-column>
    </e-columns>
</ejs-grid>

