import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {RegimeWarrantyEnum} from '../../../../../../models/warrantyRule.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {Amount} from '../../../../../../models/amount.model';

@Component({
    selector: 'app-internal-reparation-waiting-product-retrieval2',
    templateUrl: './internal-reparation-waiting-product-retrieval2.component.html',
    styleUrls: ['./internal-reparation-waiting-product-retrieval2.component.scss']
})
export class InternalReparationWaitingProductRetrieval2Component implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    productRetrieved: boolean;
    remainingAmount: Amount;
    isOutOfWarranty: boolean;

    constructor() {
    }

    ngOnInit(): void {
        this.isOutOfWarranty = this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG;

        if (this.isOutOfWarranty) {
            this.getRemainingAmount();
        }
    }

    private getRemainingAmount() {
        const reportLines = this.folder.reparationReport.reportLine || [];

        const totalPrice = reportLines
            .map(reportLine => Number(reportLine.totalLinePrice))
            .reduce((accumulator, currentValue) => accumulator + currentValue);

        const currency = reportLines.length > 0 ? reportLines[0].currency : null;

        this.remainingAmount = {
            value: totalPrice,
            currency: currency
        };
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }
}
