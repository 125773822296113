import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {Folder} from '../../../../../../models/folder.model';
import {ReplaySubject} from 'rxjs';
import {Iris} from '../../../../../../models/reparationReport/iris.model';
import {MatTableDataSource} from '@angular/material/table';
import {ConfigurationReferential} from '../../../../../../models/configurationReferential.model';
import {startWith} from 'rxjs/operators';
import {ConfigCodeMapsEnum} from '../../../../../../shared/services/configuration-item-enum';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {RegimeWarrantyEnum} from '../../../../../../models/warrantyRule.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {CodeToLabelService} from '../../../../../../../@fuse/services/code-to-label.service';

@Component({
    selector: 'app-product-diagnostic',
    templateUrl: './product-diagnostic.component.html',
    styleUrls: ['./product-diagnostic.component.css']
})
export class ProductDiagnosticComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    irisColumns: string[] = ['symptom', 'condition', 'action'];

    dataSourceIris = [];
    irisDataTable = new MatTableDataSource<any>();
    filteredSymptomList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    symptomList = [];

    filteredConditionList: ReplaySubject<ConfigurationReferential[]> = new ReplaySubject<ConfigurationReferential[]>(1);
    conditionList = [];
    productDiagnosticForm: any = {
        iris: null
    };
    isChooseOtherCarrier = false;


    constructor(private folderSubjectService: FolderSubjectService,
                private codeToLabelService: CodeToLabelService) {
        super();
    }

    private initForm(): void {
        this.productDiagnosticForm.iris = new FormGroup({
            description: new FormControl(null),
            symptom: new FormControl(null, Validators.required),
            symptomCodeCtrl: new FormControl(''),
            condition: new FormControl(null, Validators.required),
            conditionCodeCtrl: new FormControl(''),
            status: new FormControl(null, Validators.required),
            carrier: new FormControl(null),
            trackingNumber: new FormControl(null),
        });
        this.productDiagnosticForm.placeholders = [
            {
                status: 'PANNE_CONFIRMED',
                value: 'COMPONENT.PANNE_CONFIRMED',
            },
            {
                status: 'USAGE_FAULT',
                value: 'BUTTON.USAGE_FAULT',
            }
        ];
        if (this.folder.newWarranty.warranty === RegimeWarrantyEnum.SG) {
            this.productDiagnosticForm.placeholders.push(
                {
                    status: 'WARRANTY_DISCLAIMER',
                    value: 'BUTTON.WARRANTY_DISCLAIMER',
                });
        }
        this.productDiagnosticForm.placeholders.push(
            {
                status: 'PRODUCT_NOT_REPAIRABLE',
                value: 'BUTTON.PRODUCT_NOT_REPAIRABLE',
            });
        this.searchByConfigValue(ConfigCodeMapsEnum.CONDITIONS, this.conditionList, this.filteredConditionList, 'conditionCodeCtrl');
        this.searchByConfigValue(ConfigCodeMapsEnum.SYMPTOMS, this.symptomList, this.filteredSymptomList, 'symptomCodeCtrl');
    }

    private searchByConfigValue(configValueCode: ConfigCodeMapsEnum, configValues: any, filteredList: any, fieldControl: string) {
        this.anotherSubscription = this.codeToLabelService.getCodeLabels(configValueCode).subscribe(
            response => {
                if (response.length > 0) {
                    this.anotherSubscription = this.productDiagnosticForm.iris.get([fieldControl]).valueChanges
                        .pipe(startWith(null))
                        .subscribe(input => {
                            const matchedResults = !!input ?
                                response.filter(item =>
                                    item.code.toLowerCase().includes(input.toLowerCase()) || item.label.toLowerCase().includes(input.toLowerCase())
                                ) : response;

                            filteredList.next(matchedResults);
                        });
                }
            }
        );
    }

    ngOnInit(): void {
        this.initForm();
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
        });
    }

    onSubmit() {
        this.inputMap.emit({
            'irisList': JSON.stringify(this.irisDataTable.data),
            'diagnosticComment': this.productDiagnosticForm.iris.value.description,
            'usageFault': this.productDiagnosticForm.iris.value.status === 'USAGE_FAULT',
            'warrantyDisclaimer': this.productDiagnosticForm.iris.value.status === 'WARRANTY_DISCLAIMER',
            'productNotRepairable': this.productDiagnosticForm.iris.value.status === 'PRODUCT_NOT_REPAIRABLE',
            'warrantyCode': this.folder.newWarranty.warranty,
            'quotationProcess': 'CLIENT',
            'closeFolderReason': 'FOLDER_CLOSED_USAGE_FAULT'
        });

    }

    refreshDataTableIris(): void {
        this.irisDataTable.data = [];
        this.irisDataTable = new MatTableDataSource<any>(this.dataSourceIris);
    }

    addIntoIrisList(): void {
        let iris: Iris;
        iris = {
            symptom: {
                code: this.productDiagnosticForm.iris.value.symptom.code,
                label: this.productDiagnosticForm.iris.value.symptom.label
            },
            condition: {
                code: this.productDiagnosticForm.iris.value.condition.code,
                label: this.productDiagnosticForm.iris.value.condition.label
            }
        };
        this.dataSourceIris.push(iris);
        this.refreshDataTableIris();
    }

    deleteLineDataIris(indexLine: any): void {
        this.dataSourceIris = this.irisDataTable.data.filter((item, index) => index !== indexLine);
        this.refreshDataTableIris();
    }

    chooseOtherCarrier() {
        this.isChooseOtherCarrier = !this.isChooseOtherCarrier;
    }


}
