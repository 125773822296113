    <mat-card-content>
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>

        <ng-container *ngIf="folder?.warrantyEndDate">
            <label class="h3"><span
                class="text-bold"> {{ 'FOLDER.WARRANTY_END_DATE' | translate }}
                :</span> {{ folder.warrantyEndDate  | moment:('GENERAL.DATE_FORMAT' | translate) }}
            </label>
        </ng-container>

        <ng-container *ngIf="isOutOfWarranty && remainingAmount">
            <app-request-payment [folder]="folder"
                                 [remainingAmount]="remainingAmount.value"
                                 [currency]="remainingAmount.currency">
            </app-request-payment>
        </ng-container>

        <ng-container>
            <div class="mt-12">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox [checked]="productRetrieved"  [(ngModel)]="productRetrieved">
                        {{'BUTTON.CLIENT_RETRIEVE_NOT_REPAIRED_PRODUCT' | translate}}
                    </mat-checkbox>
                </div>
            </div>
        </ng-container>

        <div fxLayout="row" fxLayoutAlign="end end">
            <button mat-stroked-button (click)="onSubmit()"
                    type="submit"
                    [disabled]="!productRetrieved"
                    [ngClass]="!productRetrieved ? 'aster_btn_disabled' : 'aster_btn'">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>

    </mat-card-content>