import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder} from '../../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {ReparationStatusEnum} from '../model/reparationStatusEnum';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {RepairStatusType} from '../../../../../../models/enums/repairStatusType.enum';
import {CommentType} from '../../../../../../models/enums/CommentType.enum';
import {OperationModeEnum} from '../../../../../../models/enums/operationMode.enum';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';

@Component({
    selector: 'app-check-and-diagnostic',
    templateUrl: './check-and-diagnostic.component.html',
    styleUrls: ['./check-and-diagnostic.component.scss']
})
export class CheckAndDiagnosticComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    reparationStatus: ReparationStatusEnum;
    checkAndDiagForm: FormGroup;
    isLoading = false;

    breakDownButtons: any[];
    warrantyButtons: any[];
    productButtons: any[];
    warrantyDisclaimerOptions: any[];
    warrantyCode: string;

    constructor(private backOfficeService: BackOfficeService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initButtons();
        this.warrantyCode = this.folder.newWarranty.warranty;
        this.changeWarrantyValidator();
        this.changeDisclaimerReasonValidator();
    }

    private initForm(): void {
        this.checkAndDiagForm = this.formBuilder.group({
            warrantyStatus: new FormControl(null),
            warrantyDisclaimerOption: new FormControl(null),
            breakDownStatus: new FormControl(null, Validators.required),
            productStatus: new FormControl(null),
            comment: new FormControl(''),
        });
    }

    private initButtons(): void {
        this.breakDownButtons = [
            {
                status: 'BREAKDOWN_CONFIRMED',
                value: 'COMPONENT.BREAKDOWN_CONFIRMED',
            },
            {
                status: 'NO_BREAKDOWN',
                value: 'COMPONENT.NO_BREAKDOWN',
            }
        ];

        this.warrantyButtons = [
            {
                status: 'WARRANTY_ACCEPTED',
                value: 'COMPONENT.WARRANTY_ACCEPTED'
            },
            {
                status: 'WARRANTY_DISCLAIMER',
                value: 'COMPONENT.WARRANTY_DISCLAIMER'
            }
        ];
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.backOfficeService.getEligibleOperatingMode(ruleEvaluationContext).subscribe(data => {
            this.productButtons = [
                {
                    status: 'REPAIRABLE',
                    value: 'COMPONENT.REPAIRABLE'
                },
                {
                    status: 'TO_BE_REPAIRED_EXTERNALLY',
                    value: 'COMPONENT.TO_BE_REPAIRED_EXTERNALLY',
                    hidden: !data.some(el => el.modOp === OperationModeEnum.SERVICE_CENTER_REPAIR)
                },
                {
                    status: 'NOT_REPAIRABLE',
                    value: 'COMPONENT.NOT_REPAIRABLE'
                }
            ];
        });

        this.warrantyDisclaimerOptions = [
            'USAGE_PROBLEM',
            'NOT_COVERED_BY_WARRANTY'
        ];
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.checkAndDiagForm.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.checkAndDiagForm.value.comment,
                type: this.toCommentType(),
            });
        }
        this.inputMap.emit({
            'breakDownStatus': this.checkAndDiagForm.value.breakDownStatus,
            'productStatus': this.checkAndDiagForm.value.productStatus,
            'warrantyStatus': this.checkAndDiagForm.value.warrantyStatus,
            'warrantyDisclaimerOption': this.checkAndDiagForm.controls['warrantyDisclaimerOption'].value
        });
    }

    toCommentType(): string {
        if (this.checkAndDiagForm.value.productStatus === 'NOT_REPAIRABLE') {
            return CommentType.COMMENT_NOT_REPAIRABLE;
        } else if (this.checkAndDiagForm.value.productStatus === 'REPAIRABLE') {
            return CommentType.COMMENT_REPAIRABLE;
        } else if (this.checkAndDiagForm.value.warrantyStatus === 'WARRANTY_DISCLAIMER') {
            return CommentType.COMMENT_WARRANTY_DISCLAIMER;
        } else if (this.checkAndDiagForm.value.breakDownStatus === 'NO_BREAKDOWN') {
            return CommentType.COMMENT_NO_BREAKDOWN;
        }
    }

    private changeWarrantyValidator() {
        this.checkAndDiagForm.get('breakDownStatus')
            .valueChanges
            .subscribe(breakDownStatus => {
                if (!!breakDownStatus) {
                    const productStatus = this.checkAndDiagForm.get('productStatus');
                    const status = this.checkAndDiagForm.get('warrantyStatus');

                    if (breakDownStatus === 'BREAKDOWN_CONFIRMED') {
                        productStatus.setValidators(Validators.required);
                        if (this.warrantyCode === 'SG') {
                            status.setValidators(Validators.required);
                        } else {
                            this.clearValidator(status);
                        }
                    } else {
                        if (status.value === 'WARRANTY_DISCLAIMER') {
                            const warrantyDisclaimerOption = this.checkAndDiagForm.get('warrantyDisclaimerOption');
                            this.clearValidator(warrantyDisclaimerOption);
                            warrantyDisclaimerOption.updateValueAndValidity();
                        }
                        this.clearValidator(productStatus);
                        this.clearValidator(status);
                    }
                    status.updateValueAndValidity();
                    productStatus.updateValueAndValidity();
                }
            });
    }

    private changeDisclaimerReasonValidator() {
        this.checkAndDiagForm.get('warrantyStatus')
            .valueChanges
            .subscribe(status => {
                if (!!status) {
                    const warrantyDisclaimerOption = this.checkAndDiagForm.get('warrantyDisclaimerOption');

                    if (status === RepairStatusType.WARRANTY_DISCLAIMER) {
                        warrantyDisclaimerOption.setValidators(Validators.required);
                    } else {
                        this.clearValidator(warrantyDisclaimerOption);
                    }
                    warrantyDisclaimerOption.updateValueAndValidity();
                }
            });
    }

    private clearValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.clearValidators();
        ValidatorValue.setValue(null);
    }


    getTaskDuration(): string {
        return this.variablesTask['diagnosisMaxDuration'];
    }

    getStatusDate(): string {
        return this.folder.currentWorkflowStatus.workflowStatusDate;
    }

}
