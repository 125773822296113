import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {Folder} from '../../../../../../models/folder.model';
import { WorkflowService } from 'app/shared/services/workflow.service';
import { SparePartService } from 'app/shared/services/spare-part.service';
import { AppState } from 'app/store/app.state';
import {TaskVariables} from '../../../task.variables';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {Quotation} from '../../../../../../models/quotation.model';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {OperationModeEnum} from '../../../../../../models/enums/operationMode.enum';
import {PaymentRequest} from '../../../../../../models/payment.request';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {SparePartsInfoComponent} from '../store-payment/spare-parts-info/spare-parts-info.component';

@Component({
    selector: 'app-old-store-payment',
    templateUrl: './old-store-payment.component.html',
    styleUrls: ['./old-store-payment.component.scss']
})
export class OldStorePaymentComponent implements OnInit {

    @Input() folder: Folder;
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() refreshWorkflowStatus = new EventEmitter();
    @Output() previewTask = new EventEmitter<any>();

    paymentRequest: PaymentRequest;
    invoiceForm: any = {
        form: null,
    };

    constructor(private workflowService: WorkflowService,
                private sparePartService: SparePartService,
                private matDialog: MatDialog,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initVariables();
        this.initInvoiceForm();
    }

    initInvoiceForm(): void {
        this.invoiceForm.form = new FormGroup({
            invoiceNumber: new FormControl(null, Validators.required),
            paymentAmount: new FormControl(this.paymentRequest.priceAmount.value, [Validators.required, Validators.min(0)])
        });
    }

    initVariables(): void {
        this.getPaymentRequest();
    }

    private getPaymentRequest(): void {
        const paymentRequest = JSON.parse(this.variablesTask[TaskVariables.paymentRequest]);
        // to handle old payment request
        if (paymentRequest.hasOwnProperty('amount')) {
            this.paymentRequest = {
                priceAmount: {
                    value: paymentRequest.amount,
                    currency: 'EUR'
                },
                reason: paymentRequest.reason
            };
        } else {
            this.paymentRequest = paymentRequest;
        }
    }

    onSubmit(): void {
        // todo is tempo: todo implemente this logic in backend
        if (this.isAutorizedOperationMode() && this.quotationHasSpareParts()) {
            this.store$.dispatch(new StartLoading());
            const lastQuotation = this.getLastQuotation();
            this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), this.prepareStockRequest(lastQuotation))
                .subscribe(availabilityResponse => {
                    if (!!availabilityResponse) {
                        if (availabilityResponse.availabilityStatus === 'AVAILABLE') {
                            this.fireWorkflow();
                        } else {
                            this.store$.dispatch(new StopLoading());

                            const dialogRef = this.matDialog.open(SparePartsInfoComponent, {
                                height: 'auto',
                                width: '100vh',
                                minWidth: '800px', data: {
                                    stocks: availabilityResponse.stocks,
                                    folder: this.folder
                                }
                            });
                            dialogRef.afterClosed().subscribe(chooseOtherSparePart => {
                                    if (chooseOtherSparePart) {
                                        this.submitToPreviewsTask();
                                    }
                                }
                            );
                        }
                    } else {
                        this.fireWorkflow();
                    }
                });
        } else {
            this.fireWorkflow();
        }

    }

    private prepareStockRequest(lastQuotation: Quotation) {
        const stockRequest = lastQuotation?.quotationLines
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(quotationLine => ({
                sparePartCode: quotationLine.code.trim(),
                quantity: quotationLine.quantity
            }));
        return stockRequest;
    }

    private submitToPreviewsTask() {
        if (this.folder.operationMode === 'SPARE_PARTS_ORDER') {
            this.previewTask.emit({
                'currentTask': 'STORE_PAYMENT',
                'previewsTask': 'CHOOSE_SPARE_PART_TO_ORDER'
            });
        } else if (this.folder.operationMode === 'INTERNAL_REPARATION') {
            this.previewTask.emit({
                'currentTask': 'STORE_PAYMENT',
                'previewsTask': 'INPUT_REQUIREMENT_2'
            });
        }
    }

    private fireWorkflow() {
        this.store$.dispatch(new StopLoading());
        this.inputMap.emit({
            'PAYMENT_REFERENCE': this.invoiceForm.form.value.invoiceNumber,
            'PAYMENT_PRICE': this.invoiceForm.form.value.paymentAmount,
            'payment': 'PAYMENT_ACCEPTED',
            'hasPaid': 'true'
        });
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    private quotationHasSpareParts(): boolean {
        return this.getLastQuotation()?.quotationLines.some(quotationLine => quotationLine.type === QuotationType.SPARE_PART);
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    public isAuthorizedEditMode() {
        return this.isInternalReparation() || this.isExternalReparation();
    }

    public isAutorizedOperationMode() {
        return this.isInternalReparation() || this.isSparePartOrder();
    }

    private isExternalReparation() {
        return this.folder.operationMode === OperationModeEnum.SERVICE_CENTER_REPAIR;
    }

    private isSparePartOrder() {
        return this.folder.operationMode === OperationModeEnum.SPARE_PARTS_ORDER;
    }

    private isInternalReparation() {
        return this.folder.operationMode === OperationModeEnum.INTERNAL_REPARATION;
    }

    cancelPayment(): void {
        this.inputMap.emit({'payment': 'PAYMENT_CANCELED'});
    }


    switchPaymentMode(): void {
        this.store$.dispatch(new StartLoading());
        this.workflowService.switchPaymentMode(this.folder.id).subscribe(() => {
            this.store$.dispatch(new StopLoading());
            this.refreshWorkflowStatus.emit();
        }, () => {
            this.store$.dispatch(new StopLoading());

        });
    }
}

