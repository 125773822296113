import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {FileService} from '../../../../../../shared/services/file.service';
import {Image, RelatedSparePart} from 'app/models/spare-parts/relatedSparePart.model';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {GLOBAL} from '../../../../../../app-config';
import {ConfigCodeMapsEnum} from '../../../../../../shared/services/configuration-item-enum';
import {SparePartStock, StockDetails} from '../../../../../../models/spare-parts/stock.model';
import {StocksAvailabilityResponse} from '../../../../../../models/spare-parts/stocksAvailabilityResponse.model';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderStatus} from '../../../../../../models/enums/orderStatus.enum';
import {MultiImageModalComponent} from '../../../../../multi-image-modal/multi-image-modal.component';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {currency, vats} from '../../../../../../store/organization/organization.selectors';
import {DatePipe} from '@angular/common';
import {isNorauto} from '../../../../../../store/user/user.selectors';
import {StockAvailability} from '../../../../../../models/enums/stockAvailability.enum';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {ConfigV2Service} from '../../../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../../../models/element.model';

@Component({
    selector: 'app-check-spare-parts',
    templateUrl: './check-spare-parts.component.html',
    styleUrls: ['./check-spare-parts.component.scss']
})
export class CheckSparePartsComponent extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();

    placeholder = GLOBAL.sparePartsPlaceHolder;
    sparePartsToBeOrdered: OrderItem[] = [];
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ['reference', 'label', 'supplier', 'stock', 'image', 'price', 'quantity', 'status', 'totalPrice', 'action'];
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    isStockLoading = true;
    stockSubscription: Subscription;
    isUnderWarranty: boolean;
    isNorauto$: Observable<boolean>;
    shipmentFeesList: CodeLabel[] = [];
    stockAvailable = true;
    order = new Order();
    sparePartForm: FormGroup;
    isAllSelected = true;
    selectedShipmentFee;
    currentIndex: number;
    warrantyCode: string;
    defaultVat;
    currency: string;

    constructor(private orderSubjectService: OrderSubjectService, private matDialog: MatDialog,
                private store$: Store<AppState>,
                private translateService: TranslateService,
                private sparePartService: SparePartService,
                private omsService: OmsService,
                private fileService: FileService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private configV2Service: ConfigV2Service,
                private datePipe: DatePipe,
                private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.isNorauto$ = this.store$.pipe(select(isNorauto));
        this.anotherSubscription = this.store$.pipe(select(vats)).subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true)?.rate;
        });
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
        this.warrantyCode = this.folder.newWarranty.warranty.valueOf();
        this.isUnderWarranty = this.warrantyCode === 'SG';
        this.loadOrderData();

    }

    loadOrderData() {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.refreshDataTable();
            this.initShipmentFees();
        }, () => this.store$.dispatch(new StopLoading()));
    }

    refreshDataTable() {
        this.dataSource.data = [];
        this.sparePartsToBeOrdered = this.order.orderItems || [];
        this.dataSource = new MatTableDataSource<OrderItem>(this.sparePartsToBeOrdered);

    }

    onSubmit() {
        this.store$.dispatch(new StartLoading());
        this.checkAvailability();
    }

    confirmAllRows(): void {
        this.dataSource.data.forEach((orderLine, index) => {
            orderLine.isSelected = this.isAllSelected;
            orderLine.itemStatus = this.isAllSelected ? OrderStatus.NOT_CONFIRMED : OrderStatus.PICKING;
            this.updateStatusInForm(index, orderLine);
        });
        this.updateOrderLine();
    }

    updateStatusInForm(index: number, orderLine: OrderItem) {
        const rowFormGroup = this.sparePartsFormArray.controls[index] as FormGroup;
        rowFormGroup.controls['itemStatus'].setValue(orderLine.itemStatus);
        rowFormGroup.controls['quantityPacked'].setValue(this.computePackedQuantity(orderLine));
    }

    updateItemStatus(orderLine: OrderItem, index): void {
        orderLine.itemStatus = this.getItemStatus(orderLine);
        this.updateStatusInForm(index, orderLine);
        this.isAllSelected = this.dataSource.data.every(item => item.isSelected);
        this.updateOrderLine();
    }

    private getItemStatus(orderLine: OrderItem) {
        return orderLine.isSelected ? OrderStatus.NOT_CONFIRMED : OrderStatus.PICKING;
    }

    private computePackedQuantity(orderLine: OrderItem) {
        return this.isPicking(orderLine.itemStatus) ? orderLine.quantityOrdered : 0;
    }

    private isPicking(status: OrderStatus) {
        return status === OrderStatus.PICKING;
    }

    openMultiImageModal(images: Image[]): void {
        this.matDialog.open(MultiImageModalComponent, {
            height: 'auto',
            width: '100vh',
            minWidth: '800px',
            data: images
        });
    }

    onLoad(image: any) {
        image.isLoading = false;
    }

    onImageError(event: ErrorEvent) {
        (event.target as HTMLImageElement).src = this.placeholder;
    }

    hasStock(status: string): boolean {
        return status && status === StockAvailability.AVAILABLE;
    }

    displayStockStatus(status: string): string {
        if (!status || StockAvailability.UNKNOWN === status) {
            return 'TEXT.UNKNOWN';
        }

        if (status === StockAvailability.AVAILABLE) {
            return 'TEXT.AVAILABLE';
        }

        if (status === StockAvailability.NOT_AVAILABLE) {
            return 'TEXT.UNAVAILABLE';
        }
    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case StockAvailability.AVAILABLE:
                return 'green-color';
            case StockAvailability.NOT_AVAILABLE:
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }

    private fetchStocksOfDisplayedSpareParts(): void {
        const displayedData = this.dataSource._pageData(this.dataSource.data);
        const sparePartCodes = displayedData.filter((item) => item.code != null).map(data => data.code.trim());
        const managementSiteCode = this.getManagementSiteCode();

        if (!!this.stockSubscription) {
            this.stockSubscription.unsubscribe();
        }

        this.isStockLoading = true;
        this.stockSubscription = this.sparePartService.getStocks(sparePartCodes, managementSiteCode)
            .subscribe(stocks => {
                    this.merge(displayedData, stocks);
                    this.isStockLoading = false;
                },
                () => this.isStockLoading = false,
                () => {
                    this.store$.dispatch(new StopLoading());
                    this.isStockLoading = false;
                });
    }

    private fetchImagesOfDisplayedSpareParts() {
        const displayedData = this.dataSource._pageData(this.dataSource.data);

        displayedData
            .filter(sparePart => !!sparePart.images && sparePart.images.length > 0)
            .forEach(sparePart => {
                this.getImage(sparePart);
            });
    }

    private getImage(sparePart) {
        const localImages = sparePart.images.filter(image => !!image.id);
        const remoteImages = sparePart.images.filter(image => !!image.fullUrl);

        if (localImages.length > 0) {

            this.fileService.getAttachmentFile(localImages[0].id).subscribe(imageAsBlob => {
                sparePart.images[0].isLoading = true;
                const reader = new FileReader();
                reader.readAsDataURL(imageAsBlob);
                reader.onloadend = () => {
                    sparePart.images[0].src = reader.result;
                };
                sparePart.images[0].isLoading = false;
            });
        } else if (remoteImages.length > 0) {
            sparePart.images[0].isLoading = true;
            sparePart.images[0].src = remoteImages[0].fullUrl;
        }
    }

    private merge(spareParts: RelatedSparePart[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        const rowsForm = this.rowsFormByCode();
        spareParts.forEach(sparePart => {
            sparePart.stock = stocksByCode.get(sparePart.code);
            const rowFormGroup = rowsForm.get(sparePart.code);
            rowFormGroup.controls['stock'].setValue(sparePart.stock);
            this.computeAvailableQuantity(rowFormGroup, sparePart.stock);
            (this.isStockAvailableInStore(sparePart.stock) || this.isPicking(rowFormGroup.value.itemStatus)) ?
                this.setPickingSparePart(rowFormGroup) : this.setSparePartToOrder(rowFormGroup);


        });

    }

    private rowsFormByCode(): Map<string, FormGroup> {
        return new Map(this.sparePartsFormArray.controls.map(formGroup => [formGroup.value.code, (formGroup as FormGroup)]));
    }

    private setSparePartToOrder(rowFormGroup: FormGroup) {
        rowFormGroup.controls['isSelected'].setValue(true);
        this.refreshRowValue(rowFormGroup.value);
    }

    private setPickingSparePart(rowFormGroup: FormGroup) {
        rowFormGroup.controls['isSelected'].setValue(false);
        this.isAllSelected = false;
        if (!this.isPicking(rowFormGroup.controls['itemStatus'].value)) {
            this.updatePickingStatus(rowFormGroup);
            this.refreshRowValue(rowFormGroup.value);
        } else {
            this.refreshRowValue(rowFormGroup.value);
        }
        //

    }

    private updatePickingStatus(rowFormGroup: FormGroup): void {
        rowFormGroup.controls['itemStatus'].setValue(OrderStatus.PICKING);
        rowFormGroup.controls['quantityPacked'].setValue(this.computePackedQuantity(rowFormGroup.value));
        this.updateOrderLine();
    }

    private refreshRowValue(value: any) {
        const index = this.getIndex(value);
        const row = this.getRow(index);
        if (row) {
            Object.assign(row, value);
            this.dataSource.data[index] = row;

        }
    }

    private getRow(index: number) {
        return index !== -1 ? this.dataSource.data[index] : null;
    }

    private getIndex(value: any): number {
        return this.dataSource.data.findIndex(item => item.id === value.id);
    }

    private computeAvailableQuantity(rowFormGroup: FormGroup, sparePartStock: SparePartStock) {
        rowFormGroup.controls['isAvailable'].setValue(this.isSparePartAvailable(sparePartStock));
        rowFormGroup.controls['quantityAvailable'].setValue(this.getAvailableStock(sparePartStock));
    }

    isStockAvailableInStore(sparePartStock: SparePartStock) {
        return sparePartStock?.status === StockAvailability.AVAILABLE && this.getStockDetailsByCurrentSite(sparePartStock?.stockDetails)?.isStore;
    }

    getStockDetailsByCurrentSite(stockDetails: StockDetails[]): StockDetails {
        return stockDetails.find(details => details.siteCode === this.getManagementSiteCode());
    }

    checkAvailability(): void {
        const stockRequest = this.prepareStockRequest();
        this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), stockRequest)
            .subscribe(stockResponse => {
                if (!!stockResponse) {
                    if (stockResponse.availabilityStatus === StockAvailability.NOT_AVAILABLE || stockResponse.availabilityStatus === StockAvailability.PARTIAL) {
                        this.stockAvailable = false;
                        this.updateStockStatus(stockResponse);
                    } else {
                        this.stockAvailable = true;
                        this.validateTask();
                    }
                } else {
                    this.validateTask();
                }
            });
    }

    private prepareStockRequest() {
        return this.sparePartsToBeOrdered.filter((item) => item.type === 'SPARE_PART').map(sparePart => ({
            sparePartCode: sparePart.code.trim(),
            quantity: sparePart.quantityOrdered
        }));
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    private updateStockStatus(stockResponse: StocksAvailabilityResponse) {
        const displayedData = this.dataSource._pageData(this.dataSource.data);
        this.merge(displayedData, stockResponse.stocks);
        this.store$.dispatch(new StopLoading());
    }

    private initForm(initialShipmentFees) {
        this.sparePartForm = this.fb.group({
            spareParts: this.fb.array(this.loadData())
        });
        if (this.warrantyCode === 'HG') {
            this.sparePartForm.addControl('code', new FormControl(initialShipmentFees?.code, [Validators.required]));
            this.sparePartForm.addControl('price', new FormControl(initialShipmentFees?.price || 0, [Validators.required, Validators.min(0)]));
        }
    }

    private loadData(): FormGroup[] {
        return this.dataSource.data.map((item: OrderItem) => {
            item.isSelected = this.isItemSelected(item);
            return this.createSparePartFormGroup(item);
        });
    }

    private createSparePartFormGroup(item: OrderItem): FormGroup {
        return new FormGroup({
            quantityOrdered: new FormControl(item.quantityOrdered, [Validators.required, Validators.min(1)]),
            quantityAvailable: new FormControl(item.quantityAvailable, null),
            quantityInvoiced: new FormControl(item.quantityInvoiced, null),
            quantityPacked: new FormControl(item.quantityPacked, null),
            id: new FormControl(item.id, null),
            code: new FormControl(item.code, null),
            label: new FormControl(item.label, null),
            type: new FormControl(item.type, null),
            ean: new FormControl(item.ean, null),
            currency: new FormControl(item.currency, null),
            price: new FormControl(item.price, null),
            priceUnitwithTax: new FormControl(item.priceUnitwithTax || 0, null),
            taxPercent: new FormControl(item.taxPercent, null),
            discount: new FormControl(item.discountPercent, null),
            discountReason: new FormControl(item.discountReason, null),
            weight: new FormControl(item.weight, null),
            weightUnit: new FormControl(item.weightUnit, null),
            supplier: new FormControl(item.supplier, null),
            isAvailable: new FormControl(item.isAvailable, null),
            images: new FormControl(item.images, null),
            displayType: new FormControl(item.displayType, null),
            itemStatus: new FormControl(item.itemStatus, null),
            stock: new FormControl(null),
            isSelected: new FormControl(item.isSelected, null)

        });
    }

    private isItemSelected(item: OrderItem) {
        return item.itemStatus === OrderStatus.NOT_CONFIRMED;
    }

    changeQuantity($event, index) {
        this.currentIndex = index;
        this.getRowFormGroup.controls['quantityInvoiced'].setValue(parseInt($event.target.value, 10));
        this.updateOrderLine();
    }

    changePrice($event, index) {
        this.currentIndex = index;
        const price = this.convertTTCtoHT($event.target.value);
        this.getRowFormGroup.controls['price'].setValue(price);
        this.updateOrderLine();
    }

    get getRowFormGroup(): FormGroup {
        return this.sparePartsFormArray.controls[this.currentIndex] as FormGroup;
    }

    get sparePartsFormArray(): FormArray {
        return this.sparePartForm.controls['spareParts'] as FormArray;
    }


    get shipmentFeesLabelControl(): FormControl {
        return this.sparePartForm.controls['code'] as FormControl;
    }

    get shipmentFeesPriceControl(): FormControl {
        return this.sparePartForm.controls['price'] as FormControl;
    }

    backToChooseSpareParts() {
        this.inputMap.emit({
            'userChoise': 'selectSpareParts'
        });
    }

    private initShipmentFees(): void {
        this.anotherSubscription = this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.TYPE_OF_SERVICE]).subscribe(
            response => {
                this.shipmentFeesList = response.filter((item) => item.type === 'SHIPMENT_FEES');
                const initialShipmentFees = this.getInitialShipmentFees();
                this.selectedShipmentFee = initialShipmentFees;
                this.initForm(initialShipmentFees);
                this.fetchStocksOfDisplayedSpareParts();
                this.fetchImagesOfDisplayedSpareParts();
            }
        );
    }

    private toConfigurationReferential(shipmentFees: OrderItem) {
        return {
            code: shipmentFees.code,
            price: shipmentFees.rowTotal,
            label: shipmentFees.label,
            type: shipmentFees.type
        };
    }


    private getInitialShipmentFees() {
        const savedShipmentFees = this.getSavedShipmentFees();
        return savedShipmentFees ? this.toConfigurationReferential(savedShipmentFees) :
            (this.shipmentFeesList && this.shipmentFeesList.length > 0 ? this.shipmentFeesList[0] : null);

    }

    private getSavedShipmentFees() {
        return this.order.orderExtraItems?.find((item) => item.type === 'SHIPMENT_FEES');
    }

    displayShipmentFee = (shipmentFeeCode: string) => {
        const shipmentFees = this.shipmentFeesList.find(x => x.code === shipmentFeeCode);
        return shipmentFees ? shipmentFees.label : '';
    };

    onSelectShipmentFee($event: any): void {
        this.selectedShipmentFee = this.shipmentFeesList.find(x => x.code === $event.option.value);
        this.shipmentFeesPriceControl.setValue(
            this.sparePartService.formatPriceAccordingToCurrency(this.selectedShipmentFee.price, this.selectedShipmentFee?.currency));


    }

    private computePriceWithoutTax(price: number) {
        return this.sparePartService.normalizeFloat(price / (1 + (20 / 100)));
    }


    formatAmount($event): void {
        const price = $event.target.value;
        if (!price || isNaN(price)) {
            this.shipmentFeesPriceControl.setErrors({'invalid': true});
        } else {
            this.shipmentFeesPriceControl.setValue(this.sparePartService.formatPriceAccordingToCurrency(price, this.selectedShipmentFee?.currency));
        }
    }

    displayTotalPriceTTC(element: OrderItem): number {
        return element.priceUnitwithTax * element.quantityOrdered;
    }

    private isSparePartAvailable(stock) {
        return (stock?.status === StockAvailability.AVAILABLE) || false;
    }

    private getAvailableStock(stock) {
        return stock?.totalStock || 0;
    }

    private prepareOrderItems() {
        this.order.orderItems = this.sparePartForm.value.spareParts.filter((item) => item.type === QuotationType.SPARE_PART);
        if (this.warrantyCode === 'HG') {
            this.order.orderExtraItems = [];
            const savedShipmentFees = this.getSavedShipmentFees();
            this.order.orderExtraItems.push(this.toOrderItem(savedShipmentFees));
        }

    }

    private updateOrderLine() {
        this.store$.dispatch(new StartLoading());
        this.prepareOrderItems();
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
            }
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    displayItemStatus(status: number | string) {
        if ((!status || 'UNKNOWN' === status)) {
            return 'TEXT.UNKNOWN';
        }
        if (status === OrderStatus.CONFIRMED) {
            return 'ORDER.TEXT.CONFIRMED';
        }
        if (status === OrderStatus.NOT_CONFIRMED) {
            return 'ORDER.TEXT.NOT_CONFIRMED';
        }
        if (status === OrderStatus.SHIPPED || status === OrderStatus.ORDERED) {
            return 'ORDER.TEXT.ORDERED';
        }
        if (status === OrderStatus.PICKING) {
            return 'ORDER.TEXT.PICKING';
        }

        if (status === OrderStatus.DELIVERED) {
            return 'ORDER.TEXT.DELIVERED';
        }

    }

    validateTask() {
        this.updateOrder();
    }

    private prepareOrder() {
        if (this.warrantyCode === 'HG') {
            this.order.orderExtraItems = [];
            const savedShipmentFees = this.getSavedShipmentFees();
            this.order.orderExtraItems.push(this.toOrderItem(savedShipmentFees));
        }
        this.order.siteCode = this.getManagementSiteCode();
    }

    private updateOrder() {
        this.prepareOrder();
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
            }
            this.inputMap.emit({
                'userChoise': 'orderChecked'
            });
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private toOrderItem(savedShipmentFees: OrderItem): OrderItem {
        return {
            id: savedShipmentFees?.id,
            code: this.selectedShipmentFee?.code,
            label: this.selectedShipmentFee?.label,
            currency: this.currency,
            price: this.computePriceWithoutTax(this.sparePartForm.value.price),
            type: 'SHIPMENT_FEES',
            quantityOrdered: 1,
            quantityInvoiced: 1,
            taxPercent: this.defaultVat,
            displayType: 'SHIPMENT_FEES'
        };

    }

    public convertHTtoTTC(price) {
        return AmountUtils.convertHTtoTTC(price, parseFloat(this.defaultVat));
    }

    public convertTTCtoHT(price) {
        return AmountUtils.convertTTCtoHT(price, parseFloat(this.defaultVat));
    }

    isFree(element: OrderItem): boolean {
        return this.isUnderWarranty && element.supplier?.freeUnderWarranty && element.price === 0;
    }

    deleteLine(indexLine: number): void {
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.sparePartsFormArray.removeAt(indexLine);
                this.updateOrderLine();
                this.refreshDataTable();

            }
        });

    }
}
