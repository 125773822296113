import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {startWith} from 'rxjs/operators';
import {forkJoin, ReplaySubject} from 'rxjs';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Unsubscriber} from '../../../../../unsubscriber';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {CodeToLabelService} from '../../../../../../@fuse/services/code-to-label.service';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {CodeLabel} from '../../../../../models/element.model';

@Component({
    selector: 'app-symptom-search-dialog',
    templateUrl: './symptom-search-dialog.component.html',
    styleUrls: ['./symptom-search-dialog.component.scss'],
    animations: [trigger('detailExpand', [state('collapsed', style({
        height: '0px', minHeight: '0'
    })), state('expanded', style({height: '*'})), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
})
export class SymptomSearchDialogComponent extends Unsubscriber implements OnInit {

    @Input() productCode: string;
    @Input() organizationCode: string;
    @Input() context: string;
    @Output() updatedFaultCode = new EventEmitter<any>();
    @Output() closeField = new EventEmitter<boolean>();

    isClose = false;
    positionStep: number;
    symptomsGrid: any = {
        symptomsList: [],
        symptomsPlaceholderList: [],
        filteredSymptomsList: new ReplaySubject<any[]>(),
    };
    conditionList: CodeLabel[];
    folderUpdateRequest: IFolderUpdateRequest;
    folder: Folder;


    constructor(private backOfficeService: BackOfficeService,
                private folderSubject: FolderSubjectService,
                private codeToLabelService: CodeToLabelService) {
        super();
        this.positionStep = 1;
    }

    ngOnInit(): void {
        this.anotherSubscription = this.folderSubject.folder$.subscribe(folder => this.folder = folder);
        this.anotherSubscription = forkJoin([
            this.codeToLabelService.getCodeLabels(ConfigCodeMapsEnum.SYMPTOMS),
            this.codeToLabelService.getCodeLabels(ConfigCodeMapsEnum.CONDITIONS)
        ]).subscribe(([symptoms, conditions]) => {
            this.initSymptomsFilterForm();
            this.getConditions(conditions);
        });
    }

    getConditions(conditions: CodeLabel[]): void {
        this.conditionList = this.isLmOrganization() ? conditions : [...conditions,
            {
                    id: 'CONSTANT',
                    code: 'CONSTANT',
                    label: 'COMPONENT.CONSTANT.CONDITION'
            }];
        this.symptomsGrid.symptomSelectForm.get(['condition']).setValue(this.isLmOrganization() ? null : 'CONSTANT');
    }

    private isLmOrganization() {
        return this.folder.organization.code.startsWith('LM');
    }

    initSymptomsFilterForm(): void {
        this.symptomsGrid.symptomSelectForm = new FormGroup({
            symptomFilterCtrl: new FormControl(null),
            symptomPlaceholder: new FormGroup({
                symptomCode: new FormControl(null),
            }),
            condition: new FormControl(null)
        });
        this.getSymptoms();

    }

    onClearSymptomsFilterForm(): void {
        this.symptomsGrid.filterForm.get(['symptomPlaceholder', 'symptomCode']).setValue(null);
    }

    getSymptoms(): void {
        const evaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.anotherSubscription = this.backOfficeService.getSymptoms(evaluationContext).subscribe(symptoms => {
            this.symptomsGrid.symptomsPlaceholderList = symptoms;
            this.symptomsGrid.symptomsList = symptoms;
            this.initConfigsSearchFilters();
        });
    }

    /*UPDATE20190815: diagnostic survey code is now returned withing the symptom, instead of fetching it,
      * so we directly create the survey response, or navigate to client info */
    onSymptomSelect(index: number): void {
        this.folderUpdateRequest = {
            faultCode: {
                code: this.symptomsGrid.symptomsList[index].code,
                label: this.symptomsGrid.symptomsList[index].label,
                condition: this.symptomsGrid.symptomSelectForm.get(['condition']).value
            },
            survey: !!this.symptomsGrid.symptomsList[index].diagnosticCode ? {surveyCode: this.symptomsGrid.symptomsList[index].diagnosticCode} : null,
        };

    }

    conditionChanged(value): void {
        this.symptomsGrid.symptomSelectForm.get(['condition']).setValue(value);
        if (!this.folderUpdateRequest || !this.folderUpdateRequest.faultCode) {
            this.folderUpdateRequest = {
                faultCode: {
                    code: this.folder.faultCode.code,
                    label: this.folder.faultCode.label,
                    condition: this.conditionList.find(a => a.code === value).code
                }
            };
        } else {
            this.folderUpdateRequest.faultCode.condition = this.symptomsGrid.symptomSelectForm.get(['condition']).value;
        }

    }

    valideModification(): void {
        this.updatedFaultCode.emit(this.folderUpdateRequest);

    }

    initConfigsSearchFilters(): void {
        this.anotherSubscription = this.symptomsGrid.symptomSelectForm.get(['symptomFilterCtrl']).valueChanges
            .pipe(startWith(null))
            .subscribe(input => {
                this.symptomsGrid.filteredSymptomsList.next(this.filterListWithCodeOrLabel(this.symptomsGrid.symptomsPlaceholderList, input));
            });
    }

    close(): void {
        this.isClose = !this.isClose;
        this.closeField.emit(this.isClose);
    }

    filterListWithCodeOrLabel(list: any[], input: string): any {
        if (!!input) {
            return list.filter(el =>
                el.code.toLowerCase().includes(input.toLowerCase()) || el.label.toLowerCase().includes(input.toLowerCase())
            );
        }
        return list;
    }
}
