<div fxLayout="row" fxLayout.lt-lg="row" fxLayout.lt-md="column" fxLayoutGap="8px" fusePerfectScrollbar>
    <div  fxFlex="50">
        <app-public-detail-tabs-card [folder]="folder"></app-public-detail-tabs-card>
    </div>
    <div fxFlex="50">
        <mat-card class="mat-elevation-aster">
            <mat-card-content *ngIf="form">
                <form [formGroup]="form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
                    <mat-card-title
                        class="h3 mt-12 mb-24">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS' | translate}}</mat-card-title>
                    <fieldset>
                        <legend class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.BREAKDOWN' | translate}}</legend>
                        <ng-container>
                            <mat-radio-group formControlName="breakDownStatus" class="ml-20" labelPosition="after" fxLayout="column"
                                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                <mat-radio-button [id]="button.status" class="full-width-radio"
                                                  *ngFor="let button of breakDownButtons"
                                                  [value]="button.status">
                                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                        <div fxFlex>
                                            <div>{{ button.value | translate }}</div>
                                        </div>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </ng-container>
                    </fieldset>
                    <ng-container *ngIf="form.value.breakDownStatus === 'BREAKDOWN_CONFIRMED'">
                        <ng-container *ngIf="warrantyCode === 'SG'">
                            <fieldset>
                                <legend
                                    class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY' | translate}}</legend>
                                <ng-container>
                                    <mat-radio-group formControlName="status" class="ml-20"
                                                     labelPosition="after"
                                                     fxLayout="column"
                                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                        <mat-radio-button [id]="button.status" class="full-width-radio"
                                                          *ngFor="let button of warrantyButtons"
                                                          [value]="button.status">
                                            <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                                <div fxFlex>
                                                    <div>{{ button.value | translate }}</div>
                                                </div>
                                            </div>
                                        </mat-radio-button>
                                    </mat-radio-group>

                                    <div *ngIf="form.value.status === 'WARRANTY_DISCLAIMER'" class="ml-20">
                                        <mat-form-field class="py-4 mt-16" fxFlex="50" appearance="outline">
                                            <mat-label>{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.WARRANTY_DISCLAIMER_REASON' | translate}}</mat-label>
                                            <mat-select formControlName="warrantyDisclaimerOption">
                                                <mat-option *ngFor="let option of warrantyDisclaimerOptions"
                                                            [value]="option">
                                                    {{('COMPONENT.DIAGNOSIS_IN_PROGRESS.' + option) |translate}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </fieldset>
                        </ng-container>

                        <fieldset>
                            <legend class="h3 title-fieldset">{{'COMPONENT.DIAGNOSIS_IN_PROGRESS.PRODUCT' | translate}}</legend>
                            <ng-container>
                                <mat-radio-group formControlName="productStatus" class="ml-20" labelPosition="after"
                                                 fxLayout="column"
                                                 fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                    <mat-radio-button [id]="button.status" class="full-width-radio"
                                                      *ngFor="let button of productButtons"
                                                      [value]="button.status">
                                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                            <div fxFlex>
                                                <div>{{ button.value | translate }}</div>
                                            </div>
                                        </div>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </ng-container>
                        </fieldset>
                    </ng-container>

                    <app-comment-area [commentForm]="form.get('comment')"></app-comment-area>

                    <!-- attachments -->
                    <app-files-list
                        [displayedColumns]="displayedColumns"
                        [attachmentsTypes]="allowedAttachmentTypes"
                        [(data)]="attachments"></app-files-list>

                    <div fxLayoutAlign="end center" class="mt-24">
                        <button type="submit" mat-stroked-button [disabled]="form.invalid"
                                [ngClass]="form.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                            {{'BUTTON.VALIDATE' | translate}}
                        </button>
                    </div>
                </form>

                <mat-chip-list class="w-50-p">
                    <mat-chip color="primary" class="upload-image_style"
                              (click)="onFileUpload()">
                        <mat-icon class="mr-8">camera_alt</mat-icon>
                        <span
                            class="ml-4">{{'COMPONENT.REPAIR_IN_PROGRESS.UPLOAD_ATTACHMENTS'|translate}}</span>
                    </mat-chip>
                </mat-chip-list>

            </mat-card-content>
        </mat-card>
    </div>
</div>














