import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CheckAndDiagDecision} from '../../../diag-expert/model/internal-repair.model';
import {Folder} from '../../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {ReparationStatusEnum} from '../model/reparationStatusEnum';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {OperationModeEnum} from '../../../../../../models/enums/operationMode.enum';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-old-check-and-diagnostic',
    templateUrl: './old-check-and-diagnostic.component.html',
    styleUrls: ['./old-check-and-diagnostic.component.scss']
})
export class OldCheckAndDiagnosticComponent implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    reparationStatus: ReparationStatusEnum;
    checkAndDiagForm: FormGroup;
    choices: any[];

    constructor(private backOfficeService: BackOfficeService,
                private store$: Store<AppState>,
                private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.initForm();
        this.initChoices();
    }

    private initForm(): void {
        this.checkAndDiagForm = this.formBuilder.group({
            diagnosticDecision: [null, Validators.required]
        });
    }

    onSubmit(): void {
        switch (this.checkAndDiagForm.value.diagnosticDecision) {
            case CheckAndDiagDecision.SUBCONTRACT_THE_REPAIR:
                this.reparationStatus = ReparationStatusEnum.TO_BE_REPAIRED_EXTERNALLY;
                break;
            case CheckAndDiagDecision.IRREPARABLE:
                this.reparationStatus = ReparationStatusEnum.NOT_REPAIRABLE;
                break;
            case CheckAndDiagDecision.CLOSE_BY_DIAG_EXPRESS:
                this.reparationStatus = ReparationStatusEnum.CLOSED_BY_DIAG_EXPRESS;
                break;
            case CheckAndDiagDecision.DO_THE_REPAIR:
                this.reparationStatus = ReparationStatusEnum.INTERNAL_REPAIR;
                break;
        }
        this.inputMap.emit({
            reparationStatus: this.reparationStatus
        });
    }

    initChoices() {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        this.backOfficeService.getEligibleOperatingMode(ruleEvaluationContext).subscribe(data => {
            this.choices = [
                {
                    choice: CheckAndDiagDecision.IRREPARABLE,
                    value: 'CHECK_AND_DIAG_IRREPARABLE',
                },
                {
                    choice: CheckAndDiagDecision.SUBCONTRACT_THE_REPAIR,
                    value: 'CHECK_AND_DIAG_SUBCONTRACT_THE_REPAIR',
                    hidden: !data.some(el => el.modOp === OperationModeEnum.SERVICE_CENTER_REPAIR)
                },
                {
                    choice: CheckAndDiagDecision.DO_THE_REPAIR,
                    value: 'CHECK_AND_DIAG_DO_THE_REPAIR',
                },
                {
                    choice: CheckAndDiagDecision.CLOSE_BY_DIAG_EXPRESS,
                    value: 'CHECK_AND_DIAG_CLOSE_IN_EXPRESS',
                }
            ];
        });
    }

}

