<div fxLayout.lt-sm="column" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px"
     [formGroup]="customerDetailForm.get('company')">
    <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>
            {{'CUSTOMER.COMPANY.LEGALFORM'|translate}}</mat-label>
        <mat-select id="create_folder_legalForm"
                    formControlName="legalForm"
                    [disabled]="isReadOnly"
                    [required]="true">
            <mat-option *ngFor="let legalForm of (legalFormSelectBoxOptions$ | async)"
                        id="{{'create_folder_legalForm_' + legalForm}}"
                        [value]="legalForm">
                <span>{{legalForm}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="50">
        <mat-label>
            {{'CUSTOMER.COMPANY_NAME'|translate}}</mat-label>
        <mat-icon matSuffix class="disabled-text">verified_user</mat-icon>
        <input matInput placeholder="{{'CUSTOMER.COMPANY_NAME'|translate}}"
               formControlName="label"
               inputControl
               id="create_folder_corporateName"
               [disabled]="isReadOnly"
               [required]="true">
    </mat-form-field>
</div>

