import {Injectable} from '@angular/core';
import {SnackBarService} from './snack-bar.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {


    constructor(private snackBar: SnackBarService) {
    }

    /**
     * Build dashboard Items based on chart and chartRequest from the parent component
     * @param data
     */

    showSuccessSave() {
        this.snackBar.openAtCenter('Success', 'DASHBOARD.ITEMS.SUCCESS_CONFIRMATION');
    }

    showError() {
        this.snackBar.openAtCenter('Error', 'DASHBOARD.ITEMS.ERROR_CONFIRMATION');
    }
}
