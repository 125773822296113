import {Component, Input, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FolderPublic} from '../../../models/folder.public.model';

@Component({
    selector: 'app-public-detail-tabs-card',
    templateUrl: './public-detail-tabs-card.component.html',
    styleUrls: ['./public-detail-tabs-card.component.scss']
})
export class PublicDetailTabsCardComponent {
    protected _onDestroy = new Subject<void>();

    @Input() folder: FolderPublic;
    activeSection: string;

    updateSection(section: string): void {
        this.activeSection = section;
    }

    hasPublicQuotations(): boolean {
        return this.folder?.publicQuotations?.length > 0;
    }
}
