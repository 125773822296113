import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, combineLatest} from 'rxjs';
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {DOCUMENT} from '@angular/common';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Router} from '@angular/router';
import {FolderService} from '../../../shared/services/folder.service';
import {TagEnums} from '../../../models/enums/tag.enums';
import {Filter, FolderSearchResponse} from '../../../models/folder-search-response.model';
import {SearchFoldersRequestModel} from '../../../models/SearchFoldersRequest.model';
import {PushNotificationService} from '../../../shared/services/pushNotification.service';
import {PushNotificationSearchResponse} from '../../../models/pushNotification/pushNotification-search-response.model';
import {PushNotification, PushNotificationUpdateRequest} from '../../../models/pushNotification/pushNotification.model';
import {FolderIndex} from '../../../models/folder.index.model';
import {OPERATING_MODES_CONFIGS, WARRANTY_CONFIGS} from '../../../shared/data/static.config';
import {Context} from '../../../models/enums/context.enum';
import {Title} from '@angular/platform-browser';
import {OrganizationContext} from '../../../models/organization.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {
    affectedBusinessLinks,
    contextAttributes,
    currentBusinessLink,
    currentContext,
    displayCampaignField,
    fullName,
    hasScope,
    isReparationContext,
    isUserAssignedToSite,
    organizationAttributes,
    organizationCode,
    isLeroyMerlin,
    isNorauto,
    isElectroDepot,
    isBL,
    isAdminPlatana,
    isTechnicalAdmin,
    organizationLabel
} from '../../../store/user/user.selectors';
import {FirstLoad, Logout, SwitchBusinessLink, SwitchContext, SwitchOrganization} from '../../../store/user/user.actions';
import {ClaimWithoutCustomer, SetDashboardServerInfo, SetEnableStockClaimStatus, SetMargin, SetTemplateId, UpdateLanguage} from '../../../store/organization/organization.actions';
import {Constants} from '../../../Constants';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {currentLanguage} from '../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../unsubscriber';
import {take} from 'rxjs/operators';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {CodeLabel} from '../../../models/element.model';
import {Scopes} from '../../../models/scopes/scopes.model';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent extends Unsubscriber implements OnInit {

    @HostListener('scrollStars', ['$event'])
    @HostListener('scroll', ['$event'])
    totalElement = 10;
    totalElementNotification = 0;
    page = 0;
    totalPages = 0;

    languages = Constants.LANGUAGES;
    isMobile: boolean;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    selectedLanguage: any;
    pushNotifications = [];
    pushNotificationsResponse: PushNotificationSearchResponse;
    statusNotification = ['READ', 'UNREAD'];

    folderSearchResponse: FolderSearchResponse;
    favoritesFoldersList = [];

    availableContexts: OrganizationContext[] = [];
    currentContext: string;
    currentOrganizationCode: string;
    currentBusinessLink = '';
    organizationAttributes: string[] = [];

    layout: string;
    currentLanguage: string;
    pageLogoPath = 'assets/images/logos/logo_aster.png';

    fullName$: Observable<string>;
    businessLinks$: Observable<CodeLabel[]>;
    displayCampaignField$: Observable<boolean>;
    currentOrganizationLabel$: Observable<string>;
    isReparationContext$: Observable<boolean>;
    isUserAssignedToSite$: Observable<boolean>;
    isLeroyMerlin$: Observable<boolean>;
    isNorauto$: Observable<boolean>;
    isElectroDepot$: Observable<boolean>;
    isBL$: Observable<boolean>;
    isAdminPlatana$: Observable<boolean>;
    isTechnicalAdmin$: Observable<boolean>;
    isLMorNRorEDTechAdmin$: Observable<boolean>;

    constructor(private _fuseConfigService: FuseConfigService,
                private _fuseSidebarService: FuseSidebarService,
                private translateService: TranslateService,
                private pushNotificationService: PushNotificationService,
                private store$: Store<AppState>,
                private folderService: FolderService,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                @Inject(DOCUMENT) private document: HTMLDocument,
                private router: Router,
                private titleService: Title,
                private pushNotificationSubjectService: PushNotificationSubjectService) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this.anotherSubscription = this._fuseConfigService.config
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
                this.isMobile = settings.layout.isMobile;
            });

        this.anotherSubscription = this.store$.pipe(select(organizationAttributes))
            .subscribe(organizations => this.organizationAttributes = organizations);

        this.anotherSubscription = this.store$.pipe(select(organizationCode))
            .subscribe(organizationCode => this.currentOrganizationCode = organizationCode);

        this.anotherSubscription = this.store$.pipe(select(currentContext))
            .subscribe(context => this.currentContext = context);

        this.anotherSubscription = this.store$.pipe(select(currentBusinessLink))
            .subscribe(businessLink => this.currentBusinessLink = businessLink);

        this.anotherSubscription = this.pushNotificationSubjectService.pushNotification$
            .subscribe(pushNotification => {
                this.initPushNotifications();
            });

        this.store$.pipe(select(currentLanguage)).subscribe(
            language => {
                this.currentLanguage = language;
                this.selectedLanguage = _.find(this.languages, {'id': language});
                if (!this.selectedLanguage) {
                    this.selectedLanguage = _.find(this.languages, {'id': 'en'});
                }
            }
        );

        this.initLocalStorage();

        this.fullName$ = this.store$.pipe(select(fullName));
        this.businessLinks$ = this.store$.pipe(select(affectedBusinessLinks));
        this.displayCampaignField$ = this.store$.pipe(select(displayCampaignField));
        this.currentOrganizationLabel$ = this.store$.pipe(select(organizationLabel));
        this.isReparationContext$ = this.store$.pipe(select(isReparationContext));
        this.isUserAssignedToSite$ = this.store$.pipe(select(isUserAssignedToSite));
        this.isLeroyMerlin$ = this.store$.pipe(select(isLeroyMerlin));
        this.isNorauto$ = this.store$.pipe(select(isNorauto));
        this.isElectroDepot$ = this.store$.pipe(select(isElectroDepot));
        this.isBL$ = this.store$.pipe(select(isBL));
        this.isAdminPlatana$ = this.store$.pipe(select(isAdminPlatana));
        this.isTechnicalAdmin$ = this.store$.select(isTechnicalAdmin);
        this.isLMorNRorEDTechAdmin$ = combineLatest([
          this.isLeroyMerlin$,
          this.isNorauto$,
          this.isElectroDepot$,
          this.isTechnicalAdmin$,
          this.isAdminPlatana$,
          this.isBL$

        ]).pipe(
          map(([isLeroyMerlin, isNorauto, isElectroDepot, isTechnicalAdmin, isAdminPlatana, isBL]) =>
            (isLeroyMerlin && isTechnicalAdmin) || (isNorauto && isTechnicalAdmin) || (isNorauto && isAdminPlatana) || (isLeroyMerlin && isAdminPlatana) || (isElectroDepot && isTechnicalAdmin) || (isElectroDepot && isAdminPlatana)|| (isBL && isTechnicalAdmin) || (isBL && isAdminPlatana)
          )
        );
    }

    private initLocalStorage() {
        this.localStorage.set('publicConfiguration', null);
    }

    switchContext(context: OrganizationContext): void {
        this.store$.dispatch(new SwitchContext(context.name));
        this.changeLogoAndDocumentTitle(context);
        this.store$.dispatch(new SetDashboardServerInfo(context.dashboardDataSourceId, context.dashboardSiteId));
    }

    changeLogoAndDocumentTitle(context: OrganizationContext): void {
        this.pageLogoPath = context?.asterLogo || 'assets/images/logos/logo_aster.png';
        const favicon = context.favicon || 'assets/images/logos/Aster_Favicon.png';
        this.document.getElementById('sav-favicon').setAttribute('href', favicon);

        switch (context.name) {
            case Context.SAV:
            case Context.REPARATION:
            case Context.DIAGNOSTIC:
                !!context.title ? this.setBrowserTabTitle(context.title) : this.setBrowserTabTitleFromI18n('DOCUMENT.TITLE.ASTER');
                break;
            case Context.PRODUCT_RECALL:
                const oleaLogo = 'assets/images/logos/logo-olea.png';
                this.pageLogoPath = context.asterLogo || oleaLogo;
                this.document.getElementById('sav-favicon').setAttribute('href', context.favicon || oleaLogo);
                !!context.title ? this.setBrowserTabTitle(context.title) : this.setBrowserTabTitleFromI18n('DOCUMENT.TITLE.OLEA');
                break;
            default:
                this.setBrowserTabTitleFromI18n('BO');
        }
    }

    private setBrowserTabTitleFromI18n(i18nKey: string): void {
        this.translateService.get(i18nKey).subscribe((text: string) => {
            this.setBrowserTabTitle(text);
        });
    }

    private setBrowserTabTitle(text: string): void {
        this.titleService.setTitle(text);
        this.localStorage.set(Constants.PLATFORM, text);
    }

    logout(): void {
        this.localStorage.clear();
        this.store$.dispatch(new Logout());
    }

    navigateToApiMetrics() {
        this.router.navigate(['/api-metrics']);
    }

    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    setLanguage(lang): void {
        if (this.selectedLanguage.id !== lang.id) {
            this.selectedLanguage = lang;
            this.store$.dispatch(new UpdateLanguage(lang.id));
        }
    }

    initPushNotifications(): any {
        this.page = 0;
        if (this.page <= this.totalPages) {
            const pushNotificationFilterRequest: any = {
                status: this.statusNotification
            };
            if (this.currentOrganizationCode && this.currentOrganizationCode !== 'ALL') {
                this.anotherSubscription = this.pushNotificationService.getAllPushNotifications(this.page, this.totalElement, pushNotificationFilterRequest)
                    .subscribe(response => {
                        this.initVariablePushNotification();
                        if (!!response && response.content.length > 0) {
                            this.pushNotifications = response.content;
                            this.pushNotificationsResponse = response;
                            this.totalPages = response.totalPages;
                            this.totalElementNotification = response.totalElements;
                        } else {
                            this.pushNotifications = [];
                        }
                    }, err => {
                        console.log('ERROR get Push notification details' + err);
                    });
            }
        }
    }

    initFavoritesList(): void {
        this.folderSearchResponse = null;
        this.favoritesFoldersList = [];
        this.page = 0;
        this.getFavoritesFoldersList();
    }

    onScrollDown(): void {
        if (this.page <= this.totalPages) {
            const pushNotificationFilterRequest: any = {
                status: this.statusNotification
            };
            this.anotherSubscription = this.pushNotificationService.getAllPushNotifications(this.page, this.totalElement, pushNotificationFilterRequest)
                .subscribe(response => {
                    this.initVariablePushNotification();
                    if (!!response && response.content.length > 0) {
                        this.pushNotifications = [...this.pushNotifications, ...response.content];
                        this.pushNotificationsResponse = response;
                        this.totalPages = response.totalPages;
                        this.totalElementNotification = response.totalElements;
                    }
                }, err => {
                    console.log('ERROR get Push notification details' + err);
                });
        }
    }

    private initVariablePushNotification(): void {
        this.pushNotificationsResponse = null;
        this.totalPages = 0;
        this.totalElementNotification = 0;
    }

    goToFolderDetails(notification: PushNotification): void {
        this.updateStatus([notification.id]);
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            this.router.navigate(
                ['folder', notification.businessId],
                {queryParams: {showComments: this.isNewCommentAdded(notification)}}
            )
        );
    }

    isNewCommentAdded = (notification: PushNotification) => notification.type === 'INFO' && notification.extraInfo['INFO'] === 'NEW_COMMENT_ADDED';

    updateStatus(notificationIds: string[]): void {
        const pushNotificationUpdateRequest: PushNotificationUpdateRequest = {
            status: 'READ',
        };
        if (notificationIds && notificationIds.length > 0) {
            this.pushNotificationService.updateNotificationsStatus(notificationIds, pushNotificationUpdateRequest.status).subscribe(response => {
            });
        }
    }

    onScrollPushNotifications(event: any): void {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.page++;
            this.onScrollDown();
        }
    }

    onScrollFavoritesFolders(event: any): void {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.page++;
            if (this.page <= this.totalPages) {
                this.getFavoritesFoldersList();
            }
        }
    }

    roleIsRepairerAuthorized(): boolean {
        return !this.router.url.startsWith('/public');
    }

    getFavoritesFoldersList(): void {
        const folderFilterRequest: SearchFoldersRequestModel = {
            searchValue: null,
            filters: new Map(),
            page: this.page,
            size: 10
        };
        folderFilterRequest.filters['tags'] = new Filter(TagEnums.FAVORITES);
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.folderService.getCustomFolders(folderFilterRequest)
            .subscribe(folderSearchResponse => {
                    if (folderSearchResponse) {
                        this.folderSearchResponse = folderSearchResponse;
                        this.folderSearchResponse.content.forEach(folder => {
                            folder = this.updateFoldersFavorites(folder);
                        });
                        this.favoritesFoldersList = [...this.favoritesFoldersList, ...this.folderSearchResponse.content];
                        this.totalPages = this.folderSearchResponse.totalPages;
                    }
                    this.store$.dispatch(new StopLoading());
                },
                reason => {
                    this.store$.dispatch(new StopLoading());
                    console.log('ERROR get Folder details' + reason);
                });

    }

    updateFoldersFavorites(folder): FolderIndex {
        if (!!folder.newWarranty && !!folder.operationMode) {
            const selectedWarranty = WARRANTY_CONFIGS.filter(el => el.key === folder.newWarranty.toString())[0];
            const selectedModOp = OPERATING_MODES_CONFIGS.filter(el => el.key === folder.operationMode.toString())[0];
            folder.newWarranty = selectedWarranty;
            folder.operationMode = selectedModOp;
        }
        return folder;
    }

    openFolder(folderId: string): void {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['folder', folderId]);
        });
    }

    translationMessageNotification(key: string, pushNotificationType: string): string {
        let messageNotification = this.translateService.instant('PUSH.NOTIFICATION.ERROR.COMMON');
        if (this.translateService.instant('PUSH.NOTIFICATION.' + pushNotificationType + '.' + key) !== 'PUSH.NOTIFICATION.' + pushNotificationType + '.' + key) {
            messageNotification = this.translateService.instant('PUSH.NOTIFICATION.' + pushNotificationType + '.' + key);
        }
        return messageNotification;
    }

    filterListFolderByCampaign(businessLink: string): void {
        this.store$.dispatch(new SwitchBusinessLink(businessLink));
    }

    handleOrganizationChanged(organization): void {
        if (organization) {
            this.store$.dispatch(new SwitchOrganization(organization));

            this.store$.pipe(
                select(contextAttributes),
                take(1)
            ).subscribe((contexts) => {
                this.availableContexts = organization.organizationContexts
                    .filter(availableContext => contexts.includes(availableContext.name));
                this.initPushNotifications();
                let organizationContext: OrganizationContext;
                if (this.readyToSwitchContext()) {
                    organizationContext = this.availableContexts[0];
                    this.store$.dispatch(new SwitchContext(organizationContext.name));
                } else {
                    organizationContext = this.availableContexts.find(availableContext => availableContext.name === this.currentContext);
                }
                this.changeLogoAndDocumentTitle(organizationContext);
                this.store$.dispatch(new FirstLoad(false));
                this.store$.dispatch(new SetTemplateId(organizationContext.genericMailTemplateId));
                this.store$.dispatch(new SetEnableStockClaimStatus(organization.enableStockClaim));
                this.store$.dispatch(new ClaimWithoutCustomer(organization.claimWithoutCustomer));
                this.store$.dispatch(new SetMargin(organizationContext.marginPercentage));
                this.store$.dispatch(new SetDashboardServerInfo(organizationContext.dashboardDataSourceId, organizationContext.dashboardSiteId));
            });
        }
    }

    hasAvailableContexts = () => this.availableContexts && this.availableContexts.length > 0;
    readyToSwitchContext = () => this.hasAvailableContexts()
        && (this.checkIfContextProductRecallExist() || this.currentContextNotExistInAvailableContexts())

    private checkIfContextProductRecallExist(): boolean {
        return this.availableContexts.map(context => context.name).includes(Context.PRODUCT_RECALL) &&
            (this.availableContexts[0].name === Context.PRODUCT_RECALL || this.currentContext === Context.PRODUCT_RECALL);
    }

    private currentContextNotExistInAvailableContexts(): boolean {
        return !this.availableContexts.map(context => context.name).includes(this.currentContext);
    }

    hasScope = (scope) => this.store$.pipe(select(hasScope, {scope: scope}));

    protected readonly Scopes = Scopes;
}

