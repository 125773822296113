import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ReparationReport} from '../../../../models/reparationReport/reparation-report.model';
import {FolderPublic} from '../../../../models/folder.public.model';
import {PublicService} from '../../../../shared/services/public.service';
import {RepairerReportModalComponent} from './repairer-report-modal/repairer-report-modal.component';
import {SingleUploadModalComponent} from '../../../folder/single-upload-modal/single-upload-modal.component';
import {FileInfo} from '../../../../models/file-info.model';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {CommentAuthor} from '../../../../models/enums/commentAuthor.enum';
import {QuotationStatus} from '../../../../models/enums/quotationStatus.enum';
import {AttachmentType, AttachmentTypeSharedWithEnum} from '../../../../models/attachment-type.model';
import {Unsubscriber} from '../../../../unsubscriber';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from 'app/store/organization/organization.selectors';
import {AppState} from '../../../../store/app.state';

@Component({
    selector: 'app-repairer-in-progress',
    templateUrl: './repair-in-progress.component.html',
    styleUrls: ['./repair-in-progress.component.scss']
})
export class RepairInProgressComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: FolderPublic;
    @Output() commentGiven = new EventEmitter<any>();
    repairStatusForm: any = {
        form: null,
        placeholders: []
    };
    currentLanguage: string;

    reportLineColumns: string[] = ['code', 'label', 'type'];
    reportLineDataTable = new MatTableDataSource<any>();

    reparationReport: ReparationReport;
    attachments: FileInfo[] = [];

    displayedColumns: string[] = ['file', 'date', 'attachmentType', 'action'];

    allowedAttachmentTypes: AttachmentType[] = [];


    constructor(private publicService: PublicService,
                private matDialog: MatDialog,
                private store$: Store<AppState>) {
        super();

    }


    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.currentLanguage = language;
                this.getAllowedListAttachmentTypes(AttachmentTypeSharedWithEnum.REPAIRER, this.folder.externalUid);
            });
        this.initForm();
        this.checkQuotation();
        this.attachments = this.folder.attachments;
    }

    getAllowedListAttachmentTypes(sharedWith: string, uid: string) {
        this.publicService.getAllowedListAttachmentTypes(sharedWith, uid, this.currentLanguage).subscribe(
            value => {
                if (value){
                    this.allowedAttachmentTypes = value;
                }
            }
        );
    }

    private checkQuotation(): void {
        const quotation = this.folder.publicQuotations
            .find(quotation => quotation.status === QuotationStatus.ACCEPTED && quotation.target === 'DISTRIBUTOR');
        if (!!quotation && !!quotation.quotationLines && quotation.quotationLines.length > 0) {
            this.reportLineDataTable.data = this.prepareReportLine(quotation.quotationLines);
        }
    }

    private initForm(): void {
        this.repairStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            carrierCode: new FormControl(null),
            trackingNumber: new FormControl(null),
            description: new FormControl(''),
            comment: new FormControl(''),
        });
        this.repairStatusForm.placeholders = [
            {
                status: 'NOT_REPAIRABLE',
                value: 'COMPONENT.FOLDER_CLOSED_HS_PRODUCT_DESTRUCTION',
            },
            {
                status: 'FINISHED',
                value: 'COMPONENT.REPARATION.FINISHED',
            }
        ];
    }

    updateFolder(): void {
        this.publicService.updateReparationReport(this.folder.externalUid, this.reparationReport).subscribe(folder => {
            this.sendComment();
            this.inputMap.emit({
                'reparationStatus': this.repairStatusForm.form.value.status,
                'report': JSON.stringify(this.reparationReport),
                'carrierCode': this.repairStatusForm.form.value.carrierCode,
                'trackingNumber': this.repairStatusForm.form.value.trackingNumber,
                'attachments': JSON.stringify(this.getAttachmentsToAdd())
            });
        });
    }

    getAttachmentsToAdd(): FileInfo[] {
        return this.attachments.filter(item => this.folder.attachments.indexOf(item) < 0);
    }

    onSubmit(): void {
        if (this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE') {
            this.inputMap.emit({
                'reparationStatus': this.repairStatusForm.form.value.status,
                'commentRepairerNotRepairable': this.repairStatusForm.form.value.description,
                'attachments': JSON.stringify(this.getAttachmentsToAdd())
            });
            return;
        }
        if (this.repairStatusForm.form.value.status === 'FINISHED') {
            this.reparationReport = {
                reportLine: this.reportLineDataTable.data
            };
            this.updateFolder();
        }
    }

    private sendComment(): void {
        if (this.repairStatusForm.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.repairStatusForm.form.value.comment,
                type: CommentType.COMMENT_REPAIRABLE,
                from: CommentAuthor.REPAIRER
            });
        }
    }

    openQuotationModal(): void {
        const initData = {
            reportLine: this.reportLineDataTable.data,
            folder: this.folder
        };
        const dialogRef = this.matDialog.open(RepairerReportModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: initData
        });
        dialogRef.afterClosed().subscribe(dataReport => {
                if (!!dataReport) {
                    this.reportLineDataTable.data = dataReport.reportLine;
                }
            }
        );
    }

    private prepareReportLine(data) {
        const reportLine = [];
        data.forEach(value => {
            reportLine.push(
                {
                    code: value.code,
                    label: value.label,
                    type: value.type,
                    payer: value.payer,
                    quantity: value.quantity,
                    price: !!value.priceAmount ? value.priceAmount.value : value.price,
                    tva: !!value.vat ? value.vat.rate : value.tva,
                    discountReason: value.discountReason,
                    discount: value.discount,
                    totalLinePrice: this.getTotalLinePrice(value),
                    currency: this.folder.currency
                }
            );
        });
        return reportLine;
    }

    private getTotalLinePrice(value) {
        return !!value.totalLinePrice ? value.totalLinePrice.value : 0;
    }

    disabledButtonForm(): boolean {
        if (this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE') {
            return false;
        }
        if (this.repairStatusForm.form.value.status === 'FINISHED') {
            return !(!!this.repairStatusForm.form.value.carrierCode && !!this.repairStatusForm.form.value.trackingNumber);
        }
        return !this.repairStatusForm.form.value.status;
    }

    public isNotRepairable(): boolean {
        return this.repairStatusForm.form.value.status === 'NOT_REPAIRABLE';
    }

    onFileUpload() {
        const dialogRef = this.matDialog.open(SingleUploadModalComponent, {
            disableClose: true,
            data: {
                attachmentTypes: this.allowedAttachmentTypes,
                uploadAttachment: true,
                folderId: this.folder.externalUid,
                organizationCode: this.folder.organizationCode
            }
        });
        dialogRef.afterClosed().subscribe(
            data => {
                if (!!data) {
                    this.attachments = [...this.attachments, data];
                }
            }
        );
    }
}
