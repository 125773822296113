<mat-card-content>
    <div fxLayout="column" fxLayoutAlign="start">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
        <div class="text-center pt-44 pb-44 w-100-p">
            <mat-icon class="icon-wait_payment">payment</mat-icon>
            <h3 class=" text-bold">{{titleCustomerToPay$ | async}}</h3>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end  center" fxLayoutGap="10px">
        <button mat-stroked-button
                type="submit"
                [ngClass]="'aster_btn'"
                (click)="switchPaymentMode()">
            {{'FOLDER.WORKFLOW.WAITING_PAYMENT.SWITCH_PAYMENT_MODE' | translate}}
        </button>
    </div>
</mat-card-content>
