export enum ConfigCodeMapsEnum {
    SYMPTOMS = 'SAV.CONFIG.SYMPTOMS',
    CONDITIONS = 'SAV.CONFIG.CONDITIONS',
    REPAIR = 'SAV.CONFIG.REPAIR',
    FAMILY = 'SAV.CONFIG.FAMILY_1',
    SUB_FAMILY = 'SAV.CONFIG.FAMILY_2',
    TYPE = 'SAV.CONFIG.FAMILY_3',
    SUB_TYPE = 'SAV.CONFIG.FAMILY_4',
    SEGMENT = 'SAV.CONFIG.FAMILY_5',
    BRANDS = 'SAV.CONFIG.BRANDS',
    PRODUCT_IDENTIFICATION_BRAND = 'SAV.CONFIG.IDENTIFICATION_PRODUCT_BRAND',
    PRODUCT_IDENTIFICATION_CATEGORY = 'SAV.CONFIG.IDENTIFICATION_PRODUCT_CATEGORY',
    DIAGNOSTIC = 'SAV.CONFIG.DIAGNOSTIC',
    CARRIER = 'SAV.CONFIG.CARRIER',
    CARRIER_RULE = 'SAV.RULES.CARRIERS',
    REPAIRER = 'SAV.CONFIG.REPAIR_CENTER',
    SYMPTOMS_GROUP = 'SAV.CONFIG.SYMPTOMS_GROUP',
    WARRANTY_REASON_SG = 'SAV.CONFIG.WARRANTY_REASON_SG',
    WARRANTY_REASON_HG = 'SAV.CONFIG.WARRANTY_REASON_HG',
    MOD_OP = 'SAV.CONFIG.MODE_OP',
    PRODUCT_STATE= 'SAV.CONFIG.PRODUCT_STATE',
    SKILLS = 'SAV.CONFIG.SKILLS',
    SPARE_PARTS = 'SAV.CONFIG.SPARE_PARTS',
    SERVICES = 'SAV.CONFIG.SERVICES',
    ACCESSORY = 'SAV.CONFIG.ACCESSORY',
    SITE = 'SAV.CONFIG.SITE',
    DASHBOARD_LABEL = 'SAV.CONFIG.DASHBOARD.LABEL',
    IRIS_DEFECTS = 'SAV.CONFIG.IRIS_DEFECTS',
    IRIS_SYMPTOMS = 'SAV.CONFIG.IRIS_SYMPTOMS',
    IRIS_SECTIONS = 'SAV.CONFIG.IRIS_SECTIONS',
    SPARE_PART_NOMENCLATURE_RAY  = 'SAV.CONFIG.SPARE_PART_NOMENCLATURE_RAY',
    SPARE_PART_NOMENCLATURE_SUB_RAY  = 'SAV.CONFIG.SPARE_PART_NOMENCLATURE_SUB_RAY',
    SPARE_PART_NOMENCLATURE_TYPE  = 'SAV.CONFIG.SPARE_PART_NOMENCLATURE_TYPE',
    SPARE_PART_NOMENCLATURE_SUB_TYPE  = 'SAV.CONFIG.SPARE_PART_NOMENCLATURE_SUB_TYPE',
    SPARE_PART_NOMENCLATURE_SEGMENT  = 'SAV.CONFIG.SPARE_PART_NOMENCLATURE_SEGMENT',
    SPARE_PART_LOCATION = 'SAV.CONFIG.SPARE_PART_LOCATION',
    SPARE_PART_SIDE = 'SAV.CONFIG.SPARE_PART_SIDE',
    SPARE_PART_ALLEY = 'SAV.CONFIG.SPARE_PART_ALLEY',
    SPARE_PART_LEVEL = 'SAV.CONFIG.SPARE_PART_LEVEL',
    SPARE_PART_MEASURE_UNIT = 'SAV.CONFIG.SPARE_PART_MEASURE_UNIT',
    SPARE_PART_CONDITIONING = 'SAV.CONFIG.SPARE_PART_CONDITIONING',
    TYPE_OF_SERVICE = 'SAV.CONFIG.TYPE_OF_SERVICE',
    REPAIR_PACKAGES = 'SAV.CONFIG.REPAIR_PACKAGES',
    SUPPLIER_CONTACTS = 'SAV.CONFIG.SUPPLIER_CONTACTS',
    ATTACHMENT_TYPE = 'SAV.CONFIG.ATTACHMENT_TYPE',


}
