import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {Quotation} from '../../../../../../models/quotation.model';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {AppState} from '../../../../../../store/app.state';
import {currency} from '../../../../../../store/organization/organization.selectors';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {TaskVariables} from '../../../task.variables';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {FolderSubjectService} from '../../../../folder-subject.service';

@Component({
    selector: 'app-quotation-acceptation-distributor',
    templateUrl: './quotation-acceptation-distributor.component.html',
    styleUrls: ['./quotation-acceptation-distributor.component.scss']
})
export class QuotationAcceptationDistributorComponent extends Unsubscriber implements OnInit {

    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;
    folder: Folder;
    quotation: Quotation;
    quotationId: string;
    currency: string;
    attachments = new Array<any>();

    quotationChoiceForm: FormGroup = new FormGroup({});
    quotationChoices = [];
    constructor(
        private folderService: FolderService,
        private store$: Store<AppState>,
        private folderSubjectService: FolderSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.initQuotationChoiceForm();
        this.initChoices();
        this.initCurrency();
        this.anotherSubscription = this.folderSubjectService.folder$
                                    .subscribe(folder => {
                                        this.folder = folder;
                                        this.initQuotation();
                                    });

    }

    private initQuotation() {
        this.quotationId = this.variablesTask[TaskVariables.quotationId];

        if (!!this.quotationId) {
            this.quotation = this.folder.quotations
                .find(quotation => quotation.id === this.quotationId);
        }
    }

    private initCurrency() {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
        });
    }

    private initQuotationChoiceForm(): void {
        this.quotationChoiceForm = new FormGroup({
            status: new FormControl(null, Validators.required),
        });
    }


    private initChoices() {
        this.quotationChoices = [
            {
                status: 'ACCEPTED',
                value: 'FOLDER.WORKFLOW.ACCEPT_QUOTATION',
            },
            {
                status: 'RETURNED',
                value: 'FOLDER.WORKFLOW.QUOTATION_ACCEPTATION.RETURNED',
            }
        ];
    }

    stopPropagation(): boolean {
        return this.quotationChoiceForm.invalid;

    }

    completeTask() {
        this.store$.dispatch(new StartLoading());
        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMap.emit({
                'quotationDistributorStatus': this.quotationChoiceForm.value.status
            });
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }

    getAttachments(attachments: any[]) {
        this.attachments = attachments;
    }

    isNegative() {
        return this.quotation?.totalPrice?.value < 0;
    }
}
