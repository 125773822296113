import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TypeOfServiceEnum} from '../../../models/typeOfService.model';
import {Observable, ReplaySubject} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QuotationModalConfig} from './quotationModal.model';
import {Payer} from '../../../models/enums/payer.enum';
import {DataSourceLine, Quotation, QuotationLine} from '../../../models/quotation.model';
import {QuotationType} from '../../../models/enums/quotationType.enum';
import {ConfigCodeMapsEnum} from '../../services/configuration-item-enum';
import {PAYERS_CONFIGS} from '../../data/static.config';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {Amount} from '../../../models/amount.model';
import {Vat} from '../../../models/vat.model';
import {SparePartService} from '../../services/spare-part.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {Unsubscriber} from '../../../unsubscriber';
import {countryCode, currency, vats} from '../../../store/organization/organization.selectors';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {TypeOfBenefitService} from '../../services/typeOfBenefit.service';
import * as accents from 'remove-accents';
import {RelatedSparePart, RelatedSparePartRequest, RelatedSparePartView} from '../../../models/spare-parts/relatedSparePart.model';
import {DatePipe} from '@angular/common';
import {SparePartStock, StockDetails} from '../../../models/spare-parts/stock.model';
import {IFolderUpdateRequest} from '../../../models/folder.model';
import {FolderSubjectService} from '../../../main/folder/folder-subject.service';
import {FolderService} from '../../services/folder.service';
import {IEvent} from '../../../models/events.model';
import * as uuid from 'uuid';
import {RuleEvaluationContext} from '../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../models/enums/productFamilyType.enum';
import {ConfigV2Service} from '../../services/config-v2.service';

@Component({
    selector: 'app-quotation-modal',
    templateUrl: './quotation-modal.component.html',
    styleUrls: ['./quotation-modal.component.scss']
})
export class QuotationModalComponent extends Unsubscriber implements OnInit {
    @Input() variablesTask: any;
    vatsList$: Observable<Vat[]>;
    private defaultVat;

    payers = [];
    sparePartsTypes = [];

    displayedColumns: string[];
    dataSource: DataSourceLine[] = [];
    discountQuotations = 0;
    computeTotalPrice: Amount;
    quotationLines = new MatTableDataSource<DataSourceLine>();
    typeOfServices = new Array<any>();
    filteredData = new ReplaySubject<any[]>(1);

    quotationForm: FormGroup = new FormGroup({});
    isSparePartAdd = false;
    isLoading = false;
    payersData = PAYERS_CONFIGS;
    currency: string;
    private sparePartsRelatedToProduct: RelatedSparePartView[] = [];
    countryCode: string;
    stockSparePartAvailable = true;
    stockSparePartsAvailable = true;
    isAvailable = true;
    availableQuantity = 0;

    events: IEvent [] = [];
    quotation: Quotation;

    quotationTouched = false;

    constructor(private dialogRef: MatDialogRef<QuotationModalComponent>,
                private matDialog: MatDialog,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private typeOfBenefitService: TypeOfBenefitService,
                private store$: Store<AppState>,
                private sparePartService: SparePartService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private configV2Service: ConfigV2Service,
                @Inject(MAT_DIALOG_DATA) public quotationModalConfig: QuotationModalConfig) {

        super();
        this.payers = Object.keys(Payer);
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(code => this.countryCode = code);
        this.initDefaultVat();
        this.initTypeOfBenefitService();

        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
        if (this.quotationModalConfig.loadTypeOfBenefit) {
            this.initTypeOfServices();
        }
        this.initQuotationForm(TypeOfServiceEnum.SPARE_PART);
        this.computeDisplayedColumns();
         if (!!this.quotationModalConfig.initialQuotation) {
            this.loadInitialQuotation();
        }

        if (this.quotationModalConfig.loadSparePartCatalog) {
            this.loadRelatedSparePartViews();
        }
    }



    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.sparePartsTypes.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.sparePartsTypes.push(value);
            });
        });
    }

    private loadRelatedSparePartViews() {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        const product = this.quotationModalConfig.product;
        const request: RelatedSparePartRequest = {
            productCode: product.code,
            eanCode: product.codeEAN,
            supplierCode: product.supplier?.code,
            brand: product.brand,
            family1: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.FAMILY),
            countryCode: this.countryCode,
            managementSiteCode: this.quotationModalConfig.managementSite.code,
            withMedia: false,
        };

        this.sparePartService.getRelatedSpareParts(request)
            .subscribe(spareParts => {
                spareParts.forEach(value => this.sparePartsRelatedToProduct.push(this.buildSparePartView(value)));
                if (this.quotationForm.get('type').value === TypeOfServiceEnum.SPARE_PART) {
                    this.filteredData.next(this.sparePartsRelatedToProduct);
                }
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            });
    }

    private buildSparePartView(value: RelatedSparePart) {
        return {
            id: value.id,
            code: value.code,
            label: value.label,
            price: value?.approximatePrice?.value,
            purchasePrice: value.purchasePrice?.value,
            marginPercentage: value.marginPercentage,
            information: value.information,
            supplier: value.suppliers?.length > 0 ? value.suppliers[0] : null,
            images: value.images
        };
    }

    private computeDisplayedColumns(): void {
        this.displayedColumns = ['type', 'code', 'label', 'price', 'quantity'];
        if (this.isSparePartForm(this.quotationForm)) {
            this.displayedColumns.push('stock');
        }
        this.displayedColumns.push('discount', 'vat', 'totalPrice', 'action');
    }


    initTypeOfServices(): void {
        this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.TYPE_OF_SERVICE]).subscribe(
            response => {
                if (this.quotationModalConfig.workforcesRule?.length > 0) {
                    this.typeOfServices = response.filter(item => item.type !== TypeOfServiceEnum.WORKFORCE);
                } else {
                    this.typeOfServices = response;
                }
            }
        );
    }

    initQuotationForm(type: string): void {
        this.quotationForm = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            payer: new FormControl(this.getPayerForType(type),  Validators.required),
            quantity: new FormControl(1, [Validators.required, Validators.min(1)]),
            price: new FormControl(0, [Validators.required, Validators.min(0)]),
            vat: new FormControl(this.defaultVat, Validators.required),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            type: new FormControl(type, Validators.required),
            discountReason: new FormControl(null),
            totalLinePrice: new FormControl(0)
        });
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    loadInitialQuotation(): void {
        this.dataSource = this.toDataSourceLines(this.quotationModalConfig.initialQuotation.quotationLines);
        this.discountQuotations = this.quotationModalConfig.initialQuotation.discount ? this.quotationModalConfig.initialQuotation.discount : 0;
        this.computeTotalPrice = {
            value: this.quotationModalConfig.initialQuotation.totalPrice?.value,
            currency: this.currency
        } || {
            value: 0,
            currency: this.currency
        };
        if (!this.quotationModalConfig.showFormAddQuotation) {
            this.displayedColumns.splice(7, 0, 'payer');
        }
        this.refreshDataSourceTable();
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    validateForm(): void {
        if (this.hasSparePart()) {
            this.store$.dispatch(new StartLoading());
            const stocksRequest = this.dataSource
                .filter(value => value.type === QuotationType.SPARE_PART)
                .map(data => ({
                    sparePartCode: data.code.trim(),
                    quantity: data.quantity
                }));
            this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stocksRequest)
                .subscribe(stockResponse => {
                    if (!!stockResponse) {
                        if (stockResponse.availabilityStatus === 'AVAILABLE' || stockResponse.availabilityStatus === 'UNKNOWN') {
                            this.stockSparePartsAvailable = true;
                            this.onCloseAfterEdit();
                            this.store$.dispatch(new StopLoading());
                        } else {
                            this.dataSource.map(value => stockResponse.stocks
                                .filter(stock => value.code === stock.sparePartCode)
                                .map(stock => value.stock = stock));
                            this.refreshDataSourceTable();
                            this.stockSparePartsAvailable = false;
                            this.store$.dispatch(new StopLoading());
                        }
                    } else {
                        this.onCloseAfterEdit();
                        this.store$.dispatch(new StopLoading());
                    }
                });
        } else {
            this.onCloseAfterEdit();
        }
    }
    private onCloseAfterEdit() {
        this.prepareQuotation();
        const folderUpdateRequest = {quotation: this.quotation};
        this.updateFolder(folderUpdateRequest);

    }
    private onCloseQuotationModal() {
        this.dialogRef.close({
            quotationLines: this.quotation?.quotationLines,
            quotationId: this.quotation?.id,
            discount: this.discountQuotations,
            totalPrice: this.computeTotalPrice
        });

    }

    private hasSparePart() {
        return this.quotationModalConfig.loadSparePartCatalog && this.dataSource
            .some(value => value.type === QuotationType.SPARE_PART);
    }

    formatAndCalculateTotalQuotationLine(): void {
        const unitPrice = this.quotationForm.controls.price.value;
        this.quotationForm.controls.price.setValue(this.getPriceWithFormatAccordingToCurrency(unitPrice));
        const totalLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalLinePrice());
        this.quotationForm.controls.totalLinePrice.setValue(totalLinePrice);
    }

    private computeTotalLinePrice() {
        const price = this.sparePartService.normalizeFloat(this.quotationForm.controls.price.value);
        return this.sparePartService.getTotalLinePrice(price,
            this.quotationForm.controls.quantity.value, this.quotationForm.controls.discount.value, this.quotationForm.controls.vat.value);
    }

    private getPriceWithFormatAccordingToCurrency(price: number): string {
        return this.sparePartService.formatPriceAccordingToCurrency(price, this.currency);
    }

    changeLinePrice(): void {
        this.dataSource = this.quotationLines.data;
        this.dataSource.forEach(quotationLine => {
            quotationLine.totalLinePrice = this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, this.defaultVat);
        });
        this.refreshDataSourceTable();
    }

    normalize(value): number {
        return Number(this.sparePartService.normalizeFloat(value).toFixed(2));
    }

    getTotalPriceHT(): any {
        return !!this.dataSource ? this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): any {
        if (!!this.dataSource) {
            const totalPriceHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;

    }

    getTotalNetHT(): any {
        if (!!this.dataSource) {
            let totalNetHT;
            totalNetHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.price) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.price) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.price) * line.quantity), 0);
            if (this.discountQuotations > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotations / 100);
            }
            return totalNetHT;
        }
        return 0;

    }

    getTotalPriceTVA(): any {
        if (!!this.dataSource) {
            return this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.price) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = this.defaultVat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    addIntoQuotationLines(): void {
        this.quotationTouched = true;
        if (this.isSparePartForm(this.quotationForm)) {
            this.checkAvailabilityStock();
            this.isSparePartAdd = true;
        } else {
            this.checkDiscountValue();
            const dataSourceLine = this.quotationForm.value;
            this.addAndRefreshDataSourceLine(dataSourceLine);
        }
    }

    private checkAvailabilityStock() {
        this.store$.dispatch(new StartLoading());
        const stockRequest = [{
            sparePartCode: this.quotationForm.value.code.trim(),
            quantity: this.quotationForm.value.quantity
        }];
        this.store$.dispatch(new StartLoading());
        this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stockRequest).subscribe(response => {
            this.checkDiscountValue();
            if (!!response) {
                if (response.availabilityStatus === 'AVAILABLE' || response.availabilityStatus === 'UNKNOWN') {
                    const dataSourceLine = this.quotationForm.value;
                    this.stockSparePartAvailable = true;
                    dataSourceLine.stock = {...(response.stocks[0] || this.getDefaultStock())};
                    this.addAndRefreshDataSourceLine(dataSourceLine);
                    this.isSparePartAdd = false;
                } else {
                    this.stockSparePartAvailable = false;
                    this.isSparePartAdd = false;
                    this.store$.dispatch(new StopLoading());
                }
            } else { // organization hasn't api availability
                const dataSourceLine = this.quotationForm.value;
                this.checkApiStock(dataSourceLine);
                this.addAndRefreshDataSourceLine(dataSourceLine);
                this.isSparePartAdd = false;
            }

        });
    }

    private checkDiscountValue() {
        if (!this.quotationForm.value.discount) {
            this.quotationForm.get(['discount']).setValue(0);
        }
    }

    private addAndRefreshDataSourceLine(dataSourceLine) {
        this.dataSource.unshift(dataSourceLine);
        this.refreshDataForm(TypeOfServiceEnum.SPARE_PART);
        this.refreshDataSourceTable();
    }

    private checkApiStock(dataSourceLine) {
        dataSourceLine.stock = {isLoading: true};
        this.sparePartService.getStocks([dataSourceLine.code.trim()], this.quotationModalConfig.managementSite.code).subscribe(stocks => {
                dataSourceLine.stock = {...stocks[0], isLoading: false};
                this.availableQuantity = stocks[0]?.totalStock;
            },
            error => dataSourceLine.stock.isLoading = false,
            () => dataSourceLine.stock.isLoading = false);
    }

    refreshDataForm(type: string): void {
        this.initQuotationForm(type);
        this.stockSparePartAvailable = true;
        if (type === TypeOfServiceEnum.SPARE_PART) {
            this.filteredData.next(this.sparePartsRelatedToProduct);
        } else if (type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig?.workforcesRule?.length > 0) {
            this.loadWorkforce(type);
        } else {
            this.changeFileredTypeOfService(type);
        }
    }

    private loadWorkforce(type: string) {
        this.filteredData.next(this.quotationModalConfig.workforcesRule);
    }

    private changeFileredTypeOfService(type) {
        const typeOfService = this.typeOfServices.filter(item => item.type === type);
        this.filteredData.next(typeOfService);
    }

    deleteLine(indexLine: number): void {
        this.quotationTouched = true;
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.dataSource = this.dataSource.filter((item, index) => index !== indexLine || this.isFeesQuotation(item));
                this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
                const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
                this.computeTotalPrice = {
                    value: totalPrice,
                    currency: this.currency
                };
            }
        });

    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [];
        this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
        const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
        this.store$.dispatch(new StopLoading());
    }

    private hideQuotationFees(service: string) {
        return !(!!this.dataSource.find(line => line.type === 'QUOTATION_FEES') && service === 'QUOTATION_FEES');
    }


    onCodeChanges(): void {
        const type = this.quotationForm.get('type').value;
        const code = this.quotationForm.get('code').value?.trim();

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('code', code);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig.workforcesRule?.length > 0) {
            this.searchWorkForce('code', code);
        }
    }

    onLabelChanges(): void {
        const type = this.quotationForm.get('type').value;
        const label = this.quotationForm.get('label').value;

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('label', label);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.quotationModalConfig.workforcesRule?.length > 0) {
            this.searchWorkForce('label', label);
        }
    }

    private searchWorkForce(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.quotationModalConfig.workforcesRule.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);
        } else {
            this.filteredData.next(this.quotationModalConfig.workforcesRule);
        }
    }

    private searchSpareParts(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.sparePartsRelatedToProduct.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);

        } else {
            this.filteredData.next(this.sparePartsRelatedToProduct);
        }
    }

    sparePartChanged(event): void {
        this.initQuotationForm(this.quotationForm.controls.type.value);

        this.quotationForm.controls.price.setValue(event.option.value.price);
        this.quotationForm.controls.code.setValue(event.option.value.code.trim());
        this.quotationForm.controls.label.setValue(event.option.value.label);
        this.quotationForm.controls.supplier.setValue(event.option.value.supplier);


        if (event.option.value.payer) {
            this.quotationForm.controls.payer.setValue(event.option.value.payer);
        }
        this.formatAndCalculateTotalQuotationLine();
    }

    isFeesQuotation(quotationLine: DataSourceLine): boolean {
        return quotationLine.type.startsWith(QuotationType.QUOTATION_FEES) && quotationLine.price < 0;
    }

    toDataSourceLines(quotationLines: QuotationLine[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        if (quotationLines?.length > 0) {
            quotationLines.forEach(quotationLine => {
                // map some specific values
                const line = {
                    ...quotationLine,
                    vat: Number(quotationLine.vat?.rate),
                    price: quotationLine.priceAmount.value,
                    purchasePrice: quotationLine.purchasePrice?.value,
                    totalLinePrice: quotationLine.totalLinePrice?.value,
                    payer: this.quotationModalConfig.payer ?? quotationLine.payer
                };
                if (quotationLine.type === 'SPARE_PART') {
                    line.stock = {isLoading: true};
                }
                const {priceAmount, ...dataSourceLine} = line;
                dataSourceLines.push(dataSourceLine);
            });
            this.fetchStocksOfDisplayedSpareParts(dataSourceLines);
        }
        return dataSourceLines;
    }



    private prepareStockRequest(quotationLines) {
        return quotationLines
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(quotationLine => ({
                sparePartCode: quotationLine.code.trim(),
                quantity: quotationLine.quantity
            }));
    }

    private fetchStocksOfDisplayedSpareParts(dataSourceLines: DataSourceLine[]): void {
        const stockRequest = this.prepareStockRequest(dataSourceLines);
        if (!!stockRequest && stockRequest.length > 0) {
            this.sparePartService.getAvailabilityOfStocks(this.quotationModalConfig.managementSite.code, stockRequest).subscribe(response => {
                if (!!response) {
                    this.isAvailable = response.availabilityStatus === 'AVAILABLE';
                    this.merge(dataSourceLines, response.stocks);
                } else {
                    const sparePartCodes = dataSourceLines.map(data => data.code);
                    this.sparePartService.getStocks(sparePartCodes.map(code => code.trim()), this.quotationModalConfig.managementSite.code)
                        .subscribe(stocks => this.merge(dataSourceLines, stocks));
                }
            });
        }
    }

    private merge(quotationLines: DataSourceLine[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        quotationLines.forEach(quotationLine => {
            quotationLine.stock = stocksByCode.get(quotationLine.code) ?? this.getDefaultStock();
            quotationLine.stock.isLoading = false;
        });
    }

    private getDefaultStock(): SparePartStock {
        return {
            status: 'UNKNOWN',
            isLoading: false
        } as SparePartStock;
    }

    toQuotationLines(dataSourceLines: DataSourceLine[]): QuotationLine[] {
        const quotationLines: QuotationLine[] = [];
        dataSourceLines.forEach(dataSourceLine => {
            const quotationLine: QuotationLine = {
                ...dataSourceLine,
                type: dataSourceLine.type,
                payer: dataSourceLine.payer,
                vat: {rate: String(dataSourceLine.vat)},
                priceAmount: {
                    value: this.sparePartService.normalizeFloat(dataSourceLine.price),
                    currency: this.currency
                },
                purchasePrice: {
                    value: this.getPrice(dataSourceLine),
                    currency: this.currency
                },

                totalLinePrice: {
                    value: this.sparePartService.normalizeFloat(dataSourceLine.totalLinePrice),
                    currency: this.currency
                }
            };

            delete quotationLine['price'];
            quotationLines.push(quotationLine);
        });

        return quotationLines;
    }

    hasStock(status: string): boolean {
        return status && status === 'AVAILABLE';
    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case 'AVAILABLE':
                return 'green-color';
            case 'NOT_AVAILABLE':
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    displayStockStatus(status: string, type: string): string {
        if (type !== 'SPARE_PART') {
            if ('UNKNOWN' === status || !status) {
                return 'TEXT.UNKNOWN';
            }
        } else {
            if ('UNKNOWN' === status) {
                return 'TEXT.UNKNOWN';
            }
            if (!status) {
                return 'TEXT.UNDEFINED';
            }
        }

        if (status === 'AVAILABLE') {
            return 'TEXT.AVAILABLE';
        }

        if (status === 'NOT_AVAILABLE') {
            return 'TEXT.UNAVAILABLE';
        }
    }

    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }

    private getPayerForType(type: string): string {
        if (this.quotationModalConfig.payer) {
            return this.quotationModalConfig.payer;
        } else if (!!this.quotationModalConfig.payers) {
            if (TypeOfServiceEnum.DISPLACEMENT === type) {
                return this.quotationModalConfig.payers.displacement;
            } else if (TypeOfServiceEnum.WORKFORCE === type) {
                return this.quotationModalConfig.payers.workforce;
            }
            return this.quotationModalConfig.payers.spareParts;
        }
        return null;
    }

    isSparePartForm(form): boolean {
        return form.get('type')?.value === 'SPARE_PART' && this.quotationModalConfig.loadSparePartCatalog;
    }

    disabledForm(): boolean {
        return this.quotationLines.data.length <= 0 || (this.quotationModalConfig.loadSparePartCatalog && !this.isAvailable)
                || !this.quotationLines.data.every(value => value.price ?? false) || this.computeTotalPrice?.value < 0;

    }



    private getPrice(dataSourceLine: DataSourceLine) {
        return dataSourceLine.purchasePrice > 0 ? this.sparePartService.normalizeFloat(dataSourceLine.purchasePrice) : this.sparePartService.normalizeFloat(dataSourceLine.price);
    }


    get filteredSparePartsTypes() {
        return this.sparePartsTypes.filter((service) => this.hideQuotationFees(service.code));
    }

    private prepareQuotation() {
        if (this.quotationModalConfig.initialQuotation) {
            this.quotation = this.quotationModalConfig.initialQuotation;
        } else {
            this.quotation = new Quotation();
        }
        if (!this.quotation.id) {
            this.quotation.id = uuid.v4();
            this.quotationTouched = true;
        }
        this.quotation.quotationLines = this.toQuotationLines(this.dataSource);
        this.quotation.discount = this.discountQuotations;
        this.quotation.totalPrice = this.computeTotalPrice;
        this.quotation.target = 'DISTRIBUTOR';

    }


    private updateFolder(folderUpdateRequest: IFolderUpdateRequest) {
        if (!this.quotationTouched) {
            this.onCloseQuotationModal();
            return;
        }
        this.folderService.updateFolder(this.quotationModalConfig.folderId, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.onCloseQuotationModal();
            this.store$.dispatch(new StopLoading());
        });
    }


}
