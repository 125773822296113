import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardV2Service} from '../../../../shared/services/dashboard-v2.service';
import {switchMap, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {PageEvent} from '@angular/material/paginator';
import {FormGroup} from '@angular/forms';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {currentUser} from '../../../../store/user/user.selectors';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfigExtraCodesEnum} from '../../../../models/codeLabelObjectWithExtra.model';
import {MatDialog} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {Column, GridComponent, PageSettingsModel} from '@syncfusion/ej2-angular-grids';
import {DashboardRule} from '../../../../models/dashboard/dashboard-rule.model';
import {Unsubscriber} from '../../../../unsubscriber';

@Component({
    selector: 'config-dashboard',
    templateUrl: './config-dashboard.component.html',
})
export class ConfigDashboardComponent extends Unsubscriber implements OnInit, OnDestroy {

    @ViewChild('dashboardGridComponent') dashboardGridComponent: GridComponent;
    dataSource = new MatTableDataSource();
    dashboards: DashboardRule[];
    pageSettings: PageSettingsModel;
    filterForm: FormGroup;
    page = {index: 0, size: 10, totalElements: 0, sizeOptions: [10, 25, 50, 100]};
    columns = ['dashboard', 'user', 'role', 'siteCode', 'businessLink', 'actions'];
    fields: any[];
    isSAVContext = false;

    constructor(private dashboardService: DashboardV2Service,
                private store$: Store<AppState>,
                private dialog: MatDialog,
                private snackBar: SnackBarService,
                private translateService: TranslateService,
                private router: Router) {
        super();
        this.pageSettings = {pageSizes: this.page.sizeOptions, pageSize: this.page.size};
    }

    ngOnInit() {
        this.getDashboards();
        this. anotherSubscription = this.store$.select(currentUser).pipe(take(1)).subscribe(user => {
            this.isSAVContext = user.context === 'SAV';
        });
    }

    private getDashboards() {
        this.anotherSubscription = this.dashboardService.search({page: 0, size: 100}, {}).subscribe({
                next: (dashboards) => {
                    this.dashboards = dashboards.content;
                    this.dashboardGridComponent.refresh();
                },
            }
        );
    }

    create() {
        this.router.navigate(['dashboard-v2/create']);
    }

    edit(element) {
        this.router.navigate(['dashboard-v2/edit', element.id]);
    }

    delete(element) {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });

        dialogRef.componentInstance.title = 'CONFIGURATION.DASHBOARD_V2.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'CONFIGURATION.DASHBOARD_V2.DELETE_DIALOG.MESSAGE';

        dialogRef.afterClosed().subscribe(isConfirmed => {
            if (isConfirmed) {
                this.anotherSubscription = this.dashboardService.deleteRule(element.id)
                    .pipe(switchMap(() => this.dashboardService.deleteDashboard(element.dashboard.dashboardId)))
                    .subscribe(() => {
                        this.snackBar.openAtStart(
                            'Success',
                            'CODE_LABEL_CONFIG.FORM.TOAST_MESSAGES.SUCCESS_DELETE',
                            {
                                configCodeName: this.translateService.instant(`CODE_LABEL_CONFIG.CONFIG_CODES_TITLES.${ConfigExtraCodesEnum.DASHBOARD_V2}`)
                            });
                        this.getDashboards();
                    });
            }
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
    }
}



