import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../models/folder.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FolderService} from '../../../shared/services/folder.service';
import {WorkflowService} from '../../../shared/services/workflow.service';
import {Permission} from '../../../models/permession.model';
import {GENERIC_COMPONENTS_TASKS} from './generic-components-tasks';
import {FolderSubjectService} from '../folder-subject.service';
import {TaskVariables} from './task.variables';
import {IWorkflowProcess} from '../../../models/workflow.model';
import {Product} from '../../../models/product.model';
import {Context} from '../../../models/enums/context.enum';
import {StatusEnum} from '../../../models/enums/StatusEnum';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {currentUser, hasAccessToFolder, hasScope} from '../../../store/user/user.selectors';
import {UserState} from '../../../store/user/user.state';
import {Unsubscriber} from '../../../unsubscriber';
import {switchMap, take} from 'rxjs/operators';
import {CommentAuthor} from '../../../models/enums/commentAuthor.enum';
import {Observable, of} from 'rxjs';
import * as moment from 'moment';
import {WebsocketService} from '../websocket/websocket.service';
import {OrderSubjectService} from '../../../shared/services/order-subject.service';
import {OmsService} from 'app/shared/services/oms.service';
import {AppFeatures} from '../../../shared/features/app-features';
import {GrowthbookService} from '../../../shared/services/growthbook.service';
import {IEvent} from '../../../models/events.model';
import {BackOfficeService} from '../../../shared/services/back-office.service';
import {LoadingScreenService} from '../../../shared/services/loading-screen.service';
import {Title} from '@angular/platform-browser';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CodeToLabelService} from '../../../../@fuse/services/code-to-label.service';
import {Constants} from '../../../Constants';

@Component({
    selector: 'app-folder-workflow',
    templateUrl: './folder-workflow.component.html',
    styleUrls: ['./folder-workflow.component.scss']
})
export class FolderWorkflowComponent extends Unsubscriber implements OnInit, OnDestroy {
    folderId: string;
    surveyResponseId: string;
    folder: Folder;
    genericComponent: any;
    permission: Permission;
    inputDynamicData = {
        folder: null,
        variablesTask: null,
        permission: null,
        workflowStatus: null,
        processName: null,
        instructionUserTask: null
    };
    outputDynamicData = {
        inputMap: mapInput => this.completeTaskWorkflow(mapInput),
        refreshWorkflowStatus: () => this.getCurrentTaskWorkflow(),
        previewTask: previewTask => this.getPreviewTask(previewTask),
        commentGiven: comment => this.addCommentToFolder(comment)
    };
    private genericComponents = GENERIC_COMPONENTS_TASKS;
    workflowStatus: string;
    processName: string;
    folderClosed: boolean;
    folderClosedFinished = 'FOLDER_CLOSED_FINISHED';
    product: Product;
    isFolderNotFound: boolean;
    isFolderReason: string;
    FOLDER_NOT_FOUND = 'FOLDER_NOT_FOUND';
    private currentUser: UserState;
    private dateConfirm: Date;
    private dateWaiting: Date;
    private taskPaymentDate: Date;

    constructor(
        private websocketService: WebsocketService,
        private activatedRoute: ActivatedRoute,
        private folderService: FolderService,
        private workflowService: WorkflowService,
        private router: Router,
        public folderSubjectService: FolderSubjectService,
        private orderSubjectService: OrderSubjectService,
        private omsService: OmsService,
        private growthbookService: GrowthbookService,
        private store$: Store<AppState>,
        private loadingScreenService: LoadingScreenService,
        private backOfficeService: BackOfficeService,
        private codeToLabelService: CodeToLabelService,
        private readonly titleService: Title,
        @Inject(LOCAL_STORAGE) private localStorage: StorageService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.codeToLabelService.cache$.pipe(switchMap(() => {
            return this.backOfficeService.getInstructionUserTask();
        })).subscribe(
            response => {
                this.codeToLabelService.setInstructionUserTasks(response);
                this.setInstructionUserTaskCode();
            }
        );
        this.beforeUnloadHandler();
        this.folderId = this.activatedRoute.snapshot.params.folderId;
        this.anotherSubscription = this.store$.pipe(
            select(currentUser),
            take(1)
        ).subscribe(user => {
            this.permission = {
                canModifyCustomerAddress: true,
                canModifyWarranty: true,
            };
            this.currentUser = user;
            this.getFolderDetails(this.folderId);
            this.websocketService.initWebsocket(this.folderId, user);
        });

        this.anotherSubscription = this.folderSubjectService.refreshWorkflowStatus$.subscribe(() => {
            this.getCurrentTaskWorkflow();
        });

        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(res => {
            if (!!res && (
                (this.currentUser.context === Context.PRODUCT_RECALL && this.currentUser.businessLink === res.businessLink) ||
                this.currentUser.context === res.context
            )) {
                this.folder = res;
                this.setInstructionUserTaskCode();
            } else {
                this.folder = null;
            }
        });
    }


    private setInstructionUserTaskCode() {
        if (!!this.folder) {
            if (this.isCustomerDecisionTask()) {
                this.inputDynamicData.instructionUserTask = {code: 'CUSTOMER_DECISION'};
            } else if (this.isCustomerDidNotRespondTask()) {
                this.inputDynamicData.instructionUserTask = {code: 'CUSTOMER_DID_NOT_RESPOND'};
            } else {
                this.getInstructionUserTask(this.folder.currentWorkflowStatus.status);
            }
        }
        if (!!this.folder && !!this.folder.currentStatus && this.folder.currentStatus.status.toString().includes('FOLDER_CLOSED')) {
            this.genericComponent = this.genericComponents[this.folderClosedFinished];

        }
    }

    private getInstructionUserTask(status: string): void {
        this.anotherSubscription = this.codeToLabelService
            .getInstructionUserTaskByCode(status)
            .subscribe(instructionUserTask => {
                this.inputDynamicData.instructionUserTask = instructionUserTask || {
                    code: this.folder.currentWorkflowStatus.status
                };
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.titleService.setTitle(this.localStorage.get(Constants.PLATFORM));
        this.folderSubjectService.clearFolder();
        this.websocketService.disconnect(this.folderId);
        this.orderSubjectService.clearOrder();
    }

    getFolderDetails(id: string): void {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.folderService.getFolder(id)
            .subscribe(folder => {
                    this.titleService.setTitle(this.computeTabTitle(folder));
                    this.folderClosed = !folder.currentStatus.status.toString().includes('FOLDER_CLOSED');
                    this.anotherSubscription = this.store$.pipe(select(hasAccessToFolder, {folder: folder}))
                        .subscribe(hasAccess => {
                            if (!hasAccess) {
                                this.router.navigate(['folder', 'list']);
                            }
                        });
                    this.inputDynamicData.folder = folder;
                    this.folder = folder;
                    this.folderSubjectService.folderLoaded(folder);
                    if (!!this.folder.orderIds && this.folder.orderIds.length > 0) {
                        this.getOrder();
                    }

                    if (!this.product || this.product.code !== this.folder.product.code) {
                        this.product = this.folder.product;
                    }
                    if (!!this.folder.currentStatus && this.folder.currentStatus.status.toString().includes('FOLDER_CLOSED')) {
                        this.genericComponent = this.genericComponents[this.folderClosedFinished];
                        this.store$.dispatch(new StopLoading());
                    } else if (this.isCustomerDidNotRespondTask()) {
                        this.genericComponent = this.genericComponents['CUSTOMER_DID_NOT_RESPOND'];
                        this.store$.dispatch(new StopLoading());
                    } else if (this.isCustomerDecisionTask()) {
                        this.genericComponent = this.genericComponents['CUSTOMER_DECISION'];
                        this.store$.dispatch(new StopLoading());
                    } else {
                        this.getCurrentTaskWorkflow();
                    }
                },
                reason => {
                    this.isFolderNotFound = false;
                    this.isFolderReason = reason;
                    this.store$.dispatch(new StopLoading());
                });
    }


    private computeTabTitle(folder: Folder) {
        return [folder.incrementalReference, folder.customer?.lastName, folder.product?.label]
            .filter(Boolean)
            .join('-');
    }

    private atWaitingReparationAgreementUserTask(taskDefinitionKey: string): boolean {
        return taskDefinitionKey === 'WAITING_REPARATION_AGREEMENT';
    }

    getLastEventsByName(data: IEvent[], eventName: string) {
        const filteredEvents = data.filter((e) => e.eventType === 'WORKFLOW_STATUS' && (e.eventName === eventName));
        if (!!filteredEvents && filteredEvents.length > 0) {
            return filteredEvents[filteredEvents.length - 1];
        }
        return null;
    }

    //todo to be deleted after 60 days
    private handleWaitingReparationAgreement(toWaitForReparationAgreement: string): void {
        this.folderService.getEventList(this.folder.id).pipe(switchMap((data: IEvent[]) => {
                const eventConfirmCarrierAndRepairer = this.getLastEventsByName(data, 'CONFIRM_CARRIER_AND_REPAIRER');
                if (!!eventConfirmCarrierAndRepairer) {
                    const eventWaitingReparationAgreement = this.getLastEventsByName(data, 'WAITING_REPARATION_AGREEMENT');
                    if (!!eventWaitingReparationAgreement) {
                        this.dateWaiting = eventWaitingReparationAgreement.userAction.actionDate;
                    }
                    this.dateConfirm = eventConfirmCarrierAndRepairer.userAction.actionDate;
                    return this.growthbookService.getFeatureValue(AppFeatures.NEW_VERSION_OF_AGREEMENT_REPARATION, {}, null);
                } else {
                    return of(null);
                }
            })
        ).subscribe(value => {
            if (!!value && value <= moment(this.dateConfirm).format('YYYY-MM-DD')) {
                if (!!this.dateWaiting && value > moment(this.dateWaiting).format('YYYY-MM-DD')) {
                    this.genericComponent = this.genericComponents['OLD_WAITING_REPARATION_AGREEMENT'];
                } else {
                    this.genericComponent = this.genericComponents['WAITING_REPARATION_AGREEMENT'];
                }
            } else {
                if (toWaitForReparationAgreement === undefined || toWaitForReparationAgreement == null) {
                    this.genericComponent = this.genericComponents['OLD_WAITING_REPARATION_AGREEMENT'];
                } else {
                    this.genericComponent = this.genericComponents['WAITING_REPARATION_AGREEMENT'];
                }
            }
        });
    }

    completeTaskWorkflow(mapInput): void {
        this.store$.dispatch(new StartLoading());
        mapInput['currentTaskFrontOffice'] = this.workflowStatus;
        this.workflowService.getCompleteTaskWorkflow(this.folder.id, mapInput).then(value => {
            this.getFolderDetails(this.folder.id);
            this.loadingScreenService.stopLoading();
        }).catch(reason => {
            this.store$.dispatch(new StopLoading());
            this.loadingScreenService.stopLoading();
        });
    }

    getCurrentTaskWorkflow(): void {
        this.store$.dispatch(new StartLoading());
        this.workflowService.getCurrentTaskWorkflow(this.folder.id).then(workflowProcess => {
            if (!!workflowProcess.taskDefinitionKey && workflowProcess.taskDefinitionKey !== '') {
                this.workflowStatus = workflowProcess.taskDefinitionKey;
                this.inputDynamicData.workflowStatus = this.workflowStatus;
                this.updateVariables(workflowProcess);
                if (this.atWaitingReparationAgreementUserTask(workflowProcess.taskDefinitionKey)) {
                    this.handleWaitingReparationAgreement(workflowProcess.taskVariables[TaskVariables.waitForReparationAgreement]);
                } else {
                    this.genericComponent = this.genericComponents[workflowProcess.taskDefinitionKey];
                }

            } else if (this.folder.currentWorkflowStatus.origin === 'internal-reparation') {
                this.genericComponent = this.genericComponents[this.folder.currentWorkflowStatus.status];
            } else if (!this.isFolderClosed()) {
                this.workflowStatus = 'WAITING_ASYNC_PROCESS';
                this.genericComponent = this.genericComponents[this.workflowStatus];
            }
            this.processName = workflowProcess.processName;
            this.inputDynamicData.processName = this.processName;
            this.store$.dispatch(new StopLoading());
        }).catch(() => {
            this.store$.dispatch(new StopLoading());
        });
    }

    isFolderClosed(): boolean {
        return this.folder.currentStatus.status.includes('FOLDER_CLOSED');
    }

    getWorkflowTask(taskDefinitionKey: string): void {
        this.workflowService.getCurrentTaskWorkflow(this.folder.id).then(workflowProcess => {
            this.workflowStatus = taskDefinitionKey;
            this.updateVariables(workflowProcess);
        });
    }

    private updateVariables(workflowProcess: IWorkflowProcess): void {
        if (!workflowProcess.taskVariables) {
            return;
        }
        if (TaskVariables.resolutionSurvey in Object.keys(workflowProcess.taskVariables)) {
            this.surveyResponseId = JSON.parse(workflowProcess.taskVariables[TaskVariables.surveyResponseId]);
        }
        this.inputDynamicData.variablesTask = workflowProcess.taskVariables;
        this.permission = {
            canModifyCustomerAddress: (!!workflowProcess.taskVariables[TaskVariables.canModifyCustomerAddress] ? JSON.parse(workflowProcess.taskVariables[TaskVariables.canModifyCustomerAddress]) : true),
            canModifyWarranty: workflowProcess.processName === 'workflow_folder' || workflowProcess.processName === 'workflow_folder_v2' ? true : this.currentUser.context === Context.PRODUCT_RECALL.toString() ? false : false,
        };
        this.inputDynamicData.permission = this.permission;
    }

    getPreviewTask(activityMap): void {
        this.workflowService.getPreviewsTaskWorkflow(this.folderId, activityMap).then(value => {
            this.getFolderDetails(this.folderId);
        }).catch(reason => {
        });
    }

    private addCommentToFolder(comment: { value: string, type: string, author: string }): void {
        const folderUpdateRequest: IFolderUpdateRequest = {};

        if (!comment.author || comment.author === CommentAuthor.AGENT) {
            folderUpdateRequest.agentComment = {
                content: comment.value,
                type: comment.type ?? this.folder.currentWorkflowStatus.status
            };
        } else if (comment.author === CommentAuthor.REPAIRER) {
            folderUpdateRequest.repairerComment = {
                content: comment.value,
                type: comment.type ?? this.folder.currentWorkflowStatus.status
            };
        }

        this.folderService.updateFolder(this.folderId, folderUpdateRequest).then();
    }

    hasWriteAccessToUserTask(): Observable<boolean> {
        if (this.currentUser.context === Context.SAV.toString()) {
            return this.hasAccessScope();
        } else {
            /*temporary solution for a fast deployment to prod.
            Correct solution should be to configure user tasks for contexts product recall and reparation
            and configure permissions accordingly*/
            return of(true);
        }
    }

    private hasAccessScope(): Observable<boolean> {
        return (this.isCustomerDecisionTask() || this.isCustomerDidNotRespondTask()) ? of(true) :
            this.store$.pipe(select(hasScope, {scope: 'WRITE_ACCESS_TO_' + this.folder.currentWorkflowStatus?.status}));
    }

    isCustomerDecisionTask(): boolean {
        return this.folder.currentStatus.status === StatusEnum.FOLDER_CREATED && this.folder.context === 'SAV';
    }

    isCustomerDidNotRespondTask(): boolean {
        return this.folder.currentStatus.status === StatusEnum.FOLDER_CREATED && this.folder.context === 'PRODUCT_RECALL';
    }

    getOrder() {
        this.anotherSubscription = this.omsService.getOrderByFolderId(this.folder.id).subscribe(orders => {
            if (!!orders && orders?.length > 0) {
                const order = orders.reduce((prev, current) =>
                    prev?.creationDate > current.creationDate ? prev : current
                );
                this.orderSubjectService.orderLoaded(order);
            }
        });
    }

    beforeUnloadHandler(): void {
        window.onbeforeunload = () => {
            this.websocketService.disconnect(this.folderId);
        };
    }
}
