<fieldset class="container">
    <legend class="h3 title-fieldset">{{ 'COMPONENT.REQUEST_PAYMENT.TITLE' | translate }}</legend>

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

        <mat-card-title class="h3 mb-12">{{ remainingAmount | currency: currency :'symbol-narrow' | space }}
        </mat-card-title>

        <ng-container *ngIf="(isFeatureOn | async)">
            <button mat-stroked-button
                    (click)="requestPayment()"
                    [disabled]="isDisabled()"
                    [ngClass]="isDisabled() ? 'aster_btn_disabled' : 'aster_btn'">
                <div fxLayoutAlign="center center">
                    <mat-icon style="margin-right: 5px;">
                        credit_card
                    </mat-icon>
                    {{ 'COMPONENT.REQUEST_PAYMENT.BUTTON_TEXT' | translate }}
                </div>
            </button>
        </ng-container>

        <ng-container *ngIf="requestPaymentReference">
            <div fxLayout="row" fxLayoutAlign="space-around center">
                <mat-icon>check</mat-icon>
                <div class="h3 text-bold">
                    {{ 'COMPONENT.REQUEST_PAYMENT.REFERENCE_TEXT'|translate }} :
                    <span class="h3">{{ requestPaymentReference }}</span>
                </div>


            </div>
        </ng-container>

    </div>
</fieldset>