import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder, IFolderUpdateRequest} from '../../../../../../models/folder.model';
import {Observable, of, Subscription} from 'rxjs';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {MatTableDataSource} from '@angular/material/table';
import {Quotation} from '../../../../../../models/quotation.model';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {WorkflowService} from '../../../../../../shared/services/workflow.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FileService} from '../../../../../../shared/services/file.service';
import {TranslateService} from '@ngx-translate/core';
import {QuotationService} from '../../../../../../shared/services/quotation.service';
import {MatDialog} from '@angular/material/dialog';
import {TaskVariables} from '../../../task.variables';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QuotationModalConfig} from '../../../../../../shared/generic/quotation-modal/quotationModal.model';
import {QuotationModalComponent} from '../../../../../../shared/generic/quotation-modal/quotation-modal.component';
import {FileInfo} from '../../../../../../models/file-info.model';
import {saveAs} from 'file-saver';
import * as uuid from 'uuid';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentLanguage} from '../../../../../../store/organization/organization.selectors';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';


@Component({
    selector: 'app-reparation-quotation-acceptation',
    templateUrl: './reparation-quotation-acceptation.component.html',
    styleUrls: ['./reparation-quotation-acceptation.component.scss']
})
export class ReparationQuotationAcceptationComponent implements OnInit {

    @ViewChild('dropzone') dropzone: ElementRef;
    @Output() inputMap = new EventEmitter<any>();
    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;


    customerAcceptQuotationProcess: boolean;
    showResetVariableButton: boolean;

    folder: Folder;
    private folderSubscription: Subscription;

    displayedColumns: string[] = ['type', 'number', 'totalPrice'];
    displayedColumnsAttachment: string[] = ['name', 'date', 'action'];

    private sparePartsTypes = Object.keys(TypeOfServiceEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    quotationSummary = new MatTableDataSource<any>();

    InProgress = false;
    quotation: Quotation;

    fileUploadForm: any = {
        file: null,
        hasFile: false
    };

    quotationChoiceForm: any = {
        form: null,
        placeholders: []
    };

    quotationId: String;
    quotationDraftId: string;
    attachments = new Array<any>();
    currency: string;
    currentLanguage$: Observable<string>;
    warrantyCode: string;
    operatingMode: string;

    constructor(private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private workflowService: WorkflowService,
                private imageCompress: NgxImageCompressService,
                private fileService: FileService,
                private translateService: TranslateService,
                private quotationService: QuotationService,
                private store$: Store<AppState>,
                private matDialog: MatDialog) {
    }


    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.currency = this.variablesTask[TaskVariables.currency];
        this.customerAcceptQuotationProcess = this.variablesTask[TaskVariables.quotationProcess] === 'CLIENT';
        if (!this.customerAcceptQuotationProcess) {
            this.initQuotationChoseForm();
            this.quotation = Quotation.getEmptyQuotation(this.currency);
            this.folderSubscription = this.folderSubjectService.folder$.subscribe(folder => {
                this.folder = folder;
                this.operatingMode = this.folder.operationMode.valueOf();
                this.warrantyCode = this.folder.newWarranty.warranty.valueOf();
            });
            this.initQuotationVariable();
        }
    }

    initQuotationVariable(): void {
        if (this.folder.quotations) {
            this.getDataQuotation();
            this.showResetVariableButton = false;
        }
    }

    private getDataQuotation(): void {
        this.quotationId = this.variablesTask[TaskVariables.quotationSuggestionId];
        this.quotation = this.folder.quotations.filter(quotation => quotation.id === this.quotationId)[0];
        this.quotation.totalPrice = {
            value: !!this.variablesTask[TaskVariables.totalQuotationPrice] ? this.variablesTask[TaskVariables.totalQuotationPrice] : 0,
            currency: this.variablesTask[TaskVariables.currency]
        };
        this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
        this.initDataTable();
    }

    private initQuotationChoseForm(): void {
        this.quotationChoiceForm.form = new FormGroup({
            quotationAccepted: new FormControl(null, Validators.required),
        });
        this.quotationChoiceForm.placeholders = [
            {
                quotationAccepted: 'true',
                value: 'FOLDER.WORKFLOW.QUOTATION_ACCEPTATION.ACCEPT_QUOTATION',
            },
            {
                quotationAccepted: 'false',
                value: 'FOLDER.WORKFLOW.QUOTATION_ACCEPTATION.REFUSE_QUOTATION',
            },
        ];
    }

    ngOnDestroy(): void {
        if (!!this.folderSubscription) {
            this.folderSubscription.unsubscribe();
        }
    }

    private initDataTable(): void {
        let dataSource = [];
        let numberSpareParts = 0;
        let totalTTC = 0;
        for (const sparePartsCode of this.sparePartsTypes) {
            numberSpareParts = this.getNumberSparePartsOfQuotation(sparePartsCode);
            totalTTC = this.getTotalPriceSparePartsOfQuotation(sparePartsCode);
            if (numberSpareParts !== 0) {
                dataSource.push({
                    type: sparePartsCode,
                    number: numberSpareParts,
                    totalTTC: totalTTC
                });
            }
        }
        this.quotationSummary = new MatTableDataSource<any>(dataSource);
    }

    getNumberSparePartsOfQuotation(sparePartsCode): number {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + 1 : total, 0);
    }

    getTotalPriceSparePartsOfQuotation(sparePartsCode): any {
        return this.quotation.quotationLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            line.type === sparePartsCode ? total + this.quotationService.computeTotalPriceQuotationLine(line) : total, 0);
    }

    completeTask(): void {
        if (!this.variablesTask['quotationDistributorId']) {
            this.quotationDraftId = uuid.v4();
        } else {
            this.quotationDraftId = this.quotation.id;
        }

        this.quotation.id = this.quotationDraftId;

        this.folderService.updateQuotationToFolder(this.folder.id, this.attachments, this.quotation).subscribe(value => {
            this.inputMap.emit({
                quotationId: this.quotation.id,
                quotationAccepted: this.quotationChoiceForm.form.value.quotationAccepted
            });
            this.store$.dispatch(new StopLoading());
        });
    }

    openQuotationModal(): void {
        this.InProgress = true;
        this.getDataQuotation();
        const quotationModalConfig: QuotationModalConfig = {
            showFormAddQuotation: false,
            initialQuotation: this.quotation,
            warrantyCode: this.warrantyCode,
            titleModal: 'MODAL.QUOTATION.EDIT_QUOTATION',
            folderId: this.folder.id,
            product: this.folder.product,
            organizationCode: this.folder.organization.code,
            context: this.folder.context,
            hideTva$: of(true),
            managementSite: this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE),
            loadSparePartCatalog: false,
            loadTypeOfBenefit: false
        };
        const dialogRef = this.matDialog.open(QuotationModalComponent, {
            height: '90vh', width: '100vh', minWidth: '80%', data: quotationModalConfig
        });
        dialogRef.afterClosed().subscribe(quotation => {
            if (!this.variablesTask['quotationDistributorId']) {
                this.quotationDraftId = uuid.v4();
            } else {
                this.quotationDraftId = this.quotation.id;
            }
            if (!!quotation) {
                this.quotation.id = this.quotationDraftId;
                this.quotation.quotationLines = quotation.quotationLines;
                this.quotation.discount = quotation.discount;
                this.quotation.totalPrice = quotation.totalPrice;
                this.store$.dispatch(new StartLoading());
                this.updateQuotation();
                this.initDataTable();
            }
            dialogRef.componentInstance.ngOnDestroy();

        });
    }

    updateQuotation(): void {
        const folderUpdateRequest: IFolderUpdateRequest = {
            quotation: this.quotation
        };

        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.folder = folder;
            // update variable quotationDistributorId
            this.workflowService.updateQuotationDistributorId(this.folder.id, this.quotation.id).subscribe(() => {
                this.variablesTask['quotationDistributorId'] = this.quotation.id;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.store$.dispatch(new StopLoading());
            });
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
            this.store$.dispatch(new StopLoading());
        });
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            this.attachments.push({file: blobFile, info: $event[0]});
            this.attachmentQuotationDataTable.data = this.attachments;
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }


    removeFile(fileId): void {
        this.folderService.deleteAttachmentFileFolder(this.folder.id, fileId).then(resFile => {
            this.folder.attachments = this.folder.attachments.filter(file => file.id !== fileId);
            this.quotation.quotationAttachments = this.quotation.quotationAttachments.filter(file => file !== fileId);
            this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
        });
    }

    getFileInfo(fileId: string): FileInfo {
        return this.folder.attachments.find(file => file.id.startsWith(fileId));
    }

    downloadFile(fileId: string): void {
        this.fileService.getAttachmentFile(fileId).subscribe(value => {
            saveAs(value, this.getFileInfo(fileId).name);
        });
    }

    stopPropagation(): boolean {
        return;
    }
}
