import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {DashboardActionType, LoadDashboard, SaveDashboard, SetDashboards, UpdateDashboardLanguage, UpdateDashboardNavigation} from './dashboard.actions';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {DashboardUtils} from './dashboard.utils';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {selectedDashboardMode} from './dashboard.selectors';
import {currentLanguage, dashboardInfo} from '../organization/organization.selectors';
import {KeycloakService} from 'keycloak-angular';
import {DashboardV2Service} from '../../shared/services/dashboard-v2.service';
import {DashboardServer} from '../../models/dashboard/dashboard-server.model';
import {DashboardInfo} from '../organization/organization.state';
import {accessSites, currentBusinessLink} from '../user/user.selectors';
import {FuseNavigationService} from '../../../@fuse/components/navigation/navigation.service';
import {Router} from '@angular/router';
import {UserActionType} from '../user/user.actions';


@Injectable()
export class DashboardEffects {

    constructor(private actions$: Actions,
                private store$: Store<AppState>,
                private keycloak: KeycloakService,
                private fuseNavigationService: FuseNavigationService,
                private dashboardService: DashboardV2Service,
                private router: Router) {
    }

    @Effect({dispatch: false})
    loadDashboard$ = this.actions$.pipe(
        ofType(DashboardActionType.LOAD_DASHBOARD),
        withLatestFrom(
            this.store$.select(currentLanguage),
            this.store$.select(dashboardInfo),
            this.store$.select(accessSites),
            this.store$.select(currentBusinessLink)
        ),
        switchMap(([action, lang, dashboardInfo, sites, businessLink]: [LoadDashboard, string, DashboardInfo, string[], string]) =>
            this.dashboardService.getServerInfo().pipe(
                map((server: DashboardServer) =>
                    this.keycloak.getToken().then(token =>
                        DashboardUtils.loadDashboard({
                            ...action.options,
                            serverUrl: server.url + '/' + dashboardInfo.dashboardSiteId,
                            datasourceId: dashboardInfo.dashboardDataSourceId,
                            currentLang: lang,
                            accessSites: sites,
                            businessLink: businessLink,
                            token: token
                        })
                    )
                )
            )
        )
    );

    @Effect({dispatch: false})
    updateLanguage$ = this.actions$.pipe(
        ofType(DashboardActionType.UPDATE_DASHBOARD_LANGUAGE),
        withLatestFrom(this.store$.select(selectedDashboardMode)),
        map(([action, mode]: [UpdateDashboardLanguage, string]) =>
            DashboardUtils.updateLanguage(mode, action.language)
        ));

    @Effect()
    updateNavigation$ = this.actions$.pipe(
        ofType(
            DashboardActionType.UPDATE_DASHBOARD_NAVIGATION,
            UserActionType.UPDATE_BUSINESS_LINKS,
            UserActionType.SWITCH_BUSINESS_LINK
        ),
        switchMap(() => this.dashboardService.getAllDashboards()
            .pipe(map(dashboards => {
                        this.fuseNavigationService.updateNavigationItem('nav_dashboard-v2', {
                            children: DashboardUtils.computeDashboardItems(dashboards)
                        });
                        return new SetDashboards(dashboards);
                    }
                )
            )
        )
    );

    @Effect()
    saveDashboard$ = this.actions$.pipe(
        ofType(DashboardActionType.SAVE_DASHBOARD),
        map((action: SaveDashboard) => {
            if (action.dashboardId) {
                this.router.navigate(['dashboard-v2', action.dashboardId]);
            }
            return new UpdateDashboardNavigation();
        })
    );

}
