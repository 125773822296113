import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PushNotificationUpdateRequest} from '../../../../models/pushNotification/pushNotification.model';
import {PushNotificationService} from '../../../../shared/services/pushNotification.service';
import {PushNotificationSubjectService} from '../../../../models/pushNotification/pushNotification-subject.service';
import {TranslateService} from '@ngx-translate/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-push-notification-folder-modal',
    templateUrl: './push-notification-folder-modal.component.html',
    styleUrls: ['./push-notification-folder-modal.component.css']
})
export class PushNotificationFolderModalComponent implements OnInit, AfterViewInit {

    displayedColumns: string[] = ['type', 'details', 'date', 'action'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource: any = {
        page: null,
        pageSizeOptions: [5, 10, 25, 50, 100],
    };
    dataSourcePushNotification = new MatTableDataSource<any>([]);

    page = 0;
    currentLanguage$: Observable<string>;

    constructor(
        public dialogRef: MatDialogRef<PushNotificationFolderModalComponent>,
        private pushNotificationService: PushNotificationService,
        private pushNotificationSubjectService: PushNotificationSubjectService,
        @Inject(MAT_DIALOG_DATA) public data,
        private store$: Store<AppState>,
        private translateService: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.loadPushNotifications(this.page, this.dataSource.pageSizeOptions[0]);
        this.dataSourcePushNotification.paginator = this.paginator;
        this.closeWithEscape();
    }

    private closeWithEscape(): void {
       this.dialogRef.keydownEvents().subscribe(event => {
            if (event.key === 'Escape' || event.key === 'ESC'){
                this.onClose();
            }
        });

    }
    private loadPushNotifications(page: any, totalElement: any) {
        const pushNotificationFilterRequest: any = {
            businessId: this.data.folderId
        };
        this.pushNotificationService.getAllPushNotifications(page, totalElement, pushNotificationFilterRequest).subscribe(pushNotificationsResponse => {
            if (pushNotificationsResponse?.content) {

                pushNotificationsResponse.content.forEach((notification: any) => {
                    if (this.isExternalErrorNotification(notification) && !this.checkTranslationExistsAndReturnKey(notification).exists){
                        notification.withDetail = true;
                    }
                });
                this.dataSourcePushNotification = new MatTableDataSource(pushNotificationsResponse.content);
                this.dataSource.page = pushNotificationsResponse;
            }
        }, err => {
            this.dataSourcePushNotification.data = [];
            console.log('ERROR get Push notification details' + err);
        });
    }


    ngAfterViewInit(): void {
        this.dataSourcePushNotification.paginator = this.paginator;
    }

    updateStatus(status: string, pushNotification): void {
        const pushNotificationUpdateRequest: PushNotificationUpdateRequest = {
            status: status,
        };

        if (!!pushNotificationUpdateRequest) {
            this.pushNotificationService.updateNotificationsStatus([pushNotification.id], pushNotificationUpdateRequest.status).subscribe(updatedNotifications => {
                const index = this.dataSourcePushNotification.data.indexOf(pushNotification);
                if (index !== -1) {
                    this.dataSourcePushNotification.data[index] = updatedNotifications[0];
                }

                updatedNotifications.forEach(value => {
                    this.pushNotificationSubjectService.refreshPushNotification(value);
                });

                this.dataSourcePushNotification.connect().next(this.dataSourcePushNotification.data);
            });
        }
    }


    onClose(): void {
        this.dialogRef.close(this.dataSourcePushNotification.data);
    }


    checkTranslationExistsAndReturnKey(pushNotification: any): { exists: boolean, key: string, translationKey: string } {
        const key = pushNotification.extraInfo[pushNotification.type];
        const translationKey = `PUSH.NOTIFICATION.${pushNotification.type}.${key}`;
        const translationExists = this.translateService.instant(translationKey) !== translationKey;
        return { exists: translationExists, key, translationKey };
    }

    getNotificationMessage(pushNotification: any): Observable<string> {
        const { exists, translationKey } = this.checkTranslationExistsAndReturnKey(pushNotification);
        if (this.isExternalErrorNotification(pushNotification) && !exists) {
            return this.translateService.stream('PUSH.NOTIFICATION.ERROR.COMMON.EXTERNAL', { 'API_NAME': pushNotification.extraInfo.API_NAME });
        } else if (exists) {
            return this.translateService.stream(translationKey);
        } else {
            return this.translateService.stream('PUSH.NOTIFICATION.ERROR.COMMON');
        }
    }

    getNotificationMessageDetails(pushNotification: any): Observable<string> {
        const { exists, translationKey } = this.checkTranslationExistsAndReturnKey(pushNotification);
        if (exists) {
            return this.translateService.stream(translationKey);
        }else{
            const message = pushNotification.extraInfo[pushNotification.type] ?
                pushNotification.extraInfo[pushNotification.type] :
                ' ';
            return this.translateService.stream(message);
        }
    }




        paginate(event: PageEvent): void {
        this.loadPushNotifications(event.pageIndex, event.pageSize);
    }


    isExternalErrorNotification(pushNotification: any){
        return pushNotification.extraInfo.DISPLAY_ERROR === 'true';
    }
}
