import {Component, Inject, OnInit} from '@angular/core';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {select, Store} from '@ngrx/store';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {TypeOfBenefitService} from '../../../../../../shared/services/typeOfBenefit.service';
import {AppState} from '../../../../../../store/app.state';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {FolderSubjectService} from '../../../../folder-subject.service';
import {FolderService} from '../../../../../../shared/services/folder.service';
import {QuotationModalComponent} from '../../../../../../shared/generic/quotation-modal/quotation-modal.component';
import {countryCode, currency, margin, vats} from '../../../../../../store/organization/organization.selectors';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {Vat} from '../../../../../../models/vat.model';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {isNorauto} from '../../../../../../store/user/user.selectors';
import {ConfigCodeMapsEnum} from '../../../../../../shared/services/configuration-item-enum';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {RelatedSparePart, RelatedSparePartRequest, RelatedSparePartView} from '../../../../../../models/spare-parts/relatedSparePart.model';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {ProductFamilyType} from '../../../../../../models/enums/productFamilyType.enum';
import {SupplierDTO} from '../../../../../../models/spare-parts/supplier.model';
import {map} from 'rxjs/operators';
import {AddRequirementModal} from '../../../../../../models/add-requirement.modal';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {MatTableDataSource} from '@angular/material/table';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Amount} from '../../../../../../models/amount.model';
import {SparePartStock, StockDetails} from '../../../../../../models/spare-parts/stock.model';
import * as accents from 'remove-accents';
import {PAYERS_CONFIGS} from '../../../../../../shared/data/static.config';
import {DataSourceLine, Quotation, QuotationLine} from '../../../../../../models/quotation.model';
import {IFolderUpdateRequest} from '../../../../../../models/folder.model';
import * as uuid from 'uuid';
import {FuseConfirmDialogComponent} from '../../../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {ConfigV2Service} from '../../../../../../shared/services/config-v2.service';

@Component({
    selector: 'app-add-requirement-dialog',
    templateUrl: './add-requirement-dialog.component.html',
    styleUrls: ['./add-requirement-dialog.component.scss']
})
export class AddRequirementDialogComponent extends Unsubscriber implements OnInit {

    sellingPriceSubscription: Subscription;

    requirementForm: FormGroup = new FormGroup({});
    dataSource = new MatTableDataSource<OrderItem>();
    dataSourceLines: OrderItem[] = [];
    displayedColumns: string[];

    countryCode: string;

    currency: string;
    defaultMargin = 0;
    vatsList$: Observable<Vat[]>;
    defaultVat;
    computeTotalPrice: Amount;
    discountQuotations = 0;

    payers = PAYERS_CONFIGS;
    sparePartsTypes = [];
    typeOfServices = new Array<any>();

    hidePayer = true;
    showSupplier = false;
    displayPurchasePrice = false;
    showMargin = false;
    showTotalUnitPrice = false;
    hidePurchasePrice = true;
    hideMarge = true;
    hideSupplier = true;
    hidePrice = true;
    hideDiscount = true;
    hideVat = true;

    isSparePartAdd = false;
    isLoading = false;
    isAvailable = true;
    sparePartsRelatedToProduct: RelatedSparePartView[] = [];
    filteredData = new ReplaySubject<any[]>(1);
    filteredSuppliers: Observable<SupplierDTO[]>;
    stockSparePartAvailable = true;
    stockSparePartsAvailable = true;
    availableQuantity = 0;

    quotation: Quotation;
    order = new Order();
    orderItems: OrderItem[] = [];
    orderExtraItems: OrderItem[] = [];
    quotationTouched = false;

    constructor(private dialogRef: MatDialogRef<QuotationModalComponent>,
                private matDialog: MatDialog,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private typeOfBenefitService: TypeOfBenefitService,
                private store$: Store<AppState>,
                private sparePartService: SparePartService,
                private omsService: OmsService,
                private backOfficeService: BackOfficeService,
                private orderSubjectService: OrderSubjectService,
                private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private configV2Service: ConfigV2Service,
                @Inject(MAT_DIALOG_DATA) public addRequirementModal: AddRequirementModal) {
        super();

    }

    ngOnInit(): void {
        this.initDefaultVat();
        this.initTypeOfBenefitService();
        this.initTypeOfServices();
        this.quotation = this.addRequirementModal.initialQuotation;
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => {
            this.currency = currency;
            this.initForm(TypeOfServiceEnum.SPARE_PART);
            this.initOrder();
        });
        this.anotherSubscription = this.store$.pipe(select(margin)).subscribe(value => {
            this.defaultMargin = value;
        });
        this.anotherSubscription = this.store$.pipe(select(countryCode)).subscribe(code => this.countryCode = code);
        this.anotherSubscription = this.store$.pipe(select(isNorauto)).subscribe(isNorauto => {
            this.hideVat = isNorauto;
            this.hideDiscount = isNorauto;
            this.hidePrice = isNorauto;
            this.hidePayer = isNorauto;
            this.showSupplier = !isNorauto;
            this.hideSupplier = !this.showSupplier;
            this.displayPurchasePrice = !isNorauto;
            this.hidePurchasePrice = !this.displayPurchasePrice;
            this.showMargin = !isNorauto;
            this.hideMarge = !this.showMargin;
            this.showTotalUnitPrice = !isNorauto;
        });
        this.computeDisplayedColumns();
        this.loadRelatedSparePartViews();
        this.initSuppliers();
        this.displayQuotationFees();
    }

    private initForm(type: string): void {
        this.requirementForm = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            payer: new FormControl(this.getPayerForType(type), !this.hidePayer ? Validators.required : null),
            quantityOrdered: new FormControl(1, [Validators.required, Validators.min(1)]),
            quantityInvoiced: new FormControl(1),
            price: new FormControl(0, [Validators.required, Validators.min(0)]),
            approximatePrice: new FormControl(0),
            purchasePrice: new FormControl(0, {
                validators: this.purchasePriceValidator(type)
            }),
            taxPercent: new FormControl(this.defaultVat, Validators.required),
            marginPercentage: new FormControl(this.defaultMargin, this.marginPercentageValidator(type)),
            discountPercent: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            type: new FormControl(type, Validators.required),
            discountReason: new FormControl(null),
            currency: new FormControl(this.currency),
            isAvailable: new FormControl(this.stockSparePartAvailable),
            supplier: new FormControl(null, this.supplierValidator(type)),
            ean: new FormControl(null),
            images: new FormControl(null),
            weight: new FormControl(null),
            rowTotal: new FormControl(0),
            totalUnitLinePrice: new FormControl(0, Validators.required)
        });

    }

    /**
     * Form controls
     * @private
     */
    get discountPercentFormControl(): FormControl {
        return this.requirementForm.controls.discountPercent as FormControl;
    }

    get totalUnitLinePriceFormControl(): FormControl {
        return this.requirementForm.controls.totalUnitLinePrice as FormControl;
    }

    get rowTotalFormControl(): FormControl {
        return this.requirementForm.controls.rowTotal as FormControl;
    }

    get priceFormControl(): FormControl {
        return this.requirementForm.controls.price as FormControl;
    }

    get purchasePriceFormControl(): FormControl {
        return this.requirementForm.controls.purchasePrice as FormControl;
    }

    get supplierFormControl(): FormControl {
        return this.requirementForm.controls.supplier as FormControl;
    }

    private supplierEditable() {
        this.supplierFormControl.enable();
    }

    private supplierNotEditable() {
        this.supplierFormControl.disable();
    }

    private initDefaultVat() {
        this.vatsList$ = this.store$.pipe(select(vats));
        this.vatsList$.subscribe(vats => {
            this.defaultVat = vats.find(value => value.standard === true).rate;
        });
    }

    private initTypeOfBenefitService() {
        const sparePart = {
            code: TypeOfServiceEnum.SPARE_PART,
            label: this.translateService.instant('MODAL.QUOTATION.TYPE.SPARE_PART')
        };
        this.sparePartsTypes.push(sparePart);
        this.typeOfBenefitService.all().subscribe(types => {
            types.forEach(value => {
                this.sparePartsTypes.push(value);
            });
        });
    }

    initTypeOfServices(): void {
        this.configV2Service.findLocalizedValuesOf([ConfigCodeMapsEnum.TYPE_OF_SERVICE]).subscribe(
            response => {
                if (this.addRequirementModal.workforcesRule?.length > 0) {
                    this.typeOfServices = response.filter(item => item.type !== TypeOfServiceEnum.WORKFORCE);
                } else {
                    this.typeOfServices = response;
                }
            }
        );
    }

    private getPayerForType(type: string): string {
        if (!!this.addRequirementModal.payers) {
            if (TypeOfServiceEnum.DISPLACEMENT === type) {
                return this.addRequirementModal.payers.displacement;
            } else if (TypeOfServiceEnum.WORKFORCE === type) {
                return this.addRequirementModal.payers.workforce;
            }
            return this.addRequirementModal.payers.spareParts;
        }
        return null;
    }

    private purchasePriceValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const purchasePrice = control.value;

            if (this.displayPurchasePrice && (!purchasePrice || parseFloat(purchasePrice) <= 0) && type === TypeOfServiceEnum.SPARE_PART) {
                if (parseFloat(purchasePrice) < 0) {
                    control.markAsTouched();
                }
                return {'purchasePriceInvalid': true};
            }

            return null;
        };
    }

    private marginPercentageValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const marginPercentage = control.value;
            if (this.showMargin && (marginPercentage === undefined || parseFloat(marginPercentage) < 0) && type === TypeOfServiceEnum.SPARE_PART) {
                return {'marginPercentageInvalid': true};
            }
            return null;
        };
    }

    private supplierValidator(type: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const supplier = control.value;
            if (this.showSupplier && !supplier && type === TypeOfServiceEnum.SPARE_PART) {
                return {'supplierInvalid': true};
            }
            return null;
        };
    }

    private computeDisplayedColumns(): void {
        this.displayedColumns = ['type', 'code', 'label', 'price', 'quantity'];
        if (this.isSparePartForm()) {
            this.displayedColumns.push('stock');
        }
        this.displayedColumns.push('discount', 'vat', 'totalPrice', 'action');
        if (this.showMargin) {
            this.displayedColumns.splice(4, 0, 'marginPercentage');
        }
        if (this.displayPurchasePrice) {
            this.displayedColumns.splice(3, 0, 'purchasePrice');
        }
    }

    private isSparePartForm(): boolean {
        return this.requirementForm.controls.type?.value === TypeOfServiceEnum.SPARE_PART;
    }

    private loadRelatedSparePartViews() {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        const product = this.addRequirementModal.product;
        const request: RelatedSparePartRequest = {
            productCode: product.code,
            eanCode: product.codeEAN,
            supplierCode: product.supplier?.code,
            brand: product.brand,
            family1: RuleEvaluationContext.getFamilyByType(product.families, ProductFamilyType.FAMILY),
            countryCode: this.countryCode,
            managementSiteCode: this.addRequirementModal.managementSite.code,
            withMedia: false,
        };

        this.sparePartService.getRelatedSpareParts(request)
            .subscribe(spareParts => {
                spareParts.forEach(value => this.sparePartsRelatedToProduct.push(this.buildSparePartView(value)));
                if (this.isSparePartForm()) {
                    this.filteredData.next(this.sparePartsRelatedToProduct);
                }
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            }, () => {
                this.isLoading = false;
                this.store$.dispatch(new StopLoading());
            });
    }

    private buildSparePartView(value: RelatedSparePart) {
        return {
            id: value.id,
            code: value.code,
            label: value.label,
            price: value?.approximatePrice?.value,
            purchasePrice: value.purchasePrice?.value,
            marginPercentage: value.marginPercentage,
            information: value.information,
            supplier: value.suppliers?.length > 0 ? value.suppliers[0] : null,
            images: value.images,
            weight: parseFloat(value.caracteristics?.weight),
            ean: value.ean,
            approximatePrice: value.approximatePrice
        };
    }

    initSuppliers() {
        this.filteredSuppliers = this.getFilteredSuppliers('');
    }


    private getFilteredSuppliers(searchValue: string): Observable<SupplierDTO[]> {
        return this.sparePartService.findSuppliers(0, 30, {code: searchValue, name: searchValue, matchingMode: 'ANY'})
            .pipe(
                map(page => {
                    return page.content;
                })
            );
    }

    private initOrder() {
        if (!this.addRequirementModal.initialOrder?.id) {
            return;
        }
        this.order = this.addRequirementModal.initialOrder;
        this.orderItems = this.order.orderItems || [];
        this.orderExtraItems = this.order.orderExtraItems || [];
        this.dataSourceLines = [...this.orderItems, ...this.orderExtraItems];
        this.fetchStocksOfDisplayedSpareParts(this.orderItems);
        this.refreshDataSourceTable();
    }

    private displayQuotationFees() {
        const quotationFeesFromOrder =  this.dataSourceLines?.find(item => item.type === 'QUOTATION_FEES');
        const quotationFees = this.addRequirementModal.quotationFees;
        if (!!quotationFees && !quotationFeesFromOrder) {
            this.dataSourceLines.push(quotationFees);
            this.refreshDataSourceTable();
        }
    }

    private fetchStocksOfDisplayedSpareParts(dataSourceLines: OrderItem[]): void {
        const stockRequest = this.prepareStockRequest(dataSourceLines);
        if (!!stockRequest && stockRequest.length > 0) {
            this.sparePartService.getAvailabilityOfStocks(this.addRequirementModal.managementSite.code, stockRequest).subscribe(response => {
                if (!!response) {
                    this.isAvailable = response.availabilityStatus === 'AVAILABLE';
                    this.merge(dataSourceLines, response.stocks);
                } else {
                    const sparePartCodes = dataSourceLines.map(data => data.code);
                    this.sparePartService.getStocks(sparePartCodes.map(code => code.trim()), this.addRequirementModal.managementSite.code)
                        .subscribe(stocks => this.merge(dataSourceLines, stocks));
                }
            });
        }
    }

    private prepareStockRequest(dataSourceLines: OrderItem[]) {
        return dataSourceLines
            .filter(line => line.type === QuotationType.SPARE_PART)
            .map(line => {
                line['isLoading'] = true;
                return {
                    sparePartCode: line.code.trim(),
                    quantity: line.quantityOrdered
                };
            });
    }

    getStockIndicatorClass(status: string): string {
        switch (status) {
            case 'AVAILABLE':
                return 'green-color';
            case 'NOT_AVAILABLE':
                return 'red-color';
            default:
                return 'black-color';
        }
    }

    displayStockStatus(status: string, type: string): string {
        if (type !== 'SPARE_PART') {
            if ('UNKNOWN' === status || !status) {
                return 'TEXT.UNKNOWN';
            }
        } else {
            if ('UNKNOWN' === status) {
                return 'TEXT.UNKNOWN';
            }
            if (!status) {
                return 'TEXT.UNDEFINED';
            }
        }

        if (status === 'AVAILABLE') {
            return 'TEXT.AVAILABLE';
        }

        if (status === 'NOT_AVAILABLE') {
            return 'TEXT.UNAVAILABLE';
        }
    }
    hasStock(status: string): boolean {
        return status && status === 'AVAILABLE';
    }
    stockDetailsTooltip(stocks: StockDetails[], totalStock: string): string {
        let tooltipMessage = '';
        if (totalStock) {
            tooltipMessage = this.translateService.instant('TOOLTIP.STOCK.DETAILS.STOCK', {stock: totalStock});

        } else {
            const stockDetails = stocks
                .map(stock => this.translateService.instant('TOOLTIP.STOCK.DETAILS',
                    {
                        siteCode: stock?.siteCode,
                        siteLabel: stock?.siteLabel,
                        deliveryDate: this.datePipe.transform(stock?.deliveryDate, 'dd-MM-yyyy')
                    }));

            stockDetails.map(stockDetails => tooltipMessage += stockDetails + '\n');
        }
        return tooltipMessage;
    }
    private refreshDataSourceTable(): void {
        this.dataSource.data = [];
        this.dataSource = new MatTableDataSource<OrderItem>(this.dataSourceLines);
        const totalPrice = this.sparePartService.computeTotalPrice(this.dataSourceLines.map(line => line.rowTotal));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
        this.store$.dispatch(new StopLoading());
    }

    private merge(dataSourceLines: OrderItem[], stocks: SparePartStock[]): void {
        const stocksByCode = new Map(stocks.map(stock => [stock.sparePartCode, stock]));
        dataSourceLines.forEach(dataSourceLine => {
            dataSourceLine.stock = stocksByCode.get(dataSourceLine.code) ?? this.getDefaultStock();
            dataSourceLine.stock.isLoading = false;
        });
    }

    private getDefaultStock(): SparePartStock {
        return {
            status: 'UNKNOWN',
            isLoading: false
        } as SparePartStock;
    }

    refreshDataForm(type: string): void {
        this.initForm(type);
        this.stockSparePartAvailable = true;
        if (type === TypeOfServiceEnum.SPARE_PART) {
            this.filteredData.next(this.sparePartsRelatedToProduct);
            this.hidePurchasePrice = false;
            this.hideMarge = false;
            this.hideSupplier = !this.showSupplier;
        } else if (type === TypeOfServiceEnum.WORKFORCE && this.addRequirementModal?.workforcesRule?.length > 0) {
            this.loadWorkforce();
            this.hidePurchasePrice = true;
            this.hideMarge = true;
            this.hideSupplier = true;
        } else {
            this.changeFileredTypeOfService(type);
            this.hidePurchasePrice = true;
            this.hideMarge = true;
            this.hideSupplier = true;
        }
    }

    private loadWorkforce() {
        this.filteredData.next(this.addRequirementModal.workforcesRule);
    }

    private changeFileredTypeOfService(type: string) {
        const typeOfService = this.typeOfServices.filter(item => item.type === type);
        this.filteredData.next(typeOfService);
    }

    get filteredSparePartsTypes() {
        return this.sparePartsTypes.filter((service) => this.hideQuotationFees(service.code));
    }

    private hideQuotationFees(service: string) {
        return !(!!this.dataSourceLines.find(line => line.type === 'QUOTATION_FEES') && service === 'QUOTATION_FEES');
    }

    changeQuantity(){
        const quantityOrdered = this.requirementForm.controls.quantityOrdered?.value;
        this.requirementForm.controls.quantityInvoiced.setValue(quantityOrdered);
        this.formatAndCalculateTotalQuotationLine();
    }
    onCodeChanges(): void {
        const type = this.requirementForm.controls.type?.value;
        const code = this.requirementForm.controls.code?.value?.trim();

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('code', code);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.addRequirementModal.workforcesRule?.length > 0) {
            this.searchWorkForce('code', code);
        } else {
            this.searchTypeOfService('code', code, type);

        }
    }

    onLabelChanges(): void {
        const type = this.requirementForm.controls.type?.value;
        const label = this.requirementForm.controls.label?.value?.trim();

        if (!!type && type === TypeOfServiceEnum.SPARE_PART) {
            this.searchSpareParts('label', label);
        } else if (!!type && type === TypeOfServiceEnum.WORKFORCE && this.addRequirementModal.workforcesRule?.length > 0) {
            this.searchWorkForce('label', label);
        } else {
            this.searchTypeOfService('label', label, type);
        }
    }

    private searchSpareParts(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.sparePartsRelatedToProduct.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);
            if (!filteredByCode || filteredByCode.length === 0) {
                this.supplierEditable();
            }

        } else {
            this.filteredData.next(this.sparePartsRelatedToProduct);
        }
    }

    private searchWorkForce(criteria: string, value: string) {
        if (value && value.trim().length > 0) {
            const filteredByCode = this.addRequirementModal.workforcesRule.filter(sparePart =>
                accents.remove(sparePart[criteria].toLowerCase())
                    .includes(accents.remove(value.toLowerCase()))
            );
            this.filteredData.next(filteredByCode);
        } else {
            this.filteredData.next(this.addRequirementModal.workforcesRule);
        }
    }

    private searchTypeOfService(criteria: string, value: string, type: string) {
        const inputs = {
            'type': type
        };
        inputs[criteria] = value;

        this.isLoading = true;
        this.filteredData.next(this.filterTypeOfService(inputs, criteria));
        this.isLoading = false;
    }

    private filterTypeOfService(value: any, criteria: string): string[] {
        const filterValue = value[criteria]?.toLowerCase();
        return value && filterValue ? this.typeOfServices.filter(item => item.type === value.type &&
            accents.remove(item[criteria].toLowerCase()).includes(accents.remove(filterValue))) : this.typeOfServices.filter(item => item.type === value.type);
    }

    filterSupplier(event): void {
        const value = event.target.value;
        if (value && value.trim().length > 0) {
            this.filteredSuppliers = this.getFilteredSuppliers(value);
        }

    }

    supplierChanged(event): void {
        this.requirementForm.controls.supplier.setValue(event.option.value);
        this.initSuppliers();

    }

    displayFn(supplier: SupplierDTO): string {
        return supplier?.name;
    }

    private isUnderWarranty() {
        return this.addRequirementModal.warrantyCode === 'SG';
    }

    sparePartChanged(event): void {
        this.initForm(this.requirementForm.controls.type.value);
        this.supplierNotEditable();
        if (!event.option.value.supplier) {
            this.supplierEditable();
        }
        this.calculateSellingPrice(event);

        this.requirementForm.controls.code.setValue(event.option.value.code.trim());
        this.requirementForm.controls.label.setValue(event.option.value.label);
        this.requirementForm.controls.supplier.setValue(event.option.value.supplier);


        if (event.option.value.payer) {
            this.requirementForm.controls.payer.setValue(event.option.value.payer);
        }
        this.requirementForm.controls.ean.setValue(event.option.value.ean);
        this.requirementForm.controls.approximatePrice.setValue(event.option.value.approximatePrice?.value);
        this.requirementForm.controls.images?.setValue(event.option.value.images);
        this.requirementForm.controls.weight.setValue(event.option.value.weight);
    }

    addIntoDataSourceLines(): void {
        this.quotationTouched = true;
        if (this.isSparePartForm()) {
            this.checkAvailabilityStock();
            this.isSparePartAdd = true;
        } else {
            this.checkDiscountValue();
            const dataSourceLine = this.requirementForm.value;
            this.addAndRefreshDataSourceLine(dataSourceLine);
        }
    }
    isFeesQuotation(quotationFees: OrderItem): boolean {
        return quotationFees.type.startsWith(QuotationType.QUOTATION_FEES) && quotationFees.price < 0;
    }
    deleteLine(indexLine: number): void {
        this.quotationTouched = true;
        const dialog = this.matDialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true,
            disableClose: false,
        });
        dialog.componentInstance.title = this.translateService.instant('CONFIRMATION.MODAL.DELETE_DIALOG.TITLE');
        dialog.componentInstance.message = this.translateService.instant('FOLDER.FILE.DELETE_DIALOG.MESSAGE');
        dialog.componentInstance.confirmButtonLabel = this.translateService.instant('BUTTON.DELETE');
        dialog.componentInstance.cancelButtonLabel = this.translateService.instant('BUTTON.CANCEL');
        dialog.afterClosed().subscribe(isDelete => {
            if (isDelete) {
                this.dataSourceLines = this.dataSourceLines.filter((item, index) => index !== indexLine || this.isFeesQuotation(item));
                this.dataSource = new MatTableDataSource<OrderItem>(this.dataSourceLines);
                const totalPrice = this.sparePartService.computeTotalPrice(this.dataSourceLines.map(quotationLine => quotationLine.rowTotal));
                this.computeTotalPrice = {
                    value: totalPrice,
                    currency: this.currency
                };
            }
        });

    }
    private addAndRefreshDataSourceLine(dataSourceLine) {
        this.dataSourceLines.unshift(dataSourceLine);
        this.refreshDataForm(TypeOfServiceEnum.SPARE_PART);
        this.refreshDataSourceTable();
    }

    private checkDiscountValue() {
        if (!this.requirementForm.value.discountPercent) {
            this.requirementForm.controls.discountPercent.setValue(0);
        }
    }

    private checkAvailabilityStock() {
        this.store$.dispatch(new StartLoading());
        const stockRequest = [{
            sparePartCode: this.requirementForm.controls.code.value.trim(),
            quantity: this.requirementForm.controls.quantityOrdered.value
        }];
        this.store$.dispatch(new StartLoading());
        this.sparePartService.getAvailabilityOfStocks(this.addRequirementModal.managementSite.code, stockRequest).subscribe(response => {
            this.checkDiscountValue();
            if (!!response) {
                if (response.availabilityStatus === 'AVAILABLE' || response.availabilityStatus === 'UNKNOWN') {
                    const dataSourceLine = this.requirementForm.getRawValue();
                    this.stockSparePartAvailable = true;
                    this.requirementForm.controls.isAvailable.setValue(true);
                    dataSourceLine.stock = {...(response.stocks[0] || this.getDefaultStock())};
                    this.addAndRefreshDataSourceLine(dataSourceLine);
                    this.isSparePartAdd = false;
                } else {
                    this.requirementForm.controls.isAvailable.setValue(false);
                    this.stockSparePartAvailable = false;
                    this.isSparePartAdd = false;
                    this.store$.dispatch(new StopLoading());
                }
            } else { // organization hasn't api availability
                const dataSourceLine = this.requirementForm.getRawValue();
                this.checkApiStock(dataSourceLine);
                this.addAndRefreshDataSourceLine(dataSourceLine);
                this.isSparePartAdd = false;
            }

        });
    }

    private checkApiStock(dataSourceLine) {
        dataSourceLine.stock = {isLoading: true};
        this.sparePartService.getStocks([dataSourceLine.code.trim()], this.addRequirementModal.managementSite.code).subscribe(stocks => {
                dataSourceLine.stock = {...stocks[0], isLoading: false};
                this.availableQuantity = stocks[0]?.totalStock;
            },
            error => dataSourceLine.stock.isLoading = false,
            () => dataSourceLine.stock.isLoading = false);
    }

    /**
     *
     * @param event
     * @private  pricing
     */
    private calculateSellingPrice(event) {
        if (!!this.sellingPriceSubscription) {
            this.sellingPriceSubscription.unsubscribe();
        }
        const purchasePrice = event.option.value.purchasePrice || event.option.value.supplier?.unitPrice?.value;
        const sparePart = event.option.value;
        if (typeof purchasePrice === 'number' && purchasePrice > 0) {
            const approximatePrice = !!event.option.value.approximatePrice?.value ? event.option.value.approximatePrice.value : 0;
            this.sellingPriceSubscription = this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice)
                .subscribe(data => {
                    if (!!data) {
                        sparePart.price = data.sellingPrice || 0;
                        sparePart.marginPercentage = data.marginPercentage || 0;
                        sparePart.purchasePrice = data.purchasePrice || 0;
                    }
                }, () => {
                }, () => {
                    this.completeFormWithPrices(event, purchasePrice);
                });
        } else {
            this.completeFormWithPrices(event, purchasePrice);
        }
    }

    private completeFormWithPrices(event, purchasePrice: number) {
        const price = this.getSellingPrice(event);
        const margin = this.margin(event);
        this.requirementForm.controls.price.setValue(price);
        this.requirementForm.controls.purchasePrice.setValue(purchasePrice || 0);
        this.requirementForm.controls.marginPercentage.setValue(margin || this.defaultMargin);
        this.formatAndCalculateTotalQuotationLine();
    }

    private getSellingPrice(event) {
        return this.isFreeSparePart(event.option.value.supplier) ? 0 : event.option.value.price || 0;
    }

    private margin(event) {
        return event.option.value.marginPercentage === 0 && event.option.value.purchasePrice === 0 ?
            this.defaultMargin : event.option.value.marginPercentage;
    }

    private isFreeSparePart(supplier) {
        return supplier?.freeUnderWarranty && this.isUnderWarranty();
    }

    formatAndCalculateTotalQuotationLine(): void {
        const unitPrice = this.requirementForm.controls.price.value;
        this.requirementForm.controls.price.setValue(this.sparePartService.normalizeNumber(this.getPriceWithFormatAccordingToCurrency(unitPrice)));
        const totalUnitLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalUnitPrice());
        this.requirementForm.controls.totalUnitLinePrice.setValue(totalUnitLinePrice);
        const totalLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalLinePrice());
        this.requirementForm.controls.rowTotal.setValue(this.sparePartService.normalizeNumber(totalLinePrice));
    }

    private getPriceWithFormatAccordingToCurrency(price: number): string {
        return this.sparePartService.formatPriceAccordingToCurrency(price, this.currency);
    }

    private computeTotalUnitPrice() {
        const price = this.sparePartService.normalizeFloat(this.requirementForm.controls.price.value);
        return this.sparePartService.getTotalLinePrice(price, 1, this.requirementForm.controls.discountPercent.value,
            this.requirementForm.controls.taxPercent.value);
    }

    private computeTotalLinePrice() {
        const price = this.sparePartService.normalizeFloat(this.requirementForm.controls.price.value);
        return this.sparePartService.getTotalLinePrice(price,
            this.requirementForm.controls.quantityOrdered.value, this.requirementForm.controls.discountPercent.value, this.requirementForm.controls.taxPercent.value);
    }

    calculatePrices() {
        const purchasePrice = this.requirementForm.controls.purchasePrice.value;
        const approximatePrice = this.sparePartsRelatedToProduct
            .find(value => value.code === this.requirementForm.controls.code?.value)
            ?.price;
        this.backOfficeService.computeSellingPrice(purchasePrice, this.defaultMargin, approximatePrice).subscribe(prices => {
            const sellingPrice = !this.isFreeSparePart(this.requirementForm.controls.supplier.value) ? prices.sellingPrice || 0 : 0;
            this.requirementForm.controls.price.setValue(sellingPrice);
            this.requirementForm.controls.marginPercentage.setValue(prices.marginPercentage || 0);
            this.formatAndCalculateTotalQuotationLine();
        });
    }

    calculatePrice() {
        const marginPercentage = this.requirementForm.controls.marginPercentage.value;
        const purchasePrice = this.requirementForm.controls.purchasePrice.value;
        const price = this.computeSellingPrice(purchasePrice, marginPercentage);
        this.requirementForm.controls.price.setValue(price);
        this.formatAndCalculateTotalQuotationLine();
    }

    private computeSellingPrice(purchasePrice: number, margin: number): number {
        const sellingPrice = this.sparePartService.normalizeFloat((purchasePrice * (1 + (margin / 100))));
        return !this.isFreeSparePart(this.requirementForm.controls.supplier.value) ? sellingPrice : 0;
    }

    calculatePriceAndMargin() {
        const unitPrice = this.computeUnitPrice();
        this.requirementForm.controls.price.setValue(this.getPriceWithFormatAccordingToCurrency(unitPrice));
        const purchasePrice = this.requirementForm.controls.purchasePrice.value;
        const margin = this.getMargin(unitPrice, purchasePrice);
        this.requirementForm.controls.marginPercentage.setValue(margin);
        const totalLinePrice = this.getPriceWithFormatAccordingToCurrency(this.computeTotalLinePrice());
        this.requirementForm.controls.rowTotal.setValue(totalLinePrice);

    }

    private computeUnitPrice() {
        const totalUnitLinePrice = this.sparePartService.normalizeFloat(this.requirementForm.controls.totalUnitLinePrice.value);
        return this.sparePartService.getProductPrice(totalUnitLinePrice, this.requirementForm.controls.discountPercent.value,
            this.requirementForm.controls.taxPercent.value);
    }

    calculateMarge() {
        const newPrice = this.requirementForm.controls.price.value;
        const purchasePrice = this.requirementForm.controls.purchasePrice.value;
        const margin = this.getMargin(newPrice, purchasePrice);
        this.requirementForm.controls.marginPercentage.setValue(margin);
        this.formatAndCalculateTotalQuotationLine();
    }

    private getMargin(sellingPrice: number, purchasePrice: number): number {
        return purchasePrice > 0 ? this.sparePartService.normalizeFloat((((sellingPrice / purchasePrice) - 1) * 100)) : 0;
    }

    getTotalPriceHT(): any {
        return !!this.dataSourceLines ? this.dataSourceLines.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered, 0) : 0;
    }

    getTotalDiscount(): any {
        if (!!this.dataSourceLines) {
            const totalPriceHT = this.dataSourceLines.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;

    }

    getTotalNetHT(): any {
        if (!!this.dataSourceLines) {
            let totalNetHT;
            totalNetHT = this.dataSourceLines.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discountPercent > 0 ? total + (this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered -
                    ((this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered) * (line.discountPercent / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered), 0);
            if (this.discountQuotations > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotations / 100);
            }
            return totalNetHT;
        }
        return 0;

    }

    getTotalPriceTVA(): any {
        if (!!this.dataSourceLines) {
            return this.dataSourceLines.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.price) * line.quantityOrdered;
                const discount = line.discountPercent / 100;
                const vatRate = this.defaultVat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    displayMargin(): string {
        const margin = this.requirementForm.controls.marginPercentage.value;
        if (margin < 0) {
            this.requirementForm.controls.marginPercentage.markAsTouched();
            return '-';
        }

        return margin?.toString();
    }

    displayMarginInTable(line: DataSourceLine): number | string {
        const type = line.type;
        switch (type) {
            case TypeOfServiceEnum.SPARE_PART:
                return this.showMargin ? line.marginPercentage || 0 : '-';
            default:
                return '-';
        }
    }

    displayPurchasePriceInTable(line: DataSourceLine): number | string {
        const type = line.type;
        switch (type) {
            case TypeOfServiceEnum.SPARE_PART:
                return this.displayPurchasePrice ? line.purchasePrice : '-';
            default:
                return '-';
        }
    }
    normalize(value): number {
        return Number(this.sparePartService.normalizeFloat(value).toFixed(2));
    }
    private hasSparePart() {
        return this.dataSourceLines.some(value => value.type === QuotationType.SPARE_PART);
    }

    private prepareOrder() {
        this.orderItems = this.dataSourceLines.filter(dataSourceLine => dataSourceLine.type === QuotationType.SPARE_PART);
        this.orderItems.map(value => this.computeSupplierUnitPrice(value))
        this.orderExtraItems = this.dataSourceLines.filter(dataSourceLine => dataSourceLine.type !== QuotationType.SPARE_PART);
        this.order.externalId = this.addRequirementModal.folderId;
        this.order.organizationCode = this.addRequirementModal.organizationCode;
        this.order.shippingAddress = this.addRequirementModal.managementSite?.address;
        this.order.shippingContact = this.addRequirementModal.managementSite?.contact;
        this.order.siteCode = this.addRequirementModal.managementSite?.code;
        this.order.currency = this.currency;
        this.order.orderItems = this.orderItems;
        this.order.orderExtraItems = this.orderExtraItems;
    }
    private computeSupplierUnitPrice(orderItem: OrderItem): void {
        if (orderItem && orderItem.supplier && orderItem.currency) {
            orderItem.supplier.unitPrice ??= {
                currency: orderItem.currency,
                value: orderItem.purchasePrice
            };
        }
    }

    private prepareFolderUpdateRequest(): IFolderUpdateRequest {
        return {
            orderId: this.order.id
        };
    }

    private getPrice(orderItem: OrderItem) {
        return orderItem.purchasePrice > 0 ? this.sparePartService.normalizeFloat(orderItem.purchasePrice) : this.sparePartService.normalizeFloat(orderItem.price);
    }

    private getMarginPercentage(orderItem: OrderItem) {
        return orderItem.purchasePrice > 0 ? orderItem.marginPercentage : 0;
    }

    toQuotationLines(orderItems: OrderItem[]): QuotationLine[] {
        const quotationLines: QuotationLine[] = [];
        orderItems.forEach(orderItem => {
            const quotationLine: QuotationLine = {
                ...orderItem,
                quantity: orderItem.quantityOrdered,
                type: orderItem.type,
                payer: orderItem.payer,
                vat: {rate: String(orderItem.taxPercent)},
                discount: orderItem.discountPercent,
                priceAmount: {
                    value: this.sparePartService.normalizeFloat(orderItem.price),
                    currency: this.currency
                },
                purchasePrice: {
                    value: this.getPrice(orderItem),
                    currency: this.currency
                },
                marginPercentage: this.getMarginPercentage(orderItem),
                totalLinePrice: {
                    value: this.sparePartService.normalizeFloat(orderItem.rowTotal),
                    currency: this.currency
                }
            };
            quotationLines.push(quotationLine);
        });

        return quotationLines;
    }

    private prepareQuotation() {
        if (this.addRequirementModal.initialQuotation) {
            this.quotation = this.addRequirementModal.initialQuotation;
        } else {
            this.quotation = new Quotation();
        }
        if (!this.quotation.id) {
            this.quotation.id = uuid.v4();
            this.quotationTouched = true;
        }
        this.quotation.quotationLines = this.toQuotationLines(this.dataSourceLines);
        this.quotation.discount = this.discountQuotations;
        this.quotation.totalPrice = this.computeTotalPrice;
        this.quotation.target = 'CLIENT';
    }

    private updateFolder(folderUpdateRequest: IFolderUpdateRequest) {
        if (!this.quotationTouched) {
            this.onCloseAfterEditModal();
            return;
        }
        this.folderService.updateFolder(this.addRequirementModal.folderId, folderUpdateRequest).then(folder => {
            this.folderSubjectService.folderLoaded(folder);
            this.onCloseAfterEditModal();
            this.store$.dispatch(new StopLoading());
        });
    }

    private addOrder() {
        this.omsService.createOrder(this.order).subscribe((data) => {
            this.store$.dispatch(new StopLoading());
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                const folderUpdateRequest = this.prepareFolderUpdateRequest();
                if (this.addRequirementModal.warrantyCode === 'HG') {
                    this.prepareQuotation();
                    folderUpdateRequest.quotation = this.quotation;
                }
                this.updateFolder(folderUpdateRequest);

            }
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private updateOrder() {
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            this.store$.dispatch(new StopLoading());
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
                if (this.addRequirementModal.warrantyCode === 'HG') {
                    this.prepareQuotation();
                    const folderUpdateRequest = {
                        quotation: this.quotation
                    };
                    this.updateFolder(folderUpdateRequest);
                } else {
                    this.onCloseAfterEditModal();
                }
            }
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private OnSubmit() {
        this.prepareOrder();
        this.store$.dispatch(new StartLoading());
        if (!!this.order?.id) {
            this.updateOrder();
        } else {
            this.addOrder();
        }

    }

    validateForm(): void {
        if (this.hasSparePart()) {
            this.store$.dispatch(new StartLoading());
            const stocksRequest = this.dataSourceLines
                .filter(value => value.type === QuotationType.SPARE_PART)
                .map(data => ({
                    sparePartCode: data.code.trim(),
                    quantity: data.quantityOrdered
                }));
            this.sparePartService.getAvailabilityOfStocks(this.addRequirementModal.managementSite.code, stocksRequest)
                .subscribe(stockResponse => {
                    if (!!stockResponse) {
                        if (stockResponse.availabilityStatus === 'AVAILABLE' || stockResponse.availabilityStatus === 'UNKNOWN') {
                            this.stockSparePartsAvailable = true;
                            this.OnSubmit();
                            this.store$.dispatch(new StopLoading());
                        } else {
                            this.dataSourceLines.map(value => stockResponse.stocks
                                .filter(stock => value.code === stock.sparePartCode)
                                .map(stock => value.stock = stock));
                            this.refreshDataSourceTable();
                            this.stockSparePartsAvailable = false;
                            this.store$.dispatch(new StopLoading());
                        }
                    } else {
                        this.OnSubmit();
                        this.store$.dispatch(new StopLoading());
                    }
                });
        } else {
            this.OnSubmit();
        }
    }

    disabledForm(): boolean {
        const lines = this.dataSourceLines?.filter(dataSourceLine => dataSourceLine?.type !== QuotationType.QUOTATION_FEES);
        return this.computeTotalPrice?.value < 0 || lines?.length === 0;
    }

    private onCloseAfterEditModal() {
        this.dialogRef.close({
            order: this.order,
            quotationId: this.quotation?.id,
            discount: this.discountQuotations,
            totalPrice: this.computeTotalPrice
        });

    }

    onCloseModal(): void {
        this.dialogRef.close();
    }


}
