import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FileInfo} from '../../../../models/file-info.model';
import {FilesUtils} from '../../../../shared/utils/files-utils';
import {FileService} from '../../../../shared/services/file.service';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl, FormGroup} from '@angular/forms';
import {CodeLabel} from '../../../../models/element.model';
import {CodeToLabelService} from '../../../../../@fuse/services/code-to-label.service';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';

@Component({
    selector: 'app-reports-user-task',
    templateUrl: './reports-user-task.component.html',
    styleUrls: ['./reports-user-task.component.scss']
})
export class ReportsUserTaskComponent implements OnChanges {

    @Input() reportsCodeList: FileInfo[];
    userTaskReportsDataSource: any = {
        dataSource: new MatTableDataSource<FileInfo>([]),
        displayedColumns: ['status', 'date', 'attachmentType'],
        filterForm: null,
    };

    constructor(private fileService: FileService,
                private codeToLabelService: CodeToLabelService) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reportsCodeList && changes.reportsCodeList.currentValue) {
            this.codeToLabelService.getCodeLabels(ConfigCodeMapsEnum.ATTACHMENT_TYPE).subscribe(
                (response: CodeLabel[]) => {
                    this.codeToLabelService.setCodeLabels('SAV.CONFIG.ATTACHMENT_TYPE', response);
                    this.updateDataSource();
                }
            );
        }
    }

    private updateDataSource(): void {
        this.userTaskReportsDataSource.dataSource.data = this.reportsCodeList.filter(it => it.id != null);
        this.initAttachmentForm();
    }

    initAttachmentForm(): void {
        this.userTaskReportsDataSource.filterForm = new FormGroup({
            type: new FormControl(null),
        });
    }

    downloadAttachment(fileAttachment: FileInfo): void {
        this.fileService.getAttachmentFile(fileAttachment.id)
            .subscribe(resFile => {
                const reader = new FileReader();
                reader.readAsDataURL(resFile);
                reader.onloadend = () => {
                    FilesUtils.saveFile(reader.result, fileAttachment.name, resFile.type);
                };
            });
    }

}
