import {Order, OrderItem} from './order.model';
import {Payer} from './enums/payer.enum';
import {PayerModel} from './payer.model';
import {Product} from './product.model';
import {Site} from './site.model';
import {Quotation} from './quotation.model';

export class AddRequirementModal {
    organizationCode: string;
    context: string;
    managementSite: Site;
    initialOrder: Order;
    initialQuotation: Quotation;
    quotationFees: OrderItem;
    product: Product;
    payers: PayerModel;
    workforcesRule = [];
    warrantyCode: string;
    titleModal: string;
    folderId: string;
}
