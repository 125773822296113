<div id="dialog-container">
    <ejs-dialog #dialogComponent [visible]="false" width="350px" [isModal]="true" [closeOnEscape]="true" [showCloseIcon]="true" (close)="closeDialog()">
        <ng-template #header>
                <h2 class="text-bold">
                    {{ 'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG'|translate }}</h2>
        </ng-template>
        <ng-template #content>
            <form [formGroup]="chartsConfigForm" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="40px"
                  (ngSubmit)="saveDashboard()">
                <div class="form-group">
                    <div class="col-sm-12">
                        <label for="site">{{ 'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.SITE'|translate }}</label>
                        <ejs-dropdownlist required id="site" formControlName="site" [dataSource]="sites"
                                          [fields]="fields"></ejs-dropdownlist>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-12">
                        <div class="e-float-input e-input-group">
                            <input type="text" id="user" formControlName="user"
                                         placeholder="{{'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.USER'|translate}}">
                            <span class="e-float-line"></span>
                            <label class="e-float-text">{{ 'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.USER'|translate }}</label>

                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="roles">{{ 'DASHBOARD.WIDGET.FORM.SAVE_CHARTS_DIALOG.ROLE'|translate }}</label>
                    <ejs-multiselect #rolesMultiSelect
                                     required
                                     id="roles"
                                     formControlName="roles"
                                     [(value)]="selectedValue"
                                     [dataSource]="roles"
                                     [fields]="fields"
                                     (change)="changeHandler($event)"
                                     [changeOnBlur]="false">
                    </ejs-multiselect>
                </div>
                <div class="form-group" style="display: flex; gap: 10px; ">
                    <button ejs-button type="submit"
                            class="aster_btn"
                            [ngClass]="chartsConfigForm.invalid ? 'aster_btn_disabled':'aster_btn'"
                            [disabled]="chartsConfigForm.invalid">
                        {{ 'BUTTON.SAVE' | translate }}
                    </button>
                    <button ejs-button type="reset" class="aster_btn_cancel" (click)="closeDialog()">
                        {{ 'BUTTON.CANCEL' | translate }}
                    </button>
                </div>
            </form>
        </ng-template>
    </ejs-dialog>
</div>

<style>
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
    .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after {
        background: #181551;
    }
    .e-dropdownbase .e-list-item.e-active,
    .e-dropdownbase .e-list-item.e-selected {
        color:  #181551;
    }
</style>