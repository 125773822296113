import {BoldBI} from '@boldbi/boldbi-embedded-sdk';
import {GLOBAL} from '../../app-config';
import {DashboardOptions} from '../../models/dashboard/dashboard-options.model';
import {HttpParams} from '@angular/common/http';
import {Dashboard} from '../../models/dashboard/dashboard.model';
import {FuseNavigationItem} from '../../../@fuse/types';
import {Context} from '../../models/enums/context.enum';

export class DashboardUtils {

    static baseUrl = `${GLOBAL.gatewayEndpoint}/back-office-service/api/dashboard/v2`;

    static createInstance = (options: DashboardOptions) =>
        BoldBI.create({
            ...options,
            embedType: BoldBI.EmbedType.Component,
            environment: BoldBI.Environment.Enterprise,
            width: '100%',
            height: '100%',
            expirationTime: 100000,
            filterParameters: DashboardUtils.computeFilters(options),
            authorizationServer: {
                url: DashboardUtils.baseUrl + '/authorization',
                headers: {
                    'Authorization': `Bearer ${options.token}`
                }
            },
            dashboardSettings: {
                dataSourceConfig: {
                    hideDataSourceConfig: true
                }
            },
            autoRefreshSettings: {
                enabled: true,
                hourlySchedule: {
                    hours: 0,
                    minutes: 10,
                    seconds: 0
                }
            },
            actionComplete: args => {
                if (args.eventType.startsWith('Save')) {
                    options.onSave(args.data);
                }
            }
        })

    static loadDashboard = (options: DashboardOptions) => {
        BoldBI.getInstance(options.embedContainerId)?.destroy();
        switch (options.embedContainerId) {
            case 'view-dashboard':
                return DashboardUtils.createInstance(options).loadDashboard();
            case 'create-dashboard':
            case 'edit-dashboard':
                return DashboardUtils.createInstance(options).loadDesigner();
        }
    }

    static updateLanguage = (mode: string, language: string) =>
        BoldBI.getInstance(mode)?.updateFilters(`Language=${language}`)

    static computeFilters = (options: DashboardOptions) => {
        let filters = new HttpParams()
            .set('Language', options.currentLang);
        if (options.accessSites.length > 0) {
            const accessSitesInClause = `IN(${options.accessSites.map(site => `${site}`).join(',')})`;
            filters = filters.set('AccessSites', accessSitesInClause);
        }
        if (options.businessLink) {
            filters  = filters.set('BusinessLink', options.businessLink);
        }
        return filters.toString();
    }

    static computeDashboardItems = (dashboards: Dashboard[]) => {
        const children: FuseNavigationItem[] = dashboards.map(dashboard => ({
            id: dashboard.dashboardId,
            translate: dashboard.dashboardName,
            url: `/dashboard-v2/${dashboard.dashboardId}`,
            type: 'item',
            context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION],
            inRightPosition: false,
            scope: 'view.dashboard-v2'
        }) as FuseNavigationItem);

        children.push({
            id: 'create-dashboard',
            translate: 'NAV.DASHBOARD.CREATE',
            type: 'item',
            icon: 'add',
            context: [Context.PRODUCT_RECALL, Context.SAV, Context.REPARATION],
            url: '/dashboard-v2/create',
            inRightPosition: false,
            scope: 'configuration.dashboard-v2',
            hasDividerBefore: true
        } as FuseNavigationItem);

        return children;
    }

}
