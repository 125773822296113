import {Component, ElementRef, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationTurkish} from 'app/navigation/i18n/tr';

import {fuseConfig_horizontal} from 'app/fuse-config/index_horizontal';
import {fuseConfig_vertical} from 'app/fuse-config/index_vertical';
import {MatomoInjector} from 'ngx-matomo';
import {environment} from '../environments/environment';
import {Unsubscriber} from './unsubscriber';
import {select, Store} from '@ngrx/store';
import {AppState} from './store/app.state';
import {L10n} from '@syncfusion/ej2-base';
import {Constants} from './Constants';
import {UserService} from './shared/services/store/user.service';
import {currentLanguage} from './store/organization/organization.selectors';
import {isNorauto} from './store/user/user.selectors';
import {filter, map, switchMap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {CodeToLabelService} from '../@fuse/services/code-to-label.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    host: {
        '(window:resize)': 'onWindowResize($event)'
    }
})
export class AppComponent extends Unsubscriber implements OnInit {
    fuseConfig: any;
    navigation: any;

    isMobile = false;
    width: number = window.innerWidth;
    height: number = window.innerHeight;
    mobileWidth = 860;
    private previousUrl: string;


    constructor(@Inject(DOCUMENT) private document: any,
                private _elementRef: ElementRef,
                private _fuseConfigService: FuseConfigService,
                private _fuseNavigationService: FuseNavigationService,
                private _fuseSidebarService: FuseSidebarService,
                private _fuseSplashScreenService: FuseSplashScreenService,
                private _fuseTranslationLoaderService: FuseTranslationLoaderService,
                private store$: Store<AppState>,
                private _translateService: TranslateService,
                private _platform: Platform,
                private matomoInjector: MatomoInjector,
                private userService: UserService,
                private renderer: Renderer2,
                private codeToLabelService: CodeToLabelService,
                private router: Router) {

        super();
        this.matomoInjector.init(environment.matomoUrl, environment.matomoUrlSiteID);
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'fr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        L10n.load(Constants.L10N_LANGUAGES);

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        this._elementRef?.nativeElement?.removeAttribute('ng-version');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.isMobile = this.width < this.mobileWidth;
        this._fuseConfigService.setConfig({
            layout: this.isMobile ? fuseConfig_vertical.layout : fuseConfig_horizontal.layout
        });
        // Subscribe to config changes
        this.anotherSubscription = this._fuseConfigService.config
            .subscribe((config) => {
                this.fuseConfig = config;
                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        this.previousUrl = this.router.url;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const currentUrl = this.router.url.split('?')[0];
                const previousUrl = this.previousUrl.split('?')[0];

                if (currentUrl !== previousUrl) {
                    this.codeToLabelService.clear();
                }

                this.previousUrl = this.router.url;
            }
        });
        this.anotherSubscription = combineLatest([
            this.store$.pipe(select(currentLanguage)),
            this.store$.pipe(select(isNorauto))
        ]).pipe(
            map(([currentLang, isNorauto]) => ({currentLang, isNorauto})),
            filter(({currentLang, isNorauto}) => currentLang === 'fr' && isNorauto),
            switchMap(() => this.userService.lemonUserLoaded$),
            filter((user) => !!user),
            switchMap((user) => this.loadScripts(user))
        ).subscribe(() => console.log('Lemon learning is loaded!'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }


    onWindowResize(event): void {
        this.width = event.target.innerWidth;
        this.height = event.target.innerHeight;
        this.isMobile = this.width < this.mobileWidth;
        this.updateFuseConfig();
    }


    private updateFuseConfig(): void {
        if (!this.fuseConfig || !this.fuseConfig.layout) {
            return;
        }
        if (this.fuseConfig.layout.isMobile !== this.isMobile) {
            this._fuseConfigService.setConfig({
                layout: this.isMobile ? fuseConfig_vertical.layout : fuseConfig_horizontal.layout
            });
        }
    }

    private loadScripts(user: any): Promise<void> {
        sessionStorage.setItem('lemon_name', user.username);
        sessionStorage.setItem('lemon_email', user.email);
        sessionStorage.setItem('lemon_user', JSON.stringify(user));
        return Promise.all([
            this.loadScript('assets/js/lemon.learning.app.js'),
            this.loadScript('https://static.lemonlearning.com/player/bundle.js', 'lemonlearning-player-embed')
        ]).then(() => {
            console.log('lemon starting!!');
        });
    }
    private loadScript(scriptUrl: string, id?): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const script = this.renderer.createElement('script');
            script.src = scriptUrl;
            script.async = '';
            script.defer = true;
            if (id) {
                script.id = id;
            }
            script.onload = () => {
                resolve();
            };
            script.onerror = (error) => {
                reject(error);
            };
            this.renderer.appendChild(document.body, script);
        });
    }

}
