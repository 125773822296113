import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WorkflowService} from 'app/shared/services/workflow.service';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Folder, Payment} from '../../../../../../models/folder.model';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {TaskVariables} from '../../../task.variables';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {SparePartsInfoComponent} from './spare-parts-info/spare-parts-info.component';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {Quotation} from '../../../../../../models/quotation.model';
import {OperationModeEnum} from '../../../../../../models/enums/operationMode.enum';

@Component({
    selector: 'app-store-payment',
    templateUrl: './store-payment.component.html',
    styleUrls: ['./store-payment.component.scss']
})
export class StorePaymentComponent implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Output() refreshWorkflowStatus = new EventEmitter();
    @Output() previewTask = new EventEmitter<any>();

    invoiceForm: any = {
        form: null,
    };

    private paymentId: string;
    private payment: Payment;

    constructor(private workflowService: WorkflowService,
                private sparePartService: SparePartService,
                private matDialog: MatDialog,
                private store$: Store<AppState>) {
    }

    ngOnInit(): void {
        this.initVariables();
        this.payment = this.folder.payments.find(value => value.id === this.paymentId);
        this.initInvoiceForm();
    }

    initInvoiceForm(): void {
        this.invoiceForm.form = new FormGroup({
            invoiceNumber: new FormControl(null, Validators.required),
            paymentAmount: new FormControl(this.payment.amountToPay, [Validators.required, Validators.min(0)])
        });
    }

    initVariables(): void {
        this.paymentId = this.variablesTask[TaskVariables.PAYMENT_ID];
    }

    onSubmit(): void {
        // todo is tempo: todo implemente this logic in backend
        if (this.isAutorizedOperationMode() && this.quotationHasSpareParts()) {
            this.store$.dispatch(new StartLoading());
            const lastQuotation = this.getLastQuotation();
            this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), this.prepareStockRequest(lastQuotation))
                .subscribe(availabilityResponse => {
                    if (!!availabilityResponse) {
                        if (availabilityResponse.availabilityStatus === 'AVAILABLE') {
                            this.fireWorkflow();
                        } else {
                            this.store$.dispatch(new StopLoading());

                            const dialogRef = this.matDialog.open(SparePartsInfoComponent, {
                                height: 'auto',
                                width: '100vh',
                                minWidth: '800px', data: {
                                    stocks: availabilityResponse.stocks,
                                    folder: this.folder
                                }
                            });
                            dialogRef.afterClosed().subscribe(chooseOtherSparePart => {
                                    if (chooseOtherSparePart) {
                                        this.submitToPreviewsTask();
                                    }
                                }
                            );
                        }
                    } else {
                        this.fireWorkflow();
                    }
                });
        } else {
            this.fireWorkflow();
        }

    }

    private prepareStockRequest(lastQuotation: Quotation) {
        const stockRequest = lastQuotation?.quotationLines
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(quotationLine => ({
                sparePartCode: quotationLine.code.trim(),
                quantity: quotationLine.quantity
            }));
        return stockRequest;
    }

    private submitToPreviewsTask() {
        if (this.folder.operationMode === 'SPARE_PARTS_ORDER') {
            this.previewTask.emit({
                'currentTask': 'STORE_PAYMENT',
                'previewsTask': 'CHOOSE_SPARE_PART_TO_ORDER'
            });
        } else if (this.folder.operationMode === 'INTERNAL_REPARATION') {
            this.previewTask.emit({
                'currentTask': 'STORE_PAYMENT',
                'previewsTask': 'INPUT_REQUIREMENT_2'
            });
        }
    }

    private fireWorkflow() {
        this.inputMap.emit({
            'PAYMENT_REFERENCE': this.invoiceForm.form.value.invoiceNumber,
            'PAYMENT_PRICE': this.invoiceForm.form.value.paymentAmount,
            'payment': 'PAYMENT_ACCEPTED',
            'hasPaid': 'true'
        });
    }

    private getManagementSiteCode(): string {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE)?.code;
    }

    private quotationHasSpareParts(): boolean {
        return this.getLastQuotation()?.quotationLines.some(quotationLine => quotationLine.type === QuotationType.SPARE_PART);
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }

    public isAuthorizedEditMode() {
        return this.isInternalReparation() || this.isExternalReparation();
    }

    public isAutorizedOperationMode() {
        return this.isInternalReparation() || this.isSparePartOrder();
    }

    private isExternalReparation() {
        return this.folder.operationMode === OperationModeEnum.SERVICE_CENTER_REPAIR;
    }

    private isSparePartOrder() {
        return this.folder.operationMode === OperationModeEnum.SPARE_PARTS_ORDER;
    }

    private isInternalReparation() {
        return this.folder.operationMode === OperationModeEnum.INTERNAL_REPARATION;
    }

    cancelPayment(): void {
        this.inputMap.emit({'payment': 'PAYMENT_CANCELED'});
    }


    switchPaymentMode(): void {
        this.store$.dispatch(new StartLoading());
        this.workflowService.switchPaymentMode(this.folder.id).subscribe(() => {
            this.store$.dispatch(new StopLoading());
            this.refreshWorkflowStatus.emit();
        }, () => {
            this.store$.dispatch(new StopLoading());

        });
    }
}
