
    <mat-card-content *ngIf="repairStatusForm.form">
        <form [formGroup]="repairStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
            <app-workflow-status-progress-bar taskTimeoutDuration="{{getTaskDuration()}}"
                                              currentStatusDate="{{getStatusDate()}}">
            </app-workflow-status-progress-bar>
            <repairer-task-title [folder]="folder"
                                 [instructionUserTask]="instructionUserTask"
                                 [productLocation]="variablesTask.productLocation"
            ></repairer-task-title>

            <mat-radio-group formControlName="status" class="ml-20" labelPosition="after" fxLayout="column"
                             fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <mat-radio-button [id]="status.status" class="full-width-radio" *ngFor="let status of repairStatusForm.placeholders"
                                  [value]="status.status">
                    <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                        <div fxFlex>
                            <div>{{ status.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="repairStatusForm.form.value.status === 'FINISHED'">
                <br>
                <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="space-around center" fxLayoutGap="12px">
                    <div *ngIf="folder.context === 'REPARATION'">
                        <label class="h3  text-bold"
                               *ngIf="!isChooseOtherCarrier">{{'PRODUCT_RETURNED_WITH_CHRONOPOST' |translate}}</label>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                            <mat-checkbox (change)="chooseOtherCarrier()"
                                          [checked]="isChooseOtherCarrier">
                                {{'CHOOSE_OTHER_CARRIER' | translate}}
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="isChooseOtherCarrier">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="12px">
                            <mat-form-field appearance="outline">
                                <mat-label>{{'FOLDER.TRACKING_NUMBER'|translate}}</mat-label>
                                <input matInput placeholder="{{'FOLDER.TRACKING_NUMBER'|translate}}"
                                       formControlName="trackingNumber">
                            </mat-form-field>
                            <div *ngIf="!!folder.context">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{'FOLDER.CARRIER_CODE'|translate}}</mat-label>
                                    <input matInput
                                           placeholder="{{'FOLDER.CARRIER_CODE' | translate }}"
                                           formControlName="carrier" required [matAutocomplete]="autoCarrier"
                                           (keyup)="changeCarrier()">
                                    <mat-autocomplete #autoCarrier="matAutocomplete"
                                                      (optionSelected)='carrierChanged($event)'>
                                        <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                                        <ng-container *ngIf="!isLoading">
                                            <mat-option *ngFor="let carrier of carriers "
                                                        [value]="carrier">
                                                <span>{{ carrier.label }}</span>
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="12px">
                        <div fxLayout="row" fxLayoutAlign="end end">

                            <div (click)="openQuotationModal()" [ngClass]="'cursor-pointer pr-10'" fxLayout="row"
                                 fxLayoutGap="5px"
                                 fxLayoutAlign="end center">
                                <ng-container
                                    *ngIf="reportLineDataTable.data.length>0 || irisDataTable.data.length>0;else SHOW_ADD_BUTTON">
                                    <mat-icon class="mr-8">edit</mat-icon>
                                    <span [ngClass]="'link-underline'">{{'BUTTON.EDIT' | translate}}</span>
                                </ng-container>
                                <ng-template #SHOW_ADD_BUTTON>
                                    <mat-icon>add_circle_outline</mat-icon>
                                    <span [ngClass]="'link-underline'">{{'BUTTON.ADD' | translate}}</span>
                                </ng-template>
                            </div>

                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                        <table mat-table [dataSource]="reportLineDataTable"
                               class="mat-elevation-z4 w-100-p mt-16 mb-16">
                            <ng-container matColumnDef="code">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.CODE' | translate}}  </th>
                                <td mat-cell *matCellDef="let sparePart"> {{sparePart.code}} </td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.TYPE' | translate}}  </th>
                                <td mat-cell
                                    *matCellDef="let sparePart"> {{'MODAL.QUOTATION.TYPE.' + sparePart.type | translate}} </td>
                            </ng-container>
                            <ng-container matColumnDef="label">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.LABEL' | translate}}  </th>
                                <td mat-cell *matCellDef="let sparePart"> {{sparePart.label}} </td>
                            </ng-container>
                            <ng-container matColumnDef="payer">
                                <th mat-header-cell *matHeaderCellDef
                                    class="table-header">  {{'MODAL.QUOTATION.PAYER' | translate}}  </th>
                                <td mat-cell
                                    *matCellDef="let sparePart">{{!!sparePart.payer ? ('RULE_WARRANTIES.CONFIGS.PAYERS_CONFIGS.' + sparePart.payer | translate) : "-"}} </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="reportLineColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: reportLineColumns;"></tr>
                        </table>

                    </div>
                </div>

<!--                <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px" class="mb-16">-->
<!--                    <label class="h3 text-bold mt-16 mb-16">-->
<!--                        {{'FOLDER.REPAIR_IN_PROGRESS.IRIS' | translate}}-->
<!--                    </label>-->
<!--                    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">-->
<!--                        <table mat-table [dataSource]="irisDataTable" class="mat-elevation-z4 w-100-p">-->
<!--                            <ng-container matColumnDef="symptom">-->
<!--                                <th mat-header-cell *matHeaderCellDef-->
<!--                                    class="table-header">{{'FOLDER.REPAIR_IN_PROGRESS.SYMPTOM' | translate}} </th>-->
<!--                                <td mat-cell *matCellDef="let iris"-->
<!--                                > {{iris.symptom?.label}} </td>-->
<!--                            </ng-container>-->
<!--                            <ng-container matColumnDef="condition">-->
<!--                                <th mat-header-cell *matHeaderCellDef-->
<!--                                    class="table-header"> {{'FOLDER.REPAIR_IN_PROGRESS.CONDITION' | translate}} </th>-->
<!--                                <td mat-cell-->
<!--                                    *matCellDef="let iris">{{ iris.condition?.label}} </td>-->
<!--                            </ng-container>-->
<!--                            <ng-container matColumnDef="repair">-->
<!--                                <th mat-header-cell *matHeaderCellDef-->
<!--                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.REPARATION' | translate}} </th>-->
<!--                                <td mat-cell *matCellDef="let iris"> {{iris.reparation?.label}} </td>-->
<!--                            </ng-container>-->
<!--                            <ng-container matColumnDef="defect">-->
<!--                                <th mat-header-cell *matHeaderCellDef-->
<!--                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.DEFECT' | translate}} </th>-->
<!--                                <td mat-cell *matCellDef="let iris"> {{iris.defect?.label}} </td>-->
<!--                            </ng-container>-->
<!--                            <ng-container matColumnDef="section">-->
<!--                                <th mat-header-cell *matHeaderCellDef-->
<!--                                    class="table-header">  {{'FOLDER.REPAIR_IN_PROGRESS.SECTION' | translate}} </th>-->
<!--                                <td mat-cell *matCellDef="let iris"> {{iris.section?.label}} </td>-->
<!--                            </ng-container>-->
<!--                            <tr mat-header-row *matHeaderRowDef="irisColumns"></tr>-->
<!--                            <tr mat-row *matRowDef="let row; columns: irisColumns;"></tr>-->
<!--                        </table>-->
<!--                    </div>-->
<!--                </div>-->
                <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="12px">
                    <form [formGroup]="agentCommentForm" fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="end end">
                            <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                                <mat-label>{{'FOLDER.COMMENT' | translate}}</mat-label>
                                <textarea matInput inputControl rows="4" cols="7"
                                          [formControl]="agentCommentForm.get(['comment'])"></textarea>
                            </mat-form-field>
                        </div>
                    </form>
                </div>
                <label class="h3 text-bold mt-16 mb-16">
                    {{'FOLDER.REPAIR_IN_PROGRESS.REPAIR_REPORT' | translate}}
                </label>
                <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="12px">
                    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="30" fxFlex.lt-md="100">
                        <ngx-dropzone
                            #dropzone
                            label="{{fileUploadForm.hasFile ? fileUploadForm.file: 'FOLDER.FILE.UPLOAD_MSG' | translate}}"
                            [showPreviews]="true" [multiple]="false" [accept]="'*'" (filesAdded)="onFilesAdded($event)">
                        </ngx-dropzone>
                    </mat-card>
                    <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex="70" fxFlex.lt-md="100">
                        <table mat-table [dataSource]="attachmentReportDataTable" class="w-100-p mt-8">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> {{'FOLDER.FILE.TITLE' | translate}}</th>
                                <td mat-cell *matCellDef="let element" class="text-left">
                                    <ng-container>
                                            <p class="file_name">
                                                <a (click)="downloadAttachmentFile(element.id,element.name)" class="cursor-pointer">
                                                <mat-icon>file_copy</mat-icon>
                                                {{element.name}}
                                                </a>
                                            </p>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> {{'BUTTON.ACTION' | translate}}</th>
                                <td mat-cell
                                    *matCellDef="let element">
                                    <mat-icon color="warn" class="cursor-pointer"
                                              (click)="removeFile(element)">
                                        delete_forever
                                    </mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></tr>
                        </table>
                    </mat-card>
                </div>
            </ng-container>

            <div *ngIf="checkReparationStatus()">
                <div fxLayout="column" fxLayoutAlign="space-between none">
                    <mat-form-field class="py-4" appearance="outline" fxFlex="100">
                        <mat-label>{{'FOLDER.COMMENT_FORM.LABEL' | translate}}</mat-label>
                        <textarea matInput inputControl rows="10" cols="7"
                                  [placeholder]="'FOLDER.COMMENT_FORM.PLACEHOLDER' | translate"
                                  formControlName="description"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayoutAlign="end center" class="mt-24">
                <button type="submit" mat-stroked-button [disabled]="disabledButtonForm()"
                        [ngClass]="disabledButtonForm() ? 'aster_btn_disabled' : 'aster_btn'">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </mat-card-content>
