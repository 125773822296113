import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {GLOBAL} from '../../app-config';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FolderPublic} from '../../models/folder.public.model';
import {Page} from '../../models/page.model';
import {PublicQuotation} from '../../models/quotation.model';
import {ConfigCodesEnum, ICodeLabelObject} from '../../models/codeLabelObject.model';
import {FolderSupplierPublic} from '../../models/supplier/folder-supplier-public.model';
import {ReparationReport} from '../../models/reparationReport/reparation-report.model';
import {CountryConfiguration} from '../../models/country.model';
import {ConfigurationReferentialDto} from '../../models/configurationReferentialDto.model';
import {PageSearchRequest} from '../../models/page-search-request.model';
import {IEvent} from '../../models/events.model';
import {AttachmentType} from '../../models/attachment-type.model';
import {FileInfo} from '../../models/file-info.model';


@Injectable({
    providedIn: 'root'
})
export class PublicService {
    private pathPublic = `${GLOBAL.gatewayEndpoint}/back-office-service/api/public`;

    constructor(public httpClient: HttpClient) {
    }

    getAllowedListAttachmentTypes(sharedWith: String, uidFolder: String, locale: string): Observable<AttachmentType[]> {
        let params = new HttpParams();
        params = params.append('locale', String(locale));
        return this.httpClient.get<AttachmentType[]>(`${this.pathPublic}/allowedList/${sharedWith}/${uidFolder}`, {params: params});
    }
    getFolderByUid(uid: string, isSupplier: boolean, locale: string): Observable<FolderPublic> {
        let params = new HttpParams();
        params = params.append('isSupplier', isSupplier + '');
        params = params.append('locale', locale);
        return this.httpClient.get<FolderPublic>(`${this.pathPublic}/folder/${uid}`, {params: params});
    }

    getFolderDetails(uid: string, isSupplier: boolean, locale: string): Observable<FolderPublic> {
        let params = new HttpParams();
        params = params.append('locale', String(locale));
        return this.httpClient.get<FolderPublic>(`${this.pathPublic}/folder-details/${uid}/${isSupplier}`, {params: params});
    }


    bulkSearchCodeLabelObjects(folderUid: string, configCode: ConfigCodesEnum, queryParams: PageSearchRequest, inputs: Map<string, string>): Promise<Page<ICodeLabelObject>> {
        const requestParam = this.buildRequestParamFromInputs(inputs);
        return this.httpClient.get<Page<ICodeLabelObject>>(`${this.pathPublic}/${folderUid}/configs/item/${configCode}/_search?${requestParam}`, {params: <any>queryParams}).toPromise();
    }

    /* Complete Task Workflow quotation repairer*/
    completeTaskQuotationRepairer(folderUid: string, attachments: any, quotation: PublicQuotation, repairerComment: string): Observable<any> {
        const formData = new FormData();
        formData.append('quotation', JSON.stringify(quotation));
        if (!!repairerComment) {
            formData.append('repairerComment', repairerComment);
        }
        attachments.forEach(attachment => {
            const fileUploaded = new File([attachment.file], attachment.info.name, {type: attachment.info.type, lastModified: Date.now()});
            formData.append('files', fileUploaded);
        });
        const headersHttp = new HttpHeaders();
        return this.httpClient.put<any>(`${this.pathPublic}/workflow/complete/quotation/repairer/${folderUid}`, formData, {
            observe: 'response',
            headers: headersHttp
        }).pipe(map((response) => {
            return response;
        }));
    }

    completeTaskCancelIntervention(folderUid: string): Observable<any>{
        return this.httpClient.put<any>(`${this.pathPublic}/workflow/complete/cancelIntervention/${folderUid}`, null);
    }

    getCompleteTaskWorkflow(folderUid: string, mapInput: any, isSupplier: boolean): Promise<any> {
        if (!isSupplier) {
            return this.httpClient.put<any>(`${this.pathPublic}/workflow/complete/${folderUid}`, mapInput).toPromise();
        } else {
            return this.httpClient.put<any>(`${this.pathPublic}/supplier/workflow/${folderUid}`, mapInput).toPromise();
        }
    }

    private buildRequestParamFromInputs(inputs: Map<string, string>): string {
        let requestParam = '';
        inputs.forEach((value: string, key: string) => {
            if (!value || value === '*') {
                requestParam += `${key}=any&`;
            } else {
                requestParam += `${key}=${value}&`;
            }
        });

        requestParam = requestParam.substring(0, requestParam.length - 1);
        return requestParam;
    }

    getSparePartsOrderSupplier(uidFolder: string, requestedServiceId): Observable<FolderSupplierPublic> {
        return this.httpClient.get<any>(`${this.pathPublic}/spare-parts-order/${uidFolder}/${requestedServiceId}`);
    }

    addRepairerComment(uidFolder: string, repairerComment: string, commentType: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('repairerComment', repairerComment + '');
        params = params.append('commentType', commentType + '');
        return this.httpClient.put<any>(`${this.pathPublic}/repairer-comment/${uidFolder}`, params);
    }

    updateReparationReport(uidFolder: string, reparationReport: ReparationReport): Observable<any> {
        return this.httpClient.put<any>(`${this.pathPublic}/reparation-report/${uidFolder}`, reparationReport);
    }

    updateAttachments(uidFolder: string, attachments: FileInfo[]): Observable<any> {
        return this.httpClient.put<any>(`${this.pathPublic}/attachments/${uidFolder}`, attachments);
    }
    updateFolderWithSupplierComment(uidFolder: string, supplierComment: string, commentType: string): Observable<any> {
        let params = new HttpParams();
        params = params.append('supplierComment', supplierComment + '');
        params = params.append('commentType', commentType + '');
        return this.httpClient.put<any>(`${this.pathPublic}/supplier-comment/${uidFolder}`, params);
    }

    getByCountryCode(uidFolder: string): Observable<CountryConfiguration> {
        return this.httpClient.get<CountryConfiguration>(`${this.pathPublic}/${uidFolder}/country`);
    }

    getConfigurationReferential(uidFolder: string, locale: string): Observable<ConfigurationReferentialDto> {
        let params = new HttpParams();
        params = params.append('locale', String(locale));
        return this.httpClient.get<ConfigurationReferentialDto>(`${this.pathPublic}/config-referential/${uidFolder}`, {params: params});
    }

    getEventWorkflow(uidFolder: string, eventName: string): Observable<IEvent> {
        let params = new HttpParams();
        params = params.append('eventName', eventName);
        return this.httpClient.get<IEvent>(`${this.pathPublic}/folder/event/${uidFolder}`, {params: params});
    }
}
