<div class="page-layout blank p-24" fusePerfectScrollbar fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="row" class="w-100-p mb-16" fxLayoutAlign="space-between center">
        <div>
            <h1 class="text-bold">{{ 'NAV.NOTIFICATIONS.LIST' | translate }}</h1>
        </div>
    </div>
    <ejs-grid #grid [dataSource]="datasource" [allowPaging]='true' [allowSorting]='true'
              [allowExcelExport]="true" [pageSettings]='{pageSize: 10, pageSizes: [10, 25, 50, 100]}'
              [selectionSettings]="{type: 'Multiple', checkboxOnly: true}" (actionBegin)="onAnyAction($event)">
        <ng-template #toolbarTemplate let-data>
            <ejs-toolbar>
                <e-items>
                    <e-item id="resolve">
                        <ng-template #template>
                            <button ejs-button cssClass='e-success'
                                    (click)="updateStatusOfNotificationSelected('RESOLVED')"
                                    [disabled]="notificationsSelected.length === 0"
                                    [ngClass]="{'disabled': notificationsSelected.length === 0,
                                        'enabled': notificationsSelected.length !== 0}">
                                <span class="e-icons e-check check-icon"></span>
                                {{ 'BUTTON.RESOLVED' | translate }}
                            </button>
                        </ng-template>

                    </e-item>
                    <e-item id="ignore">
                        <ng-template #template>
                            <button ejs-button cssClass="e-warning"
                                    (click)="updateStatusOfNotificationSelected('IGNORED')"
                                    [disabled]="notificationsSelected.length === 0"
                                    [ngClass]="{'disabled': notificationsSelected.length === 0,
                                        'enabled': notificationsSelected.length !== 0}">
                                <span class="e-icons e-close close-icon"></span>
                                {{ 'BUTTON.IGNORED' | translate }}
                            </button>
                        </ng-template>
                    </e-item>
                </e-items>
            </ejs-toolbar>
        </ng-template>
        <e-columns>
            <e-column headerText="" width='30'>
                <ng-template #headerTemplate let-data>
                    <input *ngIf="hasSelectableRows()" type="checkbox" [checked]="allSelected"
                           (change)="selectAll($event)"/>
                </ng-template>
                <ng-template #template let-data>
                    <input *ngIf="data.status !== 'IGNORED' && data.status !== 'RESOLVED'"
                           type="checkbox" [checked]="isRowSelected(data)" (change)="checkedRow($event, data)"/>
                </ng-template>
            </e-column>
            <e-column field="type" [headerText]="('NOTIFICATION.TYPE' | translate)" width="50">
                <ng-template #template let-data>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <ng-container *ngIf="data.type === 'ERROR'">
                            <span class="e-icons e-error-treeview error-icon"></span>
                            <span>{{ 'NOTIFICATION.ERROR' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="data.type === 'INFO'">
                            <span class="e-icons e-circle-info info-icon"></span>
                            <span>{{ 'NOTIFICATION.INFO' | translate }}</span>
                        </ng-container>
                    </div>
                </ng-template>
            </e-column>


            <e-column field="creationDate" [headerText]="('NOTIFICATION.CREATION_DATE' | translate)" width="90">
                <ng-template #template let-data>
                    <div>
                        {{ data.creationDate | date: ('GENERAL.DATE_TIME_FORMAT' | translate) }}
                    </div>
                </ng-template>
            </e-column>

            <e-column [headerText]="('NOTIFICATION.MESSAGE' | translate)" width="130">
                <ng-template #template let-data>
                    <div class="notification-message">
                        {{ getNotificationKey(data.extraInfo[data.type], data.type) | translate }}
                    </div>
                </ng-template>
            </e-column>

            <e-column field="businessId" [headerText]="('NOTIFICATION.FOLDER_ID' | translate)" width="110">
                <ng-template #template let-data>
                    <div fxLayout="row" fxLayoutGap="10px" (click)="openFolder(data.businessId)">
                        <span class="ellipsis-pointer">{{ data.businessId }}</span>
                        <span class="e-icons e-open-link open-link-icon" (click)="openFolder(data.businessId)"></span>
                    </div>
                </ng-template>
            </e-column>

            <e-column field="status" [headerText]="('NOTIFICATION.STATUS' | translate)" width="80">
                <ng-template #template let-data>
                    <ejs-chiplist cssClass="e-outline">
                        <e-chips>
                            <e-chip
                                [text]="('FOLDER.PUSH.NOTIFICATION.' + data.status) | translate"
                                [cssClass]="getChipClass(data.status)">
                            </e-chip>
                        </e-chips>
                    </ejs-chiplist>
                </ng-template>
            </e-column>

            <e-column [headerText]="('BUTTON.ACTION' | translate)" width="80" [allowFiltering]="false">
                <ng-template #template let-data>
                    <ng-container
                        *ngIf="data.status === 'RESOLVED' || data.status === 'IGNORED'; else actionButtons">
                        <button *ngIf="data.previousStatus" ejs-button cssClass="e-primary"
                                (click)="restoreStatus(data)">
                            <span class="e-icons e-undo undo-icon"></span>
                            {{ 'BUTTON.RESTORE' | translate }}
                        </button>
                    </ng-container>
                    <ng-template #actionButtons>
                        <div fxLayout="row" fxLayoutGap="10px">
                            <button ejs-button cssClass="e-success" (click)="updateStatus('RESOLVED', data)">
                                <span class="e-icons e-check check-icon"></span>
                                {{ 'BUTTON.RESOLVED' | translate }}
                            </button>
                            <button ejs-button cssClass="e-warning" (click)="updateStatus('IGNORED', data)">
                                <span class="e-icons e-close close-icon"></span>
                                {{ 'BUTTON.IGNORED' | translate }}
                            </button>
                        </div>
                    </ng-template>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>
</div>