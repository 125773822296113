import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DashboardService} from 'app/shared/services/dashboard.service';
import {select, Store} from '@ngrx/store';
import {map, take} from 'rxjs/operators';
import {currentUser} from 'app/store/user/user.selectors';
import {UserState} from '../../../../store/user/user.state';
import {AppState} from '../../../../store/app.state';
import {ConfigV2Service} from '../../../../shared/services/config-v2.service';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';
import {FieldsModel} from '@syncfusion/ej2-dropdowns';
import {DialogComponent} from '@syncfusion/ej2-angular-popups';

import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {DashboardRule} from '../model/dashboard.model';
import {Configuration} from '../../../../models/configuration.value';
import {Unsubscriber} from '../../../../unsubscriber';
import {MultiSelectComponent} from '@syncfusion/ej2-angular-dropdowns';
import {CodeLabel} from '../../../../models/element.model';

@Component({
    selector: 'app-add-edit-dashboard-dialog',
    templateUrl: './add-edit-dashboard-dialog.component.html'
})
export class AddEditDashboardDialogComponent extends Unsubscriber implements OnInit, OnDestroy {
    @ViewChild('dialogComponent') dialogComponent: DialogComponent;
    @ViewChild('rolesMultiSelect') rolesMultiSelect!: MultiSelectComponent;

    @Output() refreshEvent = new EventEmitter<string>();
    chartsConfigForm: FormGroup;
    sites: Configuration[];
    fields: FieldsModel;
    roles: CodeLabel[];
    user: UserState;
    data: any;
    selectedValue = [];

    constructor(private store$: Store<AppState>,
                private dashboardService: DashboardService,
                private configV2Service: ConfigV2Service,
                private backOfficeService: BackOfficeService) {
        super();
        this.fields = {
            text: 'label',
            value: 'code',
        };
    }

    ngOnInit() {
        this.createFormGroup();
        this.anotherSubscription = this.configV2Service.findAllValues(ConfigCodeMapsEnum.SITE).pipe(
            take(1),
            map(sites => [
                {id: '', code: 'any', label: '*', parent: ''},
                {id: '', code: '*', label: 'ALL', parent: ''},
                ...sites])
        ).subscribe(sites => this.sites = sites);
        this.anotherSubscription = this.backOfficeService.getRoles().subscribe((data) => {
            this.roles = [
                ...data.map(role => ({code: role, label: role})),
                {code: 'any', label: '*'}
            ];
        });
        this.anotherSubscription = this.store$.pipe(select(currentUser), take(1))
            .subscribe(currentUser => this.user = currentUser);
    }

    changeHandler(args: any): void {
        if (args.value.includes('any') && args.value.length > 1) {
            this.selectedValue = args.value.filter((val: string) => val !== 'any');
        } else {
            this.selectedValue = args.value;
        }

    }

    open(data: any): void {
        this.data = data;
        if (this.data.rule) {
            this.initForm();
        }
        this.dialogComponent.show();
    }

    createFormGroup() {
        this.chartsConfigForm = new FormGroup({
            site: new FormControl(null, Validators.required),
            user: new FormControl('any'),
            roles: new FormControl([], Validators.required)
        });
    }

    initForm() {
        this.chartsConfigForm.patchValue(this.data.rule);
    }

    closeDialog() {
        this.chartsConfigForm.reset();
        this.dialogComponent.hide();
    }

    saveDashboard() {
        const dashboardRuleRequest: DashboardRule = {
            context: this.user.context,
            organizationCode: this.user.organizationCode,
            user: this.getValueOrAny('user'),
            roles: this.chartsConfigForm.get('roles').value,
            site: this.chartsConfigForm.get('site').value,
            businessLink: 'any',
        };
        this.data.save(dashboardRuleRequest)
            .pipe(take(1))
            .subscribe(() => {
                this.closeDialog();
                this.dashboardService.showSuccessSave();
            }, () => this.dashboardService.showError());
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getValueOrAny = (field: string | (string | number)[]) => this.chartsConfigForm.get(field).value ?? 'any';
}
