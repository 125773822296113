import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {forkJoin, ReplaySubject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';
import {ConfigurationUtils} from '../../../../store/configuration/configuration.utils';
import {ConfigV2Service} from '../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../models/element.model';
import {ConfigCodesEnum} from '../../../../models/codeLabelObject.model';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {switchMap} from 'rxjs/operators';
import {CodeToLabelService} from '../../../../../@fuse/services/code-to-label.service';

@Component({
    selector: 'warranty-reason-selector',
    templateUrl: './warranty-reason-selector.component.html'
})
export class WarrantyReasonSelectorComponent extends Unsubscriber implements OnInit {

    @Input() selectedWarrantyReasons: string[];
    @Output() selectedWarrantyReasonsChange = new EventEmitter<string[]>();

    warrantyReasons: CodeLabel[] = [];
    warrantyReasonSearchField: string;
    filteredWarrantyReasons = new ReplaySubject<CodeLabel[]>();
    firstTimeLoading = true;

    constructor(private codeToLabelService: CodeToLabelService) {
        super();
    }

    ngOnInit() {
        if (this.selectedWarrantyReasons.length > 0) {
            this.loadWarrantyReasons();
        }
    }

    loadWarrantyReasons() {
        if (this.firstTimeLoading) {
            this.triggerListeningForCacheClear();
            this.firstTimeLoading = false;
        }
    }

    private triggerListeningForCacheClear() {
        this.anotherSubscription = this.codeToLabelService.cache$.pipe(
            switchMap(() => forkJoin([
                this.codeToLabelService.getCodeLabels(ConfigCodesEnum.WARRANTY_REASON_HG),
                this.codeToLabelService.getCodeLabels(ConfigCodesEnum.WARRANTY_REASON_SG)
            ]))
        ).subscribe(([warrantyReasonsHG, warrantyReasonsSG]) => {
            this.warrantyReasons = [...warrantyReasonsHG, ...warrantyReasonsSG];
            this.filteredWarrantyReasons.next(this.warrantyReasons);

        });
    }

    onWarrantyReasonSearch(value) {
        const matchedResults = ConfigurationUtils.findByCodeOrLabel(value, this.warrantyReasons);
        this.filteredWarrantyReasons.next(matchedResults);
    }

    selectAllWarrantyReason(select: MatSelect): void {
        const selected = [];
        select.options.toArray()
            .filter(opt => opt.value != null)
            .forEach(item => selected.push(item.value));
        this.updateSelectedWarrantyReasons(selected);
    }

    deselectAllWarrantyReason(select: MatSelect): void {
        this.updateSelectedWarrantyReasons([]);
        select.value = [];
    }

    labelFromCode = warrantyReason => ConfigurationUtils.labelFromCode(warrantyReason, this.warrantyReasons);

    updateSelectedWarrantyReasons(value) {
        this.selectedWarrantyReasons = value;
        this.selectedWarrantyReasonsChange.emit(this.selectedWarrantyReasons);
    }
}
