import {AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {TaskVariables} from '../../task.variables';
import {DiagExpertDecision, DiagExpertMethod} from '../model/diag-expert.model';
import {MatRadioChange} from '@angular/material/radio';
import {FolderService} from 'app/shared/services/folder.service';
import {Folder} from '../../../../../models/folder.model';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {FolderSubjectService} from '../../../folder-subject.service';
import {Unsubscriber} from '../../../../../unsubscriber';
import {Observable} from 'rxjs';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {ConfigV2Service} from '../../../../../shared/services/config-v2.service';
import {take} from 'rxjs/operators';
import {CodeLabel} from '../../../../../models/element.model';

@Component({
    selector: 'app-diag-expert-decision',
    templateUrl: './diag-expert-decision.component.html',
    styleUrls: ['./diag-expert-decision.component.scss']
})
export class DiagExpertDecisionComponent extends Unsubscriber implements OnInit, AfterContentChecked {
    @Input() variablesTask: any;
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();

    diagExpertFrom: FormGroup;
    isDiagExpertMethodSent = false;
    choices = [
        {
            choice: DiagExpertDecision.USAGE_PROBLEM,
            value: 'DIAG_EXPERT_USAGE_PROBLEM',
        },
        {
            choice: DiagExpertDecision.PANNE_CONFIRMED,
            value: 'DIAG_EXPERT_PANNE_CONFIRMED',
        },
        {
            choice: DiagExpertDecision.WARRANTY_DISCLAIMER,
            value: 'DIAG_EXPERT_WARRANTY_DISCLAIMER',
        }
    ];
    carriers$: Observable<CodeLabel[]>;

    constructor(private folderSubjectService: FolderSubjectService,
                private folderService: FolderService,
                private cdRef: ChangeDetectorRef,
                private configV2Service: ConfigV2Service) {
        super();
    }

    ngOnInit(): void {
        this.initCarrierList();
        this.initForm();
        this.isDiagExpertMethodSent = this.variablesTask[TaskVariables.diagExpertMethod] === DiagExpertMethod.SEND;
    }

    ngAfterContentChecked() {
        this.cdRef.detectChanges();
    }

    onSubmit(): void {
        if (this.diagExpertFrom.value.description) {
            this.folderService.updateFolder(this.folder.id, {
                repairerComment: {
                    content: this.diagExpertFrom.value.description,
                    type: CommentType.COMMENT_DIAG_EXPERT
                }
            }).then((folder) => {
                this.folderSubjectService.folderLoaded(folder);
                this.completeTask();
            });
        } else {
            this.completeTask();
        }
    }

    private completeTask(): void {
        this.inputMap.emit({
            expertDiagnosticDecision: this.diagExpertFrom.value.expertDiagnosticDecision,
            carrierCode: this.diagExpertFrom.value.carrier?.code,
            trackingNumber: this.diagExpertFrom.value.trackingNumber,
        });
    }

    initCarrierList(): void {
        this.carriers$ = this.configV2Service.findAllValues(ConfigCodeMapsEnum.CARRIER).pipe(take(1));
    }

    private initForm(): void {
        this.diagExpertFrom = new FormGroup({
            expertDiagnosticDecision: new FormControl(null, Validators.required),
            carrier: new FormControl(null),
            trackingNumber: new FormControl(null),
            description: new FormControl(''),
        });
    }

    diagnosticDecisionChanged($event: MatRadioChange): void {
        if ($event.value !== DiagExpertDecision.USAGE_PROBLEM) {
            this.diagExpertFrom.get('carrier').setValue(null);
            this.diagExpertFrom.get('trackingNumber').setValue(null);
            this.diagExpertFrom.get('carrier').setValidators(null);
            this.diagExpertFrom.get('trackingNumber').setValidators(null);
            this.diagExpertFrom.get('carrier').reset();
            this.diagExpertFrom.get('trackingNumber').reset();
        } else {
            this.diagExpertFrom.get('carrier').setValidators([Validators.required]);
            this.diagExpertFrom.get('trackingNumber').setValidators([Validators.required]);
        }
    }
}
