import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GrowthbookService} from '../../services/growthbook.service';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../store/user/user.selectors';
import {map, switchMap} from 'rxjs/operators';
import {GrowthbookAttributes} from '../../features/growthbook-attributes';
import {Unsubscriber} from '../../../unsubscriber';
import {AppState} from '../../../store/app.state';
import {Observable} from 'rxjs';
import {AppFeatures} from '../../features/app-features';
import {LoadingScreenService} from '../../services/loading-screen.service';
import {BackOfficeService} from '../../services/back-office.service';
import {Folder} from '../../../models/folder.model';


export const PAYMENT_REQUEST_REFERENCE = 'PAYMENT_REQUEST_REFERENCE';

@Component({
    selector: 'app-request-payment',
    templateUrl: './request-payment.component.html',
    styleUrls: ['./request-payment.component.scss']
})
export class RequestPaymentComponent extends Unsubscriber implements OnInit, OnDestroy {

    @Input() folder: Folder;
    @Input() remainingAmount: number | string;
    @Input() currency: string;

    paymentRequested = false;
    requestPaymentReference: string;

    isFeatureOn: Observable<boolean>;
    folderHasReference = false;

    constructor(private growthbookService: GrowthbookService,
                private loadingScreenService: LoadingScreenService,
                private backOfficeService: BackOfficeService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.loadingScreenService.startLoading();
        const externalReferences = new Map(Object.entries(this.folder.externalReferences));
        this.folderHasReference = externalReferences.has(PAYMENT_REQUEST_REFERENCE);
        if (this.folderHasReference) {
            this.requestPaymentReference = externalReferences.get(PAYMENT_REQUEST_REFERENCE);
        }

        this.anotherSubscription = this.isFeatureOn = this.getFeatureValue();
    }

    private getFeatureValue() {
        return this.store$.pipe(select(currentUser))
            .pipe(
                switchMap((user) => {
                    const userTask = this.folder.currentWorkflowStatus.status;

                    const attributes: GrowthbookAttributes = {
                        organizationCode: user.organizationCode.toUpperCase(),
                        context: user.context.toUpperCase(),
                        userTask: userTask.toUpperCase()
                    };
                    const featureName = `${AppFeatures.USER_TASK_FEATURE_PREFIX}:${userTask.toLowerCase()}`;
                    return this.growthbookService.getFeatureValue(featureName, attributes, {});
                }),
                map(featureValue => {
                    this.loadingScreenService.stopLoading();
                    return featureValue && featureValue.displayRequestPaymentButton as boolean;
                })
            );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    requestPayment() {
        this.paymentRequested = true;
        this.loadingScreenService.startLoading();

        this.backOfficeService.request(this.folder.id)
            .then(response => this.requestPaymentReference = response.reference)
            .catch(() => this.paymentRequested = false)
            .finally(() => this.loadingScreenService.stopLoading());
    }

    isDisabled() {
        return this.paymentRequested || this.folderHasReference;
    }

}
