import {Component, Inject, Input, OnInit} from '@angular/core';
import {FolderPublic} from '../../../../models/folder.public.model';
import {Supplier} from '../../../../models/supplier.model';
import {DetailSupplierDialogComponent} from '../../../folder/folder-detail-tabs-card/folder-details-card/detail-supplier-dialog/detail-supplier-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {Moment} from 'moment';
import {FileInfo} from '../../../../models/file-info.model';
import {ShowImageModalComponent} from '../../../image-modal/show-image-modal.component';
import {FormControl, FormGroup} from '@angular/forms';
import {PublicService} from '../../../../shared/services/public.service';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../../../../Constants';
import {of, Subject} from 'rxjs';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {ConfigurationReferentialDto} from '../../../../models/configurationReferentialDto.model';
import {WARRANTY_CONFIGS} from '../../../../shared/data/static.config';
import {ConfigurationReferential} from '../../../../models/configurationReferential.model';
import {ConfigCodeMapsEnum} from '../../../../shared/services/configuration-item-enum';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {Unsubscriber} from '../../../../unsubscriber';
import {SnackBarService} from '../../../../shared/services/snack-bar.service';
import {CommentType} from '../../../../models/enums/CommentType.enum';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {AttachmentType, AttachmentTypeSharedWithEnum} from '../../../../models/attachment-type.model';

@Component({
    selector: 'app-repairer-product-header',
    templateUrl: './repairer-product-header.component.html',
    styleUrls: ['./repairer-product-header.component.scss']
})
export class RepairerProductHeaderComponent extends Unsubscriber implements OnInit {
    protected _onDestroy = new Subject<void>();

    @Input() folder: FolderPublic;

    warranty;
    currentLanguage: string;
    supplier: Supplier;
    UNKNOWN_SUPPLIER = Constants.UNKNOWN_SUPPLIER;
    repairCommentForm: FormGroup;
    listWarranty: any = WARRANTY_CONFIGS;

    configRefDto: ConfigurationReferentialDto;
    symptom: string;
    condition = '';
    warrantyFolder: string;

    attachments: FileInfo[] = [];
    displayedColumns: string[] = ['file', 'date', 'attachmentType'];
    attachmentsTypes: AttachmentType[] = [];
    constructor(private dialog: MatDialog,
                private publicService: PublicService,
                private store$: Store<AppState>,
                @Inject(LOCAL_STORAGE) private localStorage: StorageService,
                private snackBar: SnackBarService,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.anotherSubscription = this.store$.pipe(select(currentLanguage))
            .subscribe(language => {
                this.currentLanguage = language;
                this.getConfigurationReferentialDto();
                this.getAllowedListAttachmentType(AttachmentTypeSharedWithEnum.REPAIRER, this.folder.externalUid);
            });
    }

    getAllowedListAttachmentType(sharedWith: string, uid: string): void {
        this.publicService.getAllowedListAttachmentTypes(sharedWith, uid, this.currentLanguage).subscribe(data => {
            this.attachmentsTypes = data;
            const attachmentTypeCodes = data.map(value => value.code);
            this.attachments = this.folder.attachments.filter(fileInfo => attachmentTypeCodes.includes(fileInfo.attachmentType));
        });
    }

    private initForm(): void {
        this.repairCommentForm = new FormGroup({
            description: new FormControl(''),
        });
    }

    getDate(purchaseDate: string): Moment {
        return moment(purchaseDate);
    }

    showDetailSupplier(): any {
        if (this.supplier.code !== this.UNKNOWN_SUPPLIER) {
            this.dialog.open(DetailSupplierDialogComponent, {
                hasBackdrop: true, disableClose: false, autoFocus: false, width: '600px', data: {
                    supplier: this.supplier
                }
            });
        }
    }

    showImageModal(srcImage): void {
        this.dialog.open(ShowImageModalComponent, {
            height: 'auto',
            width: '90vh',
            minWidth: '90%',
            data: of([srcImage])
        });
    }

    onSubmit(): void {
        this.anotherSubscription = this.publicService.addRepairerComment(this.folder.externalUid, this.repairCommentForm.value.description, CommentType.COMMENT_GENERAL_REPAIRER)
            .subscribe(folder => {
                Object.keys(this.repairCommentForm.controls).forEach(key => {
                    this.repairCommentForm.controls[key].setValue(null);
                    this.repairCommentForm.controls[key].setErrors(null);
                });
                this.repairCommentForm.setErrors({'invalid': true});
                this.showSnackBar('SUCCESSFULLY_SENT');
            });
    }

    showSnackBar(key: string): void {
        const snackBarConfigs: any[] = [
            {
                key: 'SUCCESSFULLY_SENT',
                panelClass: 'teal-500',
                messageI18nKey: 'MODAL.REPAIRER.TOAST_MESSAGES.SUCCESSFULLY_SENT'
            }];
        const currentConfig = snackBarConfigs.filter(item => item.key === key)[0];
        if (!!currentConfig) {
            this.snackBar.openAtStart('Info', currentConfig.messageI18nKey);
        }
    }

    readyToSent(): boolean {
        return !!this.repairCommentForm.value.description;
    }

    getConfigurationReferentialDto(): void {
        this.store$.dispatch(new StartLoading());
        this.publicService.getConfigurationReferential(this.folder.externalUid, this.currentLanguage)
            .subscribe(value => {
                if (!!value) {
                    this.configRefDto = value;
                    this.initConfig();
                }
                this.localStorage.set('publicConfiguration', value);
                this.store$.dispatch(new StopLoading());
            },
                () => this.store$.dispatch(new StopLoading()));
    }

    private initConfig() {
        this.initFaultCode();
        this.initCondition();
        this.initWarranty();
    }

    public getAllConfiguration(configurationItemCode: string): ConfigurationReferential[] {
        if (!!this.configRefDto) {
            return !!this.configRefDto.configurationMap[configurationItemCode] &&
            this.configRefDto.configurationMap[configurationItemCode].length > 0 ?
                this.configRefDto.configurationMap[configurationItemCode] : [];
        } else {
            return [];
        }
    }

    private initFaultCode() {
        if (!!this.folder.faultCode && this.folder.faultCode !== 'CUSTOMER_NOT_FOUND_PANNE_CODE') {
            const symtoms = this.getAllConfiguration(ConfigCodeMapsEnum.SYMPTOMS)
                .filter(value => value.code === this.folder.faultCode);
            this.symptom = symtoms.length > 0 ? symtoms[0].label : '';
        } else {
            this.getDefaultSymptomFromFolder();
        }
    }

    private getDefaultSymptomFromFolder() {
        this.symptom = this.folder.faultCodeLabel;
    }

    initCondition(): void {
        if (!!this.folder.faultCodeCondition && this.folder.faultCodeCondition !== 'CONSTANT') {
            const conditions = this.getAllConfiguration(ConfigCodeMapsEnum.CONDITIONS)
                .filter(value => value.code === this.folder.faultCodeCondition);
            this.condition = conditions.length > 0 ? conditions[0].label : '';
        } else {
            this.getDefaultCondition();
        }
    }

    private getDefaultCondition() {
        this.condition = this.translateService.instant('COMPONENT.CONSTANT.CONDITION');
    }

    initWarranty(): void {
        this.warranty = this.listWarranty.filter(el => el.key === this.folder.warranty.warranty.toString())[0];
        const configCode = this.folder.warranty.warranty === 'SG' ? ConfigCodeMapsEnum.WARRANTY_REASON_SG : ConfigCodeMapsEnum.WARRANTY_REASON_HG;
        const warranties = this.getAllConfiguration(configCode)
            .filter(value => value.code === this.folder.warranty.warrantyReason);
        this.warrantyFolder = warranties.length > 0 ? warranties[0].label : '';
    }
}
