import {Injectable} from '@angular/core';
import {CodeLabel} from '../../app/models/element.model';
import {ConfigV2Service} from '../../app/shared/services/config-v2.service';
import {map, skipWhile} from 'rxjs/operators';
import {Observable, of, ReplaySubject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../app/store/app.state';
import {currentLanguage} from '../../app/store/organization/organization.selectors';
import {currentUser} from '../../app/store/user/user.selectors';
import {ConfigCodeMapsEnum} from '../../app/shared/services/configuration-item-enum';
import {InstructionUserTask} from '../../app/models/instruction-user-task.model';

@Injectable({
    providedIn: 'root'
})
export class CodeToLabelService {

    private codeLabels: Map<string, CodeLabel[]> = new Map<string, CodeLabel[]>();
    private userTasks: InstructionUserTask[] = [];
    private currentOrganization: string;
    private currentContext: string;
    public cache$ = new ReplaySubject<string>(1);

    constructor(private configV2Service: ConfigV2Service,
                private store$: Store<AppState>) {
        this.currentLanguageChanged();
        this.organizationOrContextChanged();
    }

    public clear() {
        this.codeLabels.clear();
        this.userTasks = [];
    }

    public setCodeLabels(codeConfig: string, codeLabels: CodeLabel[]): void {
        this.codeLabels.set(codeConfig, codeLabels);
    }

    public getLabelOfCode(codeConfig: string, code: string): Observable<string> {
        const codeLabel = this.codeLabels.get(codeConfig)?.find((cl) => cl.code === code);
        if (codeLabel) {
            return of(codeLabel.label);
        }
        return this.configV2Service.findLabelOfCode(codeConfig, code).pipe(
            map((codeLabel: CodeLabel) => {
                const label = codeLabel ? codeLabel.label : code;
                const existingLabels = this.codeLabels.get(codeConfig) || [];
                this.codeLabels.set(codeConfig, [...existingLabels, {code, label}]);
                return label;
            })
        );
    }


    public getCodeLabels(codeConfig: string): Observable<CodeLabel[]> {
        const cachedLabels = this.codeLabels.get(codeConfig);
        if (cachedLabels) {
            return of(cachedLabels);
        }
        return this.configV2Service.findLocalizedValuesOf([codeConfig]).pipe(
            map((response: CodeLabel[]) => {
                if (codeConfig === ConfigCodeMapsEnum.CONDITIONS && !this.currentOrganization.toLowerCase().startsWith('lm_')) {
                    response = [...response,
                        {
                            id: 'CONSTANT',
                            code: 'CONSTANT',
                            label: 'COMPONENT.CONSTANT.CONDITION'
                        }];
                }
                this.setCodeLabels(codeConfig, response);
                return response;
            })
        );
    }

    public setInstructionUserTasks(userTasks: InstructionUserTask[]) {
        this.userTasks = userTasks;
    }

    public getInstructionUserTask(): Observable<InstructionUserTask[]> {
        return of(this.userTasks);
    }

    public getInstructionUserTaskByCode(taskCode: string): Observable<InstructionUserTask> {
        return of(this.userTasks.find(task => task.code.toLowerCase() === taskCode.toLowerCase()) ?? ({'code': taskCode} as InstructionUserTask));
    }

    private currentLanguageChanged() {
        this.store$.pipe(select(currentLanguage), skipWhile(language => !language))
            .subscribe(currentLanguage => {
                this.clear();
                this.cache$.next('clear');
            });
    }

    private organizationOrContextChanged() {
        this.store$.pipe(select(currentUser)).subscribe((currentUser) => {
            if ((!!this.currentOrganization && !!this.currentContext) &&
                (this.currentOrganization !== currentUser.organizationCode || this.currentContext !== currentUser.context)) {
                this.clear();
                this.cache$.next('clear');
            }
            this.currentOrganization = currentUser.organizationCode;
            this.currentContext = currentUser.context;
        });
    }

}
