
<app-user-task-header [instructionUserTask]="instructionUserTask"
                      [claim]="folder"></app-user-task-header>

<mat-card-content>
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
        <app-quotation-content [quotationLines]="quotation.quotationLines"
                               class="mat-elevation-z2 w-100-p mt-16 mb-16" fxFlex="70"
                               fxFlex.lt-md="100"></app-quotation-content>
        <div fxLayout="row" fxFlex="30">
            <mat-card class="mat-elevation-z2 mt-16 mb-16" fxFlex.lt-md="100">
                <div fxLayout="column" class="zone-total">
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong>{{'QUOTATION.CONTENT.TOTAL_HT' | translate}} : </strong>
                        <div>{{ quotation?.totalPriceHTAfterDiscount?.value  |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                    <div fxLayoutAlign="space-between center" fxLayoutGap="8px">
                        <strong> {{'QUOTATION.CONTENT.TOTAL_TTC' | translate}} : </strong>
                        <div>{{ quotation?.totalPrice?.value |currency:currency :'symbol-narrow' | space}}
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>
    </div>
    <app-attachment-uploader class="mb-24"
                             [folder]="folder"
                             [quotation]="quotation"
                             (attachmentsFolder)="getAttachments($event)"></app-attachment-uploader>

    <div fxLayout="row" fxLayoutAlign="start center" class="mt-36">
        <form [formGroup]="quotationChoiceForm" class="w-100-p"
              (ngSubmit)="complete()">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="8px">
                <mat-radio-group formControlName="status" class="ml-20" fxLayoutGap="30px"
                                 labelPosition="after" fxLayout="column" fxLayoutAlign="start stretch">

                    <mat-radio-button class="full-width-radio"
                                      *ngFor="let choice of quotationChoices;let i = index;"
                                      [value]="choice.status">
                        <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ choice.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end">
                <button type="submit" mat-stroked-button
                        [ngClass]="disableForm() ?'aster_btn_disabled':'aster_btn'"
                        [disabled]="disableForm()">
                    {{'BUTTON.VALIDATE' | translate}}
                </button>
            </div>
        </form>
    </div>
</mat-card-content>