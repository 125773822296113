import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Folder, RepairCenter} from '../../../../../models/folder.model';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {FolderService} from '../../../../../shared/services/folder.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {DeliveryModeEnum} from '../../../../../models/enums/deliveryMode.enum';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {SiteType} from '../../../../../models/enums/siteType.enum';
import {Site} from '../../../../../models/site.model';
import {ReceptionModeEnum} from '../../../../../models/enums/receptionMode.enum';
import {LocalizationUtils} from '../../../../../shared/utils/localization-utils';
import {RuleEvaluationContext} from 'app/models/rules/RuleEvaluationContext';
import {currency} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {TaskVariables} from '../../task.variables';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import {GrowthbookService} from '../../../../../shared/services/growthbook.service';
import {currentUser} from '../../../../../store/user/user.selectors';
import {UserState} from '../../../../../store/user/user.state';
import {GrowthbookAttributes} from '../../../../../shared/features/growthbook-attributes';
import {UserTasks} from '../../../../../shared/features/user-tasks';
import {AppFeatures} from '../../../../../shared/features/app-features';
import {FormOption} from '../../swap-process/validate-swap-in-store/validate-swap-in-store.component';
import {MatDialog} from '@angular/material/dialog';
import {DetailAddressRepairerDialogComponent} from '../detail-address-repairer-dialog/detail-address-repairer-dialog.component';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {from, Observable, of} from 'rxjs';

export const EXPEDITION = 'EXPEDITION';
export const PICKUP = 'PICKUP';

const hasSingleItem = (array: any[]): boolean => array.length === 1;

@Component({
    selector: 'app-confirm-carrier-and-repairer',
    templateUrl: './confirm-carrier-and-repairer.component.html',
    styleUrls: ['./confirm-carrier-and-repairer.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ConfirmCarrierAndRepairerComponent extends Unsubscriber implements OnInit {
    @Output() inputMap = new EventEmitter<any>();
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('grid') public grid: GridComponent;

    selectedRepairer: RepairCenter;

    managementSite: Site;
    currency: string;
    listLocalRepairers: RepairCenter[] = [];
    listRepairers: RepairCenter [];
    isListLocalRepairers: boolean;
    FALLBACK_OPTIONS: FormOption[] = [];
    carrierCode: string;
    carrierExtraData: any;
    TransportMode: string;
    isPickupSelected: boolean;

    isLoading = false;
    REPAIR = 'REPAIR';

    constructor(private backOfficeService: BackOfficeService,
                private folderService: FolderService,
                private growthbookService: GrowthbookService,
                private store$: Store<AppState>,
                public dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        this.store$.dispatch(new StartLoading());
        this.computeManagementSite();
        this.initializeRepairers();
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe((currency: string) => {
            this.currency = currency;
        });
    }
    private initializeRepairers() {
        this.store$.dispatch(new StartLoading());
        this.anotherSubscription = this.getCurrentUser().pipe(
            switchMap(user => {
                return from(this.getFeatureValue(user))
                    .pipe(switchMap(formPlaceholders => this.processLocalRepairers(formPlaceholders)));
            })
        ).subscribe((data: any) => {
            this.initRepairers();
        }, () => {
            this.store$.dispatch(new StopLoading());
        });
    }


    private getCurrentUser(): Observable<UserState> {
        return this.anotherSubscription = this.store$.pipe(select(currentUser));
    }

    private getFeatureValue(user: UserState): Promise<any> {
        const attributes: GrowthbookAttributes = {
            organizationCode: user.organizationCode,
            context: user.context,
            userTask: UserTasks.CONFIRM_CARRIER_AND_REPAIRER
        };
        return this.growthbookService.getFeatureValue(
            AppFeatures.USER_TASK_CONFIRM_CARRIER_AND_REPAIRER,
            attributes,
            this.FALLBACK_OPTIONS
        );
    }

    private processLocalRepairers(formPlaceholders: any): Observable<void> {
        if (this.canUseLocalRepairer(formPlaceholders)) {
            return this.getLocalRepairers();
        }
        return of(void 0);
    }

    private updateGridVisibility(): void {
        if (this.grid) {
            this.grid.getColumnByField('localRepaires').visible = this.isListLocalRepairers;
            this.grid.refreshColumns();
        }
    }

    private initRepairers() {
        this.folderService.getRepairCenter(this.folder.id)
            .subscribe((repairers: RepairCenter[]) => {
                this.computeRepairers(repairers);
            }, () => this.store$.dispatch(new StopLoading()));

    }

    private getLocalRepairers(): Observable<void> {
        return this.backOfficeService.getLocalRepairers(RuleEvaluationContext.fromFolder(this.folder)).pipe(
            tap(data => {
                this.listLocalRepairers = data;
                this.isListLocalRepairers = this.listLocalRepairers.length > 0;
                this.updateGridVisibility();
            }),
            catchError(error => {
                return of(void 0);
            })
        );
    }

    private canUseLocalRepairer(formPlaceholders: any): boolean {
        return !formPlaceholders.excludedWarrantyReasonsForLocalRepairer ||
            formPlaceholders.excludedWarrantyReasonsForLocalRepairer.length === 0 ||
            !formPlaceholders.excludedWarrantyReasonsForLocalRepairer.includes(this.folder.newWarranty.warrantyReason);
    }

    private computeRepairers(repairers: RepairCenter[]): void {
        if (this.isListLocalRepairers) {
            this.listRepairers = [...new Map([...this.listLocalRepairers, ...repairers].map(it => [it.code, it])).values()];
        } else {
            this.listRepairers = [...repairers];
        }
        this.setDistances(this.listRepairers);
        this.sortListRepairersByDistance(this.listRepairers);
        if (hasSingleItem(this.listRepairers)) {
            this.selectedRepairer = this.listRepairers[0];
        }
        this.store$.dispatch(new StopLoading());
    }

    private sortListRepairersByDistance(listRepairers: RepairCenter[]): RepairCenter[] {
        return listRepairers.sort((x, y) => {
            const distanceX = Number(x.completeAddress?.location?.distance);
            const distanceY = Number(y.completeAddress?.location?.distance);
            return isNaN(distanceX) ? 1 : isNaN(distanceY) ? -1 : distanceX - distanceY;
        });
    }

    updateAddressRepairer(data: any) {
        const dialogRef = this.dialog.open(DetailAddressRepairerDialogComponent, {
            width: '900px',
            minWidth: '30%',
            disableClose: true,
            data: {
                repairer: data,
                showButton: false,
            },
        });
        dialogRef.afterClosed().subscribe((address: any) => {
            if (address) {
                const repairerToUpdate = this.listRepairers.find(value => value.code === data.code);
                if (repairerToUpdate) {
                    repairerToUpdate.completeAddress = address;
                    this.setDistances(this.listRepairers);
                    this.sortListRepairersByDistance(this.listRepairers);
                    this.grid.refresh();
                }
            }
        });
    }

    isLocalRepairer(code: any): boolean {
        return this.listLocalRepairers.some(item => item.code === code);
    }

    private setDistances(repairers: RepairCenter[]): void {
        const fromLat = Number(this.managementSite.address?.location?.lat);
        const fromLon = Number(this.managementSite.address?.location?.lon);
        repairers.forEach(repairer => {
            const toLat = Number(repairer.completeAddress?.location?.lat);
            const toLon = Number(repairer.completeAddress?.location?.lon);
            if (fromLat && fromLon && toLat && toLon) {
                repairer.completeAddress.location.distance = String(LocalizationUtils.computeDistance(fromLat, fromLon, toLat, toLon));
            }
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        this.store$.dispatch(new StartLoading());
        const senderAndReceiverInformation = {
            firstName: this.managementSite.name,
            lastName: '',
            email: !!this.managementSite.contact ? this.managementSite.contact.email : '',
            phoneNumber: !!this.managementSite.contact ? this.managementSite.contact.phoneNumber : '',
            address: this.managementSite.address
        };
        const completeTaskBody = {
            deliveryMode: DeliveryModeEnum.STORE_TO_REPAIRER,
            carrierCode: this.carrierCode,
            isPickUpCarrier: this.isPickupSelected,
            repairCenterCode: this.selectedRepairer.code,
            senderInformation: JSON.stringify(senderAndReceiverInformation),
            receptionMode: ReceptionModeEnum.REPAIRER_TO_STORE
        };
        if (this.carrierExtraData?.insurance) {
            completeTaskBody[TaskVariables.INSURANCE_PRICE] = String(this.carrierExtraData?.insuranceValue);
        }
        if (this.carrierExtraData?.isProductDanger) {
            completeTaskBody[TaskVariables.IS_PRODUCT_DANGER] = String(this.carrierExtraData?.isProductDanger);
        }
        this.inputMap.emit(completeTaskBody);
    }

    changeCarrierCode($event: any) {
        this.carrierCode = $event;
    }

    changeCarrierExtraEvent($event: any) {
        this.carrierExtraData = $event;
    }

    changeTransportModeEvent($event: any) {
        this.TransportMode = $event;
        this.isPickupSelected = this.TransportMode === PICKUP;
    }

    isFormInvalid(): boolean {
        return !this.isInsurancePriceValid() || !this.TransportMode || ((!this.carrierCode && this.TransportMode === EXPEDITION) || !this.selectedRepairer);
    }

    private computeManagementSite(): void {
        this.managementSite = this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE);
    }

    isInsurancePriceValid(): boolean {
        return (this.carrierExtraData?.insurance && this.carrierExtraData?.insuranceValue > 0) || !this.carrierExtraData?.insurance;
    }
}
