import {Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {FolderService} from '../../../shared/services/folder.service';
import {NgxImageCompressService} from 'ngx-image-compress';
import {FileService} from '../../../shared/services/file.service';

@Component({
    selector: 'app-single-upload-modal',
    templateUrl: './single-upload-modal.component.html',
    styleUrls: ['./single-upload-modal.component.scss']
})
export class SingleUploadModalComponent implements OnInit {
    @Output() fileUploaded = new EventEmitter();

    @ViewChild('dropzone') dropzone: ElementRef;
    attachmentTypes: any[] = [];

    fileUploadForm: any = {
        url: 'assets/images/demo-content/machine_upload.jpg',
        file: null,
        hasFile: false,
        isUploading: false,
        hasSuccessfullyUploaded: false,
    };
    elementForm = {
        attachmentForm: null
    };

    files: any;
    sizeFile: number;
    warningSizeFile = false;
    hideFileType = false;
    sharedWithList: string[] = [];
    folderId: string;
    organizationCode: string;
    uploadAttachment: string;

    constructor(
        private dialogRef: MatDialogRef<SingleUploadModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any,
        private translateService: TranslateService,
        private folderService: FolderService,
        private fileService: FileService,
        private imageCompress: NgxImageCompressService
    ) {
    }

    ngOnInit(): void {
        this.getAttachmentTypes();
        this.initAttachmentForm();
        this.sharedWithAttachementTypes();
        this.hideFileType = this.modalData?.hideFileType;
        this.folderId = this.modalData?.folderId;
        this.organizationCode = this.modalData?.organizationCode;
        this.uploadAttachment = this.modalData?.uploadAttachment;
    }
    getAttachmentTypes(){
        if (this.modalData?.attachmentTypes){
            this.attachmentTypes = this.modalData?.attachmentTypes;
        }
    }

    sharedWithAttachementTypes(): void {
        this.elementForm.attachmentForm.get('attachmentType').valueChanges.subscribe((selectedCode) => {
            const selectedAttachment = this.attachmentTypes.find(attachment => attachment.code === selectedCode);
            if (selectedAttachment && selectedAttachment.extra?.sharedWith) {
                this.sharedWithList = selectedAttachment.extra.sharedWith
                    .map(sharedWithItem => this.translateService.instant('SHARED_WITH_' + sharedWithItem));
            } else {
                this.sharedWithList = [];
            }
        });
    }



    initAttachmentForm(): void {
        this.elementForm.attachmentForm = new FormGroup({
            attachmentType: new FormControl(Validators.required),
        });
        this.elementForm.attachmentForm.get(['attachmentType']).setValue();
    }

    onClose(): void {
        this.dialogRef.close();
    }

    // cette methode pour Fermer la modal avec un echap
    @HostListener('document:keydown', ['$event'])
    onKeyDownHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.dialogRef.close();
        }
    }

    onCloseWithURL(): void {
        this.files[0].attachmentType = this.elementForm.attachmentForm?.get('attachmentType')?.value || this.modalData.attachmentType;
        if (this.uploadAttachment){
            this.onFileChange(this.files);
        }else {
            this.dialogRef.close(this.files);
        }
    }

    onFilesAdded($event: File[]): void {
        this.sizeFile = $event[0].size / 1024 / 1024;
        if ($event[0].type === '') {
            this.onClearDropzone();
        }
        else if (this.sizeFile < 10) {
            this.fileUploadForm.hasFile = true;
            this.warningSizeFile = false;
            this.files = $event;
        } else {
            this.onClearDropzone();
            this.warningSizeFile = true;
        }
    }


    onFilesRejected($event: File[]): void {
        console.log('File sRejected:', $event);
    }

    onClearDropzone(): void {
        // @ts-ignore
        this.dropzone.reset();
        this.fileUploadForm.hasFile = false;
    }

    onUpload(): void {
        this.fileUploadForm.isUploading = true;

        setTimeout(() => this.onSuccessfullyUploaded(), 200);

    }

    onSuccessfullyUploaded(): void {
        // TEMPORARY from here
        this.onUploadTerminated();


        this.fileUploadForm.hasSuccessfullyUploaded = true;

    }

    onUploadTerminated(): void {
        this.fileUploadForm.isUploading = false;
    }

    acceptType(): string {
        if (!!this.modalData) {
            if (this.modalData.type === 'PRODUCT') {
                return 'application/pdf,image/png';
            } else if (this.modalData.type === 'IMAGE') {
                return 'image/png,image/jpeg';
            } else {
                return 'application/pdf,image/png,image/jpeg';
            }
        } else {
            return 'application/pdf,image/png,image/jpeg';
        }
    }

    isEnabled(): boolean {
        return ('PRODUCT' === this.modalData?.type || this.hideFileType) ? this.fileUploadForm.hasFile :
            (this.fileUploadForm.hasFile && !!this.elementForm.attachmentForm.get(['attachmentType']).value);
    }

    onFileChange(event): void {
        const reader = new FileReader();
        const fileEvent = event[0];
        if (event && event.length) {
            const [file] = event;
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (file.type.toLowerCase().split('/')[0] === 'image') {
                    this.compressFile(reader.result, fileEvent);
                } else {
                    this.uploadFile(reader.result.toString().split(',')[1], fileEvent);
                }
            };
        }
    }

    compressFile(image, event): void {
        this.imageCompress.compressFile(image, 1, 100, 50).then(result => {
            this.uploadFile(result.toString().split(',')[1], event);
        });
    }

    uploadFile(file, event): void {
        const imageBlob = this.dataURItoBlob(file);
        const uploadCallback = (fileInfo) => {
            if (fileInfo) {
                this.fileUploaded.emit(fileInfo);
                this.dialogRef.close(fileInfo);
            }
        };
        if (!this.folderId) {
            this.fileService.uploadAttachment(imageBlob, event, this.organizationCode, null, event.attachmentType)
                .subscribe(uploadCallback);
        } else {
            this.folderService.uploadAttachment(imageBlob, event, this.folderId, this.organizationCode, event.attachmentType)
                .subscribe(uploadCallback);
        }
    }

    private dataURItoBlob(dataURI): any {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([arrayBuffer], {type: ''});
    }

}
