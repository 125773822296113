
<ejs-grid #grid [dataSource]="spareParts"
          [allowPaging]="true"
          [allowSorting]="true"
          [allowFiltering]="true"
          [showColumnMenu]="true"
          [allowTextWrap]="true"
          [pageSettings]="{pageSize: 5}"
          [filterSettings]="{type: 'CheckBox'}"
          (actionComplete)="onChangeDataByPage($event)"
>
    <e-columns>
        <e-column field="code"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.REFERENCE' | translate" width="15%"
        >
            <ng-template #template let-data>
                <span class="translate">{{data.code}}</span>

                <ng-container *ngIf="data.information && data.information.trim() !== ''">
                    <span class="translate">
                        <mat-icon color="warn"
                                  [matTooltip]="data.information"
                                  matTooltipPosition="right">info
                        </mat-icon>
                    </span>
                </ng-container>
            </ng-template>
        </e-column>
        <e-column field="label"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.LABEL' | translate"  width="25%"
        >
            <ng-template #template let-data>
                <span class="translate">{{data.label}}</span>
            </ng-template>
        </e-column>
        <e-column field="suppliers"  width="15%"
                  [headerText]="'ASSET_CATALOG.FORM.FIELDS.SUPPLIER' | translate"
                  [valueAccessor]="getSupplierName"
        >
        </e-column>
        <e-column field="stock.status " width="15%">
            <ng-template #headerTemplate class="notranslate">
                <div >{{'ASSET_CATALOG.FORM.FORM.HEADERS.STOCK' | translate}}</div>
            </ng-template>
            <ng-template  #template let-data>
                <div *ngIf="isStockLoading" class="spinner-wrapper">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <span *ngIf="!isStockLoading"

                      [ngClass]="getStockIndicatorClass(data.stock?.status) | async"
                      matTooltipClass="multi-line-tooltip"
                      [matTooltip]="data.stock?.status === 'AVAILABLE' ? stockDetailsTooltip(data.stock?.stockDetails, data.stock?.totalStock) : ''">
                            {{displayStockStatus(data.stock?.status) | translate}}
                </span>
            </ng-template>
        </e-column>
        <e-column field="stock.images"  width="15%"
                  [headerText]="'ASSET_CATALOG.FORM.FORM.HEADERS.IMAGE' | translate"
        >
            <ng-template #template let-data>
                <ng-container *ngIf="!!data.images && data.images.length > 0 else no_image">
                    <div *ngIf="data.images[0].isLoading" class="spinner-wrapper">
                        <mat-spinner diameter="20"></mat-spinner>
                    </div>
                    <img [hidden]="data.images[0].isLoading" height="30" width="30"
                         (click)="openMultiImageModal($event, data.images)"
                         class="cursor-pointer zone-file_img img-product_folder"
                         (load)="onLoad(data.images[0])"
                         (error)="onImageError($event, data.images[0])"
                         [src]="data.images[0].src">
                </ng-container>
                <ng-template #no_image>
                    <img height="30" width="30"
                         class="zone-file_img img-product_folder"
                         src="assets/icons/spartpart.png">
                </ng-template>
            </ng-template>
        </e-column>
        <e-column field="price.sale.ttc" [headerText]="'ASSET_CATALOG.FORM.FIELDS.SALE.PRICE.TTC' | translate"  width="15%">
            <ng-template #template let-data>
                <div *ngIf="!data.sellingPrice" class="spinner-wrapper">
                    <mat-spinner diameter="20"></mat-spinner>
                </div>
                <span
                    *ngIf="!!data.sellingPrice">{{convertHTtoTTC(data.sellingPrice?.value) | currency: data.sellingPrice?.currency :'symbol-narrow'}}
                </span>
            </ng-template>
        </e-column>
        <e-column field="price.purchase.ht" [headerText]="'ASSET_CATALOG.FORM.FIELDS.PURCHASE.PRICE' | translate" [visible]="false">
            <ng-template #template let-data>
               <span *ngIf="getPurchasePrice(data) else ndValue">
                    {{ getPurchasePrice(data) | currency: getPurchaseCurrency(data) :'symbol-narrow'}}
               </span>
                <ng-template #ndValue>ND</ng-template>
            </ng-template>
        </e-column>

    </e-columns>
</ejs-grid>

<ejs-spinner width="20"
             [isLoading]="isSparePartLoading"
></ejs-spinner>
