<div>
    <form *ngIf="!!form" [formGroup]="form">
        <ng-container>
            <ng-container *ngIf="showSerialNumberActionEdit;else UPDATE_SerialNumber">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px"
                         class="mt-12 mb-12">
                        <mat-icon>
                            <svg viewBox="0 0 24 24">
                                <path
                                    d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"/>
                            </svg>

                        </mat-icon>
                        <span class="font-weight-600" > {{'FOLDER.SERIAL_NUMBER_TITLE' |translate}} : {{form.get('serialNumber').value}} </span>
                    </div>
                    <div (click)="openEditSerialNumberMode()" class="cursor-pointer">
                        <mat-icon>edit</mat-icon>
                    </div>

                </div>
            </ng-container>
        </ng-container>
        <ng-template #UPDATE_SerialNumber>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-form-field appearance="outline" fxFlex="80" class="mt-12">
                    <mat-label>{{'FOLDER.SERIAL_NUMBER_TITLE' |translate}}</mat-label>
                    <input matInput placeholder="{{'FOLDER.SERIAL_NUMBER_VALIDATION' |translate}}"
                           type="text"
                           [pattern]="serialNumberPattern"
                           [required]="isRequired"
                           autocomplete="off" formControlName="serialNumber">
                </mat-form-field>
                <div fxFlex="20">
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <ng-container>
                            <div (click)="updateSerialNumberFolder()" [ngClass]="form.valid ? 'cursor-pointer' : ''"
                                 disabled="{{form.invalid}}">
                                <mat-icon [ngClass]="form.invalid ? 'disabled-icon' : ''">check</mat-icon>
                            </div>
                        </ng-container>

                        <div (click)="closeEditSerialNumberMode()" class="cursor-pointer">
                            <mat-icon>clear</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </form>
</div>
