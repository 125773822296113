import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {MatRadioChange} from '@angular/material/radio';
import {AppFeatures} from '../../../../../shared/features/app-features';
import {GrowthbookService} from '../../../../../shared/services/growthbook.service';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../../store/user/user.selectors';
import {switchMap, take} from 'rxjs/operators';
import {UserState} from '../../../../../store/user/user.state';
import {GrowthbookAttributes} from '../../../../../shared/features/growthbook-attributes';
import {UserTasks} from '../../../../../shared/features/user-tasks';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';


export interface Choice {
    status: string;
    value: string;
}

export interface FeatureValue {
    choices: Choice[];
}

@Component({
    selector: 'app-waiting-credit-supplier',
    templateUrl: './waiting-credit-supplier.component.html',
    styleUrls: ['./waiting-credit-supplier.component.css']
})
export class WaitingCreditSupplierComponent extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    commentTitle: string;
    choices: Choice[] = [];

    creditSupplierStatusForm: any = {
        form: null
    };

    constructor(private store$: Store<AppState>,
                private growthbookService: GrowthbookService) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm(): void {
        this.store$.dispatch(new StartLoading());

        this.creditSupplierStatusForm.form = new FormGroup({
            status: new FormControl(null, Validators.required),
            comment: new FormControl(null),
        });

        this.anotherSubscription = this.store$.pipe(
            select(currentUser), take(1))
            .pipe(
                switchMap((user: UserState) => {
                    return this.getUserTaskAttributes(user);
                }),
            ).subscribe((featureValue: FeatureValue) => {
                    this.choices = featureValue.choices;
                    this.store$.dispatch(new StopLoading());
                },
                () => {
                    this.store$.dispatch(new StopLoading());
                });


        this.creditSupplierStatusForm.form.get('status').valueChanges.subscribe(requestCreditSupplier => {
            if (requestCreditSupplier == null) {
                return;
            }
            if (requestCreditSupplier === 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED') {

                this.commentTitle = 'FOLDER.CANCEL_CREDIT_SUPPLIER.REASON';
                this.creditSupplierStatusForm.form.get('comment').setValidators(Validators.required);
            } else if (requestCreditSupplier === 'FOLDER_CLOSED_CREDIT_SUPPLIER_REFUSED') {

                this.commentTitle = 'FOLDER.REFUSED_CREDIT_SUPPLIER.REASON';
                this.creditSupplierStatusForm.form.get('comment').setValidators(Validators.required);
            } else {
                this.resetValidator(this.creditSupplierStatusForm.form.get('comment'));
            }
            this.creditSupplierStatusForm.form.get('comment').updateValueAndValidity();

        });
    }

    private getUserTaskAttributes(user: UserState) {
        const attributes: GrowthbookAttributes = {
            organizationCode: user.organizationCode,
            context: user.context,
            userTask: UserTasks.WAITING_CREDIT_SUPPLIER
        };
        return this.growthbookService.getFeatureValue(AppFeatures.USER_TASK_WAITING_CREDIT_SUPPLIER, attributes, []);
    }

    creditSupplierDecisionChanged($event: MatRadioChange) {
        this.resetValidator(this.creditSupplierStatusForm.form.get('comment'));
    }

    private resetValidator(ValidatorValue: AbstractControl) {
        ValidatorValue.setValue(null);
        ValidatorValue.clearValidators();
    }

    onSubmit(): void {
        if (!!this.creditSupplierStatusForm.form.value.comment && this.creditSupplierStatusForm.form.value.comment.length > 0) {
            this.commentGiven.emit({
                value: this.creditSupplierStatusForm.form.value.comment,
                type: this.toCommentType(),
            });
        }
        this.inputMap.emit({
            closingFolderReason: this.creditSupplierStatusForm.form.value.status
        });
    }

    toCommentType(): string {
        if (this.creditSupplierStatusForm.form.value.status === 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED') {
            return CommentType.REASON_FOR_CANCEL_CREDIT_SUPPLIER;
        }
        if (this.creditSupplierStatusForm.form.value.status === 'FOLDER_CLOSED_CREDIT_SUPPLIER_REFUSED') {
            return CommentType.REASON_FOR_REFUSE_CREDIT_SUPPLIER;
        }
    }
}
