<div class="page-layout simple fullwidth mat-elevation-z4">
    <table class="mat-table">
        <tr>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.TYPE' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.CONTENT' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.DATE' | translate}}</th>
            <th class="mat-header-cell table-header pt-12 pb-12 w-20-p">{{'FOLDER.COMMENT.USER'|translate}}</th>
        </tr>
        <ng-container *ngIf="!!commentsList">

                <tr *ngFor="let comment of commentsList" class="text-left">
                    <td
                        class="mat-cell pt-12 pb-12 w-20-p">{{'COMPONENT.' + comment.type | translate}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{comment.content}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{comment.createdBy.actionDate | moment: ('GENERAL.DATE_MOMENT_FORMA_SCHEDULE' | translate) : (currentLanguage$ | async)}}</td>
                    <td class="mat-cell pt-12 pb-12 w-20-p">{{ comment.createdBy.user.fullName | translate }}
                    </td>
                </tr>
        </ng-container>
    </table>

</div>

