import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../models/folder.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../store/app.state';
import {currency} from '../../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../../unsubscriber';
import {FolderSubjectService} from '../../../folder-subject.service';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import {Quotation} from '../../../../../models/quotation.model';
import {SparePartService} from '../../../../../shared/services/spare-part.service';
import {Payer} from '../../../../../models/enums/payer.enum';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';

@Component({
    selector: 'app-waiting-product-retrieval',
    templateUrl: './waiting-product-retrieval.component.html',
    styleUrls: ['./waiting-product-retrieval.component.scss']
})
export class WaitingProductRetrievalComponent extends Unsubscriber implements OnInit, OnDestroy {
    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    retrievalDone: boolean;

    accessoriesLabel: string[] = [];
    remainingAmount: String;
    currency: string;
    quotationId: string;
    quotation: Quotation;
    isOutOfWarranty: boolean;

    constructor(private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private sparePartService: SparePartService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe((folder) => {
            this.isOutOfWarranty = this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG;
            if (folder.productAccessories) {
                this.accessoriesLabel = folder.productAccessories.map(productAccessory => productAccessory.label);
            }
            if (this.folder.newWarranty.warranty === RegimeWarrantyEnum.HG) {
                this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
                this.quotation = this.getLastQuotation();
                if (this.QuotationAcceptedAndProductRepairable()) {
                    this.getRemainingAmount();
                }
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private QuotationAcceptedAndProductRepairable() {
        return !!this.variablesTask['quotationAccepted'] &&
            (this.variablesTask['quotationAccepted'] === 'true' || this.variablesTask['quotationAccepted'] === 'ACCEPTED') &&
            !!this.variablesTask['reparationStatus'] && this.variablesTask['reparationStatus'] !== 'NOT_REPAIRABLE';
    }

    onSubmit(): void {
        this.inputMap.emit({});
    }

    private getRemainingAmount() {
        if (!!this.quotation?.totalPrice?.value ) {
            const totalPrice = this.getTotalPriceCustomerQuotation();
            if (totalPrice > 0) {
                this.remainingAmount = this.sparePartService.formatPriceAccordingToCurrency(this.sparePartService.normalizeFloat(totalPrice), this.currency);
            }
        }
    }

    private getTotalPriceCustomerQuotation(): number {
        return this.sparePartService.computeTotalPrice(this.quotation.quotationLines
            .filter(value => value.payer === Payer.CLIENT)
            .map(value => value.totalLinePrice.value));
    }

    private getLastQuotation(): Quotation {
        return this.folder.quotations.length > 0 ? this.folder.quotations.reduce((quotation1, quotation2) => {
            return quotation1.userAction.actionDate > quotation2.userAction.actionDate ? quotation1 : quotation2;
        }) : null;
    }
}
