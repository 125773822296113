<mat-card-content>
    <form [formGroup]="creditSupplierStatusForm.form" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
        <app-user-task-header [instructionUserTask]="instructionUserTask"
                              [claim]="folder"></app-user-task-header>
        <mat-radio-group formControlName="status" class="ml-20"
                         fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
            <ng-container *ngFor="let choice of choices">
                <mat-radio-button [id]="choice.status"
                                  class="full-width-radio"
                                  [value]="choice.status"
                                  (change)="creditSupplierDecisionChanged($event)">
                    <div class="my-8" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex>
                            <div>{{ choice.value | translate }}</div>
                        </div>
                    </div>
                </mat-radio-button>
                <ng-container *ngIf="creditSupplierStatusForm.form.value.status === choice.status &&
                                    ['FOLDER_CLOSED_CREDIT_SUPPLIER_REFUSED', 'FOLDER_CLOSED_CREDIT_SUPPLIER_CANCELED'].includes(choice.status)">
                    <div class="ml-44">
                        <app-comment-area [commentForm]="creditSupplierStatusForm.form.get('comment')"
                                          [isRequired]="true"
                                          [title]="commentTitle"></app-comment-area>
                    </div>
                </ng-container>
            </ng-container>
        </mat-radio-group>

        <div fxLayoutAlign="end center">
            <button type="submit" mat-stroked-button
                    class="py-8 px-24 font-weight-900 font-size-30"
                    [ngClass]="creditSupplierStatusForm.form.invalid ? 'aster_btn_disabled' : 'aster_btn'"
                    [disabled]="creditSupplierStatusForm.form.invalid">
                {{'BUTTON.VALIDATE' | translate}}
            </button>
        </div>
    </form>
</mat-card-content>