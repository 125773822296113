import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Folder} from '../../../../../../models/folder.model';
import {UserState} from '../../../../../../store/user/user.state';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {currentUser} from '../../../../../../store/user/user.selectors';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatRadioChange} from '@angular/material/radio';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Order} from '../../../../../../models/order.model';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {QuotationLine} from '../../../../../../models/quotation.model';

@Component({
    selector: 'app-waiting-for-reparation-permission2',
    templateUrl: './waiting-for-reparation-permission2.component.html',
    styleUrls: ['./waiting-for-reparation-permission2.component.scss']
})
export class WaitingForReparationPermission2Component extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;
    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();
    order = new Order();
    orderedSpareParts: QuotationLine[];
    choiceModel: any = {
        form: null,
        placeholders: []
    };

    currentUser: UserState;
    reparationNeedPermission = false;
    reparationRefused = false;
    warrantyRejected = false;
    private quotationId: any;
    isSG: boolean;
    constructor(private orderSubjectService: OrderSubjectService,
                private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.isSG = this.folder.newWarranty.warranty.valueOf() === 'SG';
        this.initForm();
        if (!!this.folder.orderIds && this.folder.orderIds?.length > 0) {
            this.getOrder();
        } else {
            // TODO this code will be deprecated
            this.quotationId = this.variablesTask['quotationId'];
            this.orderedSpareParts = this.folder.quotations
                .filter(quotation => quotation.id === this.quotationId)[0]
                .quotationLines;

        }
        this.anotherSubscription = this.store$.pipe(select(currentUser)).subscribe(userDetails => {
            this.currentUser = userDetails;
        });
    }

    private initForm(): void {
        this.choiceModel.form = new FormGroup({
            choice: new FormControl(null, Validators.required),
            comment: new FormControl('')
        });
        this.choiceModel.placeholders = [
            {
                choice: 'VALIDATE_REPARATION_REQUIREMENT',
                value: 'BUTTON.VALIDATE_REPARATION_REQUIREMENT',
            },
            {
                choice: 'REFUSE_REPARATION_REQUIREMENT',
                value: 'BUTTON.REFUSE_REPARATION_REQUIREMENT',
            }
        ];
    }

    onSubmit(): void {
        if (this.choiceModel.form.value.choice === 'REFUSE_REPARATION_REQUIREMENT') {
            // todo this variable "reparationNeedPermission" to be deleted after 60Days of MEP
            this.reparationNeedPermission = true;
            this.reparationRefused = true;
        }
        this.fireWorkflow();
    }

    private setComment() {
        if (!!this.choiceModel.form.value.comment) {
            this.commentGiven.emit({
                    value: this.choiceModel.form.value.comment,
                }
            );
        }
    }

    onChoice($event: MatRadioChange) {
        const reparationPermissionChoice = $event.value;
        if (reparationPermissionChoice === 'REFUSE_REPARATION_REQUIREMENT') {
            this.choiceModel.form.get('comment').setValidators(Validators.required);
        } else {
            this.choiceModel.form.get('comment').clearValidators();
            this.choiceModel.form.get('comment').setValue(null);
        }
        this.choiceModel.form.get('comment').updateValueAndValidity();
    }

    fireWorkflow() {
        this.setComment();
        this.inputMap.emit({
            warrantyRejected: this.warrantyRejected,
            reparationNeedPermission: this.reparationNeedPermission,
            reparationRefused: this.reparationRefused,
        });
    }

    isCommentRequired(): boolean {
        return this.choiceModel.form.get('choice').value === 'REFUSE_REPARATION_REQUIREMENT';
    }

    getOrder() {
        this.anotherSubscription = this.orderSubjectService.order$.subscribe(order => {
            this.order = order;
            this.buildDataLines();
        });

    }

    buildDataLines() {
        const lines1: QuotationLine[] = [];
        const lines2: QuotationLine[] = [];
        this.order.orderItems?.forEach(orderItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.code = orderItem.code;
            dataSourceLine.label = orderItem.label;
            dataSourceLine.quantity = orderItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: orderItem.price,
                currency: this.order.currency
            };
            dataSourceLine.stock = orderItem.stock;
            dataSourceLine.type = QuotationType.SPARE_PART;
            dataSourceLine.status = orderItem.itemStatus;
            dataSourceLine.totalLinePrice = {
                value: orderItem.rowTotal,
                currency: this.order.currency
            };
            dataSourceLine.vat = {
                rate: orderItem.taxPercent.toString()
            };
            dataSourceLine.discount = orderItem.discountPercent;
            dataSourceLine['supplierName'] = orderItem.supplier?.name;
            lines1.push(dataSourceLine);
        });
        this.order.orderExtraItems?.forEach(extraItem => {
            const dataSourceLine = new QuotationLine();
            dataSourceLine.code = extraItem.code;
            dataSourceLine.label = extraItem.label;
            dataSourceLine.quantity = extraItem.quantityOrdered;
            dataSourceLine.priceAmount = {
                value: extraItem.price,
                currency: this.order.currency
            };
            dataSourceLine.type = extraItem.type;
            dataSourceLine.status = extraItem.itemStatus;
            dataSourceLine.totalLinePrice = {
                value: extraItem.rowTotal,
                currency: this.order.currency
            };

            dataSourceLine.vat = {
                rate: extraItem.taxPercent.toString()
            };
            dataSourceLine.discount = extraItem.discountPercent;
            lines1.push(dataSourceLine);
        });
        this.orderedSpareParts = [...lines1, ...lines2];
    }

}
