import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {Folder} from '../../../../../../models/folder.model';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../../../store/app.state';
import {StartLoading, StopLoading} from '../../../../../../store/loader/loader.actions';
import {Unsubscriber} from '../../../../../../unsubscriber';
import {SiteType} from '../../../../../../models/enums/siteType.enum';
import {TaskVariables} from '../../../task.variables';
import {PaymentReason} from '../../../../../../models/enums/paymentReason.enum';
import {QuotationType} from '../../../../../../models/enums/quotationType.enum';
import {Payer} from '../../../../../../models/enums/payer.enum';
import {TranslateService} from '@ngx-translate/core';
import {Vat} from '../../../../../../models/vat.model';
import {currency} from 'app/store/organization/organization.selectors';
import {AmountUtils} from '../../../../../../shared/utils/amount-utils';
import {RuleEvaluationContext} from '../../../../../../models/rules/RuleEvaluationContext';
import {BackOfficeService} from '../../../../../../shared/services/back-office.service';
import {Workforce} from '../../../../../../models/Workforce.model';
import {SparePartService} from '../../../../../../shared/services/spare-part.service';
import {SnackBarService} from '../../../../../../shared/services/snack-bar.service';
import {StocksAvailabilityResponse} from '../../../../../../models/spare-parts/stocksAvailabilityResponse.model';
import {Order, OrderItem} from '../../../../../../models/order.model';
import {OmsService} from '../../../../../../shared/services/oms.service';
import {OrderSubjectService} from '../../../../../../shared/services/order-subject.service';
import {AddRequirementModal} from '../../../../../../models/add-requirement.modal';
import {AddRequirementDialogComponent} from '../add-requirement-dialog/add-requirement-dialog.component';
import {InstructionUserTask} from '../../../../../../models/instruction-user-task.model';
import {TypeOfServiceEnum} from '../../../../../../models/typeOfService.model';
import {Quotation} from '../../../../../../models/quotation.model';

@Component({
    selector: 'app-input-requirement2',
    templateUrl: './input-requirement2.component.html',
    styleUrls: ['./input-requirement2.component.scss']
})
export class InputRequirement2Component extends Unsubscriber implements OnInit {

    @Input() folder: Folder;
    @Input() instructionUserTask: InstructionUserTask;
    @Input() variablesTask: any;

    @Output() inputMap = new EventEmitter<any>();
    @Output() commentGiven = new EventEmitter<any>();


    reportLineColumns: string[] = ['code', 'label', 'type', 'payer'];
    dataSource = new MatTableDataSource<OrderItem>();
    dataSourceLines: OrderItem[] = [];

    comment: string;

    order = new Order();
    feesQuotationLine: OrderItem;
    quotationId: string;
    quotation: Quotation;
    workforces: Workforce[] = [];

    warrantyCode: string;
    currency: string;
    vat: Vat;
    totalPrice = 0;


    constructor(private store$: Store<AppState>,
                private matDialog: MatDialog,
                private snackBar: SnackBarService,
                private translateService: TranslateService,
                private backOfficeService: BackOfficeService,
                private sparePartService: SparePartService,
                private orderSubjectService: OrderSubjectService,
                private omsService: OmsService) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(currency)).subscribe(currency => this.currency = currency);
        this.warrantyCode = this.folder.newWarranty.warranty.valueOf();
        this.vat = JSON.parse(this.variablesTask[TaskVariables.vat]);
        this.quotation = this.getLastDraftQuotation() || this.getEmptyQuotation();
        this.quotationId = this.quotation?.id;
        this.initOrder();
        this.updateFeesQuotation();

    }


    private initOrder() {
        if (!this.folder.orderIds || this.folder.orderIds.length === 0) {
            return;
        }
            this.anotherSubscription = this.orderSubjectService.order$.subscribe(data => {
                this.order = data;
                this.dataSourceLines = [...this.order.orderItems, ...this.order.orderExtraItems];
                this.refreshDataSource();
                if (!!this.order.grandTotal) {
                    this.totalPrice += this.order.grandTotal;
                }

            });

    }

    private getLastDraftQuotation(): Quotation {
        return this.folder.quotations?.filter(quotation => quotation?.status === 'DRAFT')?.reduce((quotation1, quotation2) => {
            return quotation1?.userAction?.actionDate > quotation2?.userAction?.actionDate ? quotation1 : quotation2;
        }, null);
    }
    private refreshDataSource() {
        this.dataSource.data = [];
        this.dataSource = new MatTableDataSource<OrderItem>(this.dataSourceLines);
    }

    private updateFeesQuotation(): void {
        if (this.warrantyCode === 'SG') {
            return;
        }
        const feesPaymentQuotation = this.folder.payments.find(value => value.paymentReason.startsWith(PaymentReason.QUOTATION_FEES));
        const feesPayment =   this.order.orderExtraItems?.find(value => value.type.startsWith(PaymentReason.QUOTATION_FEES));
        if (!!feesPaymentQuotation && !feesPayment) {
            this.feesQuotationLine = this.toDataSourceLine(feesPaymentQuotation);
            this.dataSource.data.push(this.feesQuotationLine);
        }
        this.totalPrice += !!feesPayment ? 0 : feesPaymentQuotation?.amount * -1;
    }

    private toDataSourceLine(feesPaymentQuotation): OrderItem {
        return {
            code: '-',
            label: this.translateService.instant('MODAL.QUOTATION.FEES_QUOTATION'),
            payer: Payer.CLIENT,
            quantityOrdered: 1,
            quantityInvoiced: 1,
            price: AmountUtils.convertTTCtoHT(feesPaymentQuotation.amount, Number(this.vat.rate)) * -1,
            currency: this.currency,
            taxPercent: Number(this.vat.rate),
            type: QuotationType.QUOTATION_FEES,
            discountPercent: 0,
            rowTotal: feesPaymentQuotation.amount * -1
        };
    }

    onSubmit(): void {
        this.store$.dispatch(new StopLoading());
        if (this.hasSparePart()) {
            this.sparePartService.getAvailabilityOfStocks(this.getManagementSiteCode(), this.prepareStockRequest())
                .subscribe(stockResponse => {
                    if (!!stockResponse) {
                        if (stockResponse.availabilityStatus !== 'AVAILABLE' && stockResponse.availabilityStatus !== 'UNKNOWN') {
                            this.showErrorMessage();
                            this.updateOrderWithStockAvailability(stockResponse);
                            this.openQuotationModal();
                        } else {
                            this.validateTask();
                        }
                    } else {
                        this.validateTask();
                    }

                });
        } else {
            this.validateTask();
        }

    }

    private validateTask() {
        this.emitVariables();
        if (!!this.comment && this.comment.length > 0) {
            this.updateComment();
        }
    }


    private emitVariables() {
        const map: any = {
            orderId: this.order.id
        };

        if (!! this.quotationId) {
            map.quotationId = this.quotationId;
        }

        this.inputMap.emit(map);
    }

    private updateComment() {
        this.updateOrderWithComment();
        this.commentGiven.emit({
                value: this.comment
            }
        );
    }

    private updateOrderWithComment() {
        this.order.remark = this.comment;
        this.store$.dispatch(new StartLoading());
        this.omsService.updateOrder(this.order.id, this.order).subscribe((data) => {
            if (!!data) {
                this.order = data;
                this.orderSubjectService.orderLoaded(this.order);
            }
            this.store$.dispatch(new StopLoading());
        }, () => this.store$.dispatch(new StopLoading()));
    }

    private showErrorMessage() {
        this.snackBar.openAtEnd('Error', 'STOCKS.SPARE_PARTS.NOT_AVAILABLE');
    }

    private prepareStockRequest() {
        return this.dataSource.data
            .filter(value => value.type === QuotationType.SPARE_PART)
            .map(line => ({
                sparePartCode: line.code.trim(),
                quantity: line.quantityOrdered
            }));
    }

    private hasSparePart() {
        return this.dataSource.data
            .some(value => value.type === QuotationType.SPARE_PART);
    }

    private updateOrderWithStockAvailability(stockResponse: StocksAvailabilityResponse) {
        this.order.orderItems?.map(item => stockResponse.stocks
            .filter(value => value.sparePartCode === item.code)
            .map(value => item.stock = value));
    }


    openQuotationModal(): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);

        this.backOfficeService.getWorkforces(ruleEvaluationContext)
            .subscribe(value => {
                    if (!!value) {
                        this.workforces = value;
                        const requirementModalConfig: AddRequirementModal = {
                            titleModal: 'MODAL.QUOTATION.ADD_REQUIREMENT',
                            organizationCode: this.folder.organization.code,
                            context: this.folder.context,
                            folderId: this.folder.id,
                            managementSite: this.getManagementSite(),
                            product: this.folder.product,
                            initialOrder: this.order,
                            quotationFees: this.feesQuotationLine,
                            initialQuotation: this.quotation,
                            payers: this.folder.payers,
                            warrantyCode: this.warrantyCode,
                            workforcesRule: this.workforces,

                        };
                        const dialogRef = this.matDialog.open(AddRequirementDialogComponent, {
                            height: '90vh', width: '100vh', minWidth: '80%', data: requirementModalConfig
                        });
                        dialogRef.afterClosed().subscribe(dialogResult => {
                                if (!!dialogResult) {
                                    if (this.warrantyCode === 'HG') {
                                        this.quotationId = dialogResult.quotationId;
                                        this.quotation.id = this.quotationId;
                                    }
                                    this.totalPrice = dialogResult.totalPrice?.value;
                                    this.order = dialogResult.order;
                                    if (!!this.order.orderItems || !!this.order.orderExtraItems) {
                                        this.dataSourceLines = [...this.order.orderItems, ...this.order.orderExtraItems];
                                        this.refreshDataSource();
                                    }
                                }
                            }
                        );
                    }
                }
            );

    }

    disabledButtonForm(): boolean {
        return this.totalPrice < 0 || this.dataSource.data?.filter(line => line.type === TypeOfServiceEnum.SPARE_PART || line.type === TypeOfServiceEnum.WORKFORCE)?.length === 0;
    }

    private getManagementSite() {
        return this.folder.sites.find(site => site.type === SiteType.MANAGEMENT_SITE);
    }

    private getManagementSiteCode(): string {
        return this.getManagementSite()?.code;
    }

    private getEmptyQuotation(): Quotation {
        return {
            quotationLines: [], discount: 0, totalPrice: {value: 0}, quotationAttachments: [], target: 'CLIENT', userAction: null, code: ''
        };
    }
}
