import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {SparePartService} from '../../../../../../../shared/services/spare-part.service';

@Component({
    selector: 'app-spare-parts-info',
    templateUrl: './spare-parts-info.component.html',
    styleUrls: ['./spare-parts-info.component.scss']
})
export class SparePartsInfoComponent implements OnInit {

    displayedColumns: string[] = ['reference', 'label', 'stock'];

    dataSource = new MatTableDataSource<any>([]);
    chooseOtherSparePart = false;

    constructor(private dialogRef: MatDialogRef<SparePartsInfoComponent>,
                private sparePartService: SparePartService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        const codes = this.data.stocks.map(value => value.sparePartCode);
        this.sparePartService.findWithCriteria(null, codes).subscribe(spareparts => {
            this.dataSource.data = spareparts.map(value => {
                return {
                    code: value.code,
                    label: value.label,
                    status: this.data.stocks
                        .filter(stock => stock.sparePartCode === value.code)
                        .map(it => it.status)
                };
            });
        });
    }

    onCloseModal(): void {
        this.dialogRef.close();
    }

    onPreviewTask(): void {
        this.dialogRef.close(this.chooseOtherSparePart = true);
    }

}
