import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FolderPublic} from '../../../../models/folder.public.model';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {DataSourceLine, PublicQuotation, PublicQuotationLine} from '../../../../models/quotation.model';
import {Amount} from '../../../../models/amount.model';
import {MatTableDataSource} from '@angular/material/table';
import {TypeOfServicePublicRepairerEnum} from '../../../../models/typeOfService.model';
import {Observable} from 'rxjs';
import {Vat} from '../../../../models/vat.model';
import {PublicService} from '../../../../shared/services/public.service';
import {FileService} from '../../../../shared/services/file.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {SparePartService} from '../../../../shared/services/spare-part.service';
import {currentLanguage, vats} from '../../../../store/organization/organization.selectors';
import {QuotationStatus} from '../../../../models/enums/quotationStatus.enum';
import {QuotationType} from '../../../../models/enums/quotationType.enum';
import {map} from 'rxjs/operators';
import {FuseConfirmDialogComponent} from '../../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FileInfo} from '../../../../models/file-info.model';
import * as uuid from 'uuid';
import {saveAs} from 'file-saver';
import {CountryConfigurationSetSuccessfully} from '../../../../store/organization/organization.actions';

@Component({
    selector: 'app-public-waiting-for-repairer-quotation',
    templateUrl: './public-waiting-for-repairer-quotation.component.html',
    styleUrls: ['./public-waiting-for-repairer-quotation.component.scss']
})
export class PublicWaitingForRepairerQuotationComponent implements OnInit {

    @Input() folder: FolderPublic;
    @Output() inputMapQuotation = new EventEmitter<any>();
    @ViewChild('dropzone') dropzone: ElementRef;
    @ViewChild('ngFormQuotation') ngFormQuotation: NgForm;

    attachments = new Array<any>();

    displayedColumnsAttachment: string[] = ['name', 'action'];
    displayedColumns: string[] = ['code', 'label', 'type', 'price', 'quantity', 'discount', 'vat', 'totalPrice', 'action'];
    dataSource = [];

    quotation: PublicQuotation;
    discountQuotations = 0;
    computeTotalPrice: Amount;
    quotationLines = new MatTableDataSource<DataSourceLine>();

    serviceTypes = Object.keys(TypeOfServicePublicRepairerEnum);
    attachmentQuotationDataTable = new MatTableDataSource<any>();
    isLoading = false;

    quotationForm: any = {
        form: null
    };

    fileUploadForm: any = {
        file: null, hasFile: false
    };

    quotationDescriptionForm: any = {
        form: null, placeholders: []
    };

    waitQuotationForm: any = {
        form: null, placeholders: []
    };
    currency: string;
    vatsList$: Observable<Vat[]>;
    defaultVatValue: string;

    constructor(private publicService: PublicService,
                private fileService: FileService,
                private store$: Store<AppState>,
                private _translateService: TranslateService,
                private dialog: MatDialog,
                private sparePartService: SparePartService) {
        this.serviceTypes = Object.keys(TypeOfServicePublicRepairerEnum);

    }

    ngOnInit(): void {
        this.initLanguage();
        this.loadCountryConfiguration();
        this.vatsList$ = this.store$.pipe(select(vats));
        this.currency = this.folder.currency;
        this.initQuotationRepairerForm(TypeOfServicePublicRepairerEnum.SPARE_PART);
        this.initFormValidateQuotation();
        this.initQuotation();
    }

    private initLanguage() {
        this.store$.pipe(select(currentLanguage)).subscribe(
            language => {
                this._translateService.use(language);
            }
        );
    }

    private initQuotation(): void {
        if (this.folder.publicQuotations.length > 0) {
            let totalTTc = 0;
            this.quotation = this.folder.publicQuotations.find(quotation => quotation.status === QuotationStatus.DRAFT);
            if (this.quotation) {
                this.waitQuotationForm.form.controls['quotationCode'].setValue(!!this.quotation.code ? this.quotation.code : '');
                this.waitQuotationForm.form.controls['feesRefusedQuotationWithoutRestitution'].setValue(!!this.quotation.feesRefusedQuotationWithoutRestitution?.value ? this.quotation.feesRefusedQuotationWithoutRestitution.value : 0);
                this.waitQuotationForm.form.controls['feesRefusedQuotationWithRestitution'].setValue(!!this.quotation.feesRefusedQuotationWithRestitution?.value ? this.quotation.feesRefusedQuotationWithRestitution.value : 0);
                this.attachmentQuotationDataTable.data = this.quotation.quotationAttachments;
                this.dataSource = this.toDataSourceLines(this.quotation.quotationLines);
                this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
                this.quotationLines.data.map(quotationLine => {
                    totalTTc = totalTTc + this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, quotationLine.vat);
                });
                this.computeTotalPrice = {
                    value: totalTTc,
                    currency: this.currency
                } || {
                    value: 0,
                    currency: this.currency
                };
                this.quotationDescriptionForm.form.setValue({'description': !!this.quotation.agentComment ? this.quotation.agentComment : ''});
            } else {
                this.getEmptyQuotation();
            }
        } else {
            this.getEmptyQuotation();
        }
    }

    private getEmptyQuotation() {
        this.quotation = {
            quotationLines: [], discount: 0, totalPrice: {value: 0}, quotationAttachments: [], target: 'DISTRIBUTOR', userAction: null, code: ''
        };
    }

    initFormValidateQuotation(): void {
        this.waitQuotationForm.form = new FormGroup({
            quotationCode: new FormControl(null, Validators.required),
            feesRefusedQuotationWithoutRestitution: new FormControl(0),
            feesRefusedQuotationWithRestitution: new FormControl(0),
        });
        this.quotationDescriptionForm.form = new FormGroup({
            description: new FormControl(null)
        });

    }

    private loadCountryConfiguration() {
        this.publicService.getByCountryCode(this.folder.externalUid).subscribe(countryConfiguration => {
            this.store$.dispatch(new CountryConfigurationSetSuccessfully(countryConfiguration));
        });
    }

    initQuotationRepairerForm(typeValue: string): void {
        this.vatsList$.pipe(
            map(vats => vats.find(item => item.standard === true))
        ).subscribe(vat => {
            if (vat) {
                this.defaultVatValue = vat.rate;
                this.initValuesQuotationRepairerForm(typeValue);
            }
        });
    }

    initValuesQuotationRepairerForm(typeValue: string): void {
        this.quotationForm.form = new FormGroup({
            code: new FormControl(null, Validators.required),
            label: new FormControl(null, Validators.required),
            quantity: new FormControl(1, Validators.required),
            price: new FormControl(null, Validators.required),
            vat: new FormControl(this.defaultVatValue, Validators.required),
            discount: new FormControl(0, [Validators.min(0), Validators.max(100)]),
            type: new FormControl(typeValue, Validators.required),
            discountReason: new FormControl(null),
            totalLinePrice: new FormControl(0),
        });
    }

    onFilesAdded($event: any): void {
        this.fileUploadForm.hasFile = true;
        this.fileUploadForm.file = $event[0].name;
        const fileChange = this.fileService.onFileChange($event);
        fileChange.reader.onload = () => {
            const blobFile = this.fileService.dataURItoBlob(fileChange.reader.result.toString().split(',')[1]);
            const newAttachement = {file: blobFile, info: $event[0]};
            this.attachments.push(newAttachement);
            this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.concat(newAttachement);
            // @ts-ignore
            this.dropzone.reset();
            this.fileUploadForm.hasFile = false;
        };
    }

    removeFile(fileId): void {
        const index = this.attachments.indexOf(fileId);
        if (index > -1) {
            this.attachments.splice(index, 1);
        }
        if (this.quotation.quotationAttachments.length !== 0 && fileId.id) {
            this.quotation.quotationAttachments = this.quotation.quotationAttachments.filter(file => file.id !== fileId.id);
        }
        this.attachmentQuotationDataTable.data = this.attachmentQuotationDataTable.data.filter(attachment => attachment !== fileId);
    }

    validateQuotationChoiceForm(): void {
        this.quotationConfirmation(this.prepareQuotation());
    }

    private prepareQuotation() {
        return {
            id: !this.quotation.id ? uuid.v4() : this.quotation.id,
            code: this.waitQuotationForm.form.value.quotationCode,
            quotationLines: this.toQuotationLines(this.quotationLines.data),
            discount: this.quotation.discount,
            target: this.quotation.target,
            quotationAttachments: this.getQuotationAttachments(),
            agentComment: this.quotation.agentComment,
            status: this.quotation.status,
            feesRefusedQuotationWithoutRestitution: this.getFeesRefusedQuotationWithoutRestitution(),
            feesRefusedQuotationWithRestitution: this.getFeesRefusedQuotationWithRestitution(),
        };
    }

    private getFeesRefusedQuotationWithRestitution() {
        const {feesRefusedQuotationWithRestitution} = this.waitQuotationForm.form.value;
        return {
            value: !!feesRefusedQuotationWithRestitution ? feesRefusedQuotationWithRestitution : 0,
            currency: this.currency
        };
    }

    private getFeesRefusedQuotationWithoutRestitution() {
        const {feesRefusedQuotationWithoutRestitution} = this.waitQuotationForm.form.value;
        return {
            value: feesRefusedQuotationWithoutRestitution ? feesRefusedQuotationWithoutRestitution : 0,
            currency: this.currency
        };
    }

    private getQuotationAttachments() {
        return this.quotation.quotationAttachments.map(value => value.id);
    }

    quotationConfirmation(quotation): void {
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            hasBackdrop: true, disableClose: false,
        });
        dialogRef.componentInstance.title = 'CONFIRMATION.MODAL.DELETE_DIALOG.TITLE';
        dialogRef.componentInstance.message = 'CONFIRMATION.MODAL.CONFIRM.QUOTATION';
        dialogRef.componentInstance.confirmButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.VALIDATE_BUTTON';
        dialogRef.componentInstance.cancelButtonLabel = 'CONFIRMATION.MODAL.DELETE_DIALOG.CANCEL_BUTTON';
        dialogRef.afterClosed().subscribe(validate => {
            if (validate) {
                this.inputMapQuotation.emit({
                    'description': this.quotationDescriptionForm.form.value.description,
                    'quotation': quotation,
                    'attachments': this.attachments
                });
            }
        });
    }

    calculationQuotationLine(): void {
        this.quotationForm.form.get('price').setValue(this.sparePartService.formatPriceAccordingToCurrency(this.quotationForm.form.get('price').value, this.currency));
        this.quotationForm.form.get('totalLinePrice').setValue(
            this.sparePartService.formatPriceAccordingToCurrency(
                this.sparePartService.getTotalLinePrice(
                    this.sparePartService.normalizeFloat(this.quotationForm.form.value.price),
                    this.quotationForm.form.value.quantity, this.quotationForm.form.value.discount, this.quotationForm.form.value.vat),
                this.currency));
    }

    getTotalLinePrice(quotationLine: any): number {
        return this.sparePartService.getTotalLinePrice(quotationLine.price, quotationLine.quantity, quotationLine.discount, quotationLine.vat);
    }

    getTotalPriceHT(): number {
        return !!this.dataSource ? this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
            total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0) : 0;
    }

    getTotalDiscount(): number {
        if (!!this.dataSource) {
            const totalPriceHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                total + this.sparePartService.normalizeFloat(line.price) * line.quantity, 0);
            return totalPriceHT - this.getTotalNetHT();
        }
        return 0;
    }

    getTotalNetHT(): number {
        if (!!this.dataSource) {
            let totalNetHT;
            totalNetHT = this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) =>
                line.discount > 0 ? total + (this.sparePartService.normalizeFloat(line.price) * line.quantity -
                    ((this.sparePartService.normalizeFloat(line.price) * line.quantity) * (line.discount / 100))) : total
                    + (this.sparePartService.normalizeFloat(line.price) * line.quantity), 0);
            if (this.discountQuotations > 0) {
                return totalNetHT - totalNetHT * (this.discountQuotations / 100);
            }
            return totalNetHT;
        }
        return 0;
    }

    getTotalPriceTVA(): number {
        if (!!this.dataSource) {
            return this.dataSource.filter(quotationLine => quotationLine).reduce((total, line) => {
                const totalHT = this.sparePartService.normalizeFloat(line.price) * line.quantity;
                const discount = line.discount / 100;
                const vatRate = line.vat;
                if (!!vatRate && vatRate > 0) {
                    const totalNetHT = totalHT - totalHT * discount;
                    return total + (totalNetHT + totalNetHT * (vatRate / 100)) - totalNetHT;
                }
                return total;
            }, 0);
        }
        return 0;
    }

    normalize(value): number {
        return this.sparePartService.normalizeFloat(value);
    }

    addIntoQuotationLines(): void {
        this.checkDiscountValue();
        this.dataSource.unshift(this.quotationForm.form.value);
        this.refreshDataForm();
        this.refreshDataSourceTable();
    }

    private checkDiscountValue() {
        if (!this.quotationForm.form.value.discount) {
            this.quotationForm.form.get(['discount']).setValue(0);
        }
    }

    refreshDataForm(): void {
        this.ngFormQuotation.resetForm();
        this.initQuotationRepairerForm(TypeOfServicePublicRepairerEnum.SPARE_PART);
    }

    deleteLine(indexLine: number): void {
        this.dataSource = this.dataSource.filter((item, index) => index !== indexLine || item.type.startsWith(QuotationType.QUOTATION_FEES));
        this.quotationLines = new MatTableDataSource<any>(this.dataSource);
        this.refreshDataSourceTable();
    }

    refreshDataSourceTable(): void {
        this.quotationLines.data = [];
        this.quotationLines = new MatTableDataSource<DataSourceLine>(this.dataSource);
        const totalPrice = this.sparePartService.computeTotalPrice(this.dataSource.map(quotationLine => quotationLine.totalLinePrice));
        this.computeTotalPrice = {
            value: totalPrice,
            currency: this.currency
        };
    }

    downloadFile(file: FileInfo): void {
        this.fileService.getPublicAttachmentFile(file.id, this.folder.externalUid).subscribe(value => {
            saveAs(value, file.name);
        });
    }

    toDataSourceLines(quotationLines: PublicQuotationLine[]): DataSourceLine[] {
        const dataSourceLines: DataSourceLine[] = [];
        quotationLines.forEach(quotationLine => {
            // map some specific values
            const line = {
                ...quotationLine,
                vat: Number(quotationLine.vat.rate),
                price: quotationLine.priceAmount.value,
                totalLinePrice: quotationLine.totalLinePrice?.value
            };
            const {priceAmount, ...dataSourceLine} = line;
            dataSourceLines.push(dataSourceLine);
        });

        return dataSourceLines;
    }

    toQuotationLines(dataSourceLines: any[]): PublicQuotationLine[] {
        const quotationLines: PublicQuotationLine[] = [];
        dataSourceLines.forEach(line => {
            const quotationLine: PublicQuotationLine = {
                ...line,
                vat: {rate: String(line.vat)},
                priceAmount: {
                    value: this.sparePartService.normalizeFloat(line.price),
                    currency: this.currency
                },
                totalLinePrice: {
                    value: this.sparePartService.normalizeFloat(line.totalLinePrice),
                    currency: this.currency
                }
            };
            delete quotationLine['price'];
            quotationLines.push(quotationLine);
        });

        return quotationLines;
    }


}



