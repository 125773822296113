import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MomentDateAdapter} from '@angular/material-moment-adapter';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {FuseModule} from '@fuse/fuse.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {FolderModule} from './main/folder/folder.module';
import {MatPaginatorI18nIntl} from './shared/utils/mat-paginator-i18n-intl.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RoutingModule} from './app.routing';
import {HttpErrorInterceptor} from './http-error.interceptor';
import {SnackbarNotifComponent} from './shared/snackbar-notif/snackbar-notif.component';
import {KeycloakAngularModule, KeycloakBearerInterceptor} from 'keycloak-angular';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {CurrentOrganizationInterceptorService} from './shared/services/current-organization-interceptor.service';
import {HorizontalLayout1Module} from './layout/horizontal/layout-1/layout-1.module';
import {PublicModule} from './main/public/public.module';
import {FilesSliderModule} from './layout/components/files-slider/files-slider.module';
import {VerticalLayout1Module} from './layout/vertical/layout-1/layout-1.module';
import {A11yModule} from '@angular/cdk/a11y';
import {DatePickerCustomHeaderComponent} from './shared/custom/DatePickerCustomHeader.component';
import {MatomoModule} from 'ngx-matomo';
import {MatomoAnalyticsEventsService} from './shared/matomo/MatomoAnalyticsEventsService';
import {UploadModalComponent} from './shared/upload-modal/upload-modal.component';
import {ShowImageModalComponent} from './main/image-modal/show-image-modal.component';
import {FuseSharedModule} from '../@fuse/shared.module';
import {MatTreeModule} from '@angular/material/tree';
import {DynamicModule} from 'ng-dynamic-component';
import {SharedGenericModule} from './shared/generic/shared-generic.module';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MdePopoverModule} from '@material-extended/mde';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {SharedService} from './shared/services/shared.service';
import {PhoneNumberPipe} from '../@fuse/pipes/phone-number.pipe';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {CodeToLabelPipe} from '../@fuse/pipes/codeToLabel.pipe';
import {NotificationsDetailsComponent} from './main/notifications/notifications-details/notifications-details.component';
import {SuspendingFolderReasonDialogComponent} from './main/folder/suspending-folder-reason-dialog/suspending-folder-reason-dialog.component';
import {AppStoreModule} from './store/app.store.module';
import {AppAuthGuard} from './shared/utils/app.authguard';
import {ErrorComponent} from './error/error.component';
import {MultiImageModalComponent} from './main/multi-image-modal/multi-image-modal.component';
import {ApiMetricsComponent} from './layout/components/api-metrics/api-metrics.component';
import {GridModule, PagerModule} from '@syncfusion/ej2-angular-grids';
import {ButtonModule, CheckBoxModule, ChipListModule} from '@syncfusion/ej2-angular-buttons';
import {ToolbarModule} from '@syncfusion/ej2-angular-navigations';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        SnackbarNotifComponent,
        DatePickerCustomHeaderComponent,
        ShowImageModalComponent,
        UploadModalComponent,
        NotificationsDetailsComponent,
        SuspendingFolderReasonDialogComponent,
        ErrorComponent,
        MultiImageModalComponent,
        ApiMetricsComponent
    ],
    imports: [
        AppStoreModule,
        TranslateModule,
        FuseSharedModule,
        MatTreeModule,
        DynamicModule,
        SharedGenericModule,
        Ng2TelInputModule,
        MatMenuModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonModule,
        NgxMatSelectSearchModule,
        MatSelectModule,
        MatDialogModule,
        MatCheckboxModule,
        NgxDropzoneModule,
        MatCardModule,
        MatProgressBarModule,
        MdePopoverModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        DashboardModule,
        MatomoModule,
        KeycloakAngularModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RoutingModule,
        CommonModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]
            }
        }),

        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        DashboardModule,
        PublicModule,
        FolderModule,
        SharedGenericModule,
        MatIconModule,
        HorizontalLayout1Module,
        VerticalLayout1Module,
        FilesSliderModule,
        A11yModule,
        GridModule,
        ButtonModule,
        CheckBoxModule,
        PagerModule,
        ChipListModule,
        ToolbarModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        CodeToLabelPipe,
        PhoneNumberPipe,
        SharedService,
        DatePipe,
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useClass: MatPaginatorI18nIntl
        },
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: CurrentOrganizationInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakBearerInterceptor,
            multi: true
        },
        AppAuthGuard,
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        MatomoAnalyticsEventsService,
        CurrencyPipe
    ],
    entryComponents: [SnackbarNotifComponent],
    exports: [
        SharedGenericModule
    ], schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})

export class AppModule {
}
