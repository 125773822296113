import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../../models/product.model';
import {MatDialog} from '@angular/material/dialog';
import {Item} from 'app/models/invoice.model';
import {Unsubscriber} from '../../../../unsubscriber';
import {ProductDetailComponent} from '../../../../main/folder/product-detail/product-detail.component';
import {accessSites, isElectroDepot, organizationCode} from '../../../../store/user/user.selectors';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {UnknownProduct} from '../../../../main/folder/create-form/unknown-product/unknown.product';
import {StartLoading, StopLoading} from '../../../../store/loader/loader.actions';
import {Constants} from '../../../../Constants';
import {filter, map} from 'rxjs/operators';
import {ProductService} from '../../../services/product.service';

@Component({
    selector: 'app-product-preview-card',
    templateUrl: './product-preview-card.component.html',
    styleUrls: ['./product-preview-card.component.scss']
})
export class ProductPreviewCardComponent extends Unsubscriber implements OnInit{

    public readonly UNKNOWN_PRODUCT_CODE = UnknownProduct.UNKNOWN_PRODUCT_CODE;
    public readonly UNKNOWN_PRODUCT_SUPPLIER = UnknownProduct.UNKNOWN_PRODUCT_SUPPLIER;


    @Input() product: Product | Item;
    @Input() purchaseDate: string;
    currentOrganizationCode: string;
    isElectroDepot$: Observable<boolean>;
    accessSites: string[] = [];

    constructor(private dialog: MatDialog,
    private productService: ProductService,
    private store$: Store<AppState>) {
        super();
    }

    ngOnInit(): void {
        this.anotherSubscription = this.store$.pipe(select(accessSites)).subscribe(accessSites => this.accessSites = accessSites);
        this.isElectroDepot$ = this.store$.select(isElectroDepot);
        this.anotherSubscription = this.store$.pipe(select(organizationCode))
            .subscribe(organizationCode => this.currentOrganizationCode = organizationCode);
    }

    updateSupplier(supplier): void {
        this.product.supplier = supplier;
    }

    showProductDetails(): void {
        this.store$.dispatch(new StartLoading());

        const filters = new Map<string, any>();
        filters.set(Constants.ORGANIZATION_CODE, this.currentOrganizationCode);
        filters.set(Constants.PRODUCT_CODE, this.product.code);
        filters.set(Constants.WITH_SELLING_PRICE, true);
        filters.set(Constants.WITH_PURCHASE_PRICE, true);
        filters.set(Constants.WITH_DOCUMENTS, true);

        this.productService.search(filters)
            .pipe(
                map(page => page.content),
                filter(products => products.length > 0),
                map(products => products[0]),
            )
            .subscribe(product => {
                    this.dialog.open(ProductDetailComponent, {
                        width: '80%',
                        hasBackdrop: true,
                        disableClose: false,
                        autoFocus: false,
                        data: {
                            purchaseDate: this.purchaseDate,
                            product: product,
                            managementSiteCode: this.accessSites[0]
                        }
                    });
                },
                error => this.store$.dispatch(new StopLoading()),
                () => this.store$.dispatch(new StopLoading())
            );
    }
}
