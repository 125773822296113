import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {PushNotificationService} from '../../../shared/services/pushNotification.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {PushNotification, PushNotificationUpdateRequest} from '../../../models/pushNotification/pushNotification.model';
import {PushNotificationSubjectService} from '../../../models/pushNotification/pushNotification-subject.service';
import {StartLoading, StopLoading} from '../../../store/loader/loader.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/app.state';
import {take} from 'rxjs/operators';
import {Unsubscriber} from '../../../unsubscriber';
import {PushNotificationMessage} from '../../../models/enums/push-notification-message.enum';
import {ActionEventArgs, DataResult, GridComponent} from '@syncfusion/ej2-angular-grids';

@Component({
    selector: 'app-notifications-details',
    templateUrl: './notifications-details.component.html',
    styleUrls: ['./notifications-details.component.scss']
})
export class NotificationsDetailsComponent extends Unsubscriber implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('grid') grid: GridComponent;
    notificationsSelected = [];
    messages = Object.values(PushNotificationMessage);
    pageIndex = 0;
    pageSize = 10;
    filterForm: FormGroup;
    datasource: DataResult;
    allSelected = false;

    constructor(private translateService: TranslateService,
                private pushNotificationService: PushNotificationService,
                private store$: Store<AppState>,
                private router: Router,
                private pushNotificationSubjectService: PushNotificationSubjectService) {
        super();
    }

    ngOnInit(): void {
        this.initFilterForm();
        this.initNotificationsDataSource();
    }

    initFilterForm(): void {
        this.filterForm = new FormGroup({
            type: new FormControl(''),
            businessId: new FormControl(''),
            message: new FormControl(''),
        });
    }

    initNotificationsDataSource(): void {
        this.searchNotifications();
    }

    searchNotifications(page?: number) {
        this.store$.dispatch(new StartLoading());
        this.pushNotificationService.getAllPushNotifications(page ?? 0, this.grid?.pagerModule?.pagerObj?.pageSize ?? 10)
            .pipe(take(1))
            .subscribe(data => {
                this.datasource = {
                    result: data.content,
                    count: data.totalElements
                };
                this.store$.dispatch(new StopLoading());
                this.resetSelection();
            }, err => {
                this.store$.dispatch(new StopLoading());
            });
    }

    getNotificationKey(key: string, pushNotificationType: string): string {
        const notificationKey = 'PUSH.NOTIFICATION.' + pushNotificationType + '.' + key;
        if (this.translateService.instant(notificationKey) !== notificationKey) {
            return notificationKey;
        }
        return 'PUSH.NOTIFICATION.ERROR.COMMON';
    }

    updateStatus(status: string, pushNotification): void {
        const pushNotificationUpdateRequest: PushNotificationUpdateRequest = {
            status: status,
        };

        pushNotification.previousStatus = pushNotification.status;

        if (!!pushNotificationUpdateRequest) {
            this.pushNotificationService.updateNotificationsStatus([pushNotification.id], pushNotificationUpdateRequest.status)
                .subscribe(
                    updatedNotifications => {
                        const updatedNotification = updatedNotifications.find(notif => notif.id === pushNotification.id);

                        if (updatedNotification) {
                            const index = (this.datasource.result as PushNotification[]).findIndex(
                                notif => notif.id === pushNotification.id
                            );
                            if (index !== -1) {
                                this.datasource.result[index] = {
                                    ...updatedNotification,
                                    previousStatus: pushNotification.previousStatus,
                                };
                            }

                            this.notificationsSelected = [];

                            this.pushNotificationSubjectService.refreshPushNotification(updatedNotification);
                            this.grid.dataSource = {
                                result: this.datasource.result,
                                count: this.datasource.count
                            };
                            this.grid?.refresh();
                        }
                    }
                );
        }
    }


    updateStatusOfNotificationSelected(status: string): void {
        const notificationsSelectedIds = this.notificationsSelected.map(p => p.id);
        this.pushNotificationService.updateNotificationsStatus(notificationsSelectedIds, status)
            .pipe(take(1))
            .subscribe(notifications => {
                notifications.forEach(updatedNotification => {
                    const index = (this.datasource.result as PushNotification[]).findIndex(
                        notif => notif.id === updatedNotification.id
                    );
                    if (index !== -1) {
                        this.datasource.result[index] = updatedNotification;
                    }
                });

                this.notificationsSelected = [];
                this.grid.dataSource = {
                    result: this.datasource.result,
                    count: this.datasource.count
                };
                this.resetSelection();
                this.grid.refresh();
            });
    }


    checkedRow(event: Event, data: PushNotification): void {
        const isChecked = (event.target as HTMLInputElement).checked;
        if (isChecked) {
            const isAlreadySelected = this.isRowSelected(data);
            if (!isAlreadySelected) {
                this.notificationsSelected.push(data);
            }
        } else {
            this.notificationsSelected = this.notificationsSelected.filter(notification => notification.id !== data.id);
        }
        this.updateSelectAllState();
    }

    isRowSelected(data: PushNotification): boolean {
        return this.notificationsSelected.some(notification => notification.id === data.id);
    }

    selectAll(event: Event): void {
        this.allSelected = (event.target as HTMLInputElement).checked;
        const selectableRows = (this.datasource.result as PushNotification[]).filter(
            notif => notif.status !== 'IGNORED' && notif.status !== 'RESOLVED'
        );

        if (this.allSelected) {
            const newSelections = selectableRows.filter(row => {
                const alreadySelected = this.notificationsSelected.some(selected => {
                    return selected.id === row.id;
                });
                return !alreadySelected;
            });
            this.notificationsSelected = this.notificationsSelected.concat(newSelections);
        } else {
            this.notificationsSelected = this.notificationsSelected.filter(
                selected => !selectableRows.some(row => row.id === selected.id)
            );
        }
    }

    updateSelectAllState(): void {
        const totalSelectableRows = (this.datasource.result as PushNotification[]).filter(
            notif => notif.status !== 'IGNORED' && notif.status !== 'RESOLVED'
        ).length;

        this.allSelected = this.notificationsSelected.length === totalSelectableRows;
    }

    hasSelectableRows(): boolean {
        return this.datasource && this.datasource.result
            ? (this.datasource.result as PushNotification[]).some(
                notif => notif.status !== 'IGNORED' && notif.status !== 'RESOLVED'
            )
            : false;
    }

    restoreStatus(pushNotification: PushNotification) {
        if (pushNotification.previousStatus) {
            const previousStatus = pushNotification.previousStatus;
            this.updateStatus(previousStatus, pushNotification);
            pushNotification.previousStatus = null;
        }
    }

    onAnyAction(args: ActionEventArgs) {
        switch (args.requestType) {
            case 'paging':
                this.searchNotifications(args.currentPage - 1);
        }
    }

    resetSelection(): void {
        const selectableRows = (this.datasource.result as PushNotification[]).filter(
            notif => notif.status !== 'IGNORED' && notif.status !== 'RESOLVED'
        );
        this.allSelected = selectableRows.every(row =>
            this.notificationsSelected.some(selected => selected.id === row.id)
        );
    }

    getChipClass(status: string): string {
        switch (status) {
            case 'RESOLVED':
                return 'e-success';
            case 'IGNORED':
                return 'e-warning';
            case 'READ':
                return 'e-info';
            default:
                return 'chip-default';
        }
    }

    openFolder(businessId: string) {
        this.router.navigate([`/folder/${businessId}`]);
    }
}
