import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TaskVariables} from '../../task.variables';
import {Folder, IFolderUpdateRequest} from '../../../../../models/folder.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {getModOpIconName, noModeOp, WARRANTY_CONFIGS} from '../../../../../shared/data/static.config';
import {WarrantyReasonEnum} from '../../../../../models/enums/warrantyReason.enum';
import {BackOfficeService} from '../../../../../shared/services/back-office.service';
import {ConfigCodeMapsEnum} from '../../../../../shared/services/configuration-item-enum';
import {CustomerChoiceEnum} from '../../../../../models/enums/operationMode.enum';
import {ModOpDetails} from '../../../../../models/mod.op.list.details';
import {CommentType} from '../../../../../models/enums/CommentType.enum';
import {FolderService} from '../../../../../shared/services/folder.service';
import {FolderSubjectService} from '../../../folder-subject.service';
import {finalize, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../../../../store/loader/loader.actions';
import {AppState} from '../../../../../store/app.state';
import {Unsubscriber} from '../../../../../unsubscriber';
import {RuleEvaluationContext} from '../../../../../models/rules/RuleEvaluationContext';
import {RegimeWarrantyEnum} from '../../../../../models/warrantyRule.model';
import * as moment from 'moment/moment';
import {ReasonDetails} from '../../../../../models/warranty.reason.list.details';
import {InstructionUserTask} from '../../../../../models/instruction-user-task.model';
import {LoadingScreenService} from '../../../../../shared/services/loading-screen.service';
import {CodeToLabelService} from '../../../../../../@fuse/services/code-to-label.service';
import {ConfigCodesEnum} from '../../../../../models/codeLabelObject.model';
import {CodeLabel} from '../../../../../models/element.model';

@Component({
    selector: 'app-choose-operating-mode',
    templateUrl: './choose-operating-mode.component.html',
    styleUrls: ['./choose-operating-mode.component.css']
})
export class ChooseOperatingModeComponent extends Unsubscriber implements OnInit {

    @Input() variablesTask: any;
    @Input() instructionUserTask: InstructionUserTask;

    @Output() inputMap = new EventEmitter<any>();

    warrantyForm: FormGroup;
    listWarranty: any = WARRANTY_CONFIGS;
    operatingModeList: any[];
    modeOpConfigList: CodeLabel[] = [];
    modOpCommentForm: FormGroup = new FormGroup({
        comment: new FormControl(null)
    });

    customerOperatingModeChoices: Map<string, string[]> = new Map<string, string[]>(); // <OrganizationCode, CustomerChoices>
    reasonsWarranty: string[] = [];
    captureCustomerModOpChoice: boolean;
    hasDiagnosticExpert: boolean;
    folder: Folder;
    selectedWarrantyReason: string;
    constructor(private backOfficeService: BackOfficeService,
                private store$: Store<AppState>,
                private folderService: FolderService,
                private loadingScreenService: LoadingScreenService,
                private folderSubjectService: FolderSubjectService,
                private codeToLabelService: CodeToLabelService) {
        super();
    }

    ngOnInit(): void {
        this.getCodeLabels();
        this.initForm();
        this.initVariables();

        this.anotherSubscription = this.folderSubjectService.folder$
            .pipe(take(1))
            .subscribe(folder => {
                    this.folder = folder;
                    this.initCustomerOperatingModeChoices();
                    this.initWarrantyFormValue();
                }
            );
    }

    private initForm(): void {
        this.warrantyForm = new FormGroup({
            warranty: new FormControl(null, [Validators.required]),
            reason: new FormControl(null, [Validators.required]),
            operatingMode: new FormControl(null, [Validators.required]),
        });
    }

    initVariables(): void {
        this.captureCustomerModOpChoice = this.variablesTask[TaskVariables.captureCustomerModOpChoice];
    }

    changeReason(value: string): void {
        this.warrantyForm.controls['reason'].setValue(value);
        this.selectedWarrantyReason = value;
        this.calculateEligibleOperatingMode(this.warrantyForm.controls['warranty'].value.key, this.warrantyForm.controls['reason'].value);
        this.getWarrantyReasonDetails();
    }

    changeOperatingMode(modOp): void {
        this.warrantyForm.controls['operatingMode'].setValue(modOp);
    }

    getIconName(value: any): void {
        return getModOpIconName(value);
    }

    initWarrantyFormValue(): void {
        const selectedWarranty = this.listWarranty.filter(warranties => warranties.key === this.folder.newWarranty.warranty.toString())[0];
        this.warrantyForm.controls['warranty'].setValue(selectedWarranty);
        this.loadWarrantyReasons();

        if (this.captureCustomerModOpChoice) {
            this.warrantyForm.addControl('customerChoice', new FormControl(null, [Validators.required]));
        }
    }


    changeWarranty(warranty): void {
        this.warrantyForm.controls['warranty'].setValue(warranty);
        if (!!warranty.key && warranty.key === 'HG') {
            this.captureCustomerModOpChoice = false;
            if (!this.warrantyForm.get('customerChoice')) {
                this.warrantyForm.removeControl('customerChoice');
            }
        }
        this.warrantyForm.controls['reason'].setValue(null);
        this.warrantyForm.controls['operatingMode'].setValue(null);
        this.operatingModeList = [];
        this.store$.dispatch(new StartLoading());
        this.loadWarrantyReasons();

    }

    private loadWarrantyReasons(): void {
        this.reasonsWarranty = [];
        const warrantyCode = this.warrantyForm.controls['warranty'].value.key;
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        ruleEvaluationContext.warrantyCode = warrantyCode;
        this.backOfficeService.getEligibleWarrantyReasons(ruleEvaluationContext).pipe(
            finalize(
                () => {
                    this.store$.dispatch(new StopLoading());
                }
            )
        ).subscribe(data => {
            this.reasonsWarranty = data.map(el => el.reason).filter(e => e.toLocaleLowerCase() !== WarrantyReasonEnum.ANY);

            // TODO THIS IS A TEMPORARY WORKAROUND FOR LM_*, THIS METHOD SHOULD BE DELETED AFTER THE WARRANTY CALCULATION REWORK
            this.handleAdeoWarranty(warrantyCode, data);

            this.selectDefaultWarrantyReason();
        });
    }

    /**
     *  This temporary workaround will be applied only for:
     *  - The organization code is 'LM_*'
     *  - The selected warranty code is 'SG'
     *  - The 'SG_IN_MANUFACTURER_WARRANTY_PERIOD' is not listed in the reasons drop down list
     *  If the three conditions mentioned above are satisfied AND the product is still under warranty (based on product warranty not contract)
     *  we add the Manufacturer warranty period reason
     */
    private handleAdeoWarranty(warrantyCode: string, data: ReasonDetails[]) {
        if (this.folder.organization.code.startsWith('LM_') && warrantyCode === 'SG' && !data.map(x => x.reason).includes('SG_IN_MANUFACTURER_WARRANTY_PERIOD')) {
            const productWarrantyDuration = this.folder.product.warranty.duration;
            const purchaseDate = moment(this.folder.purchaseInvoice.purchaseDate, 'YYYY-MM-DD');
            const futureDate = purchaseDate.clone().add(productWarrantyDuration.valueOf(), 'years');
            const today = moment().startOf('day');

            if (futureDate.isSameOrAfter(today, 'day')) {
                this.reasonsWarranty.push('SG_IN_MANUFACTURER_WARRANTY_PERIOD');
            }
        }
    }

    private selectDefaultWarrantyReason(): void {
        if (this.reasonsWarranty.length === 1) {
            this.warrantyForm.controls['reason'].setValue(this.reasonsWarranty[0]);
            this.selectedWarrantyReason = this.warrantyForm.controls['reason'].value;
            this.calculateEligibleOperatingMode(this.warrantyForm.controls['warranty'].value.key, this.warrantyForm.controls['reason'].value);
        } else if (this.reasonsWarranty.length > 1) {
            this.warrantyForm.controls['reason'].setValue(this.reasonsWarranty[0]);
            this.selectedWarrantyReason = this.warrantyForm.controls['reason'].value;
            this.calculateEligibleOperatingMode(this.warrantyForm.controls['warranty'].value.key, this.reasonsWarranty[0]);
            this.getWarrantyReasonDetails();
        } else {
            // We get the claim's warranty ONLY IF it matches with the selected warranty
            const selectedWarranty = this.warrantyForm.controls['warranty'].value.key;
            const claimWarranty = this.folder.newWarranty.warranty;
            if (claimWarranty === selectedWarranty) {
                const claimWarrantyReason = this.folder.newWarranty.warrantyReason;
                this.reasonsWarranty = [claimWarrantyReason];
                this.warrantyForm.controls['reason'].setValue(claimWarrantyReason);
                this.selectedWarrantyReason = this.warrantyForm.controls['reason'].value;
                this.calculateEligibleOperatingMode(selectedWarranty, claimWarrantyReason);
            }
        }

        // TODO urgent hotfix, all this logic should be moved to the backend
        const selectedWarranty = this.warrantyForm.controls['warranty'].value.key;
        const claimWarranty = this.folder.newWarranty.warranty;
        if (!this.reasonsWarranty.includes(this.folder.newWarranty.warrantyReason) && claimWarranty === selectedWarranty) {
            this.reasonsWarranty.push(this.folder.newWarranty.warrantyReason);
        }
    }

    private calculateEligibleOperatingMode(warranty: RegimeWarrantyEnum, selectedReason: string): void {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        ruleEvaluationContext.warrantyCode = warranty;
        ruleEvaluationContext.warrantyReasonEnum = selectedReason;
        this.backOfficeService.getEligibleOperatingMode(ruleEvaluationContext).pipe(
            finalize(
                () => {
                    this.store$.dispatch(new StopLoading());
                }
            )
        ).subscribe(data => {
            data = this.hideModOP(data);
            this.operatingModeList = data.map(el => {
                return {
                    code: el.modOp,
                    label: this.getModOpLabel(el)
                };
            });
            this.selectDefaultOperatingMode();
        }, () => {
            this.operatingModeList = [];
        });
    }

    private getModOpLabel(el: ModOpDetails): string {
        return this.modeOpConfigList.filter(e => e.code === el.modOp).length > 0 ?
            this.modeOpConfigList.filter(e => e.code === el.modOp)[0].label : null;
    }

    private selectDefaultOperatingMode(): void {
         if (this.operatingModeList.length === 1) {
            this.warrantyForm.controls['operatingMode'].setValue(this.operatingModeList[0]);
        } else {
            this.warrantyForm.controls['operatingMode'].setValue(null);
        }
    }

    hideModOP(data: ModOpDetails[]): ModOpDetails[] {
        // hideSelfCareOnlyModOP
        return data.filter(e => !e.forSelfCareOnly);
    }

    onSubmit(): void {
        this.loadingScreenService.startLoading();
        if (!!this.modOpCommentForm.get('comment').value && this.captureCustomerModOpChoice) {
            this.addModOpComment();
            return;
        }
        this.emitWorkFlowVariables();
    }

    private emitWorkFlowVariables(): void {
        this.store$.dispatch(new StartLoading());
        this.inputMap.emit({
            warrantyCode: this.warrantyForm.controls['warranty'].value.key,
            warrantyReason: this.warrantyForm.controls['reason'].value,
            modOp: this.warrantyForm.value.operatingMode.code,
            customerModOpChoice: this.warrantyForm.value.customerChoice
        });
    }

    private getWarrantyReasonDetails(): void {
        this.backOfficeService.getWarrantyReasonDetails(this.buildRuleEvaluationContext()).subscribe(data => {
            this.captureCustomerModOpChoice = data.captureClientModOpChoice;
            this.hasDiagnosticExpert = !!data.diagnosticExpert && !!data.diagnosticExpert.method;
        });
    }

    private buildRuleEvaluationContext() {
        const ruleEvaluationContext = RuleEvaluationContext.fromFolder(this.folder);
        ruleEvaluationContext.warrantyCode = this.warrantyForm.controls['warranty'].value.key;
        ruleEvaluationContext.warrantyReasonEnum = this.warrantyForm.controls['reason'].value;
        return ruleEvaluationContext;
    }

    checkCustomerChoiceCaptured(): boolean {
        if (!this.captureCustomerModOpChoice) {
            this.warrantyForm.removeControl('customerChoice');
        } else {
            if (!this.warrantyForm.get('customerChoice') && this.captureCustomerModOpChoice && this.hasCustomerChoices()) {
                this.warrantyForm.addControl('customerChoice', new FormControl(null, [Validators.required]));
            }
        }
        return this.captureCustomerModOpChoice;
    }

    addModOpComment(): void {
        this.store$.dispatch(new StartLoading());
        const folderUpdateRequest: IFolderUpdateRequest = {
            agentComment: {
                content: this.modOpCommentForm.get('comment').value,
                type: CommentType.AGENT_COMMENT_CUSTOMER_CHOICE_MODOP
            }
        };
        this.folderService.updateFolder(this.folder.id, folderUpdateRequest).then(folder => {
            this.emitWorkFlowVariables();
            this.folderSubjectService.folderLoaded(folder);
        }).catch(reason => {
            console.log('ERROR Update Folder with id :' + this.folder.id);
        });
    }

    private initCustomerOperatingModeChoices(): void {
        const organizationCode = this.folder.organization.code;
        if (['LM_PL', 'LM_RU'].includes(organizationCode)) {
            this.customerOperatingModeChoices.set(organizationCode, Object.values(CustomerChoiceEnum));
        } else {
            this.captureCustomerModOpChoice = false;
        }
    }

    hasCustomerChoices(): boolean {
        return this.customerOperatingModeChoices.has(this.folder.organization.code);
    }

    private getCodeLabels() {
        this.anotherSubscription = this.codeToLabelService.getCodeLabels(ConfigCodeMapsEnum.MOD_OP).subscribe(
            (response: CodeLabel[]) => {
                this.modeOpConfigList = response;
            }
        );
        this.anotherSubscription = this.codeToLabelService.getCodeLabels(ConfigCodeMapsEnum.WARRANTY_REASON_HG).subscribe();
        this.anotherSubscription = this.codeToLabelService.getCodeLabels(ConfigCodesEnum.WARRANTY_REASON_SG).subscribe();
    }
}
