<div *ngIf="!!data.externalReferences">
    <h2 mat-dialog-title>
        {{ 'FOLDER.EXTERNAL_REFERENCES.OTHER' | translate }}
    </h2>
    <mat-dialog-content>
        <div *ngFor="let reference of data.externalReferences  | keyvalue ;let i=index">
            <ng-container *ngIf="editIndex !== i; else: editMode">
                <strong>{{ ('FOLDER.EXTERNAL_REFERENCES.' + reference.key)| translate }} :</strong>
                {{ reference.value }}
                <button ejs-button *ngIf="(hasScope(Scopes.UPDATE_EXTERNAL_REFERENCE) | async)"
                        (click)="editReference(i, reference.value)" cssClass="borderless-button">
                    <span class="e-icons e-edit"></span>
                </button>
            </ng-container>
            <ng-template #editMode>
                <strong>{{ ('FOLDER.EXTERNAL_REFERENCES.' + reference.key) | translate }} :</strong>
                <div class="edit-container">
                    <input [(ngModel)]="reference.value" class="input" (ngModelChange)="onValueChange($event)"/>

                    <button ejs-button (click)="saveReference(reference.key, reference.value)" [disabled]="isDisabled"
                            cssClass="borderless-button">
                        <span class="e-icons icon-check e-check"></span>
                    </button>

                    <button ejs-button (click)="cancelEdit()" cssClass="borderless-button">
                        <span class="e-icons icon-close e-close"></span>
                    </button>
                </div>
            </ng-template>
        </div>
    </mat-dialog-content>
</div>

