<div class="page-layout blank p-24" fxLayout="column" fxLayoutAlign="start center" fusePerfectScrollbar>
    <div fxLayout="row" class="w-100-p mb-24" fxLayoutAlign="space-between center">
        <div>
            <h1 class="text-bold">{{ 'NAV.CONFIGURATIONS.DASHBOARD.V2' | translate }}</h1>
            <h4 class="mt-0 secondary-text">{{ 'NAV.CONFIGURATIONS.HEADER' | translate }}</h4>
        </div>

        <div fxLayoutAlign="end end" fxLayoutGap="10px">
            <button type="button" mat-stroked-button (click)="create()"
                    class="aster-btn_border">
                {{ 'CODE_LABEL_CONFIG.LIST.CREATE_BUTTON' | translate }}
            </button>
        </div>
    </div>

    <ejs-grid #dashboardGridComponent [dataSource]="dashboards" [allowPaging]="true" [pageSettings]="pageSettings"
              [allowFiltering]="true">
        <e-columns>
            <e-column field="dashboard.dashboardName" headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_DASHBOARD_NAME' | translate}}"></e-column>
            <e-column field="user" headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_USER' | translate}}"></e-column>
            <e-column field="roles" headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_ROLES' | translate}}"></e-column>
            <e-column field="site" headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_SITE' | translate}}"></e-column>
            <e-column field="businessLink" [visible]="!isSAVContext"
                      headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_BUSINESS_LINK' | translate}}"></e-column>
            <e-column headerText="{{'CODE_LABEL_CONFIG.LIST.GRID_HEADER_ACTIONS' | translate}}"
                      [allowFiltering]="false" [width]="115">
                <ng-template #filterTemplate>
                    <span></span>
                </ng-template>
                <ng-template #template let-data>
                    <button type="button" mat-icon-button class="aster_accent" (click)="edit(data)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" mat-icon-button color="warn" (click)="delete(data)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-template>
            </e-column>
        </e-columns>
    </ejs-grid>
</div>

