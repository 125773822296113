<mat-card-content *ngIf="!!checkAndDiagForm">
    <form [formGroup]="checkAndDiagForm" fxLayout="column" fxLayoutAlign="start" (ngSubmit)="onSubmit()">
        <div fxLayout="row" fxLayoutAlign="start center">
            <app-user-task-header [instructionUserTask]="instructionUserTask"
                                  [claim]="folder"></app-user-task-header>
        </div>
        <fieldset>
            <legend class="h3 title-fieldset">{{'COMPONENT.BREAKDOWN' | translate}}</legend>
            <ng-container>
                <mat-radio-group formControlName="breakDownStatus" class="ml-20" labelPosition="after" fxLayout="column"
                                 fxLayoutAlign="start stretch" fxLayoutGap="10px">
                    <mat-radio-button [id]="button.status" class="full-width-radio"
                                      *ngFor="let button of breakDownButtons"
                                      [value]="button.status">
                        <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div fxFlex>
                                <div>{{ button.value | translate }}</div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
        </fieldset>
        <ng-container *ngIf="checkAndDiagForm.value.breakDownStatus === 'BREAKDOWN_CONFIRMED'">
            <ng-container *ngIf="warrantyCode === 'SG'">
                <fieldset>
                    <legend
                        class="h3 title-fieldset">{{'COMPONENT.WARRANTY' | translate}}</legend>
                    <ng-container>
                        <mat-radio-group formControlName="warrantyStatus" class="ml-20"
                                         labelPosition="after"
                                         fxLayout="column"
                                         fxLayoutAlign="start stretch" fxLayoutGap="10px">
                            <mat-radio-button [id]="button.status" class="full-width-radio"
                                              *ngFor="let button of warrantyButtons"
                                              [value]="button.status">
                                <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                    <div fxFlex>
                                        <div>{{ button.value | translate }}</div>
                                    </div>
                                </div>
                            </mat-radio-button>
                        </mat-radio-group>

                        <div *ngIf="checkAndDiagForm.value.warrantyStatus === 'WARRANTY_DISCLAIMER'" class="ml-20">
                            <mat-form-field class="py-4 mt-16" fxFlex="50" appearance="outline">
                                <mat-label>{{'COMPONENT.WARRANTY_DISCLAIMER_REASON' | translate}}</mat-label>
                                <mat-select formControlName="warrantyDisclaimerOption">
                                    <mat-option *ngFor="let option of warrantyDisclaimerOptions"
                                                [value]="option">
                                        {{('COMPONENT.' + option) |translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </fieldset>
            </ng-container>

            <fieldset>
                <legend class="h3 title-fieldset">{{'COMPONENT.PRODUCT' | translate}}</legend>
                <ng-container>
                    <mat-radio-group formControlName="productStatus" class="ml-20" labelPosition="after"
                                     fxLayout="column"
                                     fxLayoutAlign="start stretch" fxLayoutGap="10px">
                        <ng-container *ngFor="let button of productButtons">
                            <mat-radio-button [id]="button.status" class="full-width-radio"
                                              *ngIf="!button.hidden"
                                              [value]="button.status">
                                <div class="my-16" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                                    <div fxFlex>
                                        <div>{{ button.value | translate }}</div>
                                    </div>
                                </div>
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </ng-container>
            </fieldset>
        </ng-container>

        <app-comment-area [commentForm]="checkAndDiagForm.get('comment')"></app-comment-area>

        <div fxLayoutAlign="end center" class="mt-24">
            <button type="submit" mat-stroked-button [disabled]="checkAndDiagForm.invalid || isLoading"
                    [ngClass]="checkAndDiagForm.invalid ? 'aster_btn_disabled' : 'aster_btn'">
                <span class="button-content">
                    {{'BUTTON.VALIDATE' | translate}}
                    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
                </span>
            </button>
        </div>
    </form>
</mat-card-content>