import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../../../../models/product.model';
import {AppState} from '../../../../store/app.state';
import {select, Store} from '@ngrx/store';
import {currentUser} from '../../../../store/user/user.selectors';
import {finalize, take} from 'rxjs/operators';
import {BackOfficeService} from '../../../../shared/services/back-office.service';
import {RuleEvaluationContext} from '../../../../models/rules/RuleEvaluationContext';

@Component({
    selector: 'app-product-warranty',
    templateUrl: './product-warranty.component.html',
    styleUrls: ['./product-warranty.component.scss']
})
export class ProductWarrantyComponent implements OnInit {

    @Input() product: Product;

    isLoading = true;

    // manufacturer warranty
    manufacturerWarrantyDuration: Number;
    manufacturerWarrantyUnit: string;

    // distributor warranty
    distributorWarrantyDuration: Number;
    distributorWarrantyUnit: string;

    constructor(private store$: Store<AppState>,
                private backOfficeService: BackOfficeService) {
    }

    ngOnInit() {
        this.store$.pipe(
            select(currentUser),
            take(1)
        ).subscribe(user => {
            if (user.organizationCode.startsWith('LM_') || user.organizationCode === 'BC_IT') {
                // workaround for ADEO and BC_IT
                this.manufacturerWarrantyDuration = this.product.warranty?.duration;
                this.manufacturerWarrantyUnit = this.product.warranty?.durationUnitEnum;
                this.distributorWarrantyDuration = null;
                this.distributorWarrantyUnit = null;
                this.isLoading = false;
            } else {
                this.backOfficeService.getProductWarrantyRule(RuleEvaluationContext.fromProduct(this.product, user))
                    .pipe(finalize(() => this.isLoading = false))
                    .subscribe(productWarranty => {
                        // from contract
                        this.manufacturerWarrantyDuration = productWarranty?.warrantyReasonDetails?.defaultDuration?.duration;
                        this.manufacturerWarrantyUnit = productWarranty?.warrantyReasonDetails?.defaultDuration?.unit;
                        // from product
                        this.distributorWarrantyDuration = this.product.warranty?.duration;
                        this.distributorWarrantyUnit = this.product.warranty?.durationUnitEnum;
                        this.isLoading = false;
                    });
            }
        });
    }
}
