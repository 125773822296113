import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Unsubscriber} from '../../../../unsubscriber';
import {AppState} from '../../../../store/app.state';
import {ConfigV2Service} from '../../../../shared/services/config-v2.service';
import {CodeLabel} from '../../../../models/element.model';
import {ConfigCodesEnum} from '../../../../models/codeLabelObject.model';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {getModOpIconNameByCode} from '../../../../shared/data/static.config';
import {switchMap} from 'rxjs/operators';
import {ConfigurationUtils} from '../../../../store/configuration/configuration.utils';
import {CodeToLabelService} from '../../../../../@fuse/services/code-to-label.service';

@Component({
    selector: 'operating-mode-selector',
    templateUrl: './operating-mode-selector.component.html'
})
export class OperatingModeSelectorComponent extends Unsubscriber implements OnInit {

    @Input() selectedOperatingMode: string;
    @Output() selectedOperatingModeChange = new EventEmitter<string>();

    operatingModes: CodeLabel[] = [];
    firstTimeLoading = true;

    constructor(private codeToLabelService: CodeToLabelService) {
        super();
    }

    ngOnInit() {
        if (this.selectedOperatingMode) {
            this.loadModOps();
        }
    }

    loadModOps() {
        if (this.firstTimeLoading) {
            this.triggerListeningForCacheClear();
            this.firstTimeLoading = false;
        }
    }

    private triggerListeningForCacheClear() {
        this.anotherSubscription = this.codeToLabelService.cache$.pipe(
            switchMap(() => this.codeToLabelService.getCodeLabels(ConfigCodesEnum.MOD_OP))
        ).subscribe(modOps => {
            this.operatingModes = modOps;
        });
    }


    updateSelectedOperatingMode(value) {
        this.selectedOperatingMode = value;
        this.selectedOperatingModeChange.emit(this.selectedOperatingMode);
    }

    getIconName = (operatingMode: string) => getModOpIconNameByCode(operatingMode);
    labelFromCode = (operatingMode: string) => ConfigurationUtils.labelFromCode(operatingMode, this.operatingModes);
}
