<mat-toolbar class="p-0 mat-elevation-z1 nav-color">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="isMobile ;else toolbar">
                <button mat-icon-button class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                    <mat-icon class="white">menu</mat-icon>
                </button>

                <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
                <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                    <div class="logo ml-16">
                        <img class="aster-logo p-8" src="{{pageLogoPath}}">
                    </div>
                </div>
            </ng-container>

            <ng-template #toolbar>
                <img class="aster-logo p-8" src="{{pageLogoPath}}">
                <ng-container *ngIf="roleIsRepairerAuthorized()">
                    <!-- displayed the navigation list on the left -->
                    <div class="navbar-content">
                        <fuse-navigation class="material2" layout="horizontal"
                                         [inRightPosition]="false"></fuse-navigation>
                    </div>
                    <ng-container *ngIf="isReparationContext$ | async; else IS_NOT_REPARATION_CONTEXT">
                        <a mat-stroked-button routerLink="/folder/repairer_create"
                           class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30 nav-bar-button_new_folder ml-4">
                            <mat-icon>create_new_folder</mat-icon>
                            <span class="ml-16 nav-bar-hide_text">{{'NAV.FOLDER.CREATE'| translate}}</span>
                        </a>
                    </ng-container>
                    <ng-template #IS_NOT_REPARATION_CONTEXT>
                        <ng-container *ngIf="(hasScope(Scopes.CREATE_FOLDER) | async) && (isUserAssignedToSite$ | async)">
                            <a mat-stroked-button routerLink="/folder/create" routerLinkActive="active"
                               class="py-12 px-24 mb-8 mr-8 font-weight-900 font-size-30 nav-bar-button_new_folder ml-4">
                                <mat-icon>create_new_folder</mat-icon>
                                <span class="ml-16 nav-bar-hide_text">{{'NAV.FOLDER.CREATE'| translate}}</span>
                            </a>
                        </ng-container>
                    </ng-template>
                </ng-container>
            </ng-template>
        </div>

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="roleIsRepairerAuthorized()">
                <ng-container
                    *ngIf="displayCampaignField$ | async">
                    <mat-form-field class="w-180 field-campaign" appearance="outline">
                        <mat-label>{{ 'TOOLBAR.MENU.CAMPAIGN.LABEL' | translate }}</mat-label>
                        <mat-select [(ngModel)]="currentBusinessLink"
                                    (selectionChange)="filterListFolderByCampaign($event.value)">
                            <mat-option *ngFor="let campaign of (businessLinks$ | async)"
                                        [value]="campaign.code">{{campaign.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
                <div class="toolbar-separator"></div>
                <ng-container>
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="initFavoritesList()">
                        <mat-icon>star</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="sup-menu notifications">

                        <div *ngIf="!!folderSearchResponse"
                             [ngClass]=" favoritesFoldersList.length>8?'main-panel-favorites scroll-vertical':'main-panel-favorites'"
                             (scroll)="onScrollFavoritesFolders($event)">
                            <ng-container
                                *ngIf="favoritesFoldersList && favoritesFoldersList.length>0;else EMPTY_FAVORITES_FOLDER">
                                <button mat-menu-item *ngFor="let folder of favoritesFoldersList" class="m-4">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div>
                                            <div (click)="openFolder(folder.id)" class="title-folder-favorite">
                                        <span
                                            class="text-underline incremental-number">  {{folder.incrementalNumber }}</span>
                                                <span> - </span>
                                                {{   folder.customerFirstName ? folder.customerFirstName : 'x' }}
                                                {{folder.customerLastName ? folder.customerLastName : 'x'}}
                                            </div>
                                        </div>
                                        <div class="w-108-p">
                                    <span class="cursor-pointer" *ngIf="!!folder.newWarranty">
                                        <mat-icon class="w-20 h-20 f-s-18 mr-8"
                                                  [ngClass]="folder.newWarranty?.icon.class">
                                          {{folder.newWarranty.icon.code}}
                                        </mat-icon>
                                    </span>
                                            <span *ngIf="!!folder.operationMode" class="cursor-pointer">
                                        <mat-icon class="w-20 h-20 mr-8" svgIcon="{{folder.operationMode?.icon}}">
                                        </mat-icon>
                                    </span>
                                            <span class="cursor-pointer">
                                        <mat-icon *ngIf="folder.closed" class="w-20 h-20 f-s-18 mr-8">lock</mat-icon>
                                    </span>
                                        </div>
                                    </div>
                                </button>
                            </ng-container>
                            <ng-template #EMPTY_FAVORITES_FOLDER>
                                <div class="empty-favorites-folders">
                                    {{'TOOLBAR.MENU.EMPTY_FAVORITES_FOLDERS' | translate}}
                                </div>
                            </ng-template>
                        </div>

                    </mat-menu>
                </ng-container>
                <search-bar></search-bar>
                <div class="toolbar-separator"></div>
                <!-- displayed the navigation list on the right -->
                <div class="navbar-content">
                    <fuse-navigation class="material2" layout="horizontal"
                                     [inRightPosition]="true"></fuse-navigation>
                </div>

                <button *ngIf="hasScope(Scopes.NOTIFICATIONS) | async" mat-button class="notifications"
                        [matMenuTriggerFor]="pushNotificationMenu">
                <span fxLayout="row" fxLayoutAlign="center center">
                          <mat-icon class="s-24" matBadgePosition="above after"
                                    [matBadgeHidden]="totalElementNotification === 0"
                                    [matBadge]="totalElementNotification">notifications</mat-icon>
                </span>
                </button>
                <mat-menu #pushNotificationMenu="matMenu" class="sup-menu notifications">
                    <ng-container
                        *ngIf="!!pushNotifications && pushNotifications.length>0;else EMPTY_NOTIFICATION">
                        <mat-card class="mat-elevation-aster">
                            <mat-card-content>
                                    <span class="h2 ml-8  pt-4">
                                    {{'NAV.PUSH.NOTIFICATION.TITLE' | translate}}</span>
                                <div fxLayout="column" fxLayoutAlign="start end">

                                    <a routerLink="notifications">
                                        {{'DETAILS.NOTIFICATIONS.TITLE' | translate}}</a>
                                </div>
                            </mat-card-content>
                        </mat-card>

                        <div
                            [ngClass]=" pushNotifications.length>=8?'main-panel scroll-vertical':'main-panel'"
                            (scroll)="onScrollPushNotifications($event)"
                            fxLayout="row column" fxLayout.xs="column" fxLayoutAlign="start start">
                            <ng-container *ngFor="let pushNotification of pushNotifications">

                                <div fxLayout="row" fxLayoutAlign="start start"
                                     (click)="goToFolderDetails(pushNotification)"
                                     class="info notifications-zone">
                                    <ng-container [ngSwitch]="pushNotification.type">
                                        <div *ngSwitchCase="'ERROR'" fxFlex="10" class="mr-12 pl-8">
                                            <mat-icon class="red-icon">error</mat-icon>
                                        </div>
                                        <div *ngSwitchCase="'INFO'" fxFlex="10" class="mr-12 pl-8">
                                            <mat-icon class="green-icon">info</mat-icon>
                                        </div>
                                    </ng-container>
                                    <div fxFlex="80" class="pr-8">
                                        <div fxLayout="column" fxLayoutAlign="start start">
                                            <div>
                                                <ng-container [ngSwitch]="pushNotification.type">
                                                    <div *ngSwitchCase="'ERROR'">
                                                        <div class="font-size-13 notif-description">
                                                            {{translationMessageNotification(pushNotification.extraInfo['ERROR'], pushNotification.type)}}
                                                        </div>
                                                    </div>
                                                    <div *ngSwitchCase="'INFO'">
                                                        <div class="font-size-13 notif-description">
                                                            {{translationMessageNotification(pushNotification.extraInfo['INFO'], pushNotification.type)}}
                                                        </div>
                                                    </div>
                                                    <div *ngSwitchCase="'ACTION'">
                                                        <div class="font-size-13 notif-description">
                                                            {{translationMessageNotification(pushNotification.extraInfo['ACTION'], pushNotification.type)}}
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <div class="secondary-text">
                                                    {{pushNotification.creationDate | moment: ('GENERAL.DATE.PUSH.NOTIFICATION.CREATION_DATE' | translate) : currentLanguage}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="pushNotification.status == 'UNREAD'" fxFlex="10">
                                        <div class="NotificationUnread"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #EMPTY_NOTIFICATION>
                        <mat-card class="mat-elevation-aster">
                            <mat-card-title class="mat-card-style">
                                <a routerLink="notifications">
                                    {{'DETAILS.NOTIFICATIONS.TITLE' | translate}}</a>
                            </mat-card-title>

                            <mat-card-content>
                                <div class="empty-notifications-folders">
                                    {{'TOOLBAR.MENU.EMPTY_NOTIFICATION' | translate}}
                                </div>
                            </mat-card-content>
                        </mat-card>

                    </ng-template>
                </mat-menu>
            </ng-container>
            <button mat-button
                    class="language-button pl-0 pr-0"
                    [matMenuTriggerFor]="languageMenu">
                <span fxLayout="row" fxLayoutAlign="center center">
                    <img class="lang-flag mr-8 ml-12 w-20" [src]="'assets/icons/flags/'+selectedLanguage.flag">
                    <mat-icon class="s-16 grey-50-fg">keyboard_arrow_down</mat-icon>
                </span>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="lang-flag w-24" [src]="'assets/icons/flags/'+lang.flag">
                    </span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>
            <ng-container *ngIf="roleIsRepairerAuthorized()">

                <button mat-button [matMenuTriggerFor]="userMenu"
                        class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/admin.png">
                        <span class="username mr-12" fxHide fxShow.gt-sm>
                            {{fullName$ | async}}
                        </span>
                        <span *ngIf="currentOrganizationLabel$ | async as organizationLabel">
                            ({{organizationLabel | translate}})
                        </span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="sub-menu">
                    <button mat-menu-item [matMenuTriggerFor]="organization">
                        <mat-icon>domain</mat-icon>
                        <span>{{'TOOLBAR.MENU.ORGANIZATION_TITLE' | translate}}</span>
                    </button>
                    <mat-menu #organization="matMenu" class="sup-menu" xPosition="before">
                        <ng-container *ngIf="organizationAttributes.length > 0">
                            <lib-organizations-menu [organizations]="organizationAttributes"
                                                    (currentOrganizationChange)="handleOrganizationChanged($event)"
                                                    [currentOrganization]="currentOrganizationCode">
                            </lib-organizations-menu>
                        </ng-container>
                    </mat-menu>
                    <button mat-menu-item [matMenuTriggerFor]="context"
                            *ngIf="availableContexts?.length>1">
                        <mat-icon>library_books</mat-icon>
                        <span>{{"TOOLBAR.MENU.CONTEXT_TITLE" | translate}}</span>
                    </button>
                    <mat-menu #context="matMenu" class="sup-menu" xPosition="before">

                        <ng-container *ngIf="hasAvailableContexts()">
                            <ng-container *ngFor="let context of availableContexts">
                                <button mat-menu-item aria-checked="true" class="w-144 context-button"
                                        (click)="switchContext(context)">
                                    <ng-container>
                                    <span
                                        [ngClass]="context.name===currentContext ? 'active-context': 'context-not-selected'">{{'TOOLBAR.MENU.CONTEXT.' + context.name | translate}}</span>
                                    </ng-container>
                                </button>
                            </ng-container>
                        </ng-container>
                    </mat-menu>

                    <ng-container *ngIf="isLMorNRorEDTechAdmin$ | async">
                        <button mat-menu-item class="" (click)="navigateToApiMetrics()">
                            <mat-icon> pie_chart</mat-icon>
                            <span>{{'TOOLBAR.MENU.API_METRICS' | translate}}</span>
                        </button>
                    </ng-container>
                    <button mat-menu-item class="" (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>{{'TOOLBAR.MENU.LOGOUT_TITLE' | translate}}</span>
                    </button>

                </mat-menu>
            </ng-container>
        </div>
    </div>
</mat-toolbar>
