<div  fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span class="strong-title" class="notranslate">{{'MODAL.QUOTATION.TYPE' | translate}}</span>
        <span class="strong-title" class="notranslate">{{'MODAL.QUOTATION.CODE' | translate}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span>{{'MODAL.QUOTATION.TYPE.' + selectedSparePart.type | translate}}</span>
        <span class="translate">{{selectedSparePart.code}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span class="strong-title">{{'MODAL.QUOTATION.DISCOUNT' | translate}}</span>
        <span class="strong-title">{{'MODAL.QUOTATION.DISCOUNT_REASON' | translate}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span>{{selectedSparePart.discount}}</span>
        <span>{{selectedSparePart.discountReason ? selectedSparePart.discountReason : '-'}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span class="strong-title">{{'MODAL.QUOTATION.TVA' | translate}}</span>
        <span class="strong-title">{{'MODAL.QUOTATION.TOTAL_PRICE' | translate}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between start" class="my-12">
        <span>{{selectedSparePart.vat?.rate}}</span>
        <span> {{selectedSparePart.totalLinePrice | formatAmount}}</span>
    </div>
</div>