import {ISymptom} from './symptom.model';

export interface ICodeLabelObject {
    id: string;
    organizationCode?: string;
    code: string;
    label: string;
    parent?: string;
}

export class CodeLabelObject implements ICodeLabelObject {
    id: string;
    organizationCode?: string;
    code: string;
    label: string;
    parent?: string;

    constructor(obj: ISymptom) {
        Object.assign(this, obj);
    }
}

export enum ConfigCodesEnum {
    SYMPTOMS = 'SYMPTOMS',
    SYMPTOMS_GROUP = 'SYMPTOMS_GROUP',
    FAMILY = 'FAMILY',
    SUB_FAMILY = 'SUB_FAMILY',
    TYPE = 'TYPE',
    SUB_TYPE = 'SUB_TYPE',
    SEGMENT = 'SEGMENT',
    BRANDS = 'BRANDS',
    DIAGNOSTIC = 'DIAGNOSTIC',
    CARRIER = 'CARRIER',
    SPARE_PARTS = 'SPARE_PARTS',
    SERIAL_NUMBER = 'SERIAL_NUMBER',
    SKILLS = 'SKILLS',
    SERVICES = 'SERVICES',
    ACCESSORY = 'ACCESSORY',
    TECHNICIANS = 'TECHNICIANS',
    CONDITIONS = 'CONDITIONS',
    REPAIR = 'REPAIR',
    REPAIRER = 'REPAIRER',
    WARRANTY_REASON_SG = 'WARRANTY_REASON_SG',
    WARRANTY_REASON_HG = 'WARRANTY_REASON_HG',
    MOD_OP = 'MOD_OP',
    IRIS_DEFECTS = 'IRIS_DEFECTS',
    IRIS_SYMPTOMS = 'IRIS_SYMPTOMS',
    IRIS_SECTIONS = 'IRIS_SECTIONS',
    SPARE_PART_NOMENCLATURE_RAY = 'SPARE_PART_NOMENCLATURE_RAY',
    SPARE_PART_NOMENCLATURE_SUB_RAY = 'SPARE_PART_NOMENCLATURE_SUB_RAY',
    SPARE_PART_NOMENCLATURE_TYPE = 'SPARE_PART_NOMENCLATURE_TYPE',
    SPARE_PART_NOMENCLATURE_SUB_TYPE = 'SPARE_PART_NOMENCLATURE_SUB_TYPE',
    SPARE_PART_NOMENCLATURE_SEGMENT = 'SPARE_PART_NOMENCLATURE_SEGMENT',
    SPARE_PART_LOCATION = 'SPARE_PART_LOCATION',
    SPARE_PART_ALLEY = 'SPARE_PART_ALLEY',
    SPARE_PART_SIDE = 'SPARE_PART_SIDE',
    SPARE_PART_LEVEL = 'SPARE_PART_LEVEL',
    SPARE_PART_MEASURE_UNIT = 'SPARE_PART_MEASURE_UNIT',
    SPARE_PART_CONDITIONING = 'SPARE_PART_CONDITIONING',
    ORIGINATOR = 'ORIGINATOR',
    REPAIR_PACKAGE = 'REPAIR_PACKAGE',
    SUPPLIER_CONTACTS = 'SUPPLIER_CONTACTS',
    ATTACHMENT_TYPE= 'ATTACHMENT_TYPE',
}
