import * as fr from 'assets/i18n/fr.json';
import * as en from 'assets/i18n/en.json';
import * as it from 'assets/i18n/it.json';
import * as es from 'assets/i18n/es.json';
import * as nl from 'assets/i18n/nl.json';
import * as pl from 'assets/i18n/pl.json';
import * as ru from 'assets/i18n/ru.json';
import * as pt from 'assets/i18n/pt.json';
import * as el from 'assets/i18n/el.json';

export class Constants {
    static readonly ORGANIZATION_CODE = 'organizationCode';
    static readonly PRODUCT_CODE = 'productCode';
    static readonly WITH_DOCUMENTS = 'withDocuments';
    static readonly WITH_SELLING_PRICE = 'withSellingPrice';
    static readonly WITH_PURCHASE_PRICE = 'withPurchasePrice';
    static readonly PAGE = 'page';
    static readonly SIZE = 'size';

    static readonly ESCAPE = 'Escape';
    static readonly ESC = 'Esc';
    static readonly BUTTON_CONFIRM = 'BUTTON.CONFIRM';
    static readonly BUTTON_CANCEL = 'BUTTON.CANCEL';
    static readonly UNKNOWN_SUPPLIER = 'UNKNOWN_SUPPLIER';
    static readonly PLATFORM = 'platform';

    static readonly LANGUAGES = [
        {
            id: 'fr',
            title: 'French',
            flag: 'fr.svg'
        },
        {
            id: 'en',
            title: 'English',
            flag: 'en.svg'
        },
        {
            id: 'es',
            title: 'Espagnol',
            flag: 'es.svg',
        },
        {
            id: 'pl',
            title: 'Poland',
            flag: 'pl.svg',
        },
        {
            id: 'it',
            title: 'Italian',
            flag: 'it.svg'
        },
        {
            id: 'nl',
            title: 'Netherlands',
            flag: 'nl.svg'
        },
        {
            id: 'pt',
            title: 'Portugal',
            flag: 'pt.svg'
        },
        {
            id: 'ru',
            title: 'Russian Federation',
            flag: 'ru.svg'
        },
        {
            id: 'el',
            title: 'Greece',
            flag: 'gr.svg'
        }
    ];

    static L10N_LANGUAGES = {
        fr: fr['syncfusion'],
        en: en['syncfusion'],
        it: it['syncfusion'],
        es: es['syncfusion'],
        nl: nl['syncfusion'],
        pl: pl['syncfusion'],
        ru: ru['syncfusion'],
        pt: pt['syncfusion'],
        el: el['syncfusion']
    };
}
