import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Quotation} from '../../../../models/quotation.model';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Folder} from '../../../../models/folder.model';
import {FolderSubjectService} from '../../folder-subject.service';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../store/app.state';
import {currentLanguage} from '../../../../store/organization/organization.selectors';
import {Unsubscriber} from '../../../../unsubscriber';
import {TranslateService} from '@ngx-translate/core';
import {OperationModeEnum} from '../../../../models/enums/operationMode.enum';
import {ReportLine} from '../../../../models/reparationReport/report-line.model';

@Component({
    selector: 'app-folder-quotation-report-card',
    templateUrl: 'folder-quotation-report-card.component.html',
    styleUrls: ['folder-quotation-report-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ])
    ]
})
export class FolderQuotationReportCardComponent extends Unsubscriber implements OnInit, OnDestroy {

    folder: Folder;
    displayedColumns: string[] = ['code', 'label', 'type', 'totalPrice'];
    expandedElement: Quotation | null;

    reportLineColumns: string[] = ['code', 'label', 'type', 'totalPrice'];
    reportLineDataTable = new MatTableDataSource<ReportLine>();

    irisColumns: string[] = ['symptom', 'condition', 'repair'];
    irisDataTable = new MatTableDataSource<any>();

    currentLanguage$: Observable<string>;
    public externalReparation: boolean;

    constructor(private store$: Store<AppState>,
                private folderSubjectService: FolderSubjectService,
                private translateService: TranslateService) {
        super();
    }

    ngOnInit(): void {
        this.currentLanguage$ = this.store$.pipe(select(currentLanguage));
        this.anotherSubscription = this.folderSubjectService.folder$.subscribe(folder => {
            this.folder = folder;
            this.externalReparation = this.isExternalReparation();
            if (!!this.folder.reparationReport) {
                this.reportLineDataTable.data = this.folder.reparationReport.reportLine;
                this.irisDataTable.data = this.folder.reparationReport.iris;
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    getQuotationTarget(target: string): string {
        if (target === 'DISTRIBUTOR') {
            return 'TARGET.DISTRIBUTOR';
        }

        if (target === 'CLIENT') {
            return 'TARGET.CLIENT';
        }
    }
    getQuotationTitle(quotation: Quotation) {
        if (quotation.target && quotation.code) {
            return quotation.code + '-' + this.translateService.instant(this.getQuotationTarget(quotation.target)) + this.translateService.instant('QUOTATION.STATUS.' + quotation.status);
        } else {
            return this.translateService.instant(this.getQuotationTarget(quotation.target)) + this.translateService.instant('QUOTATION.STATUS.' + quotation.status);
        }
    }

    private isExternalReparation() {
        return this.folder.operationMode === OperationModeEnum.SERVICE_CENTER_REPAIR;
    }

    get reparationReportTotalPrice(): number {
        return this.folder?.reparationReport?.reportLine
            .map(reportLine => Number(reportLine.totalLinePrice))
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    get currency(): string {
        const reportLines = this.folder?.reparationReport?.reportLine;
        return reportLines.length > 0 ? reportLines[0].currency : null;
    }

}
